export default {
  slug: 'kultharok',
  parent: 'theater-of-pain',
  name: 'Kul\'tharok',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Johnth' ],
  content: `
    <p>Бос весь бій стоїть на місці, танку потрібно стояти поряд, інакше кастує в нього болюче
    заклинання __Spectral Reach__.</p>

    <p>Впродовж всього бою кожні 3 сек по всіх проходять пошкодження від __Chill of the Beyond__.</p>

    <p>Два основі заклинання -- __Phantasmal Parasite__ та __Draw Soul__.</p>

    ## Phantasmal Parasite

    <p>__Phantasmal Parasite__ кидає на випадкового гравця коло і наносить пошкодження цілі всім
    всередині кола. Відходимо від інших і б’ємо боса далі.</p>

    ## Draw Soul

    <p>__Draw Soul__ виглядає як промінь в ціль, кастується кілька секунд і зрештою вириває душу
    з тіла. Поки гравець не зловить свою душу -- він не може атакувати і втрачає здоров’я (__Soulless__).
    Якщо не спіймати душу за 20 сек, то на гравця накладається контроль і він починає атакувати союзників
    (__Possession__).

    <p>Для уникнення таких проблем необхідно знайти на землі калюжу з руками (буквально так і виглядає),
    __Grasping Hands__ і під кінець касту __Draw Soul__ зайти в неї або стати позаду. Душа зазвичай вибігає
    вперед і руки її зловлять, гравцю лишається лише пройти крізь неї і “підібрати”. За це гравець також
    отримує баф __Reclaimed Soul__ на +30% пошкоджень.</p>
  `,
  aside: [
    {
      type: 'spell',
      icon: 'spell_necro_deathlyecho',
      name: 'Chill of the Beyond',
      desc: 'Пристуність **Kul\'tharok** проморожує гравців до кісток і наносить 879 пошкоджень тінню кожні 3 сек.',
      whid: 322087
    },
    {
      type: 'spell',
      icon: 'ability_warlock_haunt',
      name: 'Phantasmal Parasite',
      desc: '**Kul\'tharok** чіпляє на випадкового гравця привида, який наносить 2344 пошкоджень тінню та усім гравцям в радіусі 3.5 метрів щосекунди впродовж 10 сек.',
      whid: 319626
    },
    {
      type: 'spell',
      icon: 'spell_shadow_requiem',
      name: 'Grasping Hands',
      desc: '**Kul\'tharok** викликає примарні руки у кількох місцях. Гравці в радіусі 2 метрів від місця ураження отримують 8788 пошкоджень тінню.<br><br>Руки зв\'язують гравців та їхні душі, знерухомлюючи на 10 сек, а також наносять 3222 пошкоджень тінню щосекунди.',
      whid: 319567
    },
    {
      type: 'spell',
      icon: 'spell_necro_bwonsamdisboon',
      name: 'Draw Soul',
      desc: 'Вириває душу випадкового гравця. Накладає ефект __Soulless__ на 20 сек.',
      whid: 319521
    },
    {
      type: 'spell',
      icon: 'spell_necro_inevitableend',
      name: 'Soulless',
      desc: 'Поки душа відділена від тіла, воно отримує 2 344 пошкоджень тінню щосекунди і не здатне атакувати чи здійснювати інші дії крім пересування.',
      whid: 319539
    },
    {
      type: 'spell',
      icon: 'spell_necro_undyingrelentlessness',
      name: 'Possession',
      desc: 'Якщо гравець не зловив душу за 20 сек, він стає ворожим до інших гравців.',
      whid: 333567
    },
    {
      type: 'spell',
      icon: 'spell_necro_undyingrelentlessness',
      name: 'Reclaimed Soul',
      desc: 'Повернута душа збільшує вихідні пошкодження гравця на 30% на 30 сек.',
      whid: 319637
    },
  ],
  attachments: [
    { type: 'video', videoId: 'JBvx0rVvtGw', name: 'Аналіз М+20 від Денса (2й сезон)' },
    { type: 'video', videoId: '8PP5eIgvq44', name: 'Аналіз М+17 від Денса' },
    { type: 'post', link: '/guides/tormented-affix', name: '10+ афікс' },
  ]
}
