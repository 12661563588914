export default {
  slug: 'sylvanas-windrunner',
  parent: 'sanctum-of-domination',
  name: 'Сильвана Вітрогін',
  hint: 'Sylvanas Windrunner',
  short: 'Сильвана',
  image: '/img/sylvanas-windrunner.jpg',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Dancecaptain (Makhabunda)' ],
  attachments: [
    { type: 'video', videoId: 'uTOFi6iMKEw', name: 'Першокіл (героїк)' },
    { type: 'video', videoId: 'pBu4teoaOGA', name: 'Відеотактика (англійська)' }
  ],
  aside: [
    {
      type: 'header',
      name: 'Перша фаза'
    },
    {
      type: 'spell',
      icon: 'achievement_leader_sylvanas',
      name: 'Windrunner',
      desc: 'Бос завдає серію різних спелів за короткий проміжок часу.',
      whid: 347504
    },
    {
      type: 'spell',
      icon: 'spell_shadow_painspike',
      name: 'Withering Fire',
      desc: 'Бос випускає хвилю стріл по гравцях, яка завдає 4509 Тіньового пошкодження і залишає блід – __Barbed Arrow__, який завдає 2104 Фізичної шкоди кожні 3 секунди. Ефект бліда стакається.',
      whid: 347928
    },
    {
      type: 'spell',
      icon: 'spell_animamaw_missile',
      name: 'Desecration Shot',
      desc: 'Бос створює воїд-зону, вибух якої завдає 18034 Тіньового пошкодження гравцям всередині воїд-зони.',
      whid: 348627
    },
    {
      type: 'spell',
      icon: 'ability_throw',
      name: 'Shadow Dagger',
      namet: '(до уваги хілерів)',
      desc: 'Бос запускає в гравця кинджал, який вішає дот на 9 секунд, який завдає 4960 Тіньової шкоди кожну секунду і поширює відповідний ефект на найближчих гравців.',
      whid: 347670
    },
    {
      type: 'spell',
      icon: 'inv_belt_44',
      name: 'Domination Chains',
      namet: '(важливо)',
      desc: 'Бос запускає залп з воїд-зон, які при вибуху завдають 22543 Тіньового пошкодження гравцям в 5 ярдах і створюють ланцюги (ад) які сковують гравців завдаючи 1353 Тіньової шкоди. Цей ефект стакається, однак він знімає ефект __Barbed Arrow__.',
      whid: 349458
    },
    {
      type: 'spell',
      icon: 'spell_animamaw_nova',
      name: 'Calamity',
      namet: '(героїк,важливо)',
      desc: 'Утворені від __Domination Chains__ стріли випромінюють в 4 ярдах від себе енергію, яка завдає 82749 Тіньового пошкодження гравцю.',
      whid: 356649
    },
    {
      type: 'spell',
      icon: 'ability_argus_deathfog',
      name: 'Veil of Darkness',
      namet: '(важливо)',
      desc: 'Бос кастує під собою воїд-зону, яка завдає 12023 Тіньового пошкодження і абсорбить 51194 вхідного хілінгу, та завдає 1202 Тіньової шкоди кожні пів секунди. Окрім цього, додатково 25% вхідного хілінгу абсорбиться.',
      whid: 347704
    },
    {
      type: 'spell',
      icon: 'ability_theblackarrow',
      name: 'Wailing Arrow',
      namet: '(до уваги танків)',
      desc: 'Бос запускає залп стріл по гравцю, який завдає 12023 Тіньової шкоди і додатково завдає до 42081 Тіньової шкоди всім гравцям в залежності від дистанції між стрілами та гравцем.',
      whid: 347609
    },
    {
      type: 'spell',
      icon: 'spell_priest_mindspike',
      name: 'Ranger`s Heartseeker',
      desc: 'Бос назбирує стаки __Ranger`s Heartseeker__ кожні 5 секунд. При досяганні трьох стаків, бос може запустити __Ranger Shot__ з трійним бурстом __Ranger`s Heartseeker__ в поточну ціль. Кожен з вистрілів завдає 21041 Фізичного пошкодження і 12023 Тіньового пошкодження вішаючи дот __Banshee`s Mark__, який завдає 1503 Тіньової шкоди кожну секунду впродовж 18 секунд. Ефект доти стакається.',
      whid: 352650
    },
    {
      type: 'header',
      name: 'Перехідна фаза'
    },
    {
      type: 'spell',
      icon: 'inv_belt_44',
      name: 'Domination Chains',
      namet: '(важлио)',
      desc: 'Бос запускає залп з воїд-зон, які при вибуху завдають 22543 Тіньового пошкодження гравцям в 5 ярдах і створюють ланцюги (ад) які сковують гравців завдаючи 1353 Тіньової шкоди. Цей ефект стакається, однак він знімає ефект __Barbed Arrow__.',
      whid: 349458
    },
    {
      type: 'spell',
      icon: 'achievement_raid_torghast_sylvanaswindrunner',
      name: 'Rive',
      namet: '(важливо)',
      desc: 'Бос кидає по прямій уламки вежі Торгаст, всі гравці які попадають під траєкторію уламків утримують 180347 Фізичного пошкодження, а також 30057 Тіньового пошкодження гравцям у 4 ярдах від уламків.',
      whid: 348145
    },
    {
      type: 'spell',
      icon: 'spell_shadow_soulleech_3',
      name: 'Banshee Wail',
      desc: 'Бос здає крик на гравця, який завдає шкоду в залежності від наявного життя, наприклад 4523 Тіньового пошкодження на 100% життя до 15079 Тіньового пошкодження на низькому рівні життя. Пошкодження від абілки розповсюджується на всіх гравців у радіусі 5 ярдів від гравця, на якого здійснюється крик. Також гравець та зачеплені у радіусі отримують сайленс на 3 секунди.',
      whid: 348109
    },
    {
      type: 'header',
      name: 'Фаза 2 — Сільвана'
    },
    {
      type: 'spell',
      icon: 'ability_ironmaidens_convulsiveshadows',
      name: 'Ruin',
      namet: '(смертельно, інтераптибельно)',
      desc: 'Бос кастує спел, який по своєму завершенню завдає 150289 Тіньової шкоди й ігнорує всі абсорби гравців.',
      whid: 355540
    },
    {
      type: 'spell',
      icon: 'ui_darkshore_warfront_horde_banshee',
      name: 'Haunting Wave',
      desc: 'Бос утворює хвилі крику, які відштовхують гравців та завдають 1202 Тіньової шкоди кожні пів секунди впродовж 10 секунд, якщо під них потрапити. Ефект стакається.',
      whid: 351869
    },
    {
      type: 'spell',
      icon: 'ability_argus_deathfog',
      name: 'Veil of Darkness',
      namet: '(важливо)',
      desc: 'Бос кастує під собою воїд-зону, яка завдає 12023 Тіньового пошкодження і абсорбить 51194 вхідного хілінгу, та завдає 1202 Тіньової шкоди кожні пів секунди. Окрім цього, додатково 25% вхідного хілінгу абсорбиться.',
      whid: 347704
    },
    {
      type: 'spell',
      icon: 'spell_shadow_soulleech_3',
      name: 'Banshee Wail',
      desc: 'Бос здає крик на гравця, який завдає шкоду в залежності від наявного життя, наприклад 4523 Тіньового пошкодження на 100% життя до 15079 Тіньового пошкодження на низькому рівні життя. Пошкодження від абілки розповсюджується на всіх гравців у радіусі 5 ярдів від гравця, на якого здійснюється крик. Також гравець та зачеплені у радіусі отримують сайленс на 3 секунди.',
      whid: 348109
    },
    {
      type: 'spell',
      icon: 'ability_throw',
      name: 'Shadow Dagger',
      desc: 'Бос запускає в гравця кинджал, який вішає дот на 9 секунд, який завдає 4960 Тіньової шкоди кожну секунду і поширює відповідний ефект на найближчих гравців.',
      whid: 347670
    },
    {
      type: 'header',
      name: 'Фаза 2 — Ади (Mawsworn)'
    },
    {
      type: 'spell',
      icon: 'ability_priest_surgeofdarkness',
      name: 'Hymn of the End',
      namet: '(героїк)',
      desc: 'Читання пісні кінця, яка завдає 2069 Тіньового пошкодження всім гравцям, допоки немає жодного гравця в 50 ярдах від місця читання.',
      whid: 358215
    },
    {
      type: 'spell',
      icon: 'trade_archaeology_ogre2handedhammer',
      name: 'Accursed Might',
      desc: '**Vanguard**. Іноді підсилює себе збільшуючи силу на 50%, швидкість руху на 10% і загальне життя на 30%. Ефект стакається. __Unstoppable Force__ (героїк) удари по землі які завдають 12412 Фізичної шкоди всім гравцям в 5 ярдах.',
      whid: 350865
    },
    {
      type: 'spell',
      icon: 'inv_hammer_13',
      name: 'Unstoppable Force',
      namet: '(героїк)',
      desc: '**Vanguard**. Удари по землі які завдають 12412 Фізичної шкоди всім гравцям в 5 ярдах.',
      whid: 351075
    },
    {
      type: 'spell',
      icon: 'spell_animamaw_beam',
      name: 'Destabilize',
      desc: '**Hopebreaker**. Ченел у гравця, який зменшує швидкість пересування на 5% і завдає 150 Тіньового пошкодження кожну секунду, додатково абсорбуючи вхідний хілінг на 10% від загального життя гравця на 6 секунд. Ефект стакається.',
      whid: 351092
    },
    {
      type: 'spell',
      icon: 'spell_fire_immolation',
      name: 'Enflame',
      namet: '(героїк)',
      desc: '**Hopebreaker**. Підсилення ада, під час якого збільшується швидкість пересування на 100%, атаки завдають 10343 Вогняного пошкодження головній цілі та 3103 Вогняного пошкодження всім гравцям в 60 ярдах.',
      whid: 351109
    },
    {
      type: 'spell',
      icon: 'spell_shadow_gathershadows',
      name: 'Crushing Dread',
      namet: '(Магічний ефект)',
      desc: '**Souljudge**.Вішає на гравця дебаф, який завдає 7514 Тіньового пошкодження всім гравцям в 5 ярдах від інфікованої цілі кожні 2 секунди впродовж 10 секунд. Ефект стакається. Якщо дебаф діспелиться, він перестрибує на іншого гравця до моменту смерті ада.',
      whid: 351117
    },
    {
      type: 'spell',
      icon: 'inv_sword_48',
      name: 'Lashing Strike',
      namet: '(до уваги танкам)',
      desc: '**Souljudge**. Ривок до гравця, який завдає 33099 Фізичного пошкодження і додатково вішає дот, який завдає 4137 Фізичного пошкодження кожну секунду впродовж 9 секунд. Ефект стакається.',
      whid: 351179
    },
    {
      type: 'spell',
      icon: 'spell_animamaw_orb',
      name: 'Summon Decrepit Orbs',
      desc: '**Summoner** прикликає 4 орби, які збільшують силу адів на 40% впродовж 6 секунд. Ефект стакається. По смерті орб вибухає завдаючи 901 Тіньової шкоди всім гравцям у радіусі 100 ярдів.',
      whid: 351353
    },
    {
      type: 'spell',
      icon: 'spell_animamaw_wave',
      name: 'Curse of Lethargy',
      namet: '(Курс, героїк)',
      desc: '**Summoner** завдає магічного удару по гравцю, який завдає 12412 Тіньового пошкодження і додатково 2482 Тіньового пошкодження кожну секунду і зменшує швидкість пересування на 30% впродовж 3 секунд. Кожен каст продовжує тривалість курсу на 1 секунду.',
      whid: 351451
    },
    {
      type: 'spell',
      icon: 'spell_shadow_deathscream',
      name: 'Fury',
      namet: '(до уваги танків)',
      desc: '**Goliath** збільшує свою силу з кожною мілі атакою по поточній цілі на 4% і швидкість атак на 5%. Ефект стакається допоки ад атакує поточну ціль.',
      whid: 351670
    },
    {
      type: 'header',
      name: 'Третя фаза'
    },
    {
      type: 'spell',
      icon: 'warlock_curse_shadow_aura',
      name: 'Banshee\'s Bane',
      namet: '(магічний ефект)',
      desc: 'Бос кидає на гравців дот, який завдає 1503 Тіньового пошкодження кожну секунду. Ефект стакається. Після зняття дебафу він утворює калюжу під ногами гравця. Кожен гравець, яких заходить у калюжу отримує дебаф __Banshee\'s Bane__.',
      whid: 353929
    },
    {
      type: 'spell',
      icon: 'spell_shadow_painspike',
      name: 'Bane Arrows',
      desc: 'Бос запускає залп стріл, який завдає 4509 Тіньового пошкодження всім гравцям в 5 ярдах від локації падіння стріли й створює калюжу з дебафу __Banshee\'s Bane__.',
      whid: 353972
    },
    {
      type: 'spell',
      icon: 'spell_priest_mindspike',
      name: 'Banshee\'s Heartseeker',
      namet: '(до уваги танків)',
      desc: 'Бос назбирує кожні 7 секунд стаки. При отриманні трьох стаків, бос завдає серію пострілів по поточній цілі. Кожен постріл завдає 13526 Фізичного пошкодження і 10520 Тіньового пошкодження залишаючи на цілі дебаф __Banshee\'s Bane__.',
      whid: 353965
    },
    {
      type: 'spell',
      icon: 'spell_shadow_shadowfury',
      name: 'Banshee\'s Fury',
      namet: '(героїк, важливо)',
      desc: 'Бос завдає 4137 Тіньового пошкодження всім гравцям кожні 4 секунди. Після 4 секунд __Banshee\'s Bane__ вибухають завдаючи 8275 Тіньової шкоди за кожен стак __Banshee\'s Bane__.',
      whid: 354068
    },
    {
      type: 'spell',
      icon: 'ability_theblackarrow',
      name: 'Wailing Arrow',
      namet: '(до уваги танків)',
      desc: 'Бос запускає залп стріл по гравцю, який завдає 12023 Тіньової шкоди і додатково завдає до 42081 Тіньової шкоди всім гравцям в залежності від дистанції між стрілами та гравцем.',
      whid: 347609
    },
    {
      type: 'spell',
      icon: 'ability_argus_deathfog',
      name: 'Veil of Darkness',
      namet: '(важливо)',
      desc: 'Бос кастує під собою воїд-зону, яка завдає 12023 Тіньового пошкодження і абсорбить 51194 вхідного хілінгу, та завдає 1202 Тіньової шкоди кожні пів секунди. Окрім цього, додатково 25% вхідного хілінгу абсорбиться.',
      whid: 347704
    },
    {
      type: 'spell',
      icon: 'ability_warlock_improvedsoulleech',
      name: 'Banshee Scream',
      desc: 'Бос здає крик на гравця, який завдає шкоду в залежності від наявного життя, наприклад 4959 Тіньового пошкодження на 100% життя до 16531 Тіньового пошкодження на низькому рівні життя. Пошкодження від абілки розповсюджується на всіх гравців у радіусі 5 ярдів від гравця, на якого здійснюється крик. Також гравець та зачеплені у радіусі отримують сайленс на 4 секунди.',
      whid: 353955
    },
    {
      type: 'spell',
      icon: 'ability_throw',
      name: 'Shadow Dagger',
      desc: 'Бос запускає в гравця кинджал, який вішає дот на 9 секунд, який завдає 4960 Тіньової шкоди кожну секунду і поширює відповідний ефект на найближчих гравців.',
      whid: 347670
    },
    {
      type: 'spell',
      icon: 'spell_animamaw_groundstate',
      name: 'Raze',
      namet: '(смертельно)',
      desc: 'Бос кастує воїд-зону, яка завдає 18034 Тіньового пошкодження всім гравцям у радіусі 30 ярдів і додатково 6012 Тіньового пошкодження кожні пів секунди. Окрім цього знищує __Invigorating Field__ – корисну для гравців воїд-зону, яка збільшує їхню швидкість пересування на 30% впродовж 5 секунд.',
      whid: 354147
    },
  ]
}
