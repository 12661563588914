export default {
  slug: 'the-necrotic-wake',
  parent: 'dungeons',
  name: 'Некротичний відбиток',
  hint: 'The Necrotic Wake',
  image: '/img/the-necrotic-wake.jpg',
  group: 'Тінеземлі',
  meta: {
    description: 'Підземелля Некротичний відбиток.',
    keywords: 'некротичний, відбиток, некротік, вейк, підземелля, данж, мітік, м+, тінеземлі, necrotic, wake, nw, dungeon, shadowlands'
  },
  header: { parent: true },
  archive: true
}
