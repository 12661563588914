export default {
  slug: 'raszageth',
  parent: 'vault-of-the-incarnates',
  name: 'Разаґет',
  hint: 'Raszageth',
  image: '/img/raszageth.jpg',
  meta: {
    description: 'Тактика бою проти Разаґет у Сховищі втілень.',
    keywords: 'разаґет, тактика, стратегія, механіка, гайд, бос, сховище втілень, raszageth, vault of the incarnates'
  },
  header: { parent: true },
  sidenav: true,
  authors: [ 'Semerkhet' ],
  content: `
    ## Загальна інформація

    [p][npc/199031/Разаґет] -- останній бос у Сховищі втілень. Це триетапний бій
    з двома перехідками. Raszageth має кілька здібностей на тему бурі та блискавки,
    які намагатимуться вбити гравців, перш ніж вони зможуть перемогти її. Під час
    перехідок ваш рейд боротиметься з різними мобами, викликаними щоб послабити
    Сховище Втілень.[/p]

    [p]__Heroism/Bloodlust__ краще дати на останній фазі.[/p]

    [p]Паттерн бою -- Solo + Cleave.[/p]

    ## Вміння боса

    ### Фаза 1 (100-65 відсотків хп)

    [ul sparse]
      [li]__Static Charge__.
      Троє гравців будуть відзначені негативним ефектом, який завдає шкоди рейду,
      коли закінчиться. Цільові гравці повинні втекти від рейду та один від одного,
      щоб зменшити завдану шкоду. Один або два гравці з дебаффами повинні бігти
      безпосередньо за рейдом і скидати дебаффи вздовж краю платформи. Встаньте
      приблизно на відстані 10-20 ярдів від краю платформи, тому що потрібно буде
      використовувати все поле.[/li]
      [li]__Hurricane Wing__.
      На позначці енергії 100 Raszageth викликає порив вітру, відштовхуючи гравців.
      Розташуйте весь рейд таким чином, щоб __Static Field__ знаходилося
      безпосередньо позаду рейду. Стояння в __Static Field__ запобігає
      відштовхуванню Hurricane Wing.[/li]
      [li]__Volatile Current__.
      Гравці будуть відмічені __Volatile Current__, який завдасть шкоди їм та
      іншим гравцям у радіусі 8 ярдів. Візьміть спред, щоб уникнути задвоєння
      шкоди. Кожне вдаряння гравця породжує Volatile Spark.[/li]
      [li]__Lightning Breath__ та __Lightning Strikes__ -- це дві здібності,
      від яких можна вийти протягом усієї фази.[/li]
    [/ul]

    <figure>
      <img src="/img/raszageth-1.jpg">
      <figcaption>Розкладання Static Charge</figcaption>
    </figure>

    ## Моби

    [p]Volatile Spark. Цього ада можна вбити, перервавши його, застосувавши контроль
    або знявши його щит за допомогою очищення/розсіювання.
    [icon/alert] ~~Маги, не крадіть щит собі – поки що це вбиває!~~[/p]

    ## Перехідна фаза 1

    [p]Це головний моб сутички, яке вимагає від вашого рейду вбити трьох аддів
    до завершення перехідки.[/p]

    [p]Розбийте щит Surging Ruiners, який породить кілька малих мобів, що й
    перевантажить його. Вміння Overload дає змогу мобам наносити більше шкоди та
    генерує АоЕ по рейду.[/p]

    [p]Убийте малих мобів, перш ніж переходити до наступного.[/p]

    [p]Убийте першого Surging Ruiner, до якого бос збиває вас, потім слідує
    Surging Ruiner, що залишився (знаходиться подалі від телепорту), і, нарешті,
    Surging Ruiner біля телепорту.[/p]

    [p][image/raszageth-2.jpg][/p]

    [ul sparse]
      [li]Oathsworn Vanguard[br]
      __Windforce Strikes__ призводить до того, що Oathsworn Vanguard отримує +10%
      до атаки та швидкості руху з кожною атакою, вимагаючи від вашого рейду убити
      їх, перш ніж вони складуть вашого танка.[/li]
      [li]Stormseeker Acolyte[br]
      __Storm Bolt__ -- це каст, який завдає шкоди цільовому гравцеві.
      [icon/alert] ~~Треба перервати!~~[/li]
      [li]__Lightning Devastation__[br]
      Raszageth носиться по платформі зі смертоносною атакою. Ухиляйтеся![/li]
    [/ul]

    ## Фаза 2

    [ul sparse]
      [li]__Stormsurge__.
      Це особлива здатність боса, яка діятиме протягом другої фази. З енергією 100
      бос захищатиме себе на 25 секунд. Поки щит тримається, рейд отримуватиме болючу
      шкоду. Щоб допомогти ДПС пробити щит, ваш рейд отримає негативні ефекти
      __Stormcharged__, які розділять ваш рейд на позитивні та негативні заряди.[/li]
      [li]__Stormcharged__ дає +200% нанесеної шкоди та вихідного зцілення.
      Призначте розташування групи для кожного заряду, і нехай усі гравці з однаковим
      зарядом зійдуться, щоб отримати бонус до шкоди. Протилежні заряди, що зіткнулися,
      завдадуть шкоди обом гравцям.
      [p][image/raszageth-3.jpg][/p][/li]
      [li]__Fulminating Charge__.
      Бос буде націлюватися на кількох гравців блискавкою, сповільнюючи їх і змушуючи
      їх вибухати в межах зазначеної області. Щоразу, коли негативний ефект переходить
      до нового гравця, радіус вибуху зменшується. Гравці з дебафом повинні бігти до
      краю платформи. Тримайтеся подалі від гравців із негативним ефектом, щоб він
      не перестрибнув.[/li]
      [li]__Tempest Wing__.
      Це полегшена версія Hurricane Wing з першої фази, де бос завдасть шкоди та
      відштовхне всіх на короткий час.[/li]
      [li]__Volatile Current__.
      Поводьтеся з ним так само, як на Фазі 1.[/li]
    [/ul]

    ## Перехідна фаза 2

    [p]Бос імунний до шкоди під час перехідки. Кастує __Lightning Devastation__ яка на
    першій перехідці.[/p]

    ### Colossal Stormfiend та Seeking Stormlings

    [p]Colossal Stormfiend кастує __Storm Break__, здатність, яка телепортує їх і створює
    кількох Seeking Stormling на початковому місці. Кожного разу, коли виконується
    __Storm Break__, вони також отримують багато стаків __Crackling Energy__, негативного
    ефекту, який завдає шкоди рейду. Seeking Stormlings намагатимуться дійти до
    Colossal Stormfiend, щоб зцілити моба і збільшити їхню шкоду.[/p]

    [p]Убийте Seeking Stormlings, перш ніж вони зможуть досягти цілі. Щоб уповільнити їх,
    можна використовувати стан, рути та інші ефекти контролю. __Ball Lightning__ буде
    виходити з Colossal Stormfiend, тож уникайте її! Коли Colossal Stormfiend загине,
    розмістіть свій рейд так, щоб вас відкинуло назад на головну платформу, так само,
    як на першій перехідці.[/p]

    ### Фаза 3

    [p]Ця фаза -- ДПС-перевірка.[/p]

    [ul sparse]
      [li]__Raging Storm__.
      Протягом останньої фази бос викликає шторм, який наближається до платформи,
      постійно зменшуючи кількість корисного простору. Попадання в шторм призведе до того,
      що гравець отримає постійну шкоду від Storm's Spite (ДоТ під час знаходження в зоні
      шторму).[/li]
      [li]__Fulminating Charge__ та __Tempest Wing__.
      Дійте з ними так само, як і на другій фазі.[/li]
    [/ul]

    ## Heroic

    [ul sparse]
      [li]__Static Field__ яке залишається після Static Charge, збільшиться в розмірі,
      поки гравці стоять у ньому. Киньте його на краю платформи, щоб воно не вийшло
      з-під контролю.[/li]
      [li]Volatile Spark[br]
      Volatile Spark матиме 2 стаки, які вимагатимуть від рейду перервати, діспелити
      або вбити їх двічі.[/li]
      [li]Surging Ruiner[br]
      Surging Ruiner позначає випадкових гравців __Surging Blast__, дебаффом, який
      завдає шкоди та вибухає через 6 секунд, завдаючи шкоди гравцям у радіусі 7 ярдів
      від цілі. Взяти спред, щоб уникнути шкоди.[/li]
      [li]__Stormcharged__[br]
      Контакт з гравцем протилежного заряду спровокує вибух АоЕ. Взяти спред, щоб
      уникнути шкоди.[/li]
      [li]Colossal Stormfiend[br]
      Коли Colossal Stormfiend помирає, він бафає всіх Colossal Stormfiend, що залишилися,
      на +30% швидкість атаки та дуже швидко збільшує їхню рейдову шкоду. Вбийте всіх
      Colossal Stormfiend [icon/alert] ~~рівномірно~~.[/li]
      [li]__Magnetic Charge__[br]
      Бос націлиться на випадкового гравця, і через 8 секунд він вибухне, завдаючи
      шкоди від падіння рейду та притягуючи всіх до місця вибуху. Ми рекомендуємо,
      щоб гравець, на якого націлено __Magnetic Charge__, біг до краю __Raging Storm__,
      щоб максимізувати відстань між собою та рейдом.[/li]
    [/ul]

    ### ДЛЯ ТАНКІВ

    [p]Перший і другий етапи мають однакову здатність вбивати танків, а третій
    етап є унікальним.[/p]

    [p]У першій і другій фазах танки будуть мати справу з __Electrified Jaws__,
    комбо-атакою, яка завдає як фізичної, так і природної шкоди. Використовуйте
    великі сейви, коли ви є ціллю, і змінюйтеся по таунту після кожного
    застосування.[/p]

    [p]На першій перехідці слідкуйте за кількістю стеків __Windforce Strikes__
    на мобах. Коли він досягає високої кількості стаків, використовуйте
    захист.[/p]

    [p]У третій фазі __Thunderous Blast__ -- танківський удар, який протягом
    2 секунд завдасть танку величезної шкоди від природи, а потім вибухне та
    завдасть шкоди всьому рейду залежно від того, яку шкоду отримав танк.
    [icon/alert] ~~Використовувати сейви~~. Перекривайте 2–3 власними захисними
    кд, щоб мінімізувати завдану шкоду. Після кожного застосування
    __Thunderous Blast__ -- зміна танка.[/p]

    ### ДЛЯ ХІЛІВ

    [p]Жерці особливо корисні в боротьбі з Volatile Sparks за допомогою
    ~~__Mass Dispel__~~. Танки зазнають значної шкоди, коли використовуються
    __Electrified Jaws__. У першій фазі очікуйте, що рейд отримає велику шкоду,
    коли застосовано __Hurricane Wing__, __Static Charge__, та
    __Volatile Current__.[/p]

    [p]У другій фазі найнебезпечніша частина -- під час __Stormsurge__, де рейд
    отримуватиме все більшу шкоду, поки щит тримається. Точкове лікування гравців
    під час __Fulminating Charge__ та __Lingering Charge__.[/p]

    [p]У третьому етапі рейд отримуватиме шкоду залежно від кількості шкоди,
    завданої танку __Thunderous Blast__.[/p]

    ### ДЛЯ ДПС

    [p]Переривайте Volatile Spark, використовуючи оглушення або інші форми
    жорсткого контролю, щоб зупинити їх від використання __Burst__.[/p]

    [p]Вбивайте Surging Ruiners у шаховому порядку, щоб уникнути надто
    небезпечного __Overload__.[/p]

    [p]Перервати __Storm Bolt__, від Stormseeker Acolyte на першій перехідній фазі.
    Використовуйте власні сейви під час __Stormsurge__, щоб швидше зламати щит.
    Убийте Seeking Stormling, у другій перехідній фазі, щоб не дати їм досягти
    великого моба для __Fuse__.[/p]
  `,
  aside: [
    {
      type: 'image',
      src: '/img/raszageth.jpg'
    },
    {
      type: 'header',
      name: 'Разаґет'
    },
    {
      type: 'spell',
      whid: 381615,
      icon: 'spell_shaman_staticshock',
      name: 'Static Charge',
      desc: `
        Разаґет відмічає гравців, завадає [8031/nature] щосекунди протягом 8 сек.
        По завершенню, ці гравці вибухають, чим завдають [208794/nature] всім гравцям.
        Ці пошкодження зменьшуються з відстанню гравців від епіцентру вибуху.
        [br][br]
        Створює __Static Field__ на місці жертви.
      `
    },
    {
      type: 'spell',
      whid: 377662,
      icon: 'spell_shaman_crashlightning',
      name: 'Static Field',
      desc: `
        Електричний струм шириться ареною. Завдає [4816/nature] щосекунди, пошкодження
        зростає з часом при перебування в зоні дії. Додатково, зменьшує швидкість руху
        на 50% та послаблює дію активних здібностей переміщення.
      `
    },
    {
      type: 'spell',
      whid: 377612,
      icon: 'ability_dragonriding_vigor01',
      name: 'Hurricane Wing',
      desc: `
        Після досягнення 100 енергії, Разаґет викликає потужний порив вітру. Завдає
        [112428/nature] всім гравцям. Крім того, гравців відштовхує й вони отримують
        [12849/nature] кожні 0.5 сек протягом 3 сек.
        [br][br]
        [icon/skull] Міфік: Щоразу, коли Разаґет використовує цю здібність, тривалість
        урагану збільшується на 0.5 сек.
      `
    },
    {
      type: 'spell',
      whid: 388643,
      icon: 'spell_nature_stormreach',
      name: 'Volatile Current',
      desc: `
        Разаґет наелектризовує всіх гравців. Невдовзі відбуваєть розряд, завдає
        [160611/nature] носію та всім гравцям у радіусі 8 метрів, при цьому утворюється
        __Volatile Spark__.
      `
    },
    {
      type: 'spell',
      whid: 377594,
      icon: 'ability_thunderking_thunderstruck',
      name: 'Lightning Breath',
      desc: `
        Разаґет видихає перед собою промінь блискавки, завдає [212006/nature] кожні
        0.3 сек гравцям, яких зачіпила.
      `
    },
    {
      type: 'spell',
      whid: 376126,
      icon: 'spell_lightning_lightningbolt01',
      name: 'Lightning Strikes',
      desc: `
        Разаґет переріодично викликає удари блискавки, завдає [106003/nature] всім
        гравцям в зоні удару та [приголомшує//Stun] їх на 0.5 сек.
      `
    },
    {
      type: 'spell',
      whid: 388115,
      icon: 'ability_dragonriding_diving01',
      name: 'Lightning Devastation',
      desc: `
        Разаґет проноситься по платформі, видихає блискавку в лінію під час руху.
        Завдає [1606108/nature] гравцям, які стоять у зоні удару.
      `
    },
    {
      type: 'spell',
      whid: 387261,
      icon: 'ability_evoker_unravel',
      name: 'Stormsurge',
      desc: `
      Досягнувши 100 енергії, Разаґет захищає себе тріскучим електричним бар’єром,
      який поглинає 82701548 шкоди. Разаґет пульсує, поки щит утримується, завдаючи
      [4497/nature] всім гравцям щосекунди, зростаючи за інтенсивністю на 200% з
      кожним імпульсом.
      [br][br]
      Через 25 сек щит вибухає, завдаючи [128489/nature] всім гравцям кожні
      1.5 сек протягом 1.5 хв.
      [br][br]
      Допоки щит тримається, на гравців діє __Stormharged__.
      `
    },
    {
      type: 'spell',
      whid: 391989,
      icon: 'ability_druid_twilightswrath',
      name: 'Stormcharged',
      desc: `
        Разаґет впливає на гравців позитивним або негативним зарядом. Гравці
        в радіусі 5 метрів від союзників з однаковим зарядом отримують
        [spell/394582/Focused Charge], а гравці в межах 5 метрів від союзників
        з протилежним зарядом отримують [spell/395781/Scattered Charge].
      `
    },
    {
      type: 'spell',
      whid: 377467,
      icon: 'ability_thunderking_overcharge',
      name: 'Fulminating Charge',
      desc: `
        Разаґет заряджає цілі електрикою, зменшуючи їхню швидкість руху на 3 сек.
        Після завершення дії заряд спалахує, завдає [80305/nature] гравцям у зоні
        удару та перестрибує до найближчого гравця, який потрапив у вибух.
        [br][br]
        Щоразу, коли __Fulminating Charge__ переходить до нового гравця, його
        радіус зменшується, а попередній гравець отримує
        [spell/390911/Lingering Charge].
      `
    },
    {
      type: 'spell',
      whid: 385574,
      icon: 'ability_evoker_hoverblue',
      name: 'Tempest Wing',
      desc: `
        Разаґет бʼє крилами по землі, завдає [96366/nature] всім гравцям і випускає
        штормову хвилю. Гравці у хвилі зазнають [48183/nature] кожні 0.5 сек та
        відштовхуються.
      `
    },
    {
      type: 'spell',
      whid: 377322,
      icon: 'spell_nature_eyeofthestorm',
      name: 'Raging Storm',
      desc: `
        Разаґет викликає шалений шторм, який з часом поглинає платформу. Гравці, які
        стоять у зоні, вражаються [spell/395929/Storm's Spite].
      `
    },
    {
      type: 'header',
      name: 'Surging Ruiner'
    },
    {
      type: 'spell',
      whid: 382530,
      icon: 'spell_nature_purge',
      name: 'Surge',
      desc: `
        Surging Ruiner захищає себе барʼєром та підтримує це закляття. Поки барʼєр
        існує, завдає [12849/nature] всім гравцям кожні 3 сек.
        [br][br]
        При знешкодженні барʼєра, Surging Ruiner отримує __Overload__ та прикликає
        первісні сили.
      `
    },
    {
      type: 'spell',
      whid: 389214,
      icon: 'inv_10_elementalspiritfoozles_lightning',
      name: 'Overload',
      desc: `
        Surging Ruiner перевантажує себе, завдає [25698/nature] всім гравцям кожні 3 сек.
        [br][br]
        Крім того, Surging Ruiner збільшує вихідну шкоду всіх союзників на 100% допоки
        не буде знищений.
      `
    },
    {
      type: 'header',
      name: 'Oathsworn Vanguard'
    },
    {
      type: 'spell',
      whid: 385560,
      icon: 'spell_nature_windfury',
      name: 'Windforce Strikes',
      desc: `
        Oathsworn Vanguard збільшує свою швидкість атаки та пересування на 10% з кожним
        успішним ударом ближнього бою.
      `
    },
    {
      type: 'header',
      name: 'Stormseeker Acolyte'
    },
    {
      type: 'spell',
      whid: 385553,
      icon: 'spell_lightning_lightningbolt01',
      name: 'Storm Bolt',
      desc: `
        Stormseeker Acolyte вивільняє штормову енергію в гравця, завдає [57820/nature].
      `
    },
    {
      type: 'header',
      name: 'Colossal Stormfiend'
    },
    {
      type: 'spell',
      whid: 389870,
      icon: 'inv_10_elementalspiritfoozles_lightning',
      name: 'Storm Break',
      desc: `
        Colossal Stormfiend телепортується, завдає [48183/nature] всім гравцям та
        отримує стак [spell/391281/Crackling Energy].
        [br][br]
        Утворює Seeking Stormlings на місці телепортації.
      `
    },
    {
      type: 'spell',
      whid: 385068,
      icon: 'ability_monk_forcesphere',
      name: 'Ball Lightning',
      desc: `
        Colossal Stormfiend випускає кулі блискавки, завдає [160611/nature] гравцям
        на шляху руху кулі та [приголомшує//Stun] їх на 0.5 сек.
      `
    },
  ]
}
