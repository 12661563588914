export default {
  slug: 'mists-of-tirna-scithe',
  parent: 'dungeons',
  name: 'Тумани Тірна Сайт',
  hint: 'Mists of Tirna Scithe',
  image: '/img/mists-of-tirna-scithe.jpg',
  group: 'Тінеземлі',
  meta: {
    description: 'Підземелля Тумани Тірна Сайт.',
    keywords: 'тумани, тірна, сайт, тірни, скайт, скіт, скіта, підземелля, данж, мітік, м+, тінеземлі, mists, tirna, scithe, mots, dungeon, shadowlands'
  },
  header: { parent: true },
  archive: true
}
