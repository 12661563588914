export default {
  slug: 'kin-tara',
  parent: 'spires-of-ascension',
  name: 'Kin-Tara',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Ta-na' ],
  content: `
    [p]Боса з петом тримаємо разом, гера і бурсти з пула, танкам протискатись
    перед ударом __Overhead Slash__, не стійте між босом і петом бо отримаєте
    дебаф __Deep Connection__, списи що метатиме бос виносьте аби було місце
    для бою, уникайте __Attenuated Barrage__.[/p]
  `,
  aside: [
    {
      type: 'spell',
      icon: 'inv_polearm_2h_bastion_d_01',
      name: 'Overhead Slash',
      desc: 'Наносить масивне Фіз. пошкодження, не кліває.',
      whid: 320966
    },
    {
      type: 'spell',
      icon: 'spell_animamaw_beam',
      name: 'Deep Connection',
      desc: 'Промінь енегрії між босом і петом. Кожен гравець що зайде в промінь отримає доту на 586 пошкоджень від Аркан магії кожну секунду протягом 10 сек. Цей ефект накладається.',
      whid: 331251
    },
    {
      type: 'spell',
      icon: 'spell_animamaw_orb',
      name: 'Attenuated Barrage',
      desc: 'Пет кидає енергетичні кулі наносячи 11718 пошкоджень від аркан магії всім на своєму шляху.',
      whid: 324370
    }
  ],
  attachments: [
    { type: 'video', videoId: '2hoFL3NW3HY', name: 'Аналіз М+20 від Денса (2й сезон)' },
    { type: 'video', videoId: 'roVEc0IgLbo', name: 'Аналіз М+17 від Денса' },
    { type: 'post', link: '/guides/tormented-affix', name: '10+ афікс' },
  ]
}
