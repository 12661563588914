export default {
  slug: 'de-other-side',
  parent: 'dungeons',
  name: 'Інший бік',
  hint: 'De Other Side',
  image: '/img/de-other-side.jpg',
  group: 'Тінеземлі',
  meta: {
    description: 'Підземелля Інший бік.',
    keywords: 'інший бік, інша сторона, потойбіччя, азер, сайд, підземелля, данж, мітік, м+, тінеземлі, de other side, the other side, dos, dungeon, shadowlands'
  },
  header: { parent: true },
  archive: true
}
