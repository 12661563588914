export default {
  slug: 'sun-kings-salvation',
  parent: 'castle-nathria',
  name: 'Sun King\'s Salvation',
  group: 'Reliquary of Opulence',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Vorren' ],
  content: `
    <ul>
      <li>Поле бою — великий театр з педесталом (де знаходиться Кель)</li>
      <li>Специфіка Босу — треба врятувати Кельтаса, хіляючи його</li>
      <li>Головним ворогом у цьому бою будуть ади, і їх багато</li>
      <li>TW/Героїзм — с самого початку для того щоб вбити <b>Darithos</b> з першими <b>Occultists</b></li>
    </ul>

    <p>Як хілити боса:
    <ul>
      <li>Прямими хілами (не рекомендується)</li>
      <li>Хіляючи до фула фласки, які дропають моби (про це детальніше далі)</li>
      <li>Інтерактувати з клапанами які навколо платформи боса (це зробили щоб дпсами було що робити коли немає адів)</li>
    </ul>
    </p>

    <h2>Ади</h2>
    <ul>
      <li><b>Infusers</b> — ці ади спавняться на краях та починають йти до Келя щоб його вбити, їх треба вбивати в пріоритеті.</li>
      <li><b>Occultists</b> — збивати їм <em>Vulgar Brand</em>. Після смерті вони кладуть фласки, які можна хіляти, і коли вона на фул по всьому рейду та Келю проходить великий хіл.</li>
      <li><b>Assassins</b> — їх треба танкувати та швидко вбивати, коли в них мало хп вони починають юзати <em>Return to Stone</em> — абілка яка їх відхілює. Танкує їх офтанк.</li>
      <li><b>Vanquishers</b> — велика горгулья яку треба танкувати. Вона кастує велике аое по рейду <em>Concussive Smash</em>, яке з часом стає смертельним. Танкам треба свапатись бо вони вішають дебаф.</li>
      <li><b>Fiends</b> — маленьки ади, яких треба клівати; після смерті вибухають на 7 ярдів (<em>Fragmentation</em>).</li>
      <li><b>Darithos</b> — головний ад якого тримає танк</li>
      <li><b>Тінь Кельтаса</b> — великий кровавий Кель, який танкується. Декільком гравцям потрбіно ділити <em>Ember Blast</em> (червоне коло на рандомному гравці).</li>
      <li><b>Фенікси</b> — їх 2+ вони фіксують рандомну людину, залишають за собою калюжу вогня. При смерті ресаються через 20 сек — потрібно вбивати декілька раз за Тіньову фазу.</li>
    </ul>

    <p>Шпаргалка<br>
    [image/sun-kings-salvation-1.jpg]</p>

    <h2>Тактика</h2>
    <p>Бій у дві фази — Хіл Келя / Тіньова фаза</p>

    <h3>Фаза Хіл Келя</h3>
    <p>На цій фазі ми отримаємо постійно адів. Пріорітет вбивства:<br>
    **Infusers** [icon/arrow-right-bold]
    **Occultists** [icon/arrow-right-bold]
    **Vanquishers** [icon/arrow-right-bold]
    **Assassins** [icon/arrow-right-bold]
    **Fiends**</p>

    <p><b>Infusers</b> після смерті кидают Орбу, якщо хіл її підніме
    він отримає +50% хілу на 15 сек, це необхідно робити. Весь рейд має бути розповсюдженим
    по арені, майже всі абілки мають аое ефект навколо нас.</p>

    <h3>Тіньова фаза</h3>
    <p>Починається на 45% хп Келя і хіляти його неможливо. Під час Тіньової фази треба вбити
    його <b>Тінь</b> та <b>Феніксів</b> (які після смерті респавняться через 30с). Як тільки
    <b>Тінь</b> помре, знов переходимо до Хілу Келя з адами. Тіньова фаза на 45% та 90% Келя.
    Як тільки Кель буде мати 100% ми перемогли.</p>

    <p>Дуже велику роль грає гра з адами, стани, інтерапти, рути та заповільнювання (особливо з <b>Infusers</b>).</p>
  `,
  aside: [
    {
      type: 'header',
      name: 'High Torturer Darithos'
    },
    {
      type: 'spell',
      icon: 'spell_animarevendreth_wave',
      name: 'Greater Castigation',
      desc: 'Червоний промінь до декількох гравців, наносить дамаг і аое навколо кожної цілі. Потрібно розбігатися.',
      whid: 328885
    },
    {
      type: 'header',
      name: 'Shade of Kael\'thas',
      namet: 'Тінь'
    },
    {
      type: 'spell',
      icon: 'spell_fire_burnout',
      name: 'Fiery Strike',
      desc: 'Конусне аое на 12 ярдів, наносить дамаг і стак <em>Burning Remnants</em>.',
      whid: 326455
    },
    {
      type: 'spell',
      icon: 'ability_rhyolith_magmaflow_wave',
      name: 'Blazing Surge',
      desc: 'Конусне аое на 40 ярдів. <em>Shade of Kael\'thas</em> використовує при 100 енергії.',
      whid: 329518
    },
    {
      type: 'spell',
      icon: 'spell_fire_selfdestruct',
      name: 'Ember Blast',
      desc: 'Фіксує червоне коло на рандомному гравці, невдовзі вибухає, дамаг ділиться між всіма гравцями в колі, вішає доту <em>Lingering Embers</em>. Інші гравці мають заходити в коло та ділити дамаг.',
      whid: 325877
    },
    {
      type: 'header',
      name: 'Reborn Phoenix',
      namet: 'Фенікс'
    },
    {
      type: 'spell',
      icon: 'ability_mount_fireravengodmount',
      name: 'Smoldering Plumage',
      desc: 'Аое на 6 ярідів кожні 2.5 сек. Лишає калюжу <em>Smoldering Remnants</em>.',
      whid: 341254
    },
    {
      type: 'header',
      name: 'Occultist'
    },
    {
      type: 'spell',
      icon: 'spell_animarevendreth_nova',
      name: 'Vulgar Brand',
      desc: 'Каст який треба зибвати, інакше дамаг і дебаф на вхідний дамаг на всіх гравців.',
      whid: 333002
    },
    {
      type: 'header',
      name: 'Vanquisher'
    },
    {
      type: 'spell',
      icon: 'ability_earthen_pillar',
      name: 'Concussive Smash',
      desc: 'Велике аое по рейду з послідуючим стаком <em>Reverberation</em> — подальше збільшення дамагу від цієї абілки.',
      whid: 325506
    },
    {
      type: 'header',
      name: 'Assassin'
    },
    {
      type: 'spell',
      icon: 'ability_revendreth_rogue',
      name: 'Crimson Flurry',
      desc: 'Телепортується за спину промаркованому гравцю, наносить дамаг і вішає блід всім в радіусі 8 ярдів.',
      whid: 341473
    },
    {
      type: 'spell',
      icon: 'inv_shoulder_04',
      name: 'Return to Stone',
      desc: 'Відхіл на 100% хп за 8 сек.',
      whid: 333145
    },
    {
      type: 'header',
      name: 'Fiend'
    },
    {
      type: 'spell',
      icon: 'archaeology_5_0_crackedmogurunestone',
      name: 'Fragmentation',
      desc: 'Вибух тіла після смерті, дамаг в радіусі 7 ярдів.',
      whid: 336398
    },
  ],
  attachments: [
    { type: 'video', videoId: 'nl547u6euvg', name: 'Першокіл (героїк)' }
  ]
}
