/* eslint-disable no-irregular-whitespace */
export default {
  slug: 'prokachka-kylinarii-shadowlands',
  parent: 'guides',
  name: 'Прокачка кулінарії Shadowlands',
  header: { parent: true },
  image: '/img/prokachka-kylinarii-shadowlands-1.jpg',
  authors: [ 'Prusl (Torvald)' ],
  content: `
    <p><i>Ця стаття є перекладом гайду по прокачці Shadowlands кулінарії з сайту
    <a href="https://www.wow-professions.com" target="_blank">https://www.wow-professions.com</a>.
    Ось посилання на <a href="https://www.wow-professions.com/guides/wow-cooking-leveling-guide" target="_blank">оригінал</a>,
    для тих кому зручніше все ж англійською. Переклав **Prusl (Torvald)**, сподіваюся комусь воно та й згодиться.</i></p>

    <p>Дана інструкція покликана вказати вам найшвидший та найбільш оптимальний шлях,
    як прокачати кулінарію Shadowlands з 1 до 75.</p>

    <h2>Учитель кулінарії Shadowlands</h2>

    <p>Кулінарії Shadowlands навчає [npc/156672/Chef Au'krut] який знаходиться в Oribos в Hall of Shapes.
    (координати <code>/way 46.6, 25.8</code>)<br>
    [image/prokachka-kylinarii-shadowlands-1.jpg]</p>

    <h2>Інгредієнти для кулінарії</h2>

    <h3>Риба</h3>

    <p>
    [icon/fish] [item/173032/Lost Sole]<br>
    [icon/fish] [item/173034/Silvergill Pike]<br>
    [icon/fish] [item/173035/Pocked Bonefish]<br>
    [icon/fish] [item/173033/Iridescent Amberjack]<br>
    [icon/fish] [item/173036/Spinefin Piranha]<br>
    [icon/fish] [item/173037/Elysian Thade]<br>
    </p>

    <h3>М'ясо</h3>

    <p>Нижче наведені посилання на <a href="https://www.wow-professions.com" target="_blank">https://www.wow-professions.com</a>
    гайди на фарм м’яса там дуже прості.<br>
    [icon/link] <a href="https://www.wow-professions.com/farming/creeping-crawler-meat-farming" target="_blank">Creeping Crawler Meat farming</a><br>
    [icon/link] <a href="https://www.wow-professions.com/farming/tenebrous-ribs-farming" target="_blank">Tenebrous Ribs farming</a><br>
    [icon/link] <a href="https://www.wow-professions.com/farming/raw-seraphic-wing-farming" target="_blank">Raw Seraphic Wing farming</a><br>
    [icon/link] <a href="https://www.wow-professions.com/farming/phantasmal-haunch-farming" target="_blank">Phantasmal Haunch farming</a><br>
    [icon/link] <a href="https://www.wow-professions.com/farming/shadowy-shank-farming" target="_blank">Shadowy Shank farming</a><br>
    [icon/link] <a href="https://www.wow-professions.com/farming/aethereal-meat-farming" target="_blank">Aethereal Meat farming</a>
    </p>

    <h2>Приблизний перелік інгредієнтів для прокачки кулінарії 1-75</h2>

    <p>Точну кількість риби чи м’яса, що необхідна для прокачки кулінарії до 75 вказати
    важко, оскільки зазвичай є кілька страв на вибір, які ви можете готувати на різних етапах
    прокачки.</p>

    <p>Тут наведено спосіб, який, як правило, є найдешевшим в прокачці, однак, ціна на
    м'ясо і рибу може мінятися. Якщо ви бажаєте заощадити золото, то варто прокачувати
    професію поетапно і оцінювати вартість тих чи інших матеріалів, а не закуповувати все
    одразу.</p>

    <ul>
      <li>60 x [icon/fish] [item/173032/Lost Sole], або 35 x [icon/fish] [item/173032/Lost Sole] і 25 x [icon/puzzle] [item/172052/Aethereal Meat]</li>
      <li>72 x [icon/puzzle] [item/179315/Shadowy Shank]</li>
      <li>30 x [icon/puzzle] [item/172052/Aethereal Meat]</li>
      <li>30 x [icon/puzzle] [item/172055/Phantasmal Haunch]</li>
      <li>66 x [icon/puzzle] [item/172054/Raw Seraphic Wing]</li>
      <li>15 x [icon/fish] [item/173033/Iridescent Amberjack]</li>
      <li>18 x [icon/fish] [item/173034/Silvergill Pike]</li>
    </ul>

    <p>Наведені нижче матеріли ви можете придбати у [npc/168353/Distributor Au'van] (стоїть поряд з учителем кулінарії в Oribos):</p>

    <ul>
      <li>[icon/grain] [item/172056/Medley of Transplanar Spices]</li>
      <li>[icon/grain] [item/172057/Inconceivably Aged Vinegar]</li>
      <li>[icon/grain] [item/172058/Smuggled Azerothian Produce]</li>
      <li>[icon/grain] [item/172059/Rich Grazer Milk]</li>
      <li>[icon/grain] [item/178786/Lusterwheat Flour]</li>
    </ul>

    <h2>1-22</h2>

    <p>Приготуйте 21 x [icon/food] [spell/308410/Biscuits and Caviar] (21 Lost Sole) якщо ви придбали 60 Lost Sole,
    якщо ж придбали тільки 35 то готуйте 21 х [icon/food] [spell/308414/Pickled Meat Smoothie] (21 Aethereal Meat).
    Під кінець рецепт буде жовтим, тому може статися так, що вам потрібно буде приготувати трішки більше,
    щоб досягти 22 рівня.</p>

    <h2>22-36</h2>

    <p>14 x [icon/food] [spell/308412/Meaty Apple Dumplings] — 42 Shadowy Shank.</p>

    <p>Meaty Apple Dumplings має бути найдешевшим варіантом, оскільки Shadowy Shank дешеві,
    але в якості альтернативи можна приготувати будь що з трьох наведених нижче страв, щоб досягти рівня 31:</p>

    <ul>
      <li>9 x [icon/food] [spell/308425/Sweet Silvergill Sausages] — 27 Silvergill Pike</li>
      <li>9 x [icon/food] [spell/308397/Butterscotch Marinated Ribs] — 27 Tenebrous Ribs</li>
      <li>9 x [icon/food] [spell/308404/Cinnamon Bonefish Stew] — 27 Pocked Bonefish</li>
    </ul>

    <p>Після того 5 x [icon/food] [spell/308404/Cinnamon Bonefish Stew] — 15 Pocked Bonefish, щоб прокачати 31-36.</p>

    <h2>36-51</h2>

    <p>15 x [icon/food] [spell/308415/Banana Beef Pudding] — 45 Aethereal Meat, 45 Phantasmal Haunch.</p>

    <p>Нижче наведено альтернативний варіант прокачки, однак Banana Beef Pudding швидше за все буде найдешевшою опцією.</p>

    <p>5 x [icon/food] [spell/308404/Cinnamon Bonefish Stew] — 15 Pocked Bonefish щоб досягти 41. Після цього, готуєте, будь
    що з наведеного нижче, щоб викачати 51:</p>

    <ul>
      <li>10 x [icon/food] [spell/308411/Candied Amberjack Cakes] — 30 Iridescent Amberjack, 30 Pocked Bonefish</li>
      <li>10 x [icon/food] [spell/308405/Tenebrous Crown Roast Aspic] — 30 Tenebrous Ribs, 30 Creeping Crawler Meat</li>
      <li>10 x [icon/food] [spell/308426/Steak a la Mode] — 30 Shadowy Shank, 30 Raw Seraphic Wing</li>
    </ul>

    <h2>51-61</h2>

    <p>10 x [icon/food] [spell/308426/Steak a la Mode] — 30 Shadowy Shank, 30 Raw Seraphic Wing.</p>

    <p>Знову ж таки, Steak a la Mode наводиться, як потенційно найдешевший варіант, однак в якості альтернативи
    ви можете приготувати 10 порцій одної із страв наведених нижче (дорожчі опції, але вони потенційно можуть
    бути вигідні на продаж):</p>

    <ul>
      <li>10 x [icon/food] [spell/308405/Tenebrous Crown Roast Aspic] — 30 Tenebrous Ribs, 30 Creeping Crawler Meat</li>
      <li>10 x [icon/food] [spell/308413/Iridescent Ravioli with Apple Sauce] — 30 Iridescent Amberjack, 30 Lost Sole</li>
      <li>10 x [icon/food] [spell/308400/Spinefin Souffle and Fries] — 30 Spinefin Piranha, 30 Iridescent Amberjack</li>
    </ul>

    <h2>61-66</h2>

    <p>5 x [icon/food] [spell/308413/Iridescent Ravioli with Apple Sauce] — 15 Iridescent Amberjack, 15 Lost Sole.</p>

    <h2>66-75</h2>

    <p>9 x [icon/food] [spell/308417/Seraph Tenders] — 36 Raw Seraphic Wing, 18 Lost Sole, 18 Silvergill Pike.</p>

    <p>Можна також готувати по дев'ять порції інших страв доступних на цьому етапі, але Seraph Tenders рекомендується,
    тому що всі три інгредієнти можна легко зібрати в локації Bastion.</p>
  `
}
