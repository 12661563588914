export default {
  slug: 'margrave-stradama',
  parent: 'plaguefall',
  name: 'Margrave Stradama',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Ta-na' ],
  content: `
    <p>Бій має три фази, перша триває до 66% друга до 33%, геру тримайте на останню фазу, вона найважча.
    Якщо ви маєте прайдфул то на першій фазі прайд, друга бурсти, третя гера.</p>

    <p>Танку потрібно стояти в мілі інакше бос почне кастувати __Plague Rot__, танкувати **великого**
    і протискатись під __Touch of Slime__, **малих адів**, що з’являються на перехідках і третій фазі
    краще кайтити проте набирати агро, якщо кайт не можливий протискатись.</p>

    <p>**Щупки** б’ють дуже боляче тож уникайте, коли горить жовтим значить зараз вдарить.
    Розраховуйте захисні кулдауни як групові так і персональні на __Infectious Rain__, це дізіз
    то ж деякі класи можуть і повинні знімати.</p>
  `,
  aside: [
    {
      type: 'spell',
      icon: 'spell_shadow_plaguecloud',
      name: 'Plague Rot',
      desc: 'Наносить всім гравцям 1465 пошкоджень від сил Природи кожну 1 сек протягом 30 сек коли не знаходиться в ближньому бою. Цей ефект стакається.',
      whid: 322491
    },
    {
      type: 'spell',
      icon: 'ability_creature_disease_03',
      name: 'Infectious Rain',
      desc: 'Наносить кожні 2 сек протягом 6 сек пошкоджуючи всіх гравців хто в бою 1758 пошкоджень від природи. Цей дізіз додатково наносить 293 пошкоджень від сил природи раз в 1 сек протягом 6 сек. Цей ефект накладається.',
      whid: 322232
    },
    {
      type: 'spell',
      icon: 'achievement_boss_yoggsaron_01',
      name: 'Touch of Slime',
      namet: 'великий ад',
      desc: 'Б’є по землі наносячи 5859 пошкоджень від сил природи всім гравцям в радіусі 4 метрів. Якщо ця атака не зачепить нікого, вона нанесе 23435 пошкоджень від природи всій групі.',
      whid: 322236
    },
  ],
  attachments: [
    { type: 'video', videoId: 'F6S3wPYPC6M', name: 'Аналіз М+20 від Денса (2й сезон)' },
    { type: 'video', videoId: 'vln2xil1cAI', name: 'Аналіз М+17 від Денса' },
    { type: 'post', link: '/guides/tormented-affix', name: '10+ афікс' },
  ]
}
