export default {
  slug: 'devos',
  parent: 'spires-of-ascension',
  name: 'Devos',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Dancecaptain (Makhabunda)' ],
  content: `
    [p]Бос з двома фазами, які змінюються на основі здоров'я **Девос**,
    зокрема на 70% і 30%. Перша фаза -- безпосередній бій з **Девос**,
    друга -- збирання синіх **кульок** до **списа** для метання останнього
    у боса.[/p]

    [p]В бою присутня дружня нпц [npc/165714/Кірестія], яка допомагає
    гравцям **куполом** на першій фазі та **списом** на другій.[/p]

    ## Перша фаза

    [p]**Кірестія** періодично ставить на землю **купол**, який зменшує вхідне
    пошкодження від вибуху __Abyssal Detonation__ на 90%. Боса можна всю першу
    фазу тримати всередині **куполу**, адже він ніяк не впливає на нанесення
    пошкодження по босу.[/p]

    [p]Окрім цього, **Девос** періодично вішатиме на двох гравців магічне
    закляття __Lost Confidence__ та робитиме ривок __Run Through__.[/p]

    ## Друга фаза

    [p]При досягненні 70% і 30% здоров'я боса, вона здіймається у повітря та стає
    недосяжною для безпосереднього бою. Щоб скинути її на землю треба влучити
    зарядженим **списом** у боса, після чого повторюється перша фаза. Окрім цього,
    **Девос** статично наносить всій групі Арканічні пошкодження та підсилює
    дебаф __Lingering Doubt__, також по платформі рухаються темні **калюжі**,
    наступаючи в які гравець додаткового підсилює дебаф.[/p]
  `,
  aside: [
    {
      type: 'spell',
      icon: 'spell_arcane_arcaneresilience',
      name: 'Archon\'s Bastion',
      namet: 'купол',
      desc: 'Прозорий **купол**, який зменшує пошкодження від __Abyssal Detonation__ на 90%.',
      whid: 335806
    },
    {
      type: 'spell',
      icon: 'spell_animamaw_nova',
      name: 'Abyssal Detonation',
      desc: 'Вибух через 4 сек від початку вимови закляття, наносить летальну кількість пошкодження. Переживається під прозорим **куполом**.',
      whid: 334625
    },
    {
      type: 'spell',
      icon: 'spell_misc_emotionsad',
      name: 'Lost Confidence',
      desc: '**Девос** вішає Арканічний дебаф на двох гравців на 15 сек. Діспелимо зі слабшого гравця, або вразливого до магії, інший скидатиме свій дебаф поза **куполом**.[br][br][icon/alert-circle-outline] ВАЖЛИВО: після закінчення дії дебафу, під гравцем з\'являється темна **калюжа**, яка наносить незначне пошкодження, однак нарощує інший дебаф і дає неприємний візуальний ефект, якщо у ній знаходитись.',
      whid: 322818
    },
    {
      type: 'spell',
      icon: 'ability_warrior_charge',
      name: 'Run Through',
      desc: '**Девос** вибирає любого з гравців й робить ривок до нього, завдаючи пошкодження кожному гравцю на своєму шляху. Просто відходимо від напряму ривка.',
      whid: 323943
    },
    {
      type: 'spell',
      icon: 'spell_shadow_seedofdestruction',
      name: 'Lingering Doubt',
      desc: 'Дебаф, який підсилюється під час другої фази, або отримується під час перебування у темній **калюжі** та наносить невелику кількість Арканічного пошкодження. Можна пережити або здіспелити.',
      whid: 322817
    },
    {
      type: 'spell',
      icon: 'inv_smallazeritefragment',
      name: 'Raw Anima',
      namet: 'кулька',
      desc: 'Сині **кульки**, які літають по платформі. Один гравець піднімає одну **кульку** й заносить до **спису**. Чим швидше -- тим краще.',
      whid: 330683
    },
    {
      type: 'spell',
      icon: 'inv_polearm_2h_bastionarchon_d_01',
      name: 'Archon\'s Spear',
      namet: 'спис',
      desc: '**Спис**, до якого треба занести 5 синіх **кульок**, після чого він стає активним та його треба метнути у боса.',
      whid: 322921
    }
  ],
  attachments: [
    { type: 'video', videoId: '2hoFL3NW3HY', name: 'Аналіз М+20 від Денса (2й сезон)' },
    { type: 'video', videoId: 'roVEc0IgLbo', name: 'Аналіз М+17 від Денса' },
    { type: 'post', link: '/guides/tormented-affix', name: '10+ афікс' },
  ]
}
