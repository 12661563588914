export default {
  slug: 'castle-nathria',
  parent: 'raids',
  name: 'Замок Натрія',
  hint: 'Castle Nathria',
  image: '/img/castle-nathria.jpg',
  group: 'Тінеземлі',
  header: { parent: true },
  archive: true,
  meta: {
    description: 'Рейд Замок Натрія з доповнення Тінеземлі.',
    keywords: 'замок, натрія, касл, кастл, рейд, тінеземлі, шл, castle, nathria, raid, shadowlands, sl, wow'
  }
}
