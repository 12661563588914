export default {
  slug: 'globgrog',
  parent: 'plaguefall',
  name: 'Globgrog',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Ta-na' ],
  content: `
    <p>Бій нескладний, гера бурсти з пула, періодично бос випльовує **слимаків** з себе після чого вони
    повзуть назад до боса, при контакті лікують боса, **малі** на 10%, **великий** на 30%, ви можете
    вбивати всіх чи контролити всіх, або легше за все законтролити великого і швидко вбити малих.</p>

    <p>Уникайте __Slime Wave__, плює конусом, в додаток інколи кастує __Debilitating Plague__.</p>
  `,
  aside: [
    {
      type: 'spell',
      icon: 'ability_creature_disease_03',
      name: 'Debilitating Plague',
      desc: 'Відкидає гравців наносячи 2025 пошкоджень від природи кожні 2 сек та зменшує швидкість бігу на 30% протягом 20 сек.',
      whid: 324652
    },
    {
      type: 'spell',
      icon: 'ability_creature_poison_02',
      name: 'Slime Wave',
      desc: 'Робить фронтальний плювок наносячи 11718 пошкоджень від природи всім у конусі. В додаток, потрапивши під плювок гравці будуть зарутані і отримуватимуть 2929 пошкоджень від природи щосекунди протягом 6 сек.',
      whid: 324667
    },
  ],
  attachments: [
    { type: 'video', videoId: 'F6S3wPYPC6M', name: 'Аналіз М+20 від Денса (2й сезон)' },
    { type: 'video', videoId: 'vln2xil1cAI', name: 'Аналіз М+17 від Денса' },
    { type: 'post', link: '/guides/tormented-affix', name: '10+ афікс' },
  ]
}
