export default {
  slug: 'advisor-melandrus',
  parent: 'court-of-stars',
  name: 'Радник Меландр',
  hint: 'Advisor Melandrus',
  image: '/img/advisor-melandrus.jpg',
  meta: {
    description: 'Тактика бою проти радник Меландра в Зоряному Дворі.',
    keywords: 'меландр, меландрус, меландрій, радник, тактика, стратегія, механіка, гайд, бос, зоряний двір, курт, курт оф старс, підземелля, данж, мітік, м+, легіон, advisor, melandrus, court of stars, dungeon, legion'
  },
  header: { parent: true },
  sidenav: true,
  authors: [ 'Ta-na' ],
  content: `
    [p][image/advisor-melandrus.jpg][/p]

    [p][html]
      <a href="#melandrus" class="v-btn v-btn--contained v-btn--tile theme--light v-size--default secondary">
        я знаю як добратися давай тактику
      </a>
    [/html][/p]

    ## Початок

    [p]Аби потрапити до боса спочатку потрібно знайти шпигуна який сховався в кімнаті з гостями.
    Поговоріть з [npc/105296/Лі'лет], щоб отримати маскування, інакше вас викине з кімнати.[/p]

    [p][image/advisor-melandrus-1.jpg][/p]

    ## Шпигун

    [p]Гості дадуть вам п'ять підказок такого типу:
    [ul]
      [li]Чоловік чи жінка.[/li]
      [li]Жилет темного або світлого кольору.[/li]
      [li]Довгі рукави чи короткі/ без рукавів.[/li]
      [li]Рукавички або голі руки.[/li]
      [li]Плащ або без плаща.[/li]
      [li]Без речей на поясі.[/li]
      [li]Книжка на поясі.[/li]
      [li]Портмоне на поясі.[/li]
      [li]Флакони з зіллям на поясі.[/li]
    [/ul][/p]

    [p]Група має розділитися, аби одразу отримати всі підказки. Коли вони будуть зібрані,
    гравці можуть почати досліджувати підозрілих дворян на верхніх і нижніх поверхах, щоб
    ідентифікувати шпигуна.[/p]

    ## Шпигун альтернатива

    [p]Є два інших способи ідентифікації шпигуна, мисливці на демонів через зір
    [spell/188501/Spectral Sight], паладин танк з зовнішнім виглядом артефакту доповнення
    «Legion» побачать шпигуна. В обох випадках потрібно спочатку відкрити всі підказки.[/p]

    ## Мисливець на демонів

    [p]Потрібно стати перед стінкою, чи іншим об'єктом і після активації
    [spell/188501/Spectral Sight] шпигун буде обведений червоною лінією. Виділіть
    його — шпигуна знайдено.[/p]

    [p][image/advisor-melandrus-2.jpg][/p]

    ## Паладин танк

    [p]Якщо шпигун не далеко від гравця щит почне світитися, так ви зможе звузити коло до
    малої групи, використайте підказки і виявіть шпигуна.[/p]

    [p][image/advisor-melandrus-3.jpg][/p]

    ## Міні бос

    [p][npc/108151/Герент Мерзенний] має лише кілька здібностей.
    [spell/214692/Shadow Bolt Volley] вражає всю групу, завдаючи помірної шкоди від тіні,
    і всі вражені гравці отримують 15% додаткової шкоди від тіні.
    [spell/214688/Carrion Swarm] — це передній конус, який сильно б'є і лишає прокляття,
    можна розвіяти, на будь-яких гравців у зоні його дії. Всі окрім танка стійке за спиною.
    Після смерті Герента обшукайте тіло і візьміть ключ, він відкриє кімнату до Меландра.[/p]

    [html]<a id="melandrus" style="padding-top: 3.5em; opacity: 0;">&nbsp;</a>[/html]
    ## Меландр

    [p][npc/104218/Меландр] циклічно використовує три здібності, одна з яких створює копію,
    буде повторювати його заклинання. [spell/209628/Piercing Gale], [spell/209676/Slicing Maelstrom]
    і [spell/209667/Blade Surge], він завжди використовує їх у такому порядку.[/p]

    ## «Piercing Gale»

    [p]Завдає помірної шкоди гравцям, які стоять по прямій лінії перед і позаду Меландра,
    відкидає їх назад. Під час використання заклинання він нерухомий.[/p]

    ## «Slicing Maelstrom»

    [p]Завдає чотири сплески легкої шкоди всім гравцям протягом чотирьох секунд,
    неможливо уникнути.[/p]

    ## «Blade Surge»

    [p]Кидається у випадкового бійця дальнього бою та завдає шкоди всім гравцям
    у радіусі 5 ярдів. Залишає копію що повторюватиме всі заклинання.[/p]

    ## Цілителям

    [p]Доведеться використовувати сильні заклинання, щоб зцілити пошкоження від
    «Slicing Maelstrom», коли активні кілька копій.[/p]

    ## Наостанок

    [p]Уникайте вихорів, бродять по землі, якщо потрапите вас приголомшить,
    цілителю доведеться розсіювати.[/p]
  `
}
