export default {
  slug: 'amarth',
  parent: 'the-necrotic-wake',
  name: 'Amarth',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Ta-na' ],
  content: `
    <p>Гера і бурсти з пула, цей момент буде описаний на кожному босі, проте кд гери не відкотиться на всіх,
    заздалегідь розраховуйте хто для вас важчий. На тираніку не зайвим буде прихопити з собою молотки,
    списи і сфери (дві штуки).</p>

    <p>Головна механіка боса — це __Final Harvest__, кожен не вбитий **ад** б’є по групі, навіть один може
    вайпнути, в додаток серед **адів** є два **маги**, кастують __Frostbolt__ і __Frostbolt Volley__, саме
    __Frostbolt Volley__ створює найбільшу небезпеку, важливо інтераптити.</p>

    ## Ади

    <p>Вриваємось в **кастерів**, збивши каст вони тимчасово стають мілі тому їх можна водити — це дає
    можливість звести **адів** для кліву і масових станів. Списи, молотки, сфери використовуємо теж
    в них.</p>

    <p>Якщо в групі є: хант, друїд, пріст (можливо дк, не перевірено) вони можуть законтролити,
    бос з’їсть адів проте пошкоджень групі не нанесе.</p>

    ## Танк

    <p>Бос стакає __енрейдж__ (можна пуржити) і кастує __Necrotic Bolt__ потрібно інтераптити.
    За кожного з'їденого **ада** стакає збільшення шкоди, чим довше файт тим більше довбатиме.</p>

    <p>Бос кастує промінь __Necrotic Breath__ — run (біжи)!</p>
  `,
  aside: [
    {
      type: 'spell',
      icon: 'spell_necro_voodooblast',
      name: 'Necrotic Bolt',
      desc: 'Наносить 2051 пошкоджень від темної магії і поглинає наступні 1685 відхілу.',
      whid: 320170
    },
    {
      type: 'spell',
      icon: 'spell_necro_inevitableend',
      name: 'Necrotic Breath',
      desc: 'Наносить 4394 пошкоджень від темної магії кожні 0,5 сек, зменшує вхідний хіл на 50% і вішає дебаф __Necrotic Ichor__.',
      whid: 333493
    },
    {
      type: 'spell',
      icon: 'spell_necro_conclave',
      name: 'Necrotic Ichor',
      desc: 'Наносить 1465 пошкоджень від темної магії кожну секунду, зменшує вхідний хіл на 30% протягом 10 сек.',
      whid: 333492
    },
    {
      type: 'spell',
      icon: 'spell_shadow_unholyfrenzy',
      name: 'Unholy Frenzy',
      desc: 'Енрейдж, збільшує швидкість атаки на 40% на 16 сек. Можна пуржити.',
      whid: 320012
    },
    {
      type: 'spell',
      icon: 'spell_shadow_blackplague',
      name: 'Tortured Echoes',
      desc: '**Амарт** отримує силу коли навколо гинуть союзники чи противники. Кожне застосування раз в 3 секунди наносить противникам 352 пошкоджень від темної магії, ефект сумується.',
      whid: 333633
    },
    {
      type: 'spell',
      icon: 'spell_shadow_nightofthedead',
      name: 'Land of the Dead',
      desc: 'Прикликає **адів** протягом 4 секунд і наносить 7324 пошкоджень від темної магії в радіусі 3,5 метрів.',
      whid: 321226
    },
    {
      type: 'spell',
      icon: 'spell_frost_frostbolt02',
      name: 'Frostbolt Volley',
      namet: 'ад-маг',
      desc: 'Наносить 2929 пошкоджень від магії льоду і накладає ефект __Chilled__ на всіх гравців.',
      whid: 333623
    },
    {
      type: 'spell',
      icon: 'spell_frost_wisp',
      name: 'Chilled',
      desc: 'Зменшує швидкість пересування на 10% протягом 8 секунд, ефект стакаєтсья.',
      whid: 287295
    },
    {
      type: 'spell',
      icon: 'spell_necro_deathlyecho',
      name: 'Final Harvest',
      desc: 'Збирає жатву за кожного живого ада наносить 11718 пошкоджень всім гравцям, трупи адів теж вибухають наносячи 11718 в радіусі 8 метрів.',
      whid: 321258
    },
  ],
  attachments: [
    { type: 'video', videoId: 'Uj7gFqzCAS0', name: 'Аналіз М+20 від Денса (2й сезон)' },
    { type: 'video', videoId: 'LRwEfMAXtCg', name: 'Аналіз М+16 від Денса' },
    { type: 'post', link: '/guides/tormented-affix', name: '10+ афікс' },
  ]
}
