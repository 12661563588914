/* eslint-disable no-irregular-whitespace */
export default {
  slug: 'gaid-po-tsm4-ch1',
  parent: 'guides',
  name: 'Гайд по TSM4: ч.1 Ознайомлення',
  header: { parent: true },
  image: '/img/gaid-po-tsm4-ch1-2.png',
  authors: [ 'Prusl (Torvald)' ],
  content: `
    <p>~~Привіт вар’яти, думаю більшість із вас знають про існування такого потужного аддону
    як **TradeSkillMaster**, багато хто користується, але далеко не всі вміють працювати з тим
    потужним функціоналом доступ до якого дає TSM. Матеріалу по ньому в інтернеті, доволі
    багато, але більшість гайдів або застарілі або погано структуровані. Найкраще що зараз
    можна знайти то, мабуть, серія інструкцій (всього 14 статей) на сайті
    [link/https://thelazygoldmaker.com/category/tsm4-guide/The Lazy Goldmaker].
    Зараз перед вами переклад першої статті від **The Lazy Goldmaker**, в подальшому я планую
    перекласти весь цикл, але рекомендую також ознайомитися з оригіналом, бо першоджерело
    все ж краще, та й не треба буде чекати поки у мене знайдеться час, щоб перекласти все
    інше [icon/emoticon-outline]~~</p>

    ## Що таке TSM?

    <p>**TradeSkillMaster** — аддон для управління крафтом та торговими операціями у World of
    Warcraft. Це один з найпотужніших аддонів у грі і він допоможе вам купувати та продавати
    велику кількість предметів витрачаючи на це мінімум зусиль і часу. Без перебільшень це
    найкращий аддон для накопичення золота в грі. 7 пунктів про те, чому ж TSM такий корисний
    (і це далеко не повний список):<br>
    [icon/arrow-right-bold] Може дуже швидко виставити безліч лотів на аукціон<br>
    [icon/arrow-right-bold] Може швидко купити безліч лотів на аукціоні<br>
    [icon/arrow-right-bold] Дає вам свіжу інформацію про ціни на предмети<br>
    [icon/arrow-right-bold] Обраховує витрати на крафт предметів<br>
    [icon/arrow-right-bold] Може швидко переслати багато матеріалів вашим альтам<br>
    [icon/arrow-right-bold] Може швидко вивільнити вашу поштову скриньку<br>
    [icon/arrow-right-bold] Веде детальну статистику ваших продаж і покупок</p>

    ## Основна перевага TSM

    <p>Основною перевагою TSM є те, що він може автоматизувати для вас прийняття
    рішень, які стосуються торгівлі на аукціоні. Якщо ви серйозно вирішили заробляти золото
    через торгівлю на аукціоні, то рано чи пізно дійдете до моменту коли потрібно буде купувати
    і продавати величезну кількість товарів, якщо робити це через базовий інтерфейс, то воно
    займатиме дуже багато часу.</p>

    <p>TSM дозволяє заздалегідь налаштувати, ціни для різних категорій товарів, після чого
    ви зможете швидко купувати і продавати товари, можна навіть налаштувати макрос, який
    прив’язується до скролу, щоб пришвидшити процес ще більше.</p>

    ## Завантаження і установка

    <p>Найбільш надійний спосіб отримати TSM це просто завантажити його з
    [link/https://www.tradeskillmaster.com/install/офіційного сайту].</p>

    <p>Вам потрібно завантажити програму клієнт для ПК, а також обов’язково створити
    обліковий запис на сайті. Також тут ви можете завантажити два аддони **TSM4** і **AppHelper**,
    але їх можна встановити і через curse клієнт. Настільна програма, буде автоматично витягувати
    актуальну інформацію про ціни і синхронізувати їх з аддоном в грі, то ж вам не потрібно буде
    про це турбуватися.</p>

    <p>Після того, як ви авторизуєтесь в програмі, вам потрібно вибрати інформацію про ціни
    на яких серверах ви хочете отримувати. Щоб зробити це, потрібно вибрати Settings а потім
    Setup Realms. Це перенаправить вас на сайт, де ви зможете вибрати, для яких серверів
    потрібно завантажувати інформацію.<br>
    [image/gaid-po-tsm4-ch1-1.png]</p>

    ## Інтерфейс

    <p>Що ж ми встановили програмку для ПК і аддон для гри, час швиденько оглянути, що ж
    змінилося. TSM змінить інтерфейс аукціону, поштової скриньки, там меню крафту. Не
    хвилюйтеся ви можете переключатися між стандартними інтерфейсами та інтерфейсом TSM,
    для цього є окрема кнопочка на кожному із вікон. В цій статті, ми будемо говорити про
    інтерфейс аукціону, але надалі обов’язково доберемося до крафту та поштової скриньки.</p>

    ### Основний модуль

    <p>Це, мабуть, найважливіша частина TSM, бо тут ви будете його налаштовувати, нажаль,
    це не той аддон, який можна просто встановити одразу користуватися, для початку вам
    необхідно налаштувати багато різних параметрів.</p>

    <p>Щоб відкрити дане вікно просто введіть команду <code>/TSM</code> в чат (також можна відкрити
    через іконку TSM біля міні карти), це відкриє **Dashboard** де буде відображатися статистика
    ваших торгових операцій.<br>
    [image/gaid-po-tsm4-ch1-2.png]</p>

    ### Groups and Operations

    <p>Найважливіші налаштування в TSM робляться через меню Groups та меню Operations.</p>

    <p>Groups – це набори предметів (товарів) які ви хочете обробляти за однаковими
    правилами. Вони можуть бути, як малими так і великими, а також ви можете робити
    вкладені групи, щоб таким чином ділити товари на підкатегорії.</p>

    <p>Нижче ви можете побачити приклад групи BfA Raw, як бачите група розбита на
    підгрупи за типом сировини, які в свою чергу включають підгрупи для окремих матеріалів.<br>
    [image/gaid-po-tsm4-ch1-3.png]</p>

    <p>Інше надзвичайно важливе меню це Operations, тут ви налаштовуєте набори правил,
    які будуть застосовуватись для управління вашими групами. Нижче ви можете побачити, що
    є 7 видів операцій. Найважливішими серед них є Shopping та Auctioning, тому що саме тут
    налаштовуються правили для вашої торгівлі на аукціоні.<br>
    [image/gaid-po-tsm4-ch1-4.png]</p>

    ## Інтерфейс аукціону

    <p>Це елемент з яким ви найчастіше буде працювати, користуючись TSM. В інтерфейсі
    аукціону є чотири основні вкладки, всі вони знаходяться в хедері вікна і призначення кожної
    доволі зрозуміле з їхніх назв, але давайте пройдемося по кожній трішки детальніше.</p>

    ### My Auctions

    <p>Тут відображається перелік ваших лотів на аукціоні, також звідси ви можете швидко
    зняти з аукціону лоти, робиться це відповідно до прави які налаштовуються меню Operations.<br>
    [image/gaid-po-tsm4-ch1-5.png]</p>

    ### Auctioning

    <p>На цій вкладці ви виставляєте свої лоти на аукціон. За замовчуванням в TSM є група
    Default яка містить всі ваші товари. По суті, ви таки зможете використовувати його одразу
    після того як встановили, з метою швидкого розміщення товарів, однак, щоб отримувати
    прибуток, такі потрібно буде налаштувати список груп і операції, після чого ви зможете
    вибирати товари з яких груп ви хочете виставити на аукціон, а з яких ні.<br>
    [image/gaid-po-tsm4-ch1-6.png]</p>

    ### Shopping

    <p>На цій вкладці ви можете купувати лоти з аукціону. Ви можете створити для себе
    кастомні правила для пошуку, або просто за назвою товару чи з використанням стандартних
    фільтрів з базового інтерфейсу аукціону. В наступних статтях ми детальніше розглянемо
    доступні способи пошуку на аукціоні.<br>
    [image/gaid-po-tsm4-ch1-7.png]</p>

    ### Sniper

    <p>Призначення даної вкладки, мабуть найменш очевидне з поміж усіх чотирьох. Sniper
    сканую аукціон шляхом постійного оновлення найновішої сторінки. Мета цього процесу в
    тому, виловити товари викладені за ціною нижчою від ринкової, як тільки їх виставлять на
    продаж, щоб и могли викупити їх швидше за інших.<br>
    [image/gaid-po-tsm4-ch1-8.png]</p>

    ## Кілька слів на завершення

    <p>В даній статті ми розглянули процес установки і зробили загальний огляд основних
    вікон TSM. В наступній статті детальніше розглянемо групи, розберемося як їх
    налаштовувати, як ми можемо імпортувати список товарів, а також як і навіщо робити
    вкладені групи.</p>

    <p>Після груп перейдемо до операцій, розберемося як використовувати TSM для купівлі і
    продажу товарів, після чого перейдемо до крафту і роботи з поштовою скринькою. Так
    поступово ми з вами розглянемо всі можливості аддону і навчимося користуватися ним.</p>

    <p>
    **Посилання на оригінальну статтю:**<br>
    [link/https://thelazygoldmaker.com/the-lazy-goldmakers-tsm4-guide-part-1-introduction/The Lazy Goldmakers TSM4 guide: Part 1 Introduction].<br>
    <br>
    **Переклав:** Prusl (Torvald).
    </p>
  `
}
