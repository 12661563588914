export default {
  slug: 'talixae-flamewreath',
  parent: 'court-of-stars',
  name: 'Талікса Вінець Вогню',
  hint: 'Talixae Flamewreath',
  image: '/img/talixae-flamewreath.jpg',
  meta: {
    description: 'Тактика бою проти Талікси Вінця Вогню в Зоряному Дворі.',
    keywords: 'талікса, вінець вогню, флеймврез, тактика, стратегія, механіка, гайд, бос, зоряний двір, курт, курт оф старс, підземелля, данж, мітік, м+, легіон, talixae, flamewreath, court of stars, dungeon, legion'
  },
  header: { parent: true },
  sidenav: true,
  authors: [ 'Ta-na' ],
  content: `
    [p][image/talixae-flamewreath.jpg][/p]

    [p][npc/104217/Талікса] охороняєтся трьома Емісарами, вони підсилюють один одного аурою
    [spell/209722/Bond of Flame] збільшуючи пошкодження і здоров’я одне одного, потрібно спочатки
    вбити окремо кожного Емісара і потім боса, бій з демонами не вважається боєм з босом.
    Викликати Емісара можна вбивши [npc/104278/Felbound Enforcer] чи створити диверсію.[/p]

    [p][image/talixae-flamewreath-1.jpg]
    ~~Фото дренейки~~[/p]

    ## Диверсії

    [p]Не далеко від боса розкидані предмети, що створюють диверсію і викликають демона до
    себе. Існує чотири предмети для диверсій, один чи два предмети з’являються випадково.
    [ul]
      [li]Майстри шкіри або воїни можуть взаємодіяти з базарними товарами, піднімаючи магічну
      тривогу.[/li]
      [li]Кравці або цілителі можуть допомогти пораненому нічнонародженому цивільному, який
      кричить і тікає, коли йому допомагають «сторонні».[/li]
      [li]Шахтарі або ювеліри можуть знищити статую нічнонародженого.[/li]
      [li]Мисливці чи ковалі можуть взаємодіяти з викинутим мотлохом, щоб створити пастку,
      яка видає гучний звук під час встановлення; якщо Емісар пролітає над цією пасткою —
      миттєво гине.[/li]
    [/ul][/p]

    ## Талікса

    [p]Від початку і до кінця буде підсилювати себе аурою [spell/207906/Burning Intensity],
    перервати чи розсіяти неможливо, розрахуйте власний захист і здібності цілителя. Переривайте
    закринання [spell/208165/Withering Soul]. Періодично Талікса буде робити
    [spell/207881/Infernal Eruption] — це заклинання створює калюжі під ногами гравців, при
    контакті підкине й нанесе значної шкоди (вважайте що це міна), також в калюжах з’являться
    біси і почнуть атакувати вас.[/p]

    ## «Infernal Eruption»

    [p]Протидіяти цій магії не складно, вам потрібно до застосування стояти кучно, жопка до жопки,
    і відходити коли Талікса починає читати заклинання. Бісів які з'являться потрібно швидко вбити,
    використовуйте всі доступні вам види контролю.[/p]

    [p]Цикл повторюється приблизно кожні 25 секунд, найкраще завжди знаходитись близько до танка
    і на початку заклинання відійти, після перемоги над бісами знов зійтись. Поділіть контроль ще
    до початку бою.[/p]

    [p][image/talixae-flamewreath-2.jpg]
    ~~Фото інфернал ерапшен~~[/p]
  `
}
