export default {
  slug: 'sennarth',
  parent: 'vault-of-the-incarnates',
  name: 'Сеннарт',
  hint: 'Sennarth',
  image: '/img/sennarth.jpg',
  meta: {
    description: 'Тактика бою проти Сеннарт у Сховищі втілень.',
    keywords: 'сеннарт, сенарт, тактика, стратегія, механіка, гайд, бос, сховище втілень, sennarth, vault of the incarnates'
  },
  header: { parent: true },
  sidenav: true,
  authors: [ 'Semerkhet' ],
  content: `
    ## Загальна інформація

    [p]Вибір талантів -- Соло, іноді з'являються моби (але є пріоритетні).
    Вся боротьба з [npc/187967/Сеннарт] ділиться на 2 фази, в кінці Enrage.
    Гвинтові сходи з платформами. Бос поміщений в центрі над урвищем і поступово
    піднімається вгору. По гвинтових сходах рейд піднімається майже постійно.
    Скелі з усіх боків і дуже слизькі.[/p]

    [p]Головна задача -- розкидати павутину, щоб не сповзати з платформ і сходів.
    Під стяжку / відштовхування, теж встати в павутиння. Павутиння стакається.
    Очистити можна виверженням.[/p]

    [p]Heroism (Bloodlust) дається від початку, щоб у фазі Enrage бос мав
    якомога менше HP і було мінімум трупів серед рейдерів.[/p]

    [p]У фазі 1 буде 6 зачіпок, а на полі бою 6 колон, ви можете використовувати
    їх, щоб не впасти в прірву (від абілки боса).[/p]

    ## Моби

    [p]На початку бою з'являються [npc/189234/Frostbreath Arachnid] (x1)
    і [npc/189233/Caustic Spiderling] (x5).[/p]

    [p]**Frostbreath Arachnid** (на кожній платформі буде загалом 3 моби за битву):[/p]

    [ul sparse]
      [li][spell/374112/Freezing Breath] -- фронтальний конус. Завдає велику шкоду
      та залишає __Icy Ground__.[/li]
      [li][spell/373817/Chilling Aura] -- оточує себе аурою, завдає середньої шкоди
      рейду та збільшує вразливість до холоду на 25%. Коли виходить цей моб, енергія
      боса скидається (можливо, цю механіку можна запланувати, щоб уникнути непотрібних
      накладань механік).
      [/li]
    [/ul]

    [p]**Caustic Spiderling** (ці моби з'являтимуться протягом бою):[/p]

    [ul sparse]
      [li][spell/372045/Caustic Eruption] -- вибухає після смерті, завдаючи помірної
      шкоди в радіусі 5 метрів. Знищує павутиння на землі, видаляє стаки з гравців,
      знищує кокони.[/li]
      [li][icon/skull] Міфік: __Caustic Eruption__ завдає дебаффу +500% вхідної шкоди
      від природи на 30 секунд.[/li]
    [/ul]

    ### ДЛЯ ТАНКІВ

    [ul]
      [li]__Tank Strike__ в основного танка - кожні 5 секунд, зміна 7-8 стеків
      (за відчуттями);[/li]
      [li]Другий танк тримає мобів.[/li]
      [li]Плануйте вбити Caustic Spiderling біля Коконів, щоб знищити їх.[/li]
      [li]Не вбивайте Caustic Spiderling біля калюж павутини, які ви плануєте
      використовувати.[/li]
      [li]Відвернути морозного павука від рейду (кліває).[/li]
    [/ul]

    ### ДЛЯ ЦІЛИТЕЛІВ

    [ul]
      [li]Використовуйте сейви на __Gossamer Burst__ (на всіх фазах).[/li]
      [li]__Chiling Blast__ не діспелити (ви можете вбити когось).[/li]
      [li]Поступове накопичення вразливості у фазі 2. Сейви залишайте під
      відштовхування.[/li]
    [/ul]

    ### ДЛЯ ДПС

    [ul]
      [li]Розкладіть __Sticky Webbing__ до краю платформи, щоб запобігти її
      притягненню до боса.[/li]
      [li]Відійдіть від рейду з дебафом __Chilling Blast__.[/li]
      [li]Зосередитися на вбивстві Frostbreath Arachnid.[/li]
      [li]Heroism (Bloodlust) і ваші бурсти з початку бою, щоб до 5:00
      у боса було якомога менше HP.[/li]
    [/ul]

    ### ЛАЙФГАКИ

    [ul]
      [li]У мисливців на демонів можуть виникнути проблеми з rushing /
      hunting -- ви можете полетіти в прірву.[/li]
      [li]Будь-які відриви/ривки, які відбуваються на слизькій землі або
      мають слизьку землю на своєму шляху, перетворюються на дуже довгий
      і не дуже контрольований політ. Будьте обережні, кидаючись до боса.
      Але це можна робити на сходах.[/li]
      [li]Колона не дасть бігти разом. При належній вправності ви можете
      ігнорувати павутину.[/li]
      [li][icon/alert] ~~Ворота чорнокнижників працюють через стовпи/шипи
      та інші текстури вздовж краю сходів (можливо, це помилка, яка з часом
      буде виправлена, але поки працює -- можна використовувати).~~[/li]
      [li]Лицарі смерті можуть ігнорувати стяжки.[/li]
      [li]Імунери можуть зруйнувати __Glacial Plume__ у фазі 2.[/li]
      [li]Кожну другу (парну чи непарну) стяжку монах може пережити за
      допомогою переносу. А решта -- стоячи за колонами. Здібності, що
      прискорюють, доцільно використовувати на сходах. У другій фазі те саме,
      але обережно використовуйте стрибки.[/li]
      [li]Льодовикові шлейфи, які відкидають назад, не врятують вас від
      штовхання боса.[/li]
      [li]Freedom паладінів знімає стеки __Sticky Webbing__.[/li]
    [/ul]

    [p][image/sennarth-1.jpg][/p]
  `,
  aside: [
    {
      type: 'image',
      src: '/img/sennarth.jpg'
    },
    {
      type: 'header',
      name: 'Сеннарт'
    },
    {
      type: 'header',
      name: 'Фаза 1'
    },
    {
      type: 'spell',
      whid: 372765,
      icon: 'spell_frost_frostblast',
      name: 'Breath of Ice',
      desc: `
        Створює __Icy Ground__. Гравці ковзають по ньому та отримують періодичні
        пошкодження.
      `
    },
    {
      type: 'spell',
      whid: 371976,
      icon: 'spell_fire_blueflamering',
      name: 'Chilling Blast',
      desc: `
        Покриває всіх гравців дебафом на 4 сек. Після завершення або розвіювання
        завдає шкоди на 75 тис. іншим гравцям у радіусі 4 м. Логічно розійтися і
        ні в якому разі не діспелити!
      `
    },
    {
      type: 'spell',
      whid: 372082,
      icon: 'inv_misc_web_01',
      name: 'Enveloping Webs',
      desc: `
        Огортає павутинням кількох гравців на 6 сек. Періодичні важкі пошкодження.
        Під ногами з'являється __Sticky Webbing__.
      `
    },
    {
      type: 'spell',
      whid: 372030,
      icon: 'spell_nature_web',
      name: 'Sticky Webbing',
      desc: `
        Протягом 60 сек захищає від ковзання / руху. Зменшує швидкість руху на 50%,
        а також накопичується під час стояння в павутинні. __Caustic Eruption__
        (від павука) знімає ефект.
        [br][br]
        [icon/skull] Міфік: __Sticky Webbing__ триває 10 хв. Після 5 стаків павутини
        гравець заковується в __Enveloping Webs__ (стан на 30 сек). Цей ефект можна
        усунути за допомогою __Caustic Eruption__.
      `
    },
    {
      type: 'spell',
      whid: 373405,
      icon: 'priest_icon_chakra',
      name: 'Gossamer Burst',
      desc: `
        Заплутує гравців, завдаючи серйозної фізичної шкоди та затягуючи їх у скелю.
        Тут ми застосовуємо прискорення, а також розташовуємося позаду __Sticky Webbing__
        (коли набере 100 енергії).
      `
    },
    {
      type: 'spell',
      whid: 372238,
      icon: 'inv_misc_monsterspidercarapace_01',
      name: 'Call Spiderlings',
      desc: `
        Викликає Frostbreath Arachnid і Caustic Spiderling.
      `
    },
    {
      type: 'spell',
      whid: 372129,
      icon: 'sha_spell_fire_bluepyroblast',
      name: 'Web Blast',
      desc: `
        Завдає шкоди та накладає негативний ефект на +50% вхідної шкоди на 20 сек.
      `
    },
    {
      type: 'spell',
      whid: 396795,
      icon: 'spell_frost_ring-of-frost',
      name: 'Gusting Rime',
      desc: `
        Ставить стовпи льоду. Гравці відкидаються після контакту з ними.
      `
    },
    {
      type: 'header',
      name: 'Фаза 2 (приблизно 05:00)'
    },
    {
      type: 'spell',
      whid: 372539,
      icon: 'spell_fire_bluecano',
      name: 'Apex of Ice',
      desc: `
        Босиня виповзає нагору охоплює себе щитом на -99% вхідної шкоди. Завдає AoE
        пошкодження та застосовує негативний ефект. Необхідно якомога швидше дістатися
        і перервати заклинання. Остання платформа тепер слизька всюди.
      `
    },
    {
      type: 'spell',
      whid: 371979,
      icon: 'spell_frost_frostnova',
      name: 'Frost Expulsion',
      desc: `
        Тепер __Frost Expulsion__ (здібності __Chiling Blast__) залишає __Glacial Plume__.
        Кладе зону, яка завдає великої шкоди та відкидає гравців, що її торкаються.
        Якщо дозволяє таймер, можна компактно розкласти по краю, щоб не заважало.
      `
    },
    {
      type: 'spell',
      whid: 373027,
      icon: 'inv_misc_web_02',
      name: 'Suffocating Webs',
      desc: `
        Стріляє в кількох гравців. Накладає негативний ефект на 13 тис/сек на 6 сек.
        Наприкінці дебаффу гравців відкидає назад, загортають у __Wrapped in Web__,
        а поруч з ними формується __Sticky Webbing__.
        [br][br]
        Властивості __Wrapped in Webs__ і __Sticky Webbing__ однакові.
      `
    },
    {
      type: 'spell',
      whid: 371983,
      icon: 'ability_vehicle_sonicshockwave',
      name: 'Repelling Burst',
      desc: `
        Завдає помірної шкоди та відштовхує гравців (не стійте на краю платформи).
      `
    },
    {
      type: 'spell',
      whid: 372736,
      icon: 'spell_frost_arcticwinds',
      name: 'Permafrost',
      desc: `
        Починає поступово заповнювати простір лігва льодом знизу. Періодичні
        пошкодження, накопичувальні.
      `
    },
  ]
}
