/* eslint-disable no-irregular-whitespace */
export default {
  slug: 'reitingovi-polya-bitv',
  parent: 'guides',
  name: 'Рейтингові поля битв',
  tags: [ 'guide', 'pvp' ],
  header: { parent: true },
  authors: [ 'Kørwin' ],
  content: `
    ## Основи

    <p>Метою є перемога на RBG рейдом з 10 гравців. Для досягнення мети використовують стратегію і тактику.</p>

    <p>Рейтинг на RGB у Shadowlands має таку саму вагу як і арени, що дозволяє підвищувати ilvl у брокера в Орібосі за хонор.</p>

    <p>На RBG діють аналогічні обмежання як і в аренах (інженерія, деякі абілки, порізка хілу і т.п.).</p>

    ### Корисні адони

    <ul>
      <li>BattleGroundEnemies</li>
      <li>Capping Battleground Timers</li>
    </ul>

    ### Корисні макроси та консольні команди

    <ul>
      <li>Дозволяє скинути прапор на WG чи EOS. Наприклад, щоб його підібрав танк.
        [pre]
          /stopcasting
          /cancelaura Horde Flag
          /cancelaura Netherstorm Flag
        [/pre]
      </li>
      <li>Дозволяє віддаляти камеру далі.
        [pre]
          /console cameraDistanceMaxFactor 2.6
        [/pre]
      </li>
    </ul>

    ### Корисні ресурси для отримання знань

    <ul>
      <li>Стрім [icon/twitch] [link/https://www.twitch.tv/bobydk1/bobydk1]</li>
      <li>Стрім [icon/twitch] [link/https://www.twitch.tv/eddylol1/eddylol1]</li>
    </ul>

    ## Стратегія

    <p>Стратегія - це глобальні маневри та поведінка на всьому полі бою. Яку базу варто зайняти, яку захищати, коли потрібно сапнути флагоносця, провести диверсію і т.п.
    За стратегію відповідає лідер рейду.</p>

    #### Посада лідера рейда передбачає

    <ul>
      <li>досконале знання стратегій на всіх BG, а також можливостей їх реалізації наявною командою</li>
      <li>визначення початкової стратегії</li>
      <li>корегування стратегії зі зміною ситуації</li>
      <li>постійний моніторинг карти та ворожих гравців. Наприклад якщо лідер рейду бачить що троє із ворожої тіми знаходяться в стелсі - можливо це сигнал про те що вони хочуть забрати нашу слабкозахищену базу (як от конюшня на Араті).</li>
    </ul>

    ## Тактика

    <p>Тактика - короткочасні та відносно швидкі маневри у тімфайті. <em>Death grip</em> (DK) + <em>Shining force</em> (Priest) дозволить витягнути ворожого хіла і зкинути його в безодню на EOS, це приклад тактики.
    За тактику відповідає <em>Target caller</em> (ТС).</p>

    #### Посада ТС передбачає

    <ul>
      <li>Досконале знання пвп можливостей всіх класів і їх спеків</li>
      <li>Визначення початкової тактики у тімфайті</li>
      <li>Корегування тактики у тімфайті</li>
      <li>постійний моніторинг карти та ворожих гравців у тімфайті</li>
      <li>Встановлення цілей фокусу демагу для учасників тімфайту</li>
    </ul>

    ## Рейд

    <p>Рейд складається із одного танку, 3 хілів та 6 дпс.</p>

    #### Хороший рейд виглядає так

    <ul>
      <li>__Танк:__ Boomkin off-spec</li>
      <li>__Хіли:__ Disc Priest, Disc Priest, Holy Palladin</li>
      <li>__Дпс:__ Boomkin, Boomkin, Sub Rogue, Retro paladin, Frost DK, Arms Warrior</li>
    </ul>

    <p style="margin-bottom: 0">Можливі заміни:</p>
    <ul>
      <li>__Танк:__ DH</li>
    </ul>

    <p>Часто може бути так, що Рейд Лідер і ТС - це одна і та ж особа.</p>

    <p>Учасники рейду мають негайно виконувати команди Рейд Лідера та ТС.
    Всі учасники мають дбати про ilvl свого пвп гіру, мати потрібні легендарки, вдосконалювати свої навички на Арені (краще у 3х3),
    брати участь у аналітиці після матчу.</p>

    ## На останок

    <p>Якщо ви новачок у пвп (як я), ось декілька корисних ресурсів що допожуть визначитися з розкладкою, талантами,
    гіром та підказками для вашого класу:</p>

    <ul>
      <li>[icon/link] [link/https://www.skill-capped.com/wow/сайт Skill Capped] (платний)</li>
      <li>[icon/youtube] [link/https://www.youtube.com/channel/UCJfn3qHQ-Qy4xQDtSW3XT5Q/відео гайди Skill Capped]</li>
      <li>[icon/discord] [link/https://discord.gg/TsV2yVvG/діскорд Skill Capped]</li>
    </ul>
  `
}
