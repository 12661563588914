const posts = [

  // pages
  require('./posts/pages/page-not-found'),
  require('./posts/pages/home'),
  require('./posts/pages/guides'),
  require('./posts/pages/dungeons'),
  require('./posts/pages/raids'),

  // guides
  require('./posts/guides/talanti-alhimii'),
  require('./posts/guides/znayomstvo-probydnuk-cilitel'),
  require('./posts/guides/noviy-afiks-s4-shrouded'),
  require('./posts/guides/m-plus-hitroschi-dlya-dh-tanka'),
  require('./posts/guides/tormented-affix'),
  require('./posts/guides/yak-proytu-nepomichenum-v-klyuchah'),
  require('./posts/guides/reitingovi-polya-bitv'),
  require('./posts/guides/gaid-po-tsm4-ch1'),
  require('./posts/guides/prokachka-kylinarii-shadowlands'),
  require('./posts/guides/putivnuk-po-resyrsam'),
  require('./posts/guides/pereklad-max-dps'),
  require('./posts/guides/reid-lider'),
  require('./posts/guides/osnovu-tankyvannya'),
  require('./posts/guides/markup'),

  // =================================================
  // ===================== RAIDS =====================
  // =================================================

  // Vault of the Incarnates
  require('./posts/vault-of-the-incarnates/overview'),
  require('./posts/vault-of-the-incarnates/eranog'),
  require('./posts/vault-of-the-incarnates/terros'),
  require('./posts/vault-of-the-incarnates/the-primal-council'),
  require('./posts/vault-of-the-incarnates/sennarth'),
  require('./posts/vault-of-the-incarnates/dathea-ascended'),
  require('./posts/vault-of-the-incarnates/kurog-grimtotem'),
  require('./posts/vault-of-the-incarnates/broodkeeper-diurna'),
  require('./posts/vault-of-the-incarnates/raszageth'),

  // Sanctum of Domination
  require('./posts/sanctum-of-domination/overview'),
  require('./posts/sanctum-of-domination/the-tarragrue'),
  require('./posts/sanctum-of-domination/eye-of-the-jailer'),
  require('./posts/sanctum-of-domination/the-nine'),
  require('./posts/sanctum-of-domination/remnant-of-nerzhul'),
  require('./posts/sanctum-of-domination/soulrender-dormazain'),
  require('./posts/sanctum-of-domination/painsmith-raznal'),
  require('./posts/sanctum-of-domination/guardian-of-the-first-ones'),
  require('./posts/sanctum-of-domination/fatescribe-roh-kalo'),
  require('./posts/sanctum-of-domination/kelthuzad'),
  require('./posts/sanctum-of-domination/sylvanas-windrunner'),

  // Castle Nathria
  require('./posts/castle-nathria/overview'),
  require('./posts/castle-nathria/shriekwing'),
  require('./posts/castle-nathria/huntsman-altimor'),
  require('./posts/castle-nathria/sun-kings-salvation'),
  require('./posts/castle-nathria/artificer-xy-mox'),
  require('./posts/castle-nathria/hungering-destroyer'),
  require('./posts/castle-nathria/lady-inerva-darkvein'),
  require('./posts/castle-nathria/the-council-of-blood'),
  require('./posts/castle-nathria/sludgefist'),
  require('./posts/castle-nathria/stone-legion-generals'),
  require('./posts/castle-nathria/sire-denathrius'),

  // Ny'alotha, The Waking City
  require('./posts/nyalotha/overview'),
  require('./posts/nyalotha/wrathion'),
  require('./posts/nyalotha/prophet-skitra'),
  require('./posts/nyalotha/maut'),
  require('./posts/nyalotha/dark-inquisitor-xanesh'),
  require('./posts/nyalotha/the-hivemind'),
  require('./posts/nyalotha/vexiona'),
  require('./posts/nyalotha/ra-den'),
  require('./posts/nyalotha/shadhar-the-insatiable'),
  require('./posts/nyalotha/drestagath'),
  require('./posts/nyalotha/ilgynoth'),
  require('./posts/nyalotha/carapace-of-nzoth'),
  require('./posts/nyalotha/nzoth-the-corruptor'),

  // ==================================================
  // ==================== DUNGEONS ====================
  // ==================================================

  // Court of Stars
  require('./posts/court-of-stars/overview'),
  require('./posts/court-of-stars/patrol-captain-gerdo'),
  require('./posts/court-of-stars/talixae-flamewreath'),
  require('./posts/court-of-stars/advisor-melandrus'),

  // Halls of Atonement
  require('./posts/halls-of-atonement/overview'),
  require('./posts/halls-of-atonement/halkias'),
  require('./posts/halls-of-atonement/echelon'),
  require('./posts/halls-of-atonement/high-adjudicator-aleez'),
  require('./posts/halls-of-atonement/lord-chamberlain'),

  // De Other Side
  require('./posts/de-other-side/overview'),
  require('./posts/de-other-side/hakkar-the-soulflayer'),
  require('./posts/de-other-side/the-manastorms'),
  require('./posts/de-other-side/dealer-xyexa'),
  require('./posts/de-other-side/muehzala'),

  // Sanguine Depths
  require('./posts/sanguine-depths/overview'),
  require('./posts/sanguine-depths/kryxis-the-voracious'),
  require('./posts/sanguine-depths/executor-tarvold'),
  require('./posts/sanguine-depths/grand-proctor-beryllia'),
  require('./posts/sanguine-depths/gerenal-kaal'),

  // The Necrotic Wake
  require('./posts/the-necrotic-wake/overview'),
  require('./posts/the-necrotic-wake/blightbone'),
  require('./posts/the-necrotic-wake/amarth'),
  require('./posts/the-necrotic-wake/surgeon-stitchflesh'),
  require('./posts/the-necrotic-wake/nalthor-the-rimebinder'),

  // Tazavesh
  require('./posts/tazavesh/overview'),
  require('./posts/tazavesh/zophex'),
  require('./posts/tazavesh/the-grand-menagerie'),
  require('./posts/tazavesh/post-master'),
  require('./posts/tazavesh/myzas-oasis'),
  require('./posts/tazavesh/soazmi'),
  require('./posts/tazavesh/hylbrande'),
  require('./posts/tazavesh/timecapn-hooktail'),
  require('./posts/tazavesh/soleah'),

  // Theater of Pain
  require('./posts/theater-of-pain/overview'),
  require('./posts/theater-of-pain/affront-of-challengers'),
  require('./posts/theater-of-pain/gorechop'),
  require('./posts/theater-of-pain/xav-the-unfallen'),
  require('./posts/theater-of-pain/kultharok'),
  require('./posts/theater-of-pain/mordretha-the-endless-empress'),

  // Mists of Tirna Scithe
  require('./posts/mists-of-tirna-scithe/overview'),
  require('./posts/mists-of-tirna-scithe/ingra-maloch'),
  require('./posts/mists-of-tirna-scithe/mistcaller'),
  require('./posts/mists-of-tirna-scithe/tredova'),

  // Plaguefall
  require('./posts/plaguefall/overview'),
  require('./posts/plaguefall/globgrog'),
  require('./posts/plaguefall/doctor-ickus'),
  require('./posts/plaguefall/domina-venomblade'),
  require('./posts/plaguefall/margrave-stradama'),

  // Spires of Ascension
  require('./posts/spires-of-ascension/overview'),
  require('./posts/spires-of-ascension/kin-tara'),
  require('./posts/spires-of-ascension/ventunax'),
  require('./posts/spires-of-ascension/oryphrion'),
  require('./posts/spires-of-ascension/devos'),

]

// strip "default" prop

for (let i = 0; i < posts.length; i++) {
  if (typeof posts[i].default == 'object') {
    posts[i] = posts[i].default
  }
}

// build "link" and "redirectLink" props

function buildLinks(post) {
  if (!post.link) {
    if (post.parent) {
      const parent = posts.find(p => p.slug == post.parent)
      if (parent) {
        if (!parent.link) {
          buildLinks(parent)
        }
        post.link = parent.link + '/' + post.slug
      } else {
        window.console.warn(`FIXME! Failed to find post with slug "${post.parent}", referenced by "${post.slug}" via "parent". This post will have no parent.`)
        post.link = '/' + post.slug
        delete post.parent
      }
    } else {
      post.link = '/' + post.slug
    }
  }

  if (post.redirect && !post.redirectLink) {
    const target = posts.find(p => p.slug == post.redirect)
    if (target) {
      if (!target.link) {
        buildLinks(target)
      }
      post.redirectLink = target.link
    } else {
      window.console.warn(`FIXME! Failed to find post with slug "${post.redirect}", referenced by "${post.slug}" via "redirect". This post will redirect to home.`)
      post.redirect = 'home'
      post.redirectLink = '/'
    }
  }
}

for (const post of posts) {
  buildLinks(post)
}

// all done

export default posts
