export default {
  slug: 'halkias',
  parent: 'halls-of-atonement',
  name: 'Halkias',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Ta-na' ],
  content: `
    ## Pull

    <p>Бос має ауру __Light of Atonement__, якщо ви поза нею то отримаєте фір __Sinlight Visions__, він перманентний,
    тож пуляйте боса, коли всі знаходяться біля нього. Якщо хтось попав під фір, підведіть боса до цього гравця, щоб
    аура дістала. Гера й бурсти з пула.</p>

    <p>Механіка боса така — він кидає каміння на рандомного гравця __Heave Debris__, тож тримаємо спред, щоб шкоду
    отримав тільки один.</p>

    <p>Довбе танка __Crumbling Slam__, що теж лишає калюжу, тож танкам протискатись.</p>

    <p>І наостанок, бос кастує __Refracted Sinlight__, промені що боляче смалять, тримайтесь між ними. Краще
    відслідковувати абілку і заздалегідь відводити боса на чисте.</p>

    <p>Рекомендуємо починати файт в кишені де бос, щоб максимально економити місце, кладіть каміння/калюжі під стіну
    і поруч одне до одної, тримайте спред.</p>
  `,
  aside: [
    {
      type: 'spell',
      icon: 'inv_misc_stonetablet_05',
      name: 'Crumbling Slam',
      desc: 'Голіаф опускає свої масивні кам’яні руки і б’є по землі перед собою. Наносить 14647 Фізичної шкоди ворогам в радіусі 6 метрів і залишає на місці удару __Glass Shards__.',
      whid: 322936
    },
    {
      type: 'spell',
      icon: 'inv_misc_food_legion_goochocovanilla_debris',
      name: 'Heave Debris',
      desc: 'Голіаф жбурляє у випадкового ворога уламком, що наносить 8788 Фізичної шкоди усім ворогам в радіусі 6 метрів від точки падіння та залишає по собі __Glass Shards__.',
      whid: 322943
    },
    {
      type: 'spell',
      icon: 'archaeology_5_0_crackedmogurunestone',
      name: 'Glass Shards',
      desc: 'Гострі як лезо уламки скла та каміння сповільнюють на 30% та наносять 1758 Фізичної шкоди кожні 0.5 сек.',
      whid: 323001
    },
    {
      type: 'spell',
      icon: 'ability_malkorok_blightofyshaarj_red',
      name: 'Light of Atonement',
      desc: 'Захищає гравців всередині відміченої зони від жахаючих __Sinlight Visions__.',
      whid: 339235
    },
    {
      type: 'spell',
      icon: 'spell_nzinsanity_fearofdeath',
      name: 'Sinlight Visions',
      desc: 'Ядро Голіафа випускає жахаючі видіння гріхів, накладаючи ефекти Страху на гравців поза зоною __Lights of Atonement__.',
      whid: 339237
    },
    {
      type: 'spell',
      icon: 'ability_hunter_crossfire',
      name: 'Refracted Sinlight',
      desc: 'Голіаф випускає потужні промені відбитого світла гріхів з чотирьох своїх вікон, наносячи 8202 шкоди Тінню кожному ворогу, що знаходиться у промені.',
      whid: 322711
    },
  ],
  attachments: [
    { type: 'video', videoId: '-IdFbS8oG3Q', name: 'Тиранія М+20 від Денса (2й сезон)' },
    { type: 'video', videoId: '7Omu-CwQpNs', name: 'Аналіз М+17 від Денса' },
    { type: 'post', link: '/guides/tormented-affix', name: '10+ афікс' },
  ]
}
