export default {
  slug: 'high-adjudicator-aleez',
  parent: 'halls-of-atonement',
  name: 'High Adjudicator Aleez',
  short: 'Aleez',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Ta-na' ],
  content: `
    ## Pull

    <p>Суть бою полягає в **привидах**, прикликає бос, вони фіксуються на випадковій цілі і йдуть
    до неї, вам потрібно загнати їх в **бутиль**, стоять 4шт., по арені, просто стійте за **бутилем** і
    **привид** сам зайде в нього.</p>

    <p>Боса тримаємо біля **бутиля** щоб мілі не втрачали дпсу, інтераптимо всі касти, заздалегіть
    розподіліть, гера і бурсти з пула. Уникайте калюж.</p>

    <p>Коли 4 **привиди** знайдуть свої **бутилі** з’явиться новий, але не буде світитсь як перші,
    тож не хвилюйтесь.</p>
  `,
  aside: [
    {
      type: 'spell',
      icon: 'artifactability_blooddeathknight_umbilicuseternus',
      name: 'Bolt of Power',
      desc: 'Завдає 5859 шкоди Тінню поточній цілі **Aleez**.',
      whid: 323538
    },
    {
      type: 'spell',
      icon: 'inv_artifact_bloodoftheassassinated',
      name: 'Volley of Power',
      desc: 'Завдає 5273 шкоди Тінню трьом випадковим гравцям.',
      whid: 323552
    },
    {
      type: 'spell',
      icon: 'artifactability_blooddeathknight_umbilicuseternus',
      name: 'Anima Fountain',
      desc: 'Фонтан аніми закипає, завдаючи 8788 шкоди Тінню всім гравцям у радіусі 5 метрів від епіцентру закляття.',
      whid: 329340
    },
  ],
  attachments: [
    { type: 'video', videoId: '-IdFbS8oG3Q', name: 'Тиранія М+20 від Денса (2й сезон)' },
    { type: 'video', videoId: '7Omu-CwQpNs', name: 'Аналіз М+17 від Денса' },
    { type: 'post', link: '/guides/tormented-affix', name: '10+ афікс' },
  ]
}
