export default {
  slug: 'artificer-xy-mox',
  parent: 'castle-nathria',
  name: 'Artificer Xy\'Mox',
  group: 'Reliquary of Opulence',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Vorren' ],
  content: `
    <ul>
      <li>Поле бою — арена у формі кола</li>
      <li>Специфіка Босу — ставити 2 портала та використовувати їх</li>
      <li>TW/Героїзм — на початку бою</li>
    </ul>

    <h2>Фази бою</h2>
    <ul>
      <li>Перша фаза — з'являються ади які біжуть до гравців, якщо вони здоганяють свої цілі, вони потрапляють у контроль, ади зникають після 12 сек</li>
      <li>Друга фаза — на арені з'являються бомби, які можна підняти та віднести</li>
      <li>Третя фаза — на центрі з'являється велика зброя, яка всіх притягує та вбиває</li>
    </ul>

    <h2>Тактика</h2>
    <p>Відповідно як ви здогадались, нам треба буде використовувати портали для того щоб уникати
    смерті. Ставити портали треба по самому центрі арени та біля стіни. Весь рейд знаходиться між
    цими порталами.</p>

    <p>Танк який отримує дебаф, тікає у протилежну від рейду сторону, юзає кд та вибухає. Це і є
    механіка для зміни танків.</p>

    <p><em>Stasis Trap</em> треба знешкодити класам які мають іммуни (ханти, дк наприклад).</p>

    <h3>На першій фазі</h3>
    <p>Люди які мають на собі ада, кайтять його до 1 порталу (неважливо якого) і останній момент
    перед тим як ад до них дойде — заходити у портал. Це можна робити скільки бажаєш, враховуючи
    що після використання порталу в тебе є 1-2 сек кд на нього. Портали активуються як тільки ти
    в них встаєш.</p>

    <h3>На другій фазі</h3>
    <p>Назначаємо 4-5 людей які хватають бомби з протилежного від нас напрямку — заносять їх у
    центральний портал і телепортуються назад, у цей час, весь рейд йде на ту сторону звідки
    забрали бомби.</p>

    <h3>На третій фазі</h3>
    <p>Всі тримаємось біля портала що у стіни, юзаємо персональні пришвидшувачі та коли вже
    не можна стриматись біжимо у центральний портал (він буде трошки ближчий до нас від центру)
    і використовуємо його щоб триматись подалі від центру.</p>

    <p>Приклад порталів<br>
    [image/xy-mox-1.jpg]</p>
  `,
  aside: [
    {
      type: 'spell',
      icon: 'creatureportrait_portal_alteracvalleyhorde',
      name: 'Dimensional Tear',
      desc: 'Вибирає 2 гравців (жовта стрілка та біле коло навколо) і ставить портали між якими можна телепортуватись з кд 1-2 сек.',
      whid: 328437
    },
    {
      type: 'spell',
      icon: 'ability_mage_incantersabsorbtion',
      name: 'Glyph of Destruction',
      desc: 'Дебаф на танка який вибухає на весь рейд, дамаг залежить від дистанції.',
      whid: 325361
    },
    {
      type: 'spell',
      icon: 'ability_monk_forcesphere',
      name: 'Stasis Trap',
      desc: 'Кидає ряд пасток, які станять гравця (якщо він на них встає) та не дають можливість зняти поки інші не вибьють.',
      whid: 326271
    },
  ],
  attachments: [
    { type: 'video', videoId: 'UVmkZGkVZK8', name: 'Першокіл (героїк)' }
  ]
}
