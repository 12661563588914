export default {
  slug: 'osnovu-tankyvannya',
  parent: 'guides',
  name: 'Основи танкування',
  header: { parent: true },
  authors: [ 'Ta-na' ],
  content: `
    <p>
      Отже, основной роботою для танків є <em>генерація агресії</em> проти себе і вміння захищатись.
      Агресія (<em>загроза/агро</em>) на танку повинна бути постійно.
    </p>

    <p>
      Втрачена агресія це — <em>четвертування напарників</em>, а після них і себе. Набір агресії
      в грі працює так, чим сильніше ви лязгаєте ціль, тим більш вона агресивна, проте у танків
      за замовчуванням йде збільшення в чотири рази для здібностей, тобто вважають що здібності
      танка наносять більше шкоди, хоч це й не так. Тому танки повинні постійно наносити шкоду,
      будь-якою доступною здібністю.
    </p>

    <p>
      Всі танки маюуть той чи інший захист, у кожного він своєрідний і кожен танк по різному може
      впоратись з ситуацією. Проте хороший танк повинен знати бій, свої плюси та мінуси, прогнозувати
      бій, де і коли він віддасть захисну здібність. Захист прожатий після отримання шкоди —
      <em>всратий захист</em>, бо сенс в тому аби зменьшити вхідну шкоду на
      <em>n% від противника</em>. Гра збудована таким чином, що від всього захиститись не можливо,
      тому не соромтесь просити хілів про допомогу.
    </p>

    <p>
      Пам'ятайте що в грі є декілька видів шкоди — <em>магічна і фізична</em>, різні здібності
      по різному можуть захистити або ж взагалі не захистити. Тому уважно читайте опис здібностей
      противника і плануйте свій бій. Слідкуйте за маной у хіла, якщо не хочете оконфузитись.
    </p>
  `
}
