export default {
  slug: 'xav-the-unfallen',
  parent: 'theater-of-pain',
  name: 'Xav the Unfallen',
  short: 'Xav',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Johnth' ],
  content: `
    <p>~~Чемпіон арени, що заставить грати у PvP незалежно від Ваших уподобань [icon/emoticon-devil-outline]~~</p>

    <p>Початок стандартний, бос б’є танка, ми б’ємо боса. Бос періодично використовує __Brutal Combo__.</p>

    <p>Час від часу бос ставить на арені бойовий штандарт **Oppressive Banner**, який кожні три секунди
    заповільнюватиме всіх (ефект щоразу посилюється). Необхідно фокусити баннер якомога швидше, інакше
    група не зможе нормально уникати атак боса по площі.</p>

    ## Might of Maldraxxus

    <p>Через деякий час після появи **Oppressive Banner** бос проводить перформанс під назвою
    __Might of Maldraxxus__. Він стрибає в центр арени, наносить всім гравцям 7324 фізичних пошкоджень
    і проводить ряд атак зі списку нижче у випадковому порядку:<ul>
      <li>__Deafening Crash__ -- велике коло, наносить пошкодження в радіусі 12 метрів і перериває касти
      в радіусі 55 метрів (тобто по всій арені). Виходимо і не кастуємо в момент удару.</li>
      <li>__Massive Cleave__ -- атака півколом в напрямку погляду боса. Виходимо за спину боса.</li>
      <li>__Crushing Slam__ -- виглядає як рунічне коло перед обличчям боса, але фактично б’є по прямій
      лінії на всю довжину арени. Виходимо за спину боса або вбік.</li>
    </ul></p>

    ## Blood and Glory

    <p>По досягненні 100 енергії бос кидає ланцюги у двох випадкових гравців (в описі не вказано, але,
    ймовірно, обирає лише ДД) і закидає їх на окрему арену (__Blood and Glory__).

    <p>Гравцям необхідно битись між собою до перемоги, тоді переможець отримає баф на +10% пошкоджень
    (__Glorified__), а переможений відповідно дебаф на -10% (__Bloodied__). Якщо за 45 сек бою ніхто
    не переміг, то обоє отримають дебаф на -10% пошкоджень (__Cowardice__). Також впродовж бою на обох
    стакається ефект __Glorious Combat__, збільшуючи вхідні пошкодження.</p>

    <p>Рекомендовано віддавати перемогу гравцю з більшим DPS або готовими бурстами. Також немає сенсу
    використовувати інтерапти, контроль, дефи і таке інше. Це затягує бій, а наше завдання -- якомога
    швидше повернутись до боса і зламати йому обличчя.</p>
  `,
  aside: [
    {
      type: 'spell',
      icon: 'ability_deathknight_necroticstrike',
      name: 'Brutal Combo',
      desc: 'Серія брутальних атак, яка наносить збільшені пошкодження з кожним ударом (в описі немає цифр, вирішуйте по відчуттях, можуть пригодитися мінорні сейви).',
      whid: 320644
    },
    {
      type: 'spell',
      icon: 'ability_maldraxxus_warriorplantbanner',
      name: 'Oppressive Banner',
      desc: 'Знижує швидкість пересування гравців на 15% кожні 3 сек поки існує.',
      whid: 331618
    },
    {
      type: 'header',
      name: 'Might of Maldraxxus',
      namet: 'Комбо'
    },
    {
      type: 'spell',
      icon: 'ability_bossdarkvindicator_auraofcontempt',
      name: 'Might of Maldraxxus',
      desc: 'Бос стрибає в центр арени, наносить всім гравцям 7324 фізичної шкоди та проводи випадкову комбінацію атак.',
      whid: 320050
    },
    {
      type: 'spell',
      icon: 'inv_boots_mail_pvpshaman_c_02',
      name: 'Deafening Crash',
      desc: 'Бос гупає ногою, перериваючи читання заклинань в радіусі 55 метрів на 2 секунди, а також наносить 14657 фізичної шкоди і відкидає усіх у радіусі 12 метрів.',
      whid: 339415
    },
    {
      type: 'spell',
      icon: 'ability_warrior_cleave',
      name: 'Massive Cleave',
      desc: 'Бос проводить ріжучу атаку в півколі перед собою і наносить 11718 фізичної шкоди усім в зоні ураження.',
      whid: 320729
    },
    {
      type: 'spell',
      icon: 'ability_warrior_trauma',
      name: 'Crushing Slam',
      desc: 'Бос б’є по землі і наносить 11718 фізичної шкоди гравцям на лінії перед собою.',
      whid: 317231
    },
    {
      type: 'header',
      name: 'Blood and Glory',
      namet: 'Дуель'
    },
    {
      type: 'spell',
      icon: 'ability_pvp_gladiatormedallion',
      name: 'Blood and Glory',
      desc: 'Запускається по досягненні босом 100 енергії. Закидає 2 випадкових гравців на окрему арену і робить ворожими один до одного. Дуель завершується перемогою одного з них або по таймеру за 45 секунд. Переможець отримує баф __Glorified__, переможений -- дебаф __Bloodied__. У разі завершення по таймеру (нічия) обоє отримують дебаф __Cowardice__.',
      whid: 320114
    },
    {
      type: 'spell',
      icon: 'petbattle_attack',
      name: 'Glorified',
      desc: 'Вихідні пошкодження збільшені на 10% на 20 сек.',
      whid: 320165
    },
    {
      type: 'spell',
      icon: 'petbattle_attack-down',
      name: 'Bloodied',
      desc: 'Вихідні пошкодження зменшені на 10% на 20 сек.',
      whid: 320167
    },
    {
      type: 'spell',
      icon: 'ability_druid_cower',
      name: 'Cowardice',
      desc: 'Вихідні пошкодження зменшені на 10% на 20 сек.',
      whid: 320711
    },
    {
      type: 'spell',
      icon: 'spell_warrior_gladiatorstance',
      name: 'Glorious Combat',
      desc: 'Бійці отримують на 3% більше пошкоджень, ефект сумується щосекунди.',
      whid: 332670
    },
  ],
  attachments: [
    { type: 'video', videoId: 'JBvx0rVvtGw', name: 'Аналіз М+20 від Денса (2й сезон)' },
    { type: 'video', videoId: '8PP5eIgvq44', name: 'Аналіз М+17 від Денса' },
    { type: 'post', link: '/guides/tormented-affix', name: '10+ афікс' },
  ]
}
