export default {
  slug: 'stone-legion-generals',
  parent: 'castle-nathria',
  name: 'Stone Legion Generals',
  group: 'Blood from Stone',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Vorren' ],
  content: `
    <ul>
      <li>Поле бою — відкрита платформа, можна злетіти та померти</li>
      <li>Специфіка — бій розбитий на 3 фази, по 1 босу на перших двох та разом на останній</li>
      <li>TW/Героїзм — на початку третьої фази</li>
    </ul>

    <p>Бій розбитий на 3 фази з 2 інтермісіями між ними. Час від часу спавниться додатковий
    ад — <b>Голіаф</b> — треба швидко вбити бо на 20% він вішає дебаф на рейд на мінус хіл.</p>

    <p>Коли у боса 50% хп, він отримує щит на 95% захисту і починається інтермісія. Під час інтермісії нам треба
    вбивати адів, носити орби до <b>Принца</b>, коли він настакає аніми, він всіх відштовхне та знімить дебаф.
    Обережно щоб нікого не викинуло з балкону.</p>

    <h2>Перша фаза</h2>
    <p>Протягом бою рейд має бути близько спозиціонованим. Спочатку б'ємо <b>Каалу</b>. Позицінуємось відповідно
    до скріншоту заради її кинжалу:<br>
    [image/stone-legion-generals-1.jpg]</p>

    <p>Люди які отримують мітки, мають швидко перебігати на іншу сторону щоб уникнути ситуація де кинжал
    йде по рейду. Мілішникам завжди триматись по сторону боса. Як тільки на комусь буде дебаф <em>Crystalize</em>:<br>
    [image/stone-legion-generals-2.jpg]<br>
    ця людина відійде від рейду і всі люди з дебафом (дотом) разом з танком забігають щоб зняти його. Після цього
    додаткові гравці зайдуть щоб пошарити дамаг від метеориту.</p>

    <p>На 50% хп починається інтермісія, вбиваємо адів та збираємо орби до <b>Принца</b>. Як тільки він відштовхне всіх,
    починається друга фаза бою.</p>

    <h2>Друга фаза</h2>
    <p>Тепер ми б'ємо <b>Грашаала</b>. <b>Каала</b> продовжує кидати кинжал по 2 гравцям, але тепер вона почне летіти
    з краю платформи. <b>Грашаал</b> юзає <em>Stone Fist</em> на танка, який його відкидує та вішає дебаф, треба одразу
    свапатись. На гравцях на кому буде <em>Reverberating Eruption</em> треба вибігати швидко з рейд стаку та постійно
    рухатись від шипів.<br>
    [image/stone-legion-generals-3.jpg]</p>

    <p>Решті теж треба реагувати та відходити від них. На кому буде <em>Seismic Upheaval</em>, вибігає на край платформи,
    скидує дебаф та повертається.<br>
    [image/stone-legion-generals-4.jpg]</p>

    <p>Коли в <b>Грашаала</b> буде 50% хп, почнеться інтермісія.</p>

    <h2>Третя фаза</h2>
    <p>Всі механіки будуть одночасно, боси будуть разом. Робимо те саме що і на попередніх фазах. Босів треба вбити
    одночасно та швидко.</p>

    <p>Позиціонування<br>
    [image/stone-legion-generals-5.jpg]</p>
  `,
  aside: [
    {
      type: 'header',
      name: 'General Kaal',
      namet: 'Каала'
    },
    {
      type: 'spell',
      icon: 'spell_warrior_wildstrike',
      name: 'Wicked Blade',
      desc: 'Вибирає двох гравців (червона стрілка), після чого по ним та по усіх на шляху пролетить кинжал. Вішає дебаф який може вайпнути рейд.',
      whid: 333387
    },
    {
      type: 'spell',
      icon: 'ability_xavius_nightmareblades',
      name: 'Serrated Swipe',
      desc: 'Б\'є по танку та вішає перманентний блід, стакається. Свап танків щоб можна було зняти у зоні дії <em>Crystalize</em>.',
      whid: 334929
    },
    {
      type: 'spell',
      icon: 'spell_fire_flameblades',
      name: 'Heart Rend',
      desc: 'Вішає дебаф на 4+ таргети, треба діспелити, після діспелу по гравцям з дебафом проходить дуже великий дамаг.',
      whid: 334765
    },
    {
      type: 'header',
      name: 'General Grashaal',
      namet: 'Ґрашаал'
    },
    {
      type: 'spell',
      icon: 'inv_datacrystal06',
      name: 'Crystalize',
      desc: 'Обирає рандомного гравця (навколо нього жовте коло), через короткий час він і всі навкого нього камініють, знімаючи доти.',
      whid: 339690
    },
    {
      type: 'spell',
      icon: 'inv_elementalearth2',
      name: 'Pulverizing Meteor',
      desc: 'Кастує одразу після <em>Crystalize</em>, по закамінілим падає метеорит який треба допомогати соакати.',
      whid: 339728
    },
    {
      type: 'spell',
      icon: 'spell_shadow_unholystrength',
      name: 'Stone Fist',
      desc: 'Відкидує танка, вішає дебаф, механіка для свапу.',
      whid: 342425
    },
    {
      type: 'spell',
      icon: 'inv_elementalearth2',
      name: 'Reverberating Eruption',
      desc: 'Вішає дебаф на рандомних гравців, на їхніх позиціях будуть спавнитись шипи доки дебаф не спаде.',
      whid: 344500
    },
    {
      type: 'spell',
      icon: 'spell_nature_earthquake',
      name: 'Seismic Upheaval',
      desc: 'Вішає дебаф на ціль, після закінчення у радіусі 7 ярдів буде вибух та залишається калюжа.',
      whid: 334500
    },
    {
      type: 'header',
      name: 'Goliath',
      namet: 'Голіаф'
    },
    {
      type: 'spell',
      icon: 'inv_soulhoundmount_white',
      name: 'Soultaint Effigy',
      desc: 'Коли хп голіафа зменьшується до 20%, вішає дебаф на всіх гравців, 1 стак/сек, стакається до 4 разів; зменьшення зцілення на 20% за кожен стак.',
      whid: 343898
    },
  ],
  attachments: [
    { type: 'video', videoId: '7yKYgUag4IQ', name: 'Першокіл (героїк)' }
  ]
}
