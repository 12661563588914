export default {
  slug: 'soulrender-dormazain',
  parent: 'sanctum-of-domination',
  name: 'Soulrender Dormazain',
  short: 'Dormazain',
  image: '/img/soulrender-dormazain.jpg',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Dancecaptain (Makhabunda)' ],
  attachments: [
    { type: 'video', videoId: 'uj3-aI3Kj58', name: 'Першокіл (героїк)' },
    { type: 'video', videoId: '9r2WUb51wbE', name: 'Відеотактика (англійська)' }
  ],
  aside: [
    {
      type: 'spell',
      icon: 'spell_animamaw_debuff',
      name: 'Torment',
      namet: '(смертельно)',
      desc: 'Бос мучить Гароша, випускаючи з нього хвилі аніми, які завдає 33065 Тіньового пошкодження моментально і додатково 2104 Тіньового пошкодження кожні 2 секунди впродовж 6 секунд, якщо гравець потрапив у хвилю. Ефект доти стакається.',
      whid: 350217
    },
    {
      type: 'spell',
      icon: 'spell_animarevendreth_wave',
      name: 'Tormented Eruption',
      namet: '(смертельно)',
      desc: 'Подібно до попередньої абілки, однак у цьому випадку бос запускає декілька хвиль аніми з Гароша.',
      whid: 349985
    },
    {
      type: 'spell',
      icon: 'ability_demonhunter_shatteredsouls',
      name: 'Rendered Soul',
      namet: '(героїк)',
      desc: 'Бос мучить душу Гароша звільняючи фрагменти на платформу, які завдає 24825 Тіньового пошкодження всім гравцям в 5 ярдах від місця свого падіння.',
      whid: 351229
    },
    {
      type: 'spell',
      icon: 'inv_misc_steelweaponchain',
      name: 'Warmonger Shackles',
      namet: '(до уваги хілерів)',
      desc: 'Бос окутує Гароша кайданами заставляючи його кричати. Відтягуючи кайдани на 40 ярдів від Гароша, інтераптять його крик, який завдає 6278 Фізичного пошкодження кожну секунду (В героїку та міфіку крик фіряє гравців). Ламання кайданів завдає 13826 Тіньового пошкодження всім гравцям.',
      whid: 348985
    },
    {
      type: 'spell',
      icon: 'inv_sword_1h_artifactruneblade_d_02',
      name: 'Ruinblade',
      namet: '(до уваги танків)',
      desc: 'Бос завдає удару по цілі, завдаючи 36070 Фізичного пошкодження і збільшує всі вхідні Фізичні пошкодження по цілі на 100% впродовж 40 секунд. Ефект стакається.',
      whid: 350422
    },
    {
      type: 'spell',
      icon: 'inv_pet_reliquarysuffering',
      name: 'Brand of Torment',
      desc: 'Бос поєднує гравців, заставляючи їх завдавати 4509 Тіньового пошкодження кожні 2 секунди всім цілям, окрім боса й Гароша, в 5 ярдах. Додатково, ціль яка знаходиться під ефектом Tormented отримує на 50% більше вхідних пошкоджень.',
      whid: 350648
    },
    {
      type: 'spell',
      icon: 'spell_animamaw_nova',
      name: 'Agonizing Nova',
      desc: 'Ади виділяють хвилі, які завдають 6018 Тіньового пошкодження і уповільнюють гравців на 20% впродовж 6 секунд. Ефект стакається.',
      whid: 351787
    },
    {
      type: 'spell',
      icon: 'ability_garrosh_hellscreams_warsong',
      name: 'Defiance',
      desc: 'Аніма Гарроша переповнює __the Mawsworn Agonizer__, робить їх імунними до всіх форм контролю і збільшує завдавання ними пошкоджень на 200%.',
      whid: 350650
    },
    {
      type: 'spell',
      icon: 'spell_animamaw_beam',
      name: 'River of Souls',
      desc: 'Річка Душ висмоктує життя з гравців, завдаючи їм 4509 Тіньового пошкодження кожні 0.2 секунди і зменшує демедж і хілінг гравців на 100%.',
      whid: 350851
    },
    {
      type: 'spell',
      icon: 'spell_shadow_deathscream',
      name: 'Echoing Screams',
      namet: '(до уваги хілерів)',
      desc: 'Бос кричить завдаючи 2405 Тіньового пошкодження всім гравцям кожні 5 секунд.',
      whid: 358614
    },
  ]
}
