export default {
  slug: 'echelon',
  parent: 'halls-of-atonement',
  name: 'Echelon',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Ta-na' ],
  content: `
    ## Pull

    <p>Гера і бурсти з пула, група стоїть під стіною щоб не залити всю кімнату __Blood Torrent__.
    З часом бос прикликає адів **Undying Stonefiend** — шість штук, кастують __Volatile Transformation__,
    __Villainous Bolt__, ваша задача звести адів в купу і швидко опустити здоров’я до 0. Маючи 0 здоров’я
    перетворюються на __Stone Form__ і відновлюють 5% здоров’я раз в 1,5 секунди, вбити їх може тільки
    __Stone Shattering Leap__.</p>

    <p>__Stone Shattering Leap__ кидається на випадкового гравця, його задача зайти в купу адів і знищити
    їх. Виглядає як коло, вішає на всіх дебаф __Shattered__.</p>

    <p>При 100 енергії бос проклине гравців __Curse of Stone__ (прокляття, діспелиться), уповільнює і через
    п’ять секунд перетворить на камінь, уважно слідкуйте за своїм позиціюванням щоб не вбити напарників
    __Stone Shattering Leap__.</p>
  `,
  aside: [
    {
      type: 'header',
      name: 'Echelon'
    },
    {
      type: 'spell',
      icon: 'inv_artifact_bloodoftheassassinated',
      name: 'Blood Torrent',
      desc: '**Echelon** створює потік токсичної крові, яка наносить 7324 шкоди Тінню миттєво та 3515 шкоди Тінню кожну 1 сек усім гравцям навколо.',
      whid: 319703
    },
    {
      type: 'spell',
      icon: 'inv_misc_archaeology_trollgolem',
      name: 'Curse of Stone',
      desc: 'По досягненні 100 енергії **Echelon** вивільняє потужне прокляття, сповільнюючи гравців впродовж 5 сек і потім накладає __Turned to Stone__ (перетворює в камінь).',
      whid: 319603
    },
    {
      type: 'spell',
      icon: 'ability_earthen_pillar',
      name: 'Stone Shattering Leap',
      desc: '**Echelon** розколює землю навколо себе, наносячи 8788 Природної шкоди усім гравцям в радіусі 8 метрів. Це також накладає ефект __Shattered__ та знищує всіх скам’янілих **Undying Stonefiends**.',
      whid: 319592
    },
    {
      type: 'spell',
      icon: 'inv_elementalearth2',
      name: 'Shattered',
      desc: 'Наносить 2929 шкоди від Природи кожні 2 сек протягом 8 сек.',
      whid: 344874
    },
    {
      type: 'header',
      name: 'Undying Stonefiend'
    },
    {
      type: 'spell',
      icon: 'ability_malkorok_blightofyshaarj_red',
      name: 'Villainous Bolt',
      desc: 'Наносить 2344 шкоди від Темної магії.',
      whid: 328322
    },
    {
      type: 'spell',
      icon: 'spell_nature_earthquake',
      name: 'Volatile Transformation',
      desc: '**Undying Stonefiends** вибухають під час перетворення на камінь, наносячи 1172 Природної шкоди всім ворогам.',
      whid: 328125
    },
    {
      type: 'spell',
      icon: 'trade_archaeology_druidprieststatueset',
      name: 'Stone Form',
      desc: 'Коли здоров’я **Undying Stonefiend** досягає 0, він стає імунним до пошкоджень і відновлює 5% максимального запасу здоров’я кожні 1.5 сек впродовж 30 сек.',
      whid: 319724
    },
  ],
  attachments: [
    { type: 'video', videoId: '-IdFbS8oG3Q', name: 'Тиранія М+20 від Денса (2й сезон)' },
    { type: 'video', videoId: '7Omu-CwQpNs', name: 'Аналіз М+17 від Денса' },
    { type: 'post', link: '/guides/tormented-affix', name: '10+ афікс' },
  ]
}
