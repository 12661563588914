export default {
  slug: 'ventunax',
  parent: 'spires-of-ascension',
  name: 'Ventunax',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Ta-na' ],
  content: `
    [p]Бій складний, на високих ключах краще розрахувати прайд + гера і бурсти.[/p]

    [p]Танк веди боса по прямій через коло, ренжі + хіл стійте в кінці прямої або з боку.
    **Ventunax** періодично буде пригати за спину гравцям вішати блід __Dark Stride__ і
    залишить кулю **Shadowhirl**, танк, складай кулі одна біля одної і перед кастом
    __Recharge__ відводь боса (через кожні дві кулі).[/p]

    [p]Якщо на вас __Dark Stride__ і ви не можете зняти жміть сейв, хілеру потрібно
    активно хілити гравця з дотою. Танк тримай боса лицем від групи заради
    __Blinding Flash__.[/p]
  `,
  aside: [
    {
      type: 'spell',
      icon: 'ability_skeer_bloodletting',
      name: 'Dark Stride',
      desc: '**Ventunax** стрибає за спину гравцю наносячи 2344 Фізичних пошкоджень кожну секунду протягом 20 сек. Додатково, бос лишає **Shadowhirl** позаду.',
      whid: 324154
    },
    {
      type: 'spell',
      icon: 'ability_bastion_priest',
      name: 'Recharge',
      desc: '**Ventunax** відновлює 5% енергії кожні 0.5 сек протягом 10 сек. Додатково, всі **Shadowhirls** починають йти **хвилями** протягом 10 сек.',
      whid: 334485
    },
    {
      type: 'spell',
      icon: 'ability_skyreach_flash_bang',
      name: 'Blinding Flash',
      desc: '**Ventunax** створює спалах світла, станячи гравців перед собою на 3 сек.',
      whid: 324205
    },
    {
      type: 'spell',
      icon: 'ability_butcher_whirl',
      name: 'Shadowhirl',
      desc: '**Ventunax** швидко рухається лишаючи **Shadowhirls** на своєму шляху.[br][br]Кожен **Shadowhirl** випускає **Dark Bolts**, і випускає **хвилі Dark Bolts** коли бос відновлює енергію.',
      whid: 323927
    }
  ],
  attachments: [
    { type: 'video', videoId: '2hoFL3NW3HY', name: 'Аналіз М+20 від Денса (2й сезон)' },
    { type: 'video', videoId: 'roVEc0IgLbo', name: 'Аналіз М+17 від Денса' },
    { type: 'post', link: '/guides/tormented-affix', name: '10+ афікс' },
  ]
}
