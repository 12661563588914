export default {
  slug: 'the-nine',
  parent: 'sanctum-of-domination',
  name: 'The Nine',
  image: '/img/the-nine.jpg',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Dancecaptain (Makhabunda)', 'Ta-na' ],
  attachments: [
    { type: 'video', videoId: 'Bkf2m47TYHE', name: 'Першокіл (мітік)' },
    { type: 'video', videoId: 'HcggksPaj8E', name: 'Відеотактика (англійська)' }
  ],
  content: `
    ## Коротко

    [p]Коли Кіра застосовує __Wings of Rage__, тікайте від неї, поки вона не закінчить заклинання.
    Коли Сігне застосовує __Reverberating Refrain__ біжи до неї, поки вона не закінчить заклинання.
    Гравці з __Fragments of Destiny__ повинні стояти один біля одного на краю кімнати. Коли ефект
    розвіється, біжіть назад до рейду.[/p]

    ### Танки

    [p]Тримайте Кіру та Сигне поруч щоб збільшити пошкодження. Змінитися після 3-х ефектів
    __Unending Strike__ на першій фазі. На другій фазі танки міняються на 3-ч ефектах __Pierce Soul__.[/p]

    ### Лікарі

    [p]Розсіюйте __ragments of Destiny__ поки вони не перйдуть на одного гравця. Застосовуйте сильні
    заклинання під час __Song of Dissolution__.[/p]

    ### Бійці

    [p]Переривайте заклинання __Song of Dissolution__. Опоскайте здоров’я босів рівномірно.[/p]

    [p][spell/78151/Героїзм] на 10% (15% в героїчній складності і вище) здоров’я босів.[/p]

    ## Повністю

    ### Перша фаза

    [p]Коли Кіра досягне 100% енергії почне застосовувати __Wings of Rage__, вам потрібно бути поза
    куполом під час детонації.
    [link/https://media.giphy.com/media/K2iBcQTScpBrL002TZ/giphy.gif/GIF]
    [/p]

    [p]Коли Сігне досягне 100% енергії почне застосовувати __Reverberating Refrain__, вам потрібно бути
    Всередині купола під час детонації.
    [link/https://media.giphy.com/media/Sm5PvFBrc5iSqyMzZ2/giphy.gif/GIF]
    [/p]

    [p]Танки повинні мінятись на 3-х ефектах __Unending Strike__ від Кіри, адже окрім шкоди танкам кожне
    застосування наносить додаткову шкоду по рейду. Іншому босові просто переривати заклинання,
    __Song of Dissolution__ має бути перерване обов'язково.[/p]

    [p]Інколи буде з’являтися малий посіпака, його потрібно відвести/підкинути до босів, якщо він ще
    не дійшов до боісв ні в якому разі не станьте їх, просто переривайте заклинання.[/p]

    [p]Виходьте з променів на підлозі, це погані промені. Гравці з __Daschla's Mighty Impact__
    (великі фіолетові кола) повинні відійти від рейду. Перекривайте __Aradne's Falling Strike__ малі
    кола на землі.
    [link/https://media.giphy.com/media/spmS2LDwevEOGCgXKO/giphy.gif/GIF]
    [/p]

    [p]__Brynja's Mournful Dirge__ накладає на гравця біле коло, теж відходимо від рейду.
    [link/https://media.giphy.com/media/tPpMvQFYxnAD1GYCut/giphy.gif/GIF]
    [/p]

    [p]Періодично випадковий гравець буде відмічений __Arthura's Crushing Gaze__, потрібно поділити
    з рейдом чи пережити самостійно якщо ваш клас дає таку можливість.
    [link/https://media.giphy.com/media/LuxnZdWFkKt5bZyVl8/giphy.gif/GIF]
    [/p]

    ### Друга фаза

    [p]Фаза починається на 10% (15% героїк і вище) босів і в бій вступає Скуя. Тут потрібно застосувати
    героїзм і швиденько добити перших двох босів. Гравці з ефектом __Fragments of Destiny__ повинні відбігти
    на край і чекати поки його розсіють лікарі, варлоки можуть допомогти при потребі.[/p]

    [p]Танкам потрібно підхопити нового босі і мінятись на 3-х ефектах __Pierce Soul__, поки Кіра жива
    ефект є __Unending Strike__ пріоритетний.[/p]

    [p]В героїчній складності додається __Link Essence__, гравці з цим ефектом повинні бути максильно
    обережні і при необхідності застосовувати захисні здібності.[/p]

    [p]Коли бос застосую __Annhylde's Bright Aegis__ з’явитсья купол, в ньому зменшується шкода для боса
    і його мінйонів, тому відводимо, гравці можуть знаходитись в ньому.
    [link/https://media.giphy.com/media/Bja0DOpRtymDFP1G7u/giphy.gif/GIF]
    [/p]
  `,
  aside: [
    {
      type: 'header',
      name: 'Кура',
    },
    {
      type: 'spell',
      icon: 'inv_icon_wing06a',
      name: 'Wings of Rage',
      desc: 'Досягнувши 100 енергії Кура б\'є крилами впродовж 7 секунд, притягуючи всіх гравців до себе. По завершенню притягування, Кура відкидає всіх гравців завдаючи 36070 Фізичного пошкодження гравцям в 10 ярдах від боса і станить їх на 10 секунд.',
      whid: 350365
    },
    {
      type: 'spell',
      icon: 'spell_shadow_lifedrain02_purple',
      name: 'Siphon Vitality ',
      namet: '(до уваги ДПСрів)',
      desc: '__Formless Mass__ ад, який кастує пожирання життя з всіх гравців, завдаючи 9017 Тіньового пошкодження і хілячи себе на відповідну суму (каст інтераптиться).',
      whid: 350339
    },
    {
      type: 'spell',
      icon: 'inv_weapon_shortblade_84',
      name: 'Unending Strike',
      namet: '(до уваги танків)',
      desc: 'Кура завдає удар по цілі на 22543 Фізичного пошкодження і збільшує вхідні пошкодження по цілі на 10% впродовж 25 секунд. Ефект стакається. Також удар задіває всіх гравців перед Курою, завдаючи їм 3607 Фізичного пошкодження.',
      whid: 350202
    },
    {
      type: 'header',
      name: 'Сігна',
    },
    {
      type: 'spell',
      icon: 'spell_shaman_blessingoftheeternals',
      name: 'Reverberating Refrain',
      desc: 'Досягнувши 100 енергії, Сігна співає впродовж 7 секунд відштовхуючи всіх гравців від себе. По завершенню, Сігна завдає 36070 Тіньового пошкодження всім гравцям, які знаходяться дальше 10 ярдів від Сігни і фіряє їх на 6 секунд.',
      whid: 350385
    },
    {
      type: 'spell',
      icon: 'spell_animamaw_debuff',
      name: 'Song of Dissolution',
      desc: 'Сігна співає 6 секунд, підсилюючи пісню кожні півтори секунди. Кожну секунду, на гравців вішається дот, який завдає 1202 Тіньового пошкодження кожну секунду впродовж 5 секунд. Ефект стакається (спел інтептераптиться).',
      whid: 350287
    },
    {
      type: 'spell',
      icon: 'spell_animamaw_missile',
      name: 'Soulful Blast',
      desc: 'Сігна кастує спел, який завдає 9017 Тіньового пошкодження (спел інтераптиться).',
      whid: 350283
    },
    {
      type: 'spell',
      icon: 'inv_misc_horn_03',
      name: 'Call of the Val\'kyr',
      desc: 'Впродовж файту бос кличе різних адів:',
      whid: 350467
    },
    {
      type: 'spell',
      icon: 'ability_deathknight_hungeringruneblade',
      name: 'Agatha\'s Eternal Blade:',
      desc: 'Агата. Створює копії, які пускають промені по-прямій, які завдають 30058 Фізичного пошкодження і відштовхує гравців, які попали у промінь.',
      whid: 350031
    },
    {
      type: 'spell',
      icon: 'spell_fire_twilightflamestrike',
      name: 'Daschla\'s Mighty Impact',
      desc: 'Дашла. Бере на приціл гравця роблячи вибух над ним, який завдає 18014 Тіньового пошкодження всім цілям в 20 ярдах від центру вибуху.',
      whid: 350184
    },
    {
      type: 'spell',
      icon: 'spell_winston_bubble',
      name: 'Annhylde\'s Bright Aegis',
      desc: 'Аннхілд. Створює щит, який захищає адів і боса впродовж 40 секунд, зменшуючи вхідні пошкодження по адам і босу на 90%.',
      whid: 350157
    },
    {
      type: 'spell',
      icon: 'inv_icon_wingbroken05c',
      name: 'Aradne\'s Falling Strike',
      desc: 'Арадн. Позначає на платформі декілька локацій з воїд-зонами. Після 8 секунд ад атакує локації завдаючи 10520 Тіньового пошкодження. За кожну незакриту локацію всі гравці отримують 21041 Тіньового пошкодження.',
      whid: 350098
    },
    {
      type: 'spell',
      icon: 'ability_warlock_jinx',
      name: 'Brynja\'s Mournful Dirge',
      desc: 'Брайня. Вішає дот на гравця на 6 секунд, який завдає 1803 Тіньового пошкодження кожну секунду. По завершенню дота, відбувається вибух, який завдає 19545 Тіньового пошкодження всім гравцям у радіусі 5 ярдів.',
      whid: 350109
    },
    {
      type: 'spell',
      icon: 'spell_ice_lament',
      name: 'Arthura\'s Crushing Gaze',
      desc: 'Артура. Вішає дебаф на гравці, який триває 8 секунд і позавершенню вибухає завдаючи 120232 Морозяного пошкодження поділеного між усіма гравцями у 10 ярдах.',
      whid: 350039
    },
    {
      type: 'header',
      name: 'Друга фаза',
    },
    {
      type: 'spell',
      icon: 'spell_frost_ice-shards',
      name: 'Fragments of Destiny',
      namet: '(магічний ефект)',
      desc: 'Навколо гравця формуються фрагменти льоду, які завдають 2102 Морозяного пошкодження кожну секунду. Після діспелу фрагменти розлітаються між найближчими гравцями. Якщо всі фрагменти залетіли в одну ціль, вони створюють льодяну калюжу на 10 ярдів, стоячи в якій гравець отримує 7514 Морозяного пошкодження і зменшує швидкість пересування на 50%.',
      whid: 350541
    },
    {
      type: 'spell',
      icon: 'ability_demonhunter_soulcleave2',
      name: 'Pierce Soul ',
      namet: '(до уваги танків)',
      desc: 'Скуя вішає дебаф на ціль, який завдає 22543 Фізичного пошкодження, також сповільнює швидкість руху гравця на 10% і зменшує вхідний хілінг на 10% впродовж 30 секунд. Ефект стакається.',
      whid: 350475
    },
    {
      type: 'spell',
      icon: 'spell_animamaw_buff',
      name: 'Resentment',
      desc: 'Скуя вішає дот на весь рейд, який завдає 2405 Тіньового пошкодження всім гравцям кожну секунду, впродовж 12 секунд.',
      whid: 351399
    },
    {
      type: 'spell',
      icon: 'ability_vehicle_sonicshockwave',
      name: 'Word of Recall ',
      namet: '(героїк)',
      desc: 'Скуя призиває попередню валькірію з __Call of the Val\'Kyr__',
      whid: 350687
    },
    {
      type: 'spell',
      icon: 'ability_warlock_soullink',
      name: 'Link Essence',
      namet: '(героїк)',
      desc: 'Скуя поєднує гравців. Якщо хтось отримав шкоду, вона дублюється іншому пов\'язаному гравцю у повній силі.',
      whid: 350482
    },
  ]
}
