export default {
  slug: 'timecapn-hooktail',
  parent: 'tazavesh',
  name: 'Капітан часу Гакохвіст',
  hint: 'Timecap\'n Hooktail',
  image: '/img/timecapn-hooktail.jpg',
  group: { name: 'Боралус', hint: 'Boralus' },
  meta: {
    description: 'Тактика бою проти капітана часу Гакохвоста у Тазавеші.',
    keywords: 'капітан, кеп, гакохвіст, гакохвост, таймкеп, таймкепн, таймкептн, таймкептен, хуктейл, тактика, стратегія, механіка, гайд, бос, тазавеш, підземелля, данж, мітік, м+, тінеземлі, timecap\'n, timecap, timecapn, hooktail, stormheim, tazavesh, market, dungeon, shadowlands'
  },
  header: { parent: true },
  sidenav: true,
  authors: [ 'Skye' ],
  content: `
    [p]Бій на березі моря поблизу Боралуса проти королеви піратів -- [npc/175546/капітана часу Гакохвоста],
    у своїй справжній формі -- формі дракона. Корабель в морі неподалік допомагатиме капітану, виконуючи
    її накази. Протягом бою, з корабля на берег будуть прибувати посіпаки, яких можна знищувати за допомогою
    механік бою.[/p]

    ## Бій

    [p]Протягом всього бою Гакохвіст буде прикликати [npc/178435/посіпак], причому вони будуть стрибати
    з корабля на берег -- тобто, завжди з'являються з однієї сторони арени. Посіпаки будуть переслідувати
    випадкових гравців -- позиціонуйте себе так, щоб вони потрапляли під __подих__ та __змах хвостом__
    дракона. __Подих__ одразу вбиває такого посіпаку. Важливо не допускати великої кількості живих посіпак
    тому, що бос періодично застосовує __Double Time__, чим значно їх підсилює.[/p]

    [p]Активно використовуйте свої знерухомлючі та заповільнюючі здібності проти посіпак, щоб вони не
    встигали вийти з конуса __подиху__ дракона.[/p]

    [p]Коли Гакохвіст наказує команді корабля __обстрілювати__ берег, у гравців під ногами з'являються
    червоні вихрі, відмічаючи місце вибуху. Після цього там буде вогняна калюжа. Намагайтеся планувати
    рух боса протягом бою, наприклад, по лінії паралельно до берега. Щоб ці калюжі не заважали уникати
    інших механік.[/p]

    [p]Інокли корабель буде __тягнути в море__ випадкового гравця. Ця механіка сама по собі не є небезпечною,
    але стає такою одразу коли __подих__ направлений у вашу сторону -- сторону моря, де ви намагаєтеся бігти
    від моря. __Подих__ [приголомшує//Stun] на 3 сек, чого може бути достатньо для остаточного затягування
    вас у море -- миттєва смерть.[/p]
  `,
  aside: [
    {
      type: 'image',
      src: '/img/timecapn-hooktail.jpg'
    },
    {
      type: 'header',
      name: 'Капітан часу Гакохвіст'
    },
    {
      type: 'spell',
      whid: 347149,
      icon: 'spell_deathknight_breathofsindragosa',
      name: 'Infinite Breath',
      namet: 'подих',
      desc: `
        Потужний подих Гакохвоста завдає [19198/arcane] та [приголомшує//Stun] гравців на 3 сек
        у конусі перед нею.
        [br][br]
        Зачеплені цим подихом посіпаки миттєво гинуть.
      `,
    },
    {
      type: 'spell',
      whid: 347151,
      icon: 'inv_misc_hook_01',
      name: 'Hook Swipe',
      namet: 'змах хвостом',
      desc: `
        Гакохвіст змахує своїм хвостом, завдаючи [19198/physical] та відкидуючи назад гравців разом із посіпаками.
        [br][br]
        Гравець може опинитися [spell/354334/на гачку] на 3 сек. Також буде кровоточити на [4388/physical] щосекунди протягом 10 сек.
      `,
    },
    {
      type: 'spell',
      whid: 350517,
      icon: 'ability_mage_timewarp',
      audio: 'https://wow.zamimg.com/sound-ids/live/enus/33/4070177/VO_91_Timecapn_Hooktail_208314.ogg',
      name: 'Double Time',
      desc: `
        Гакохвіст застосовує свою нескінчену магію, значно прискорюючи всіх посіпак.
        [br][br]
        Всі посіпаки збільшують швидкість атаки на 100% та швидкість руху на 200% протягом 30 сек.
      `,
    },
    {
      type: 'header',
      name: 'Корабель в морі'
    },
    {
      type: 'spell',
      whid: 347370,
      icon: 'ability_vehicle_siegeenginecannon',
      audio: 'https://wow.zamimg.com/sound-ids/live/enus/25/4070169/VO_91_Timecapn_Hooktail_208307.ogg',
      name: 'Cannon Barrage',
      namet: 'обстріл',
      desc: `
        Гармата корабля завдає [21940/fire] гравцям в радіусі 8 метрів від місця вибуху.
        [br][br]
        Лишає пляму [spell/358947/палаючої смоли], що завдає [9873/fire] гравцям щосекунди при контакті.
      `,
    },
    {
      type: 'spell',
      whid: 352345,
      icon: 'inv_misc_anchor',
      name: 'Anchor Shot',
      namet: 'тягнути в море',
      desc: `
        Корабель вистрілює якір у випадкового гравця, завдаючи [12067/physical] миттєво та починає
        тягнути його в напрямку корабля (в море) протягом наступних 10 сек.
      `,
    },
    {
      type: 'spell',
      whid: 347422,
      icon: 'inv_pet_babyshark',
      name: 'Deadly Seas',
      desc: `
        Голодні акули юрмляться в цих водах та миттєво пожирають затримавшихся в морі гравців.
      `,
    }
  ]
}
