export default {
  parent: 'de-other-side',
  name: 'Муе\'зала',
  hint: 'Mueh\'zala',
  slug: 'muehzala',
  meta: {
    description: 'Тактика бою з лоа смерті Муе\'залою в Іншому боці.',
    keywords: 'муе\'зала, лоа смерті, тактика, стратегія, механіка, гайд, бос, підземелля, данж, мітік, м+, інший бік, тінеземлі, mueh\'zala, muehzala, loa of death, de other side, shadowlands'
  },
  header: { parent: true },
  sidenav: true,
  authors: [ 'Skye' ],
  attachments: [
    { type: 'video', videoId: 'Nl8_1L4zz54', name: 'Аналіз М+20 від Денса (2й сезон)' },
    { type: 'post', link: '/guides/tormented-affix', name: '10+ афікс' }
  ],
  content: `
    [p]Бій проти лоа смерті [npc/166608/Муе'зали], де гравцям допомагає лоа мертвих [npc/168141/Бвонсамді].
    Бій завершується на 10% здоров'я супостата та має дві фази, що чергуються. Перша фаза продовжується
    близько хвилини де гравці бьються безпосередньо з Муе'залою. На другій фазі, у гравців є близько
    півхвилини щоб виконати механіку з тотемами, яка й завдає супостату найбільшої шкоди, по його
    непомірно великому рівню здоров'я.[/p]

    ## Фаза боса

    [p]Муе'зала не рухається, тому танк має завжди бути біля нього; відходити можна лише коли
    бос вимовляє закляття. Якщо поряд з босом немає цілей, він __трощить__ всіх гравців.[/p]

    [p]Час від часу Муе'зала застосовує __фіолетове коло__ на всіх гравців. У вас над головою
    з'являється фіолетова куля. При розвіюванні або через 6 сек, на вашому місці з'явиться коло,
    яке в свою чергу вибухне через 9 сек -- намагайтеся лишати ці кола так, щоб вони не заважали
    ухилятися від інших механік.[/p]

    [p]Коли Муе'зала використовує __комбо__, уважно слідкуйте за рухами його рук та уникайте
    частину платформи по якій він бьє. Коли бос бьє обома руками перед собою -- відходити від
    нього мають не тільки бійці ближнього бою, але й танк.[/p]

    [p]Наприкінці фази, супостат починає готувати __вибух__, а Бвонсамді створить __портали__.
    У гравця є 10 сек щоб зайти в такий портал і перенестися на іншу платформу.[/p]

    ## Фаза тотемів

    [p]Після __вибуху__, Муе'зала стає невразливим для гравців та починає вимовляти
    __притягування__ протягом наступних 25 сек. Наприкінці, притягує всіх гравців до себе
    тим самим завершує фазу.[/p]

    [p]Всього існує чотири тотеми, кожен на окремій платформі куди вас перенесе __портал__.
    Перед тим як тотем можна використати, потрібно вбити [npc/168326/посіпаку], який буде
    __гамселити__ по землі.[/p]

    [p]Кожен використаний тотем на цій фазі, надасть Бвонсамді __люті__ на наступній фазі боса.
    В свою чергу гравці будуть підсилені __завзяттям__ на короткий час.[/p]

    [p]Так як кожен тотем віднімає 20% здоров'я боса, а весь бій закінчується на 10% -- на плечі
    гравців лягає лише 10% безпосереднього пошкодження. Тому, рекомендовано тримати підсилюючі здібності,
    на цю фазу, щоб обов'язково розправитися з посіпаками що охороняють тотеми.[/p]

    [p]Хорошим результатом буде, якщо ви впораєтеся за дві фази тотемів, тобто "два + два".
    Найкращим та найшвидшим -- використати всі чотири тотеми за одну фазу. Варіант "три + один"
    ніяк не наближає перемогу, бо фаза тотемів завжди завершується через відведений час. Також,
    маловірогідно завдати пошкоджень босу в розмірі 20% його здоров'я до того як почнеться фаза
    тотемів знову.[/p]
  `,
  aside: [
    {
      type: 'header',
      name: 'Муе\'зала'
    },
    {
      type: 'spell',
      icon: 'spell_deathknight_icetouch',
      name: 'Crush',
      namet: 'трощити',
      desc: 'Б\'є по платформі, завдаючи 100% пошкоджень ближнього бою у вигляді пошкоджень магії тіні всім ворогам у радіусі 300 метрів.[br][br]Муе\'зала застосовує цю здібність кожні 2 сек, якщо немає цілей для ближнього бою.',
      whid: 334961
    },
    {
      type: 'spell',
      icon: 'spell_mage_supernova',
      name: 'Cosmic Artifice',
      namet: 'фіолетове коло',
      desc: 'Спотворює об\'єктивну реальність гравця, завдаючи [660/shadow] щосекунди протягом 6 сек.[br][br]Після закінчення дії, вибухає, завдаючи [660/shadow] всім гравцям.[br][br]При розсіюванні, спотворення переходить на землю у вигляді фіолетового кола.',
      audio: 'https://wow.zamimg.com/sound-ids/live/enus/227/3730659/VO_901_Muehzala_195768.ogg',
      whid: 325725,
    },
    {
      type: 'spell',
      icon: 'spell_nzinsanity_fearofdeath',
      name: 'Master of Death',
      namet: 'комбо',
      desc: 'Вивільняє магію тіні, крові та некромантії у напрямку гравців, завдаючи [11871/shadow] та додатково [1979/shadow] кожні 3 сек протягом 15 сек всім, кого зачепить. Цей ефект складається.[br][br]Вимовляючи це закляття, Муе\'зала бьє по платформі три рази. Послідовність ударів випадкова, тому слідкуйте за рухами боса. Кожен окремий удар робить частину платформи смертельно небезпечною -- уникайте. Варіанти ударів:[ul][li]обома руками перед собою по ближній до боса половині платформи[/li][li]правою рукою вздовж всієї лівої половини платформи[/li][li]лівою рукою вздовж всієї правої половини платформи[/li][/ul]',
      audio: 'https://wow.zamimg.com/sound-ids/live/enus/223/3730655/VO_901_Muehzala_195774.ogg',
      whid: 325258
    },
    {
      type: 'spell',
      icon: 'spell_warlock_focusshadow',
      name: 'Shatter Reality',
      namet: 'вибух',
      desc: 'Утворює велетенську сферу хаотичної енергії, потім розбиває її, миттєво вбиваючи всіх у радіусі 80 метрів.[br][br]Муе\'зала вимовляє це закляття протягом 10 сек наприкінці першої фази.',
      whid: 326171
    },
    {
      type: 'spell',
      icon: 'spell_priest_shadoworbs',
      name: 'Coalescing',
      namet: 'притягування',
      desc: 'Муе\'зала знову просочується в реальність, збираючі свої сили. Притягує всіх гравців до себе після 25 сек.',
      whid: 334970
    },
    {
      type: 'header',
      name: 'Shattered Visage',
      namet: 'посіпака'
    },
    {
      type: 'spell',
      icon: 'ability_bossfelorcs_necromancer_purple',
      name: 'Shattered Dominion',
      namet: 'гамселити',
      desc: 'Гамселить по землі щосекунди, завдаючи [99/shadow] всім цілям в радіусі 50 метрів від центру платформи.',
      whid: 327426
    },
    {
      type: 'header',
      name: 'Бвонсамді'
    },
    {
      type: 'spell',
      icon: 'spell_necro_deathsdoor',
      name: 'Deathgate',
      namet: 'портали',
      desc: 'Бвонсамді заварює зібране гравцями моджо та створює портали. Вони існують 10 сек і при контакті переносять гравця на іншу платформу, рятуючи від __вибуху__ Муе\'зали.',
      audio: 'https://wow.zamimg.com/sound-ids/live/enus/133/3721349/VO_801_Bwonsamdi_195783.ogg',
      whid: 324698
    },
    {
      type: 'spell',
      icon: 'spell_necro_bwonsamdisboon',
      name: 'Bwonsamdi\'s Fury',
      namet: 'лють',
      desc: 'Катує Муе\'залу силою некромантії, завдаючи пошкодження у розмірі 20% здоров\'я цілі протягом 2.5 сек. Також надає гравцям __завзяття__.',
      whid: 327891
    },
    {
      type: 'spell',
      icon: 'ability_racial_embraceoftheloa_bwonsomdi',
      name: 'Bwonsamdi\'s Fervor',
      namet: 'завзяття',
      desc: 'Бвонсамді збільшує шивдкість руху, атаки та вимови заклять гравців на 30% на 10 сек. Цей ефект складається.',
      whid: 327893
    },
  ]
}
