export default {
  slug: 'shriekwing',
  parent: 'castle-nathria',
  name: 'Shriekwing',
  group: 'Blood from Stone',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Vorren' ],
  content: `
    <ul>
      <li>Поле бою — велика кімната з 4 колонами</li>
      <li>Бій розбитий на дві фази<ul>
        <li>Коли вона набирає енергію і ми її б'ємо</li>
        <li>Коли вона має 100 енергії і на нас полює</li>
      </ul></li>
      <li>TW/Героїзм — на початку бою</li>
    </ul>

    <h2>Перша фаза</h2>
    <p>Тримаємо боса на зовнішній сторони колони, всі за босом, дебафи виносимо під стінку
    подалі від усіх. Коли вона кастує <em>Earsplitting Shriek</em> — всі крім танка ховаємось
    за колону, і швиденько назад. Ця абілка залишає велику лужу в якій стояти не можна,
    на другий каст робимо за тією ж колоною робимо LOS але не заходячи в лужу, після другого
    касту якщо там будуть ще лужи йдемо до другої колони.</p>

    <p>На Героїку все те саме, тільки ще будуть звукові диски від яких треба ухилятись.</p>

    <h2>Друга фаза</h2>
    <p>Коли у боса 100% енергії, вона стрибає на центр, її бити не можна, навколо неї великий
    радіус — Зайдеш = Помреш. Всі тікаємо від боса ухиляючись від білих дисків, якщо попадаєш
    під диск отримуєш стан + лужу під собою. Бос продовжує кастувати <em>Earsplitting Shriek</em>
    від якого треба ховатись за колони. Диски відбиваються від стін та колон тому плануйте
    наперед куди йдете.</p>

    <p>При кінці енергії Бос повертається у першу фазу і ця ротація продовжується доки ми не вб'ємо її.</p>

    <p>Як ховатись від боса під час <em>Earsplitting Shriek</em><br>
    [image/shriekwing-1.png]</p>
  `,
  aside: [
    {
      type: 'spell',
      icon: 'spell_shadow_deathscream',
      name: 'Earsplitting Shriek',
      desc: 'Довгий каст, від якого треба ховатись за колоною всім крім головного танка.',
      whid: 330711
    },
    {
      type: 'spell',
      icon: 'ability_creature_cursed_05',
      name: 'Echolocation',
      desc: 'Рандомно вибирає гравців (велика червона стрілка над ними), яким треба тікати від усіх під стінку (залишає за собою калюжу).',
      whid: 342074
    },
    {
      type: 'spell',
      icon: 'ability_racial_flayer',
      name: 'Blind Swipe',
      desc: 'Робить клів у напрямку рандомного мілішніка. Обходити.',
      whid: 343005
    },
    {
      type: 'spell',
      icon: 'ability_racial_cannibalize',
      name: 'Exsanguinating Bite',
      desc: 'Б\'є в танка, стакає <em>Exsanguinated</em>, що в свою чергу зменьшує вхідний хіл та збільшує вхідний дамаг. Танкам мінятись.',
      whid: 328857
    },
  ],
  attachments: [
    { type: 'video', videoId: 'AtknhsU8guc', name: 'Першокіл (мітік)' }
  ]
}
