export default {
  slug: 'mistcaller',
  parent: 'mists-of-tirna-scithe',
  name: 'Mistcaller',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Ta-na', 'Dancecaptain (Makhabunda)' ],
  content: `
    <h2>1 фаза</h2>
    <p>Отже, бос періодично кастує <em>Patty Cake</em>, цей каст може збити тільки активний таргет,
    тобто той на кому агро, якщо не збити ціль отримає дезорієнтацію на 5 сек, і 23 435 шкоди від природи,
    тож збиваємо (зазвичай це танк).</p>

    <p>Ще бос любить покидати м’ячі в гравців — <em>Dodge Ball</em>, виглядає як стрілка на землі,
    від боса по прямій (в напрямку стрілки) летять шари, потрібно відійти з траєкторії польоту.</p>

    <p>І наостанок бос призиває лисицю в рандомному місці — <em>Freeze Tag</em>, вона фіксується
    на рандомній цілі, при дотику до неї все в радіусі 8 ярдів замерзає на 8 сек і отримує
    8 791 шкоди — <em>Freezing Burst</em>. Лисиця існує 14 сек, на неї діють всі види контролю і
    уповільнення, тож її можна вбити (так собі варіант) чи законтролити.</p>

    <p>Тому здаємо бурсти, геру і жарим.</p>

    <h2>2 фаза</h2>
    <p>На цій фазі бос імунний до шкоди, створює чотири копії, але лише одна правильна,
    принцип дії то й же що і в лабіринті. Кожна копія постійно кастує — <em>Penalizing Burst</em>
    що в свою чергу б’є по 395 природної шкоди і збільшує на 15% за кожен каст.</p>
  `,
  aside: [
    {
      type: 'spell',
      icon: 'inv_misc_surgeonglove_01',
      name: 'Patty Cake',
      desc: 'Mistcaller кастує в активний таргет (той на кому агро) спел що наносить 23 435 шкоди від природи і дезорієнтує ціль на 5 секунд.',
      whid: 321828
    },
    {
      type: 'spell',
      icon: 'ability_monk_renewingmists',
      name: 'Guessing Game',
      desc: 'Розпочинає гру створюючи чотири клони, серед яких тільки один правильний, принцип як і в лабіринті, під час дії гри бос імунний до шкоди, вбивши вірного клона гра закінчується.',
      whid: 336499
    },
    {
      type: 'spell',
      icon: 'ability_mage_freeze',
      name: 'Freeze Tag',
      desc: 'Mistcaller призиває <em>Illusionary Vulpin</em> щоб переслідувати ворогів протягом 14 секунд. Якщо досягає гравця створює <em>Freezing Burst</em>.',
      whid: 341709
    },
    {
      type: 'spell',
      icon: 'inv_ammo_snowball',
      name: 'Dodge Ball',
      desc: 'Mistcaller хоче пограти в м’ячі і кидає м’ячі з аніми в гравців, наносячи 11 717 шкоди від Природи всім ворогам на лінії.',
      whid: 336759
    },
    {
      type: 'spell',
      icon: 'spell_nature_elementalshields',
      name: 'Penalizing Burst',
      desc: 'Наносить 395 шкоди від Природи всім ворогам. Ця шкода збільшується на 15% за кожен каст.',
      whid: 321669
    },
    {
      type: 'spell',
      icon: 'inv_infernalmounice',
      name: 'Freezing Burst',
      desc: 'Коли лисиця досягає цілі, наносить 8 791 шкоди від Льоду і станить всіх ворогів в радіусі 8 ярдів протягом 8 секунд.',
      whid: 321893
    }
  ],
  attachments: [
    { type: 'video', videoId: 'H7rr8aTKDk0', name: 'Аналіз М+20 від Денса (2й сезон)' },
    { type: 'video', videoId: 'X-WtKtC7lMs', name: 'Аналіз М+16 від Денса' },
    { type: 'post', link: '/guides/tormented-affix', name: '10+ афікс' },
  ]
}
