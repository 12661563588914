export default {
  slug: 'broodkeeper-diurna',
  parent: 'vault-of-the-incarnates',
  name: 'Хранителька виводку Діурна',
  hint: 'Broodkeeper Diurna',
  image: '/img/broodkeeper-diurna.jpg',
  meta: {
    description: 'Тактика бою проти хранительки виводку Діурни у Сховищі втілень.',
    keywords: 'хранителька, виводку, діурна, тактика, стратегія, механіка, гайд, бос, сховище втілень, broodkeeper, diurna, vault of the incarnates'
  },
  header: { parent: true },
  sidenav: true,
  authors: [ 'Semerkhet' ],
  content: `
    ## Загальна інформація

    [p]Ця матуся захищатиме не вилуплені яйця ціною власного життя, і вона кине
    на вас усе, що у неї є, включаючи БАГАТО мобів! Головний білд -- АоЕ.[/p]

    [p]Ви повинні обережно пройти по великій площі, заповненій яйцями, розбиваючи
    яйця, щоб ще більше знизити її HP, та намагайтеся не бути подоланими ворогами
    як серед яєць, так і в кімнаті. Будьте особливо обережні, оскільки вона має
    Enrage на межі смерті, завдаючи все більшої шкоди та зменшуючи зцілення цілей,
    які вона сподівається вивести з бою.[/p]

    [p]Heroism / Bloodlust можна дати на другій фазі.[/p]

    [p]Майте на увазі, в межах 50 метрів від боса, отримана шкода збільшується на 10%.[/p]

    [p]Бос обирає 2 найближчі яйця для інкубації: дракон вилуплюється. Моби
    спавняться у наступному порядку: Frost -> Storm -> Fire -> Earth -- і потім
    циклічний повтор.[/p]

    [p]2 гравці ведуть промінь по 2 яйцях, щоб вони вилупилися. Активний танк
    іде вперед. Другий танк, що залишається для мобів, віддаляється від боса,
    щоб запобігти зціленню мобів.[/p]

    [p][icon/skull] Міфік: __Static Field__ навколо посоха тепер завдає шкоди,
    а __Wildfire__ запускає вихори навколо. Гравці біля боса тепер отримають
    збільшену шкоду на 1% кожні 7 секунд, ефект накопичується та триває
    30 секунд -- розділяються на дві групи та міняються босами/мобами кожні
    30 секунд. Одразу спавняться по дві групи мобів.[/p]

    ## Вміння боса

    ### Фаза 1

    [ul sparse]
      [li]__Broodkeeper’s Bond__ -- кожне яйце в кімнаті запобігає втраті здоров’я
      Діурни, що вимагає від рейду убити всі яйця та продовжувати завдавати шкоди босу.
      Коли бос знаходиться поруч зі своїми союзниками, вона значно зменшує шкоду,
      яку вони отримують, збільшує шкоду, яку вони завдають, і зцілює їх.[/li]
      [li]__Greatstaff of the Broodkeeper__ -- посох падає на землю, пульсуючи
      пошкодженнями АоЕ поблизу. Два гравці можуть поглинути заряд посоха, змушуючи
      промінь фіксувати їх, поки той не розіб'є одне яйце.[/li]
      [li]__Clutchwatcher’s Rage__ -- коли яйце розбивається, бос пульсує шкодою
      протягом 10 секунд, стакається.[/li]
      [li]__Rapid Incubation__ -- через 15 секунд найближчі яйця вилупляться.[/li]
      [li]__Wildfire__ -- гравці в радіусі 50 ярдів запалюються, завдаючи шкоди
      в радіусі навколо себе. [icon/alert] ~~Набрати спред!~~[/li]
      [li]__Icy Shroud__ -- завдає шкоди та сповільнює гравців у радіусі 50 ярдів,
      накладаючи на них поглинання лікування.[/li]
      [li]__Mortal Stoneclaws__ -- активний танк отримує сильний удар, що зменшує
      вхідне лікування на 65% на 8 секунд і залишає ДоТ. Зміна по кожному касту.[/li]
      [li]__The Clutchwarren__ -- збільшує швидкість пересування на 15%.[/li]
      [li]__Diurna’s Gaze__ -- гравці в радіусі 50 ярдів від боса втрачають баф
      швидкості руху __Clutchwarren__ і отримують на 10% більше шкоди.[/li]
    [/ul]

    ### Моби

    [ul sparse]
      [li]Tarasek Legionnaire -- завдає шкоди гравцеві.[/li]
      [li]Primalist Mage -- __Ice Barrage__ [icon/alert] ~~треба перервати~~,
      завдає великої шкоди цілі.[/li]
      [li]Tarasek Earthreaver -- заряджає поточну ціль, зменшуючи броню на 20%,
      стакається. Посилає землетрус, який можна уникнути, станить та завдає шкоди
      гравцям.[/li]
      [li]Dragonspawn Flamebender -- вибухає, завдаючи шкоди і викликає вогняні
      промені.[/li]
      [li]Juvenile Frost Proto-Drake -- кусає та вішає кровотечу на свою ціль,
      стакається. Завдає шкоди та сповільнює гравців у радіусі 50 ярдів.[/li]
      [li]Drakonid Stormbringer -- переривчастий штормовий поштовх, який завдає
      великої шкоди та приголомшує на 2 секунди, пульсує на 5 ярдів навколо
      себе.[/li]
    [/ul]

    ### Фаза 2

    [ul]
      [li]__Broodkeeper’s Fury__ -- шкода від боса збільшена на 15%.[/li]
      [li]Розширення вмінь: посох тепер націлений на випадкових гравців.
      __Shroud__ тепер знерухомлює гравців.[/li]
    [/ul]

    ### ДЛЯ ТАНКІВ

    [ul]
      [li]Не тримати боса поруч з мобами або яйцями весь час.[/li]
      [li]Juvenile Frost Proto-Drake завдає сильної шкоди по танку.[/li]
      [li]__Mortal Stoneclaws__ -- вміння боса, за яким йде зміна активного
      танка.[/li]
    [/ul]

    ### ДЛЯ ХІЛІВ

    [ul]
      [li]Увага гравцям, які виносять промінь та знаходяться у зоні впливу боса.[/li]
      [li]Діспелити __Icy/Frozen Shroud__.[/li]
      [li]Увага танку, який взяв Juvenile Frost Proto-Drake.[/li]
    [/ul]

    ### ДЛЯ ДПС

    [ul]
      [li]Обов'язково переривати __Ice Barrage__ та __Static Jolt__.[/li]
      [li]Якщо знаходитеся поряд з босом -- використовуйте особисті сейви.[/li]
    [/ul]

    [p][image/broodkeeper-diurna-1.jpg][/p]
  `,
  aside: [
    {
      type: 'image',
      src: '/img/broodkeeper-diurna.jpg'
    },
    {
      type: 'header',
      name: 'Хранителька виводку Діурна'
    },
    {
      type: 'spell',
      whid: 375809,
      icon: 'inv_item_protodragonegg_standard01',
      name: 'Broodkeeper\'s Bond',
      desc: `
        Старанна дбайливість хранительки виводку звʼязує її життєву силу
        з невилупленими яйцями, запобігаючі будь-якій шкоді нижче 2% здоровʼя.
        Ефект складається з кожним невилупленим яйцем в кімнаті.
        [br][br]
        Додатково, ця аура збільшує пошкодження союзників неподалік на 50%,
        зменьшує отримуване пошкодження на 25% та зцілює їх на 3% від їхнього
        загального здовровʼя щосекунди протягом 5 сек. Ефект складається.
      `
    },
    {
      type: 'spell',
      whid: 375842,
      icon: 'inv_staff_2h_broodkeeper_d_01',
      name: 'Greatstaff of the Broodkeeper',
      desc: `
        Хранителька виводку встромляє свій посох у землю, завдає [14455/nature]
        гравцям неподалік кожні 1.5 сек протягом 9 сек.
        [br][br]
        Додатково, Діурна миттево отримує 3 стаки __Greatstaff's Wrath__.
      `
    },
    {
      type: 'spell',
      whid: 390710,
      icon: 'spell_nature_stormreach',
      name: 'Greatstaff\'s Wrath',
      desc: `
        Збільшує пошкодження від __Greatstaff of the Broodkeeper__ на 10%. Ефект складається.
        [br][br]
        Гравці можуть застосовувати [spell/375863/Focus Greatstaff] поки знаходяться поблизу
        посоха, поглинають один стак __Greatstaff's Wrath__ та змушують промінь переслідувати
        гравця.
        [br][br]
        Фокусуючий промінь завдає [64244/nature] гравцям в радіусі 3 метри кожні пів секунди
        переслідування, руйнуючі будь-яке яйце на своєму шляху. Руйнування яйця призводить
        до __Clutchwatcher's Rage__.
      `
    },
    {
      type: 'spell',
      whid: 375829,
      icon: 'spell_nature_shamanrage',
      name: 'Clutchwatcher\'s Rage',
      desc: `
        Хранительку виводку охоплює лють, завдає 21 тис стихійних пошкоджень всім
        гравцям та збільшує шкоду від цього ефекту на 275% на 10 сек. Ефект складається.
      `
    },
    {
      type: 'spell',
      whid: 376073,
      icon: 'shaman_pvp_lightninglasso',
      name: 'Rapid Incubation',
      desc: `
        Хранителька виводку наповнює магією найближчі 2 яйця. Після 15 сек, вони
        вилуплюються та розкривають нових противників.
      `
    },
    {
      type: 'spell',
      whid: 375871,
      icon: 'spell_shadow_rainoffire',
      name: 'Wildfire',
      desc: `
        Діурна вивільняє полумʼя, підпалює гравців у радіусі 50 метрів, завдає
        [19273/fire] та додатково [7227/fire] щосекунди протягом 9 сек гравцям в
        радіусі 4 метрів від епуцентру. Ефект складається.
        [br][br]
        Невдовзі полумʼя спалахує знову, гравці неподалік від епіцентру отримують
        додатковий стак цього закляття.
        [br][br]
        [icon/skull] Міфік: Гравці, яких зачіпило цим закляттям, додатково розкидують
        полумʼя навколо себе.
      `
    },
    {
      type: 'spell',
      whid: 388716,
      icon: 'spell_fire_blueflamering',
      name: 'Icy Shroud',
      desc: `
        Діурна виштовхує потік криги, завдає [48227/frost] гравцям неподалік, зменшує
        їхню швидкість пересування на 30% та спричиняє поглинання 96 тис подальшого
        зцілення.
        [br][br]
        [icon/skull] Міфік: Поки ефект діє, гравці отримують додатково [25698/frost]
        щосекунди.
      `
    },
    {
      type: 'spell',
      whid: 375870,
      icon: 'inv_misc_monsterclaw_01',
      name: 'Mortal Stoneclaws',
      desc: `
        Діурна болісно вражає свою ціль, завдає [256978/physical] та [160611/nature],
        спричиняє [spell/378787/Crushing Stoneclaws] та [spell/378782/Mortal Wounds].
      `
    },
    {
      type: 'spell',
      whid: 394212,
      icon: 'inv_item_protodragonegg_standard01',
      name: 'The Clutchwarren',
      desc: `
        Первісна енергія шириться гніздом, підсилює всіх хто в середині, збільшуючи
        швидкість пересування на 15%.
      `
    },
    {
      type: 'spell',
      whid: 390561,
      icon: 'achievement_raidprimalist_diurna',
      name: 'Diurna\'s Gaze',
      desc: `
        Хранителька виводку пильно слідкує за ворогами навколо, збільшує отримувану
        шкоду гравцями на 10% та знімає ефект збільшення швидкості пересування
        від __The Clutchwarren__.
      `
    },
  ]
}
