export default {
  slug: 'the-primal-council',
  parent: 'vault-of-the-incarnates',
  name: 'Первісна рада',
  hint: 'The Primal Council',
  image: '/img/the-primal-council.jpg',
  meta: {
    description: 'Тактика бою проти Первісної ради у Сховищі втілень.',
    keywords: 'первісна, рада, праймал, консіл, тактика, стратегія, механіка, гайд, бос, сховище втілень, primal, council, vault of the incarnates'
  },
  header: { parent: true },
  sidenav: true,
  authors: [ 'Semerkhet' ],
  contentrows: [
    {
      content: `
        ## Загальна інформація

        [p]Під час битви вам потрібно одночасно битися з чотирма босами одночасно.
        Для максимально вигідного використання здібностей і швидкого вбивства босів
        робіть AoE білди. Всі боси повинні бути вбиті одночасно.[/p]

        [p]Протягом усієї битви буде лише одна фаза: 2 танкових боса та 2 заклинателя.
        Якщо один із босів помре, він почне використовувати Enrage, доки не загинуть
        інші боси.[/p]

        [p]Гра на босі полягає в тому, щоб нівелювати здібності одного боса за допомогою
        здібностей іншого боса (наприклад: розтопити лід вогнем і подібні, протилежні
        одна одній ігрові здібності). Корисне WA на перериваннях як на T29-Council
        (фз, чи воно ще там є, але збірку мабуть можна пошарити)[/p]

        [p]Початок бою в центрі. Після появи перших стовпів / калюж боси відводяться
        на край і пересуваються по колу. На карті є два кільця, зовнішнє і внутрішнє.
        Боси та гравці ближнього бою знаходяться на зовнішньому,
        а дистанційні -- всередині.[/p]

        [p]Гравці з позначками відходять від інших, очищаються один за одним на
        стовпах.[/p]

        [p]Гравці з метеоритами відходять від стовпів і звертаються за допомогою до
        рейдерів.[/p]

        [p]Коли починається __Primal Blizzard__, всі гравці поступово рухаються до
        вогняних калюж і після 6-7 стаків заходять у вогонь (памʼятаєте Джайну, так?)
        Важливо не зламати вогнем ще потрібні стовпи і встигнути зняти дебафи перед
        тим, як бігти назустріч.[/p]

        [p][icon/skull] Міфік: вам потрібно знищити стовпи та очистити вогняні калюжі,
        інакше кімната дуже швидко забивається.[/p]

        ## Вміння босів
      `,
      aside: [
        {
          type: 'image',
          src: '/img/the-primal-council.jpg'
        },
      ]
    },
    {
      content: `
        ### Кардос Криголют

        [p]При 100 енергії починає застосовувати __Primal Blizzard__ (AoE на рейді)
        на 10 секунд, накладає ДоТ на гравців. Накопичує вразливість до магії льоду
        та стійкість до магії вогню.[/p]

        [p]При 10 стаках __Primal Blizzard__ гравець заковується в блок __Frost Tomb__
        і отримує багато шкоди, доки його не звільнять.[/p]

        [p]Накладає заклинання на випадкових гравців (призначте відповідальних
        переривати ці заклинання).[/p]

        [p]Лід (дебаф) можна прибрати, перебуваючи в __Scorched Ground__.[/p]

        [p]Enrage: після поразки починає AoE по рейду, збільшуючи вхідну шкоду від
        холоду на 10% кожні 5 секунд, ці стаки можна очистити в __Scorched Ground__.[/p]
      `,
      aside: [
        {
          type: 'image',
          src: '/img/the-primal-council-kadros.jpg'
        },
        {
          type: 'header',
          name: 'Кардос Криголют',
          namet: 'Kadros Icewrath'
        },
        {
          type: 'spell',
          whid: 371836,
          icon: 'spell_frost_arcticwinds',
          name: 'Primal Blizzard',
          desc: `
            При 100 енергії, Кардос починає застосовувати морозну бурю протягом 10 сек.,
            озноб завдає [6424/frost] щосекунди, збільшуючи отримувану шкоду від магії криги
            на 10% та зменшуючи отримувану шкоду він магії вогню на 10% на 15 сек.
            Ефект складається. [spell/371514/Scorched Ground] прибирає цей ефект.
            [br][br]
            При досягненні 10 стаків, гравці опиняються в пастці [spell/371591/Frost Tomb].
          `
        },
      ]
    },
    {
      content: `
        ### Датія Бурехльост

        [p]Переривати заклинання (намагайтеся якомога більше переривати заклинання).[/p]

        [p]Необхідно набрати спред на __Chain Lightning__.[/p]

        [p]Розміщує на гравцях __Conductive Mark__ — дебафф-ДоТ, на 20 секунд,
        накопичується вразливість до +8% шкоди від природи. Потрібно набрати 5 метрів;
        [code]/range 5 в DBM[/code] -- ваш друг.[/p]

        [p]Ви можете зняти дебафф при контакті з __Scorched Ground__.[/p]

        [p][icon/skull] Міфік: дебафф триває нескінченно.[/p]

        [p]Enrage: під ногами гравців виникають вихори, завдаючи шкоди та відкидаючи
        гравців назад.[/p]
      `,
      aside: [
        {
          type: 'image',
          src: '/img/the-primal-council-dathea.jpg'
        },
        {
          type: 'header',
          name: 'Датія Бурехльост',
          namet: 'Dathea Stormlash'
        },
        {
          type: 'spell',
          whid: 391686,
          icon: 'spell_shaman_staticshock',
          name: 'Conductive Mark',
          desc: `
            Датія відмічає гравця блискавкою, чим завдає [64268/nature], а також [9637/nature]
            щосекунди та збільшує отримувану шкоду від магії природи на 10%. Ефект складається.
            [br][br]
            Рухаючих поблизу (до 4 метри) інших гравців призводить до дублювання та оновлення
            ефекту цього закляття.
          `
        },
      ]
    },
    {
      content: `
        ### Опалове Ікло

        [p]__Earthen Pillar__ з'являється під ногами гравців, завдає шкоди в радіусі
        6 метрів і відштовхує гравців назад; **Звичайний**: 3 стовпи. **Героїчний**:
        4 стовпи.[/p]

        [p]__Tank Strike__: висока шкода та +100% дебафф фізичної шкоди (накопичується).[/p]

        [p]Enrage: періодично викликає землетруси під ногами гравців, завдаючи шкоди
        та перериваючи заклинання на 4 секунди.[/p]

        [p][icon/skull] Міфік: стовпи впливають на рейд, поки вони існують.
        Їх потрібно буде знищити.[/p]
      `,
      aside: [
        {
          type: 'image',
          src: '/img/the-primal-council-opalfang.jpg'
        },
        {
          type: 'header',
          name: 'Опалове Ікло',
          namet: 'Opalfang'
        },
        {
          type: 'spell',
          whid: 370991,
          icon: 'ability_earthen_pillar',
          name: 'Earthen Pillar',
          desc: `
            Опалове Ікло вивільняє стовпи каміння на поверню землі під ногами декількох гравців,
            що завдає [160611/nature] та відхтовхує гравців в радіусі 6 метрів.
          `
        },
      ]
    },
    {
      content: `
        ### Ембар Вогнешлях

        [p]__Meteor Axes__ -- метеорити летять у 2 гравців (необхідно розділити шкоду).[/p]

        [p]Розливає калюжі __Scorched Ground__. Може зламати __Earthen Pillar__.[/p]

        [p][icon/skull] Героїчний/міфік: Розділювачі отримують дебафф до шкоди від
        сокири +300% на 15 секунд.[/p]

        [p][icon/skull] Міфік: площа Scorched Ground зменшується при очищенні стаків
        від __Primal Blizzard__.[/p]

        [p]__Tank Strike__: при 100 енергії бʼє вогнем і накладає негативний ефект.
        Стакається. [icon/alert] ~~Відвернути від рейду!~~[/p]

        [p]Enrage: починає запускати хвилі вогняних войдзон по полю бою.[/p]
      `,
      aside: [
        {
          type: 'image',
          src: '/img/the-primal-council-embar.jpg'
        },
        {
          type: 'header',
          name: 'Ембар Вогнешлях',
          namet: 'Embar Firepath'
        },
        {
          type: 'spell',
          whid: 374038,
          icon: 'inv_offhand_1h_artifactdoomhammer_d_01',
          name: 'Meteor Axes',
          desc: `
            Ембар відмічає двох гравців та через 5 сек жбурляє в них начаровані вогняні
            сокири, завдає [963665/fire] поділеного між всіма гравцями в радіусі 10 метрів
            від цілей, збільшуючи отримувану шкоду відь цього закляття на 300%. Також
            завдає [19273/fire] кожні 1.5 сек протягом 15 сек.
            [br][br]
            В момент влучання сокири, на землі утворюється випалена земля
            [spell/371514/Scorched Ground] та знищуються земляні стовпи
            [spell/370991/Earthen Pillar], що були в рідусі вибуху.
          `
        },
      ]
    },
    {
      content: `
        ### ДЛЯ ТАНКІВ

        [ul]
          [li]__Earthen__ та __Fiery__ -- удари по танку (зміна на __Earthen__).[/li]
          [li]Кожен танк тримає двох босів (1 ближнього бою + 1 заклинатель).[/li]
          [li]Коли прийде весь рейд, щоб очистити __Primal Blizzard__, спостерігайте
          за кастом __Fiery__.[/li]
          [li]Клів від Embra слідує за танком, не крутіть боса.[/li]
        [/ul]

        ### ДЛЯ ЦІЛИТЕЛІВ

        [ul]
          [li]Увага до людей з __Conductive Mark__.[/li]
          [li]Можна віддавати сейви для очищення від __Primal Blizzard__.[/li]
        [/ul]

        ### ДЛЯ ДПС

        [ul]
          [li]Контролювати переривання заклинань двом босам.[/li]
          [li]Одночасно вбити всіх 4 босів.[/li]
          [li]Не торкайтеся гравців із __Conductive Mark__.[/li]
        [/ul]

        ### ЛАЙФГАКИ

        [ul]
          [li]Встаньте біля земляних стовпів заздалегідь до відліку, щоб якомога
          швидше очистити кільця.[/li]
          [li]__Scorched Ground__ знімає всі стаки __Ice__ відразу, довго стояти
          в ньому не потрібно.[/li]
          [li][icon/skull] Міфік -- рекомендовано, щоб рдд залишалися поблизу стовпів,
          щоб будувати нові стовпи до старих (цю дію необхідно виконати для подальшого
          оптимального очищення всіх стовпів).[/li]
        [/ul]

        [p][image/the-primal-council-1.jpg][/p]
      `
    }
  ]
}
