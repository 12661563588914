export default {
  slug: 'vexiona',
  parent: 'nyalotha',
  name: 'Вексіона',
  hint: 'Vexiona',
  group: 'Halls of Devotion',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Skye' ],
  content: `
    <ul>
      <li>фаза 1 чергується з перехідною фазою (змінюються з часом); фаза 2 наступає на 40% хп боса</li>
      <li>фіолетові калюжі скидувати під стінку (на одну сторону)</li>
      <li>не стояти перед лицем боса</li>
      <li>героїзм на останній фазі</li>
    </ul>

    <h3>Фаза 1</h3>

    <ul>
      <li>великий ад в пріорітеті; дрібні ади мають бути поряд з босом для кліву</li>
      <li>збивати касти віддаленим адам і напулювати на танків</li>
      <li>великий ад робить сильний промінь в танка, ніхто не повинен в нього потрапляти</li>
      <li>великий ад лишає орб коли вмирає</li>
      <li>коли бос дихає, танк отримує стаки, ці стаки можна скинути якщо підняти орб</li>
      <li>коли танк отримує дебаф <em>Despair</em>, хіли мають швидко захілити його</li>
    </ul>

    <h3>Переходка</h3>

    <ul>
      <li>бос здіймається в небо; дихає на одну з трьох полос арени; потрібно уникати</li>
      <li>добити всіх адів якомога швидше</li>
    </ul>

    <h3>Фаза 2</h3>

    <ul>
      <li>танки завжди тримаються разом; рейд під босом</li>
      <li>коли бос кастує пульсуюче коло, відбігаємо в одну точку, потім повертаємося назад під боса</li>
      <li>хто має фіолетове коло — біжуть разом скидувати в одне місце</li>
      <li>коли навколо танка крутиться коло, збігаємося в нього (мінімум 5 вар'ятів)</li>
    </ul>
  `,
  attachments: [
    { type: 'video', videoId: 'mEqS2WXoynI', name: 'Першокіл (героїк)' }
  ]
}
