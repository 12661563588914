export default {
  slug: 'blightbone',
  parent: 'the-necrotic-wake',
  name: 'Blightbone',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Ta-na' ],
  content: `
    <p>Гера і бурсти з пула, цей момент буде описаний на кожному босі, проте кд гери не відкотиться
    на всіх, заздалегідь розраховуйте хто для вас важчий.</p>

    <p>На цьому файті важливою механікою є __Heaving Retch__, бос плює на рандомного гравця, наносячи
    8788 пошкоджень всім хто в конусі і створює три **черв’яка**, якщо швидко не вбити їх вони вибухнуть
    повісивши дебаф __Carrion Eruption__ — це буде вайп.</p>

    <p>Коли **черв’яки** з’являться група повинна бути разом під босом, щоб швидко вбити, важливо збігтись
    одразу (по можливості завжди бути в мілі). **Черв’яки** боляче жують тому станьте їх.</p>

    <p>Плювок __Heaving Retch__ повинен зачепити лише одного гравця, ціль може відбігати від групи або
    група від цілі, робіть як вам зручніше.</p>

    <p>Танк, захищайся від __Crunch__ (деф абілками).</p>
  `,
  aside: [
    {
      type: 'spell',
      icon: 'ability_warrior_shieldbreak',
      name: 'Crunch',
      desc: 'Наносить 19041 фізичної шкоди поточній цілі.',
      whid: 320655
    },
    {
      type: 'spell',
      icon: 'inv_pet_diseasedsquirrel',
      name: 'Fetid Gas',
      desc: 'Випускає накоплений газ у вигляді калюжі, існує 2 хв, стоячи в ній гравець отримує 2342 пошкоджень від сил природи і сайленс на всі атаки і касти.',
      whid: 320637
    },
    {
      type: 'spell',
      icon: 'spell_shadow_plaguecloud',
      name: 'Heaving Retch',
      desc: 'Випльовує на гравців три **черв’яки** і наносить 8788 пошкоджень від сил природи одразу і додатково 1462 кожні 2 сек протягом 12 сек.',
      whid: 320596
    },
  ],
  attachments: [
    { type: 'video', videoId: 'Uj7gFqzCAS0', name: 'Аналіз М+20 від Денса (2й сезон)' },
    { type: 'video', videoId: 'LRwEfMAXtCg', name: 'Аналіз М+16 від Денса' },
    { type: 'post', link: '/guides/tormented-affix', name: '10+ афікс' },
  ]
}
