export default {
  slug: 'sludgefist',
  parent: 'castle-nathria',
  name: 'Sludgefist',
  group: 'Blood from Stone',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Vorren' ],
  content: `
    <ul>
      <li>Поле бою — та сама кімната з першим босом</li>
      <li>Специфіка босу — робить чардж у танка, якщо попаде у колону — отримає стан та + 100% дамагу по ньому. Якщо попаде у стінку — вайп</li>
      <li>TW/Героїзм — на початку бою</li>
    </ul>

    <h2>Тактика</h2>
    <p>Як завжди, Бліззард дарує нам босів з невеличкою кількість механік але з смертельними наслідками.</p>
    <ul>
      <li>Танкам треба триматись один в одному.</li>
      <li>Мдд ніколи не мають бути біля танків.</li>
      <li>Як тільки на танку буде чардж — він має негайно ховатись за колону.</li>
      <li><em>Destructive Stomp</em> знищує колони, тому боса завжди треба танкувати на дистанції від них.</li>
      <li>В кого ланцюги — тримайтесь разом, і якщо ви в парі з мдд, йдіть до мілішників.</li>
      <li><em>Chain Slam</em> (червоне коло) соукають найближчі люди. Якщо дебаф на вашому партнері з ланцюгом — ви по дефолту маєте стояти разом з ним.</li>
    </ul>
  `,
  aside: [
    {
      type: 'spell',
      icon: 'spell_warlock_demonicservitude',
      name: 'Giant Fists',
      desc: 'Б\'є по танку та іншому гравці у радіусі 5 ярдів, якщо нікого немає, б\'є двічі.',
      whid: 335297
    },
    {
      type: 'spell',
      icon: 'inv_misc_steelweaponchain',
      name: 'Chain Link',
      desc: 'Об\'єднує гравців по партнерах ланцюгами. Якщо відійдете більше ніж на 12 ярдів один від одного — обидва помрете.',
      whid: 335300
    },
    {
      type: 'spell',
      icon: 'spell_shadow_spectralsight',
      name: 'Hateful Gaze',
      desc: 'Чардж у танка, танку треба ховатись за колону, всі хто будуть на шляху чарджу — помре. Після чарджу бос у стані з подвійним дамагом на короткий час.',
      whid: 331209
    },
    {
      type: 'spell',
      icon: 'spell_nature_earthquake',
      name: 'Destructive Stomp',
      desc: 'Велике коло навкого боса, з якого треба усім вибігати. Після цього на полі бою будуть маленькі кола з яких треба теж виходити. Маленькі кола залишають за собою калюжі.',
      whid: 332318
    },
    {
      type: 'spell',
      icon: 'inv_belt_18',
      name: 'Chain Slam',
      desc: 'Вибирає рандомного гравця (червоне коло) і через короткий час притягує усіх в колі до себе, дамаг який проходить необхідно ділити між 5+ гравцями.',
      whid: 335354
    },
  ],
  attachments: [
    { type: 'video', videoId: 'rP5xzkH6tsw', name: 'Першокіл (героїк)' }
  ]
}
