export default {
  slug: 'znayomstvo-probydnuk-cilitel',
  parent: 'guides',
  name: 'Сили Життя та Часу: знайомство з пробудником-цілителем',
  image: '/img/znayomstvo-probydnuk-cilitel.jpg',
  meta: {
    description: 'Гайд гри за пробудника-цілителя.',
    keywords: 'гайд, пробудник, цілитель, хілер, евокер, збереження, презервейшин, evoker, preservation, healer, dragonflight'
  },
  header: { parent: true },
  authors: [ 'Semerkhet' ],
  content: `
    [p][image/znayomstvo-probydnuk-cilitel.jpg][/p]

    ## Терміни та скорочення

    [ul]
      [li]**Швидкість** – параметр Haste[/li]
      [li]**Майстерність** – параметр Mastery[/li]
      [li]**Універсальність** – параметр Versatility[/li]
      [li]**Крит. удар** – параметр Critical Strike[/li]
      [li]**Превентивний хіл** – цілитель, який готується до вхідної шкоди по рейду
      заздалегідь, зазвичай за 10-15 секунд.[/li]
      [li]**Мілі** – бійці ближнього бою[/li]
      [li]**Кд** – кулдаун, час відкату вміння для повторного застосування[/li]
      [li]**Прок** – додатковий позитивний ефект, який може виникнути (а може й ні).[/li]
      [li]**Есенція** – ресурс Essence[/li]
    [/ul]

    ## Загальна інформація

    [p]Евокер – перший клас, який отримав механіку так званих заряджувальних кастів,
    що змінюють свою ефективність залежно від ступеня заряду. Всього ступенів буває три,
    дефолтне значення каст-таймеру 2.1 сек, але з набуттям більшої кількості [Швидкості//Haste],
    персонаж буде здатен заряджати їх трохи скоріше. Наприклад, при 30 відсотках [Швидкості//Haste]
    час заряджання складає 1,9 сек. Таких кастів у евокера-хіла три:[/p]

    [ul]
      [li][spell/357208/Fire Breath][/li]
      [li][spell/355936/Dream Breath][/li]
      [li][spell/367226/Spiritbloom][/li]
    [/ul]

    [p]Механіка гри за цей клас цілителя нагадує комбінацію превентивних хілів
    (друїда та дисциплін жерця) та вибагливість позиціонування шамана. Більшість кастів
    мають дальність 30 ярдів, що трохи менше, ніж у більшості хілів, але й не настільки
    вимагає стояти поряд з мілі, як то роблять паладіни.[/p]

    [p]Найефективніші вміння зцілення потребують [Есенцію//Essence], яка генерується
    з часом сама, або [spell/369297/Essence Burst] дає можливість застосувати без витрат
    ресурсу.[/p]

    ## Ще більше теорії

    ### Характеристики

    [p]Звісно, що в препатчі треба віддавати перевагу не характеристикам, а максимальному
    рівню предметів, що знадобиться під час прокачки у новому доповненні.[/p]

    [p]**[Майстерність//Mastery]** – пасивне вміння [spell/363510/Mastery: Life-Binder]
    гарантує посилення зцілення кожної цілі, чий рівень здоров'я менший за наш. Ніяких
    відносних порівнянь, тригер рівня так-ні, який гарантує повне значення відсотка
    [Майстерності//Mastery].[/p]

    [p]**[Критичний удар//Critical Strike]** – визначає потенційну можливість подвоїти
    вихідне зцілення. Деякі вміння суттєво залежать від криту, такі як
    [spell/377100/Exhilarating Burst] (впливає на обсяг критичного зцілення) та
    [spell/367364/Reversion] (подовжує своє існування).[/p]

    [p]**[Швидкість//Haste]** – прискорює час застосування будь-якого вміння,
    зменшує час, потрібний на заряджання зазначених вище трьох кастів, а також вміння
    тривалого зцілення (HoT) – [spell/367364/Reversion], [spell/355936/Dream Breath],
    а також скорочує кд таких умінь як [spell/367364/Reversion],
    [spell/373861/Temporal Anomaly], [spell/360995/Verdant Embrace].[/p]

    [p]**[Універсальність//Versatility]** – додає відсоткове посилення вихідної шкоди
    або зцілення, а також зменшує вхідну шкоду.[/p]

    [p]Загалом пріоритети виглядають приблизно так:[/p]

    [html]
      <table>
        <thead>
          <tr>
            <th>Рейд</th>
            <th>М+ (посилення зцілення)</th>
            <th>М+ (посилення атакуючих здібностей)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              1. Майстерність = Критичний удар<br>
              2. Швидкість<br>
              3. Універсальність<br>
            </td>
            <td>
              1. Швидкість = Майстерність<br>
              2. Критичний удар = Універсальність<br>
            </td>
            <td>
              1. Швидкість<br>
              2. Критичний удар = Універсальність<br>
              3. Майстерність<br>
            </td>
          </tr>
        </tbody>
      </table>
    [/html]

    ## То коли вже практика?

    [p]На 60му рівні не вийде взяти всі таланти, які будуть потрібні для майже
    ідеального стану. Тому для початку розглянемо загальну механіку.[/p]

    ### Загальні пріоритети

    [ul sparse]
      [li]Застосовуйте [spell/361469/Living Flame] на ворогів чи поранених союзників,
      щоб отримати додаткові проки [spell/369297/Essence Burst] для безпосереднього
      застосування потребуючих [Есенцію//Essence] вмінь без витрат.[/li]

      [li]Кастуйте на союзників [spell/364343/Echo] до того, як по ним пройде шкода,
      аби згодом отримати перевагу від дубльованих заклинань.[/li]

      [li]Перед отриманням шкоди рейду варто розкидати декілька
      [spell/355913/Emerald Blossom] по місцях найбільшого скупчення гравців.[/li]

      [li]Застосовуйте [spell/357208/Fire Breath], [spell/355936/Dream Breath],
      [spell/367226/Spiritbloom] якнайчастіше, коли рейд отримує шкоду, бажано на
      максимальному рівні заряду. Чому до зцілення потрапило атакуюче вміння?
      Завдяки таланту [spell/369939/Leaping Flames] воно посилює зцілення
      [spell/361469/Living Flame]. Бонусом буде використовувати
      [spell/370553/Tip the Scales] для моментального застосування зарядженого
      вміння на максимальному рівні заряду.[/li]

      [li]Перед [spell/355936/Dream Breath] варто застосувати
      [spell/360995/Verdant Embrace] задля бонусу в 40% додаткового зцілення
      завдяки [spell/373834/Call of Ysera].[/li]

      [li]Підтримуйте [spell/367364/Reversion] на танку та пріоритетних цілях
      (союзники з дебафами, тощо).[/li]

      [li][spell/360995/Verdant Embrace] – суттєве зцілення однієї цілі.
      Головиний мінус – він переносить вас до цілі, тому варто застосовувати
      обережно.[/li]

      [li][spell/367226/Spiritbloom] на першому рівні заряду – теж екстрене
      піднятті по рівню здоров'я однієї цілі.[/li]

      [li]Застосовуйте [spell/363534/Rewind] після того, як рейд чи група отримала
      значну шкоду.[/li]
    [/ul]

    ### Як працює Stasis

    [p][spell/370537/Stasis] дозволяє раз на три хвилини зберегти 3 зцілюючих
    заклинання та за наступної активаціі застосує їх знову без витрат ресурсу,
    або мани.[/p]

    [ul]
      [li]Заряджені вміння дублюються на тому рівні, на якому вони були застосовані.[/li]
      [li]Не працює на [spell/363534/Rewind] та [spell/359816/Dream Flight].[/li]
      [li]Дубльований [spell/355936/Dream Breath] випускається за напрямом зору персонажа.[/li]
    [/ul]

    ### Рейд

    [p]Варті уваги таланти: [spell/371832/Cycle of Life] та [spell/359816/Dream Flight].
    Ми не можемо взяти зараз обидва, тому рейдовий білд фокусується на другому,
    як найбільш впливовому.[/p]

    [p mb-0]Строка імпорту:[/p]
    [pre]BwbBAAAAAAAAAAAAAAAAAAAAAAJUCJBgIJINaJJJJJAAAAAAEQiEIcAJJJRgkE[/pre]

    [p][image/znayomstvo-probydnuk-cilitel-1.jpg][/p]

    [p]Такі таланти як [spell/390148/Flow State], [spell/370665/Rescue] не дають великого
    впливу на механіку, тому можуть бути замінені на інші, якщо ви страждаєте від нестачі
    мобільності або маєте проблеми за маною.[/p]

    ### М+ з фокусом на зцілення

    [p mb-0]Строка імпорту:[/p]
    [pre]BwbBAAAAAAAAAAAAAAAAAAAAAABCgICSSOgUolkkkkAAAAAAQAChIBJJJRISSA[/pre]

    [p][image/znayomstvo-probydnuk-cilitel-2.jpg][/p]

    [p]Найбільший вплив на загальне зцілення має другий заряд [spell/363534/Rewind],
    а також [spell/370984/Emerald Communion] з [spell/373270/Lifebind].[/p]

    ### М+ з фокусом на завдавання шкоди ворогам

    [p mb-0]Строка імпорту:[/p]
    [pre]BwbBAAAAAAAAAAAAAAAAAAAAAABAISCSSSCkkkkEAAAAAAEQiESCkISkEOQSA[/pre]

    [p][image/znayomstvo-probydnuk-cilitel-3.jpg][/p]

    [p]Найбільший вплив на вихідну шкоду має [spell/376179/Lifeforce Mender] що суттєво
    підвищує потенціал [spell/361469/Living Flame], а комбінація
    [spell/357208/Fire Breath] + [spell/361469/Living Flame] розповсюджує вплив другого
    на ще декілька цілей.[/p]

    ## Оновлення білдів під 70 рівень

    [p]Загалом поради залишаються ті самі, лише додаються нові варіанти утіліті
    та здебільшого пасивні опції.[/p]

    ### М+

    [p]Сфокусований здебільшого на підвищені атакуючого потенціалу, але з максимально
    можливим утіліті для групи.[/p]

    [p mb-0]Імпорт:[/p]
    [pre]BwbBAAAAAAAAAAAAAAAAAAAAAABCCiIIJJJtQSSSSCAAAAAIkIkQIJBJJJSIkE[/pre]

    [p][image/znayomstvo-probydnuk-cilitel-4.jpg][/p]

    ### Рейд

    [p]Рейдові опції передбачають можливі два білди: через бурстове зцілення, та
    через максимальне застосування [spell/367364/Reversion].[/p]

    #### Бурст зцілення

    [p]Загалом це дуже мана-коштовний білд, тому намагайтеся надмірно не використовувати
    такі абілки як Time Anomaly. Цей білд ідеально підійде для початку рейдингу, поки ви
    не набрали достатньої кількості крит удару та мінімум 2 з 4 сетових шматки.[/p]

    [p mb-0]Імпорт:[/p]
    [pre]BwbBAAAAAAAAAAAAAAAAAAAAAAJUSkEgUkkg0SkkkkkAAAAAAQiQCBkEJJJSISSA[/pre]

    [p][image/znayomstvo-probydnuk-cilitel-5.jpg][/p]

    #### Reversion білд

    [p]Загалом він працює як превентивний мана-сейв білд, але вимагає наявності великої
    кількості крит удару та забезпечується першим сетовим бонусом. Без нього просто не
    набереться необхідного обсягу крит удару. Головною умовою є підтримка якомога
    більшої кількості reversion на рейді.[/p]

    [p mb-0]Імпорт:[/p]
    [pre]BwbBAAAAAAAAAAAAAAAAAAAAAAClcAJhISRSSItQSSSIAAAAAAkUCJEQSkkkIhIJB[/pre]

    [p][image/znayomstvo-probydnuk-cilitel-6.jpg][/p]
  `
}
