<template>
  <v-app>
    <NavBar />
    <v-main>
      <router-view>
      </router-view>
    </v-main>
  </v-app>
</template>

<script>
import NavBar from "@/components/NavBar";
export default {
  components: { NavBar },
};
</script>

<style scoped>

#app {
  background-color: var(--color-background) !important;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

</style>

<style>

@import url('https://cdn.jsdelivr.net/npm/@mdi/font@latest/css/materialdesignicons.min.css');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');

:root {
  --font-text: normal 400 16px "Open Sans", serif;
  --font-text-light: normal 300 16px "Open Sans", serif;
  --font-text-bold: normal 600 16px "Open Sans", serif;
  --font-text-bold-extra: normal 700 16px "Open Sans", serif;
  --font-text-bold-maximum: normal 800 16px "Open Sans", serif;
  --color-background: #fffef8;
  --color-background-alt: #bbb2a9;
  --color-text: #1e2329;
  --color-link: #1976d2;
  --content-offset-top: 20px;
  --content-offset-bottom: 40px;
  --navbar-min-height: 70px;
}

a {
  transition: .25s opacity ease-in-out;
}

a:hover {
  opacity: .65;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
  line-height: 1.1;
}

h1 {
  line-height: 1.4;
}

img {
  max-width: 100%;
}

.theme--light.v-icon {
  color: var(--color-text);
}

.v-btn {
  white-space: normal;
}

.v-btn .v-btn__content {
  max-width: 100%;
  text-align: left;
}

.app-container {
  display: flex;
  flex-direction: column;
  padding: 16px 80px;
  font: var(--font-text);
  color: var(--color-text);
}

.app-container.with-content-offset-top {
  margin-top: var(--content-offset-top);
}

.app-container.with-content-offset-bottom {
  margin-bottom: var(--content-offset-bottom);
}

.app-reading-title {
  margin: 0 auto;
  margin-bottom: 1.5em;
  display: flex;
  flex-wrap: wrap;
  width: 60%;
  font-size: 50px;
  line-height: 1.1;
}

.app-reading-title .arrow {
  padding-left: .5em;
  padding-right: .5em;
}

.app-reading-column {
  margin: 0 auto;
  display: flex;
  width: 60%;
  font-size: 24px;
  line-height: 1.6;
  overflow: auto hidden;
}

.app-reading-column .main-column {
  width: 100%;
}

.app-reading-column.with-aside {
  margin: 0;
  width: 85%;
  flex-direction: row;
}

.app-reading-column.with-aside .aside-column {
  padding-top: .35em;
  padding-right: 3em;
  width: 37%;
  font-size: 20px;
  line-height: 1.4;
}

.app-reading-column.with-aside .aside-column.empty {
  padding-top: 0;
  margin-top: 0;
  border-top: none;
}

.app-reading-column.with-aside .main-column {
  width: 55%; /* not a bug: 37 + 55 != 100 */
}

.app-reading-column .attachments-block {
  padding: .35em 0 2em;
}

.app-reading-column h2,
.app-reading-column h3,
.app-reading-column h4 {
  margin-bottom: 1em;
}

.app-reading-column p + h2,
.app-reading-column ul + h2,
.app-reading-column pre + h2,
.app-reading-column p + h3,
.app-reading-column ul + h3,
.app-reading-column pre + h3,
.app-reading-column p + h4,
.app-reading-column ul + h4,
.app-reading-column pre + h4 {
  margin-top: 2em;
}

.app-reading-column p + h4,
.app-reading-column ul + h4,
.app-reading-column h4 + p,
.app-reading-column h4 + ul {
  margin-top: .5em;
}

.app-reading-column h2 + h3,
.app-reading-column h3 + h4 {
  margin-top: 1.5em;
}

.app-reading-column p,
.app-reading-column ul,
.app-reading-column ul.with-item-gaps > li,
.app-reading-column figure {
  margin: 1em 0;
}

.app-reading-column p:first-child {
  margin-top: 0;
}

.app-reading-column p:last-child {
  margin-bottom: 0;
}

.app-reading-column p + p {
  margin-top: 1.5em;
}

.app-reading-column h2 + p,
.app-reading-column h3 + p {
  margin-top: 0;
}

.app-reading-column ul ul {
  margin: 0;
}

.app-reading-column ul.sparse > li {
  margin: 1em;
}

.app-reading-column a {
  color: var(--color-link);
  text-decoration: none;
  padding-bottom: 0;
  border-bottom: 1px solid var(--color-link);
}

.app-reading-column em {
  border-bottom: 1px solid var(--color-text);
}

.app-reading-column del {
  opacity: .4;
}

.app-reading-column pre {
  padding: .5em 1em;
  line-height: 1.25;
  overflow: auto;
  background: moccasin;
}

.app-reading-column img {
  display: block;
}

.app-reading-column figure figcaption {
  margin-top: .25em;
  line-height: 1.25;
  font-size: 75%;
  font-style: italic;
  opacity: .8;
}

.app-reading-column table {
  margin-bottom: 1em;
  width: 100%;
  caption-side: bottom;
  border-collapse: collapse;
  border-bottom: 1px solid lightgrey;
}

.app-reading-column table th {
  padding: 0.5em;
  line-height: 1.2;
  border-top: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
}

.app-reading-column table td {
  padding: 0.5em;
  vertical-align: top;
}

.app-yt-player-wrap .yt-player,
.app-yt-player-wrap .yt-player iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (min-width: 1264px) {
  .app-reading-title.with-aside {
    margin-left: 0;
    margin-right: 0;
    width: 85%;
  }

  .app-reading-column.with-aside.empty-aside .main-column {
    margin-left: 37%;
  }
}

@media (max-width: 1263px) {
  :root {
    --content-offset-top: 16px;
    --content-offset-bottom: 20px;
  }

  .app-container {
    padding-left: 32px;
    padding-right: 32px;
  }

  .app-reading-title {
    width: 100%;
    font-size: 34px;
  }

  .app-reading-column {
    font-size: 20px;
  }

  .app-reading-column {
    width: 72%;
  }

  .app-reading-column.with-aside {
    width: 100%;
  }

  .app-reading-column.with-aside .aside-column {
    padding-right: 2em;
    width: 40%;
  }

  .app-reading-column.with-aside .main-column {
    width: 60%;
  }

  .app-reading-column p + h2,
  .app-reading-column ul + h2 {
    margin-top: 1em;
  }
}

@media (max-width: 991px) {
  .app-reading-column.with-aside {
    margin: 0 auto;
    flex-direction: column-reverse;
  }

  .app-reading-column,
  .app-reading-column.with-aside {
    width: 75%;
  }

  .app-reading-column.with-aside .main-column {
    width: 100%;
  }

  .app-reading-column.with-aside .aside-column {
    padding-right: 0;
    margin-top: 2em;
    padding-top: 2em;
    width: 100%;
    font-size: 20px;
    border-top: 1px solid lightgrey;
  }
}

@media (max-width: 767px) {
  .app-reading-column,
  .app-reading-column.with-aside {
    width: 85%;
  }
}

@media (max-width: 599px) {
  :root {
    --content-offset-top: 0;
    --content-offset-bottom: 0;
  }

  .app-container {
    padding-left: 16px;
    padding-right: 16px;
  }

  .app-reading-title {
    width: 100%;
    font-size: 28px;
  }

  .app-reading-column {
    width: 100%;
    font-size: 18px;
    line-height: 1.6;
  }

  .app-reading-column.with-aside {
    width: 100%;
  }

  .app-reading-column.with-aside .aside-column {
    font-size: 18px;
  }
}

.implication {
  display: inline-block;
  border-bottom: 1px dashed #bbb;
}

.damage { white-space: nowrap; }
.damage.arcane i { color: #8ac; }
.damage.nature i { color: #4b4; }
.damage.fire i { color: #f84; }
.damage.frost i { color: #4ae; }
.damage.holy i { color: #dd0; }
.damage.shadow i { color: #a6c; }
.damage.physical i { color: #a86; }

</style>
