export default {
  slug: 'guardian-of-the-first-ones',
  parent: 'sanctum-of-domination',
  name: 'Guardian of the First Ones',
  short: 'Guardian',
  image: '/img/guardian-of-the-first-ones.jpg',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Dancecaptain (Makhabunda)' ],
  attachments: [
    { type: 'video', videoId: 'm5ksAPKUpMg', name: 'Першокіл (героїк)' },
    { type: 'video', videoId: 'z7f0X7DEEFw', name: 'Відеотактика (англійська)' }
  ],
  aside: [
    {
      type: 'header',
      name: 'Спели боса'
    },
    {
      type: 'spell',
      icon: 'spell_progenitor_areadenial',
      name: 'Purging Protocol',
      namet: '(смертельно)',
      desc: 'Досягнувши 0 енергії, бос завдає всім гравцям 15029 Арканічного пошкодження, допоки не почне відновлювати енергію від колони. Кожен наступний каст наносить на 100% більше пошкоджень.',
      whid: 352538
    },
    {
      type: 'spell',
      icon: 'inv_mace_2h_progenitorbroken_d_01',
      name: 'Elimination Pattern',
      namet: '(до уваги танків)',
      desc: 'Бос здійснює серію атак по цілі з найбільшим агром завдаючи відповідній цілі 45035 Фізичного пошкодження та іншим гравцям у 6 ярдах від неї та збільшує вхідне пошкодження від такого ж удару на 200% впродовж 20 секунд.',
      whid: 350735
    },
    {
      type: 'spell',
      icon: 'spell_progenitor_orb2',
      name: 'Obliterate',
      namet: '(важливо, героїк)',
      desc: 'Бос наносить удар по цілі, який завдає 62062 Арканічного пошкодження поділеного між гравцями в 6 ярдах від цілі. Окрім цього, всі цілі задіті відповідною абілкою боса, отримають дебаф, який збільшує вхідне пошкодження від відповідної абілки на 500% впродовж 20 секунд.',
      whid: 355352
    },
    {
      type: 'spell',
      icon: 'spell_progenitor_orb2',
      name: 'Form Sentry',
      desc: 'Бос створює воїд-зону, знаходячись у якій гравець отримує 1803 Арканічного пошкодження кожні 2 секунди і отримує неможливість атакувати чи кастити допоки знаходиться у воїд-зоні. Іноді з воїд-зони вилітає залп енергії на випадкових гравців, які отримують 4809 Арканічного пошкодження. [br]Окрім цього, __Purgin Protocol__ знищує воїд-зони.',
      whid: 352660
    },
    {
      type: 'spell',
      icon: 'spell_broker_nova',
      name: 'Threat Neutralization',
      desc: 'Бос вішає на декількох гравців мітки. Через 4 секунди відбудеться вибух який завдає 18035 Арканічного пошкодження кожному гравцю, який знаходиться в 10 ярдах від мітки.',
      whid: 356090
    },
    {
      type: 'spell',
      icon: 'spell_progenitor_beam',
      name: 'Disintegration',
      desc: 'Бос випускає промінь у гравців, який завдає 21041 Арканічного пошкодження. Окрім цього гравці, яких зачепив промінь отримують стан і  дот на 6 секунд, який завдає 3006 Арканічного пошкодження кожну секунду.',
      whid: 352833
    },
    {
      type: 'header',
      name: 'Абілки енергетичних колон'
    },
    {
      type: 'spell',
      icon: 'spell_progenitor_orb',
      name: 'Energizing Link',
      desc: 'Коли бос близько до колони, він отримує 5 енергії кожну секунду.',
      whid: 352385
    },
    {
      type: 'spell',
      icon: 'spell_progenitor_buff',
      name: 'Radiant Energy',
      namet: '(важливо)',
      desc: 'Колона має навколо себе зону, стоячи у якій гравці отримують на 90% менше вхідного пошкодження від абілки __Unstable Energy__ (до уваги хілерів) поки колона передає енергію босу, вона завдає 21041 Арканічного пошкодження всім гравцям кожну секунду.',
      whid: 352394
    },
    {
      type: 'spell',
      icon: 'spell_progenitor_nova',
      name: 'Meltdown',
      namet: '(смертельно)',
      desc: 'При досягненні 0 енергії, колона вибухає завдаючи 42081 Арканічного пошкодження всім гравцям в 15 ярдах і 21041 Арканічного пошкодження гравцям, які знаходяться дальше, ніж 15 ярдів.',
      whid: 352589
    },
  ]
}
