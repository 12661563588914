export default {
  slug: 'doctor-ickus',
  parent: 'plaguefall',
  name: 'Doctor Ickus',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Ta-na' ],
  content: `
    <p>Цікавий файт, гера і бурсти з пула. Виглядає все так, ламаєм лице босу, з часом він
    піділ’є «супутніка-v» в один з **казанів** і він перетвориться на **бімбу**, котра
    __вибухне__. Доктор буде прикликати **синього пельменя** котрий в свою чергу матиме
    __ауру__ на -75% пошкоджень по всім союзникам в радіусі 7 ярдів.</p>

    <p>Ще док пускатиме __хвилі__ на чотири сторони в радіусі 3 ярдів від епіцентру і
    кастуватиме __Burning Strain__ — збивайте.</p>

    <p>Пріоритет: **Бочка** => **Бос** => **Синій слимак**</p>

    <p>Танк, захищайся від __Slime Injection__ і відводь **синього пельменя** від **бочок**.
    Хіл, діспель танка коли той має __Slime Injection__, після діспелу з’явиться
    **зелений пельмень**, розчавіть його або вбийте. Збивайте каст __Burning Strain__.</p>

    ## Лайфхаки

    <p>**Синього пельменя** можна контролювати щоб не витрачати час (навіть законтролений
    дає __ауру__, тож відводьте боса), **зеленого** просто розчавити, боса ведіть
    до **бімби** і клівайте. Танк, коли бос __скаче__ і пускає __хвилі__ — дпсь **бімбу**.</p>
  `,
  aside: [
    {
      type: 'spell',
      icon: 'spell_shadow_plaguecloud',
      name: 'Burning Strain',
      desc: 'Наносить 4394 пошкоджень від сил природи і додатково 879 кожну секунду протягом 10 сек, якщо в мілі нема нікого, ефект стакається.',
      whid: 322358
    },
    {
      type: 'spell',
      icon: 'inv_maldraxxusslime_green',
      name: 'Slime Injection',
      desc: 'Інфікує гравця, наносить 8788 пошкоджень від сил природи та додатково 1758 кожну секунду протягом хвилини.<br><br>Після діспелу створює **пельменя**.',
      whid: 329110
    },
    {
      type: 'spell',
      icon: 'spell_shaman_earthquake',
      name: 'Slime Lunge',
      desc: 'Стрибає до найвіддаленішого гравця, завдаючи 11718 фізичних пошкоджень всім гравцям поряд з епіцетром приземлення, відкидуючи їх.',
      whid: 329217
    },
  ],
  attachments: [
    { type: 'video', videoId: 'F6S3wPYPC6M', name: 'Аналіз М+20 від Денса (2й сезон)' },
    { type: 'video', videoId: 'vln2xil1cAI', name: 'Аналіз М+17 від Денса' },
    { type: 'post', link: '/guides/tormented-affix', name: '10+ афікс' },
  ]
}
