import Vue from 'vue'
import VueRouter from 'vue-router'
import appPosts from '@/assets/app-posts'

Vue.use(VueRouter)

const routes = [
  { path: '/',                  component: () => import('@/components/Home.vue') },
  { path: '/videos',            component: () => import('@/components/Videos.vue') }, // TODO: rework Videos into post; remove "videos" from "appSettings", should be just part of the post
  { path: '/addons',            component: () => import('@/components/Addons.vue') }, // TODO: (same as for "Videos")
  { path: '/roster',            component: () => import('@/components/Roster.vue') },
  { path: '/roster/rankings',   component: () => import('@/components/Rankings.vue') },
  { path: '/roster/recipes',    component: () => import('@/components/Recipes.vue') },
  ...appPosts
    .map(p => {
      if (p.redirectLink) {
        return {
          path: p.link,
          redirect: p.redirectLink
        }
      } else {
        return {
          path: p.link,
          component: () => import('@/components/Reader.vue'),
          props: { slug: p.slug }
        }
      }
    }),
  {
    path: '*',
    component: () => import('@/components/Reader.vue'),
    props: { slug: 'page-not-found' }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    window.scroll(0, 0)
  }
})

export default router
