export default {
  slug: 'nyalotha',
  parent: 'raids',
  name: 'Ні\'алота, місто пробуджується',
  hint: 'Ny\'alotha, The Waking City',
  short: 'Ні\'алота',
  image: '/img/nyalotha.jpg',
  group: 'Битва за Азерот',
  header: { parent: true },
  archive: true,
  meta: {
    description: 'Рейд "Ні\'алота, місто пробуджується" з доповнення Битва за Азерот.',
    keywords: 'ні\'алота, ніалота, наялота, нялота, рейд, битва за азерот, бфа, ny\'alotha, nyalotha, waking, city, raid, battle for azeroth, bfa, wow'
  }
}
