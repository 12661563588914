export default {
  slug: 'domina-venomblade',
  parent: 'plaguefall',
  name: 'Domina Venomblade',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Ta-na' ],
  content: `
    <p>Гера і бурсти з пула. Логіка бою така, валим боса, вибиваємо адів з павутини, стоїмо разом.</p>

    <p>Танк протискайся під __Cytotoxic Slash__, це яд, деякі гравці можуть диспелити, також **асасини**
    що будуть з’являтися будуть гарно навалювати.</p>

    <p>Протягом всього файту потрібно стояти поряд з іншими гравцями в радіусі 6 ярдів, двох людей
    достатньо, інакше вас закутає в **павутину** і доведеться вибивати — __Solitary Prey__.</p>

    <p>Павучиха періодично буде прикликати **асасинів**, вони будуть в центрі павутини
    котру розкидає бос, це сама важлива механіка, поки в павутині кастують __Assassinate__,
    дебаф стакаєтся, ади невидимі. Вибити їх можна за допомогою АОЕ, станів, відкидувань,
    якщо в групі є можливість прискорень залишайте на фазу з адами.</p>
  `,
  aside: [
    {
      type: 'spell',
      icon: 'ability_garrosh_touch_of_yshaarj',
      name: 'Solitary Prey',
      desc: 'Якщо протягом 6 секунд ви знаходитесь більше ніж на 6 ярдів від союзників будете окутані **павутиною**, потрібно вибивати.',
      whid: 336258
    },
    {
      type: 'spell',
      icon: 'ability_demonhunter_vengefulretreat',
      name: 'Shadow Ambush',
      desc: '**Доміна** з’являється позаду відміченого гравця, бьє всіх гравців у радіусі 10 метрів від цілі на 8788 пошкоджень від темної магії та приголомшує на 3 сек.',
      whid: 325245
    },
    {
      type: 'spell',
      icon: 'spell_deathvortex',
      name: 'Cytotoxic Slash',
      desc: 'Б’є поточний таргет, наносячи 4980 Фізичних пошкоджень і залишає токсин збільшуючи отримані пошкодження від сил Природи на 100% протягом 8 сек.',
      whid: 325552
    },
    {
      type: 'spell',
      icon: 'inv_spidermount',
      name: 'Brood Assassins',
      desc: '**Доміна** прикликає вірних їй **Асасинів**.',
      whid: 332313
    },
    {
      type: 'spell',
      icon: 'inv_knife_1h_maldraxxus_d_01',
      name: 'Assassinate',
      desc: '**Асасини** періодично жбурляють отруєні ножі у випадкову ціль, наносячи 1025 пошкоджень від Природи одразу і 586 кожні 2 сек протягом 30 сек.',
      whid: 333406
    },
  ],
  attachments: [
    { type: 'video', videoId: 'F6S3wPYPC6M', name: 'Аналіз М+20 від Денса (2й сезон)' },
    { type: 'video', videoId: 'vln2xil1cAI', name: 'Аналіз М+17 від Денса' },
    { type: 'post', link: '/guides/tormented-affix', name: '10+ афікс' },
  ]
}
