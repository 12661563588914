import Vue from 'vue'
// import VueClipboard from 'vue-clipboard2'
import VueGtag from 'vue-gtag'
import VueYouTubeEmbed from 'vue-youtube-embed'

import mixin from '@/mixin'
import router from '@/router'
import vuetify from '@/plugins/vuetify'
import appSettings from '@/assets/app-settings.json'
import App from '@/App.vue'

Vue.config.productionTip = false
// VueClipboard.config.autoSetContainer = true
// Vue.use(VueClipboard)
Vue.use(VueYouTubeEmbed)

Vue.use(VueGtag, {
  // ability to disable GTM when developing locally or testing live; so it will not pollute real analytics
  enabled: !window.localStorage.gtm_disable,
  config: { id: appSettings.gtm }
}, router)

Vue.mixin(mixin)

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
