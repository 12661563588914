export default {
  slug: 'nalthor-the-rimebinder',
  parent: 'the-necrotic-wake',
  name: 'Nalthor the Rimebinder',
  short: 'Nalthor',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Ta-na' ],
  content: `
    <p>Періодично бос кидає на випадкового гравця __Frozen Binds__, цей дебаф має великий радіус і його можна
    діспелити, але якщо в колі є гравці то під час діспелу вони примерзнуть до землі (варто уникати), тому
    бійцям дальнього бою + хіл варто одразу розсередитись по платформі, ближньо бійним і танку доведеться
    бігати.</p>

    <p>__Comet Storm__ небезпечна річ, кидає льодяні комети в місце знаходження гравців кожні 0,5 сек.
    протягом 4 секунд, бійці дальнього бою і хіл бігайте навколо своєї осі (як циркуль), танк і мілі стійте
    разом і на початок шторму бігайте разом за, або проти годинникової стрілки навколо боса (перед пулом
    узгодьте).</p>

    <p>Протягом файту бос буде викидати випадкового дд на іншу платформу, потрібно швидко дістатися до кінця,
    уникати всяких как, вбити **ада** і летіти назад, у вас 50 секунд інашке буде смерть. Після повернення
    через пару секунд під гравцем розіллється __калюжа__, тому одразу при поверненні біжіть в куток і там
    залиште її, як бонус на вас буде баф +100% до крита протягом 12 сек.</p>

    <p>Гера і бурсти з пула.</p>
  `,
  aside: [
    {
      type: 'spell',
      icon: 'spell_frost_iceshard',
      name: 'Icy Shard',
      desc: 'Бос кидає уламок льоду в свій таргет наносячи 14647 фізичних пошкоджень.',
      whid: 320771
    },
    {
      type: 'spell',
      icon: 'spell_mage_cometstorm',
      name: 'Comet Storm',
      desc: 'Кидає льодяні комети в місце знаходження гравців кожні 0,5 сек. протягом 4 секунд, кожна комета наносить 8788 пошкождень від магії льоду всім гравціям в радіусі 3 ярдів і збільшує отримані пошкодження від комент на 25% протягом 5сек.',
      whid: 326675
    },
    {
      type: 'spell',
      icon: 'spell_frost_chainsofice',
      name: 'Frozen Binds',
      desc: 'Приморожує випадкового гравця до землі, наносить 2929 пошкоджень від магії льоду кожні дві секунди протягом 12 сек, при розсіюванні переходить до всіх союзників в радіусі 16 метрів.',
      whid: 320788
    },
    {
      type: 'spell',
      icon: 'ability_mage_shattershield',
      name: 'Icebound Aegis',
      desc: 'Бос закриває себе льодяним щитом, поглинаючи наступні 28885 пошкоджень, поки щит активний від боса летять хвилі енергії наносячи 2197 пошкоджень від магії льоду всім гравцям кожні 3 сек., і збільшує отримання пошкоджень від цього ж закляття на 10% протягом 5 сек., цей ефект сумується.',
      whid: 321754
    },
    {
      type: 'spell',
      icon: 'spell_necro_deathlyecho',
      name: 'Dark Exile',
      desc: 'Виганяє гравця на нижчий поверх, якщо не вибратись протягом 50 сек, гравець помирає.',
      whid: 321894
    },
  ],
  attachments: [
    { type: 'video', videoId: 'Uj7gFqzCAS0', name: 'Аналіз М+20 від Денса (2й сезон)' },
    { type: 'video', videoId: 'LRwEfMAXtCg', name: 'Аналіз М+16 від Денса' },
    { type: 'post', link: '/guides/tormented-affix', name: '10+ афікс' },
  ]
}
