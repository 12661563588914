export default {
  slug: 'gorechop',
  parent: 'theater-of-pain',
  name: 'Gorechop',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Johnth' ],
  content: `
    <p>Бос з невеликою кількістю механік, головне уважність і фокус адів.</p>

    <p>Б’є танка фізичною атакою __Hateful Strike__.</p>

    <p>Періодично біля однієї зі стін опускаються гаки __Meat Hooks__ і рухаються через арену.
    Завжди є 2 проміжки, в яких гаки з’являються і рухаються з запізненням -- це наше вікно для
    уникнення ефекту приголомшення та пошкоджень.</p>

    <p>З гаків падають ади **Oozing Leftovers** і починають стрибати у гравців, наносячи пошкодження
    всім в невеликому радіусі (__Leaping Thrash__), тому тримаємо хоча б невелику відстань між гравцями.
    Вони повільно втрачають ХП (__Oozing__), але бажано їх фокусити і зливати максимально швидко для
    спрощення пересування і роботи хіла/танка. Після смерті залишають калюжі __Coagulating Ooze__,
    які наносять пошкодження і сповільнюють.</p>

    <p>Періодично бос кастує __Tenderizing Smash__ -- притягує гравців до себе, наносить пошкодження
    і відкидає всіх, хто не вийшов з його кола.</p>

    <p>Усі механіки також накладаються одна на одну (притягування + гаки + калюжі від адів),
    ускладнюючи процес.</p>
  `,
  aside: [
    {
      type: 'header',
      name: 'Gorechop'
    },
    {
      type: 'spell',
      icon: 'spell_deathknight_butcher2',
      name: 'Hateful Strike',
      desc: 'Фізична атака в ціль, наносить 23436 пошкоджень.',
      whid: 323515
    },
    {
      type: 'spell',
      icon: 'inv_mace_1h_pvppandarias1_d_01',
      name: 'Tenderizing Smash',
      desc: 'Бос притягує усіх гравців до себе і (орієнтовно через 1-2 секунди) б’є в землю, наносячи 11718 фізичних пошкоджень та відкидаючи усіх, хто в момент удару знаходяться всередині кола біля боса.',
      whid: 318406
    },
    {
      type: 'spell',
      icon: 'ability_rogue_grapplinghook',
      name: 'Meat Hooks',
      desc: 'На арену опускаються гаки для м’яса і рухаються впоперек поля бою. Гравці, що потрапили під удар гака зазнають ефекту приголомшення і отримують __Jagged Gash__ (пошкодження впродовж 8 сек).',
      whid: 322795
    },
    {
      type: 'header',
      name: 'Oozing Leftovers'
    },
    {
      type: 'spell',
      icon: 'spell_shadow_plaguecloud',
      name: 'Oozing',
      desc: '**Oozing Leftovers** втрачають 5% максимального здоров’я кожну секунду.',
      whid: 323542
    },
    {
      type: 'spell',
      icon: 'ability_racial_flayer',
      name: 'Leaping Thrash',
      desc: '**Oozing Leftovers** стрибають на ціль, наносячи 4 687 фізичних пошкоджень усім ворогам в радіусі 4 метрів.',
      whid: 323110
    },
    {
      type: 'spell',
      icon: 'ability_creature_poison_06',
      name: 'Coagulating Ooze',
      desc: 'Після смерті **Oozing Leftovers** залишають по собі калюжі, які наносять 1685 пошкоджень чумою кожні 1.5 сек та сповільнюють швидкість пересування на 30% гравцям, що в них знаходяться.',
      whid: 321447
    },
  ],
  attachments: [
    { type: 'video', videoId: 'JBvx0rVvtGw', name: 'Аналіз М+20 від Денса (2й сезон)' },
    { type: 'video', videoId: '8PP5eIgvq44', name: 'Аналіз М+17 від Денса' },
    { type: 'post', link: '/guides/tormented-affix', name: '10+ афікс' },
  ]
}
