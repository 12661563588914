export default {
  slug: 'dealer-xyexa',
  parent: 'de-other-side',
  name: 'Дилер За\'екса',
  hint: 'Dealer Xy\'exa',
  short: 'За\'екса',
  meta: {
    description: 'Тактика бою з дилеркою За\'ексою в Іншому боці.',
    keywords: 'за\'екса, заекса, дилер, дилерка, тактика, стратегія, механіка, гайд, бос, підземелля, данж, мітік, м+, інший бік, тінеземлі, xy\'exa, xyexa, dealer, de other side, shadowlands'
  },
  header: { parent: true },
  sidenav: true,
  authors: [ 'Skye' ],
  attachments: [
    { type: 'video', videoId: 'Nl8_1L4zz54', name: 'Аналіз М+20 від Денса (2й сезон)' },
    { type: 'post', link: '/guides/tormented-affix', name: '10+ афікс' }
  ],
  content: `
    [p]Бій без фаз де бос розкидає __пастки__ які використовуються гравцями у конкретний момент
    для переживання смертельних механік.[/p]

    ## Блискавка

    [p]Майже на початку бою За'екса застосує __блискавку__ на випадкового гравця. Ефект цього
    закляття не зникає, а перестрибує на найближчого гравця кожні 9 сек протягом всього бою.
    Намагайтеся контролювати ціль __блискавки__, позиціонуючи себе відповідно.[/p]

    ## Пастки та вибухи

    [p]За'екса розкидає __пастки__ під ноги гравців, місце приземлення відмічається сірим вихорем;
    після приземлення пастка активується і при контакті з гравцем підкидає його в гору -- не потрапляйте
    в них, вони знадобляться пізніше.[/p]

    [p]Коли дилер починає вимовляти __вибух боса__, гравці підходять до своїх пасток на землі; під кінець
    вимови закляття зайдіть у пастку щоб підлетіти в повітря та не отримати шкоди від вибуху. Якщо якомусь
    гравцеві невистачає пастки -- використайте захисну здібність.[/p]

    [p]Час від часу бос використовує __вибух гравця__. Відмічений бомбою гравець вибухне через 5 сек,
    тому він має зайти у __пастку__ на останніх секундах аби вибух відбувся високо в повітрі де буде
    лише він.[/p]
  `,
  aside: [
    {
      type: 'header',
      name: 'Дилер За\'екса'
    },
    {
      type: 'spell',
      icon: 'spell_nature_stormreach',
      name: 'Arcane Lightning',
      namet: 'блискавка',
      desc: 'За\'екса насичує гравця нестабільною арканічною енргією що завдає [1649/arcane] та накладає __вразливість__ кожні 3 сек протягом 9 сек.[br][br]Кожні 9 сек блискавка переходить на іншого найближчого гравця.',
      whid: 323687
    },
    {
      type: 'spell',
      icon: 'spell_shaman_staticshock',
      name: 'Arcane Vulnerability',
      namet: 'вразливість',
      desc: 'Збільшує отриману шкоду від __блискавки__ на 5%. Цей ефект складається.',
      whid: 323692
    },
    {
      type: 'spell',
      icon: 'ability_druid_cresentburn',
      name: 'Displaced Blastwave',
      namet: 'промінь',
      desc: 'За\'екса вивільняє промінь енергії у портал поблизу, завдаючи [7914/arcane].',
      audio: 'https://wow.zamimg.com/sound-ids/live/enus/79/3501647/VO_901_Dealer_Xyexa_193598.ogg',
      whid: 324090
    },
    {
      type: 'spell',
      icon: 'ability_hunter_readiness',
      name: 'Displacement Trap',
      namet: 'пастка',
      desc: 'За\'екса створює пастку, яка при контакті з гравцем підкидає його в повітря.',
      audio: 'https://wow.zamimg.com/sound-ids/live/enus/82/3501650/VO_901_Dealer_Xyexa_193565.ogg',
      whid: 319619
    },
    {
      type: 'spell',
      icon: 'ability_monk_essencefont',
      name: 'Explosive Contrivance',
      namet: 'вибух боса',
      desc: 'За\'екса спричиняє вибух енергії, завдаючи [13190/arcane] та додатково [660/arcane] кожні 2 сек протягом хвилини всім гравцям що знаходяться на землі з нею. Цей ефект складається.[br][br]Також знищує всі __пастки__.',
      audio: 'https://wow.zamimg.com/sound-ids/live/enus/83/3501651/VO_901_Dealer_Xyexa_193572.ogg',
      whid: 320230
    },
    {
      type: 'spell',
      icon: 'ability_mage_deepfreeze',
      name: 'Localized Explosive Contrivance',
      namet: 'вибух гравця',
      desc: 'За\'екса чіпляє пристрій на випадкового гравця, який вибухає через 5 сек, завдаючи [6595/arcane] та додатково [660/arcane] кожні 2 сек протягом хвилини всім гравцям на одній висоті. Цей ефект складається.[br][br]Також знищує всі __пастки__, якщо ціль ціїє здібності лишається на землі.',
      audio: 'https://wow.zamimg.com/sound-ids/live/enus/85/3501653/VO_901_Dealer_Xyexa_193577.ogg',
      whid: 321948
    }
  ]
}
