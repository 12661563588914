export default {
  slug: 'the-hivemind',
  parent: 'nyalotha',
  name: 'Вуликовий розум',
  hint: 'The Hivemind',
  group: 'Halls of Devotion',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Skye' ],
  content: `
    <ul>
      <li>збивати Казіру каст</li>
      <li>танкам не потрібно мінятися (одразу назначити танків на конкретних босів)</li>
      <li>героїзм з пула</li>
    </ul>

    <h3>Бій</h3>

    <ul>
      <li>не потрапляти в фіолетовий мілі сектор Текріса</li>
      <li>розбігатися коли під ногами фіолетові кола</li>
      <li>ад-Реведжер: напул і фокус ада</li>
      <li>ад-Дарт (3 шт): фокус адів, по можливості притягувати до босів</li>
      <li>ад-підривник: в момент вибуху всі мають бути на фул хп</li>
      <li>ухилятися від ролів</li>
      <li>фази змінюються коли боси набирають 100 енергії</li>
      <li>під час фази контролю Текріса, боси тримаються разом (ця фаза починається з пула)</li>
      <li>під час фази контролю Казіра, боси тримаються окремо</li>
    </ul>
  `,
  attachments: [
    { type: 'video', videoId: 'iBjPtB_horo', name: 'Першокіл (міфік)' }
  ]
}
