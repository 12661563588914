export default {
  slug: 'halls-of-atonement',
  parent: 'dungeons',
  name: 'Зали спокути',
  hint: 'Halls of Atonement',
  image: '/img/halls-of-atonement.jpg',
  group: 'Тінеземлі',
  meta: {
    description: 'Підземелля Зали спокути.',
    keywords: 'зали, спокути, холс, атонмент, підземелля, данж, мітік, м+, тінеземлі, halls, atonement, hoa, dungeon, shadowlands'
  },
  header: { parent: true },
  archive: true
}
