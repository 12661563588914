export default {
  slug: 'mordretha-the-endless-empress',
  parent: 'theater-of-pain',
  name: 'Mordretha, the Endless Empress',
  short: 'Mordretha',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Johnth' ],
  content: `
    <p>Після порівняно примітивних попередніх босів, **Mordretha** може похвалитись двома фазами
    і нетиповими механіками, які за хорошою традицією будуть накладатись одна на одну.</p>

    ## Перша фаза

    <p>Перша фаза -- такий собі розігрів. Бос, крім автоатак, лупить танка __Reaping Scythe__.</p>

    <p>Час від часу **Mordretha** використовує __Dark Devastation__ -- направлений промінь аніми,
    який відштовхує і наносить пошкодження. Бос також повертається в випадковий бік під час касту.
    На початку бою це не становить значної загрози, але з нагромадженням механік (особливо на
    другій фазі) промінь відчутно ускладнює життя. Краще стояти не дуже далеко від боса, так буде
    більше шансів вчасно перебігти і уникнути його.</p>

    <p>Через певний час після старту бос кидає на гравців дебаф __Manifest Death__, який наносить
    пошкодження і після закінчення вибухає в радіусі 6 метрів і створює ада **Deathwalker** на місці
    гравця. Кожен ад починає кидати касти __Death Bolt__ в випадкову ціль. Разом з цим на арені
    з’являється **Grasping Rift**, який повільно притягує гравців до себе. При потраплянні в епіцентр
    гравець втрачає здатність рухатись і атакувати, а також отримує пошкодження (__Death Grasp__).</p>

    <p>Механіки __Manifest Death__ та __Grasping Rift__ зазвичай накладаються одна на одну першого
    разу, потім можуть розходитись в часі чи знову накладатись (ймовірно це залежить від тривалості
    бою). Нижче наведено рекомендації для першої комбінації. У випадку окремих кастів просто виконуємо
    механіку, яка активна.</p>

    <p>Для зменшення загальних вхідних пошкоджень після отримання дебафу __Manifest Death__ тримайтесь
    групою, але не ближче ніж 7-8 метрів один від одного. Відразу після вибуху дебафу і спавну адів,
    їм треба збити касти і заставити зійтись (стягнути, відкинути, обирайте з доступних варіантів),
    в ідеальному випадку -- під боса. Зрештою, їх необхідно вбити клівом (на високих складностях та
    з афіксом [icon/skull-crossbones] **Tyrannical** [icon/skull-crossbones] може знадобитись фокус).
    Що менше проживуть ади -- то менше буде успішних кастів __Death Bolt__ і, відповідно, менше
    пошкоджень по групі.</p>

    ## Друга фаза

    <p>На 50% здоров’я **Mordretha** переходить у другу фазу -- до попередніх механік додається
    комбінація __Echoes of Carnage__. Перша її частина полягає у появі на арені **примарних воїнів**
    у парах, які б’ють __Echo of Battle__ по площі в радіусі 8 метрів.
    Друга частина -- **примарні вершники**, які з’являються по краях арени і їдуть орієнтовно через
    центр, відкидаючи і наносячи пошкодження гравцям на шляху (__Ghostly Charge__). Також кожен раз
    при появі примар група отримує 5859 миттєвих пошкоджень і незначний залишок щосекунди допоки
    вони існують на арені.</p>

    <p>Від __Echo of Battle__ доволі просто відійти, а проти **вершників** зручно триматись біля одного
    з країв арени. Так ми можемо відразу відійти зі шляху ближніх **вершників** і матимемо час роздивитись,
    куди бігтимуть дальні. Складність полягає у періодичному накладанні **примарних воїнів** на інші
    механіки (промінь від боса, притягування і ади). Тут допоможе тільки уважність (і певна кількість
    прикрих вайпів, поки не звикнете слідкувати за усіма складовими цього хаосу одночасно
    [icon/emoticon-happy-outline]</p>
  `,
  aside: [
    {
      type: 'spell',
      icon: 'inv_misc_2h_farmscythe_a_01',
      name: 'Reaping Scythe',
      desc: '**Mordretha** посилює свою косу і б’є основну ціль на 9667 пошкоджень тінню та 10253 фізичних пошкоджень.',
      whid: 324079
    },
    {
      type: 'spell',
      icon: 'spell_necro_deathlyslam',
      name: 'Dark Devastation',
      desc: '**Mordretha** створює промінь аніми, що наносить 4394 пошкоджень тінню кожні 0.5 сек та відштовхує гравців, що потрапили під нього.',
      whid: 323608
    },
    {
      type: 'spell',
      icon: 'spell_necro_deathlyecho',
      name: 'Manifest Death',
      desc: 'Бос викликає примарні видіння у всіх гравців. Наносить 879 пошкоджень тінню кожні 2 сек впродовж 6 сек. Після закінчення дії ефекту він вибухає на 5860 пошкоджень тінню гравцю та всім у радіусі 6 метрів.',
      whid: 324449
    },
    {
      type: 'spell',
      icon: 'spell_necro_deathsdoor',
      name: 'Grasping Rift',
      desc: '**Mordretha** розриває простір і створює **Grasping Rift**, який притягує гравців впродовж 6 сек. Гравці, яких затягнуло у зону дії **Grasping Rift** піддаються ефекту __Death Grasp__.',
      whid: 323683
    },
    {
      type: 'spell',
      icon: 'spell_necro_conclave',
      name: 'Death Grasp',
      desc: 'Приголомшує жертву, блокує можливість рухатись чи атакувати та наносить 4693 пошкоджень тінню що 2 сек впродовж 8 сек.',
      whid: 323831
    },
    {
      type: 'header',
      name: 'Echos of Carnage',
      namet: 'Комбо'
    },
    {
      type: 'spell',
      icon: 'ability_deathknight_soulreaper',
      name: 'Echos of Carnage',
      desc: 'Після досягнення 50% здоров’я **Mordretha** застосовує __Echoes of Carnage__.<br><br>__Echoes of Carnage__ завдає 5859 пошкоджень тінню всім гравцям миттєво та and 293 пошкоджень тінню кожні 2 сек після цього.<br><br>Допоки діє ефект __Echoes of Carnage__, час від часу на арені з’являтимуться **примарні воїни**.',
      whid: 339573
    },
    {
      type: 'spell',
      icon: 'ability_argus_soulburst',
      name: 'Echo of Battle',
      desc: '**Примарні воїни** вступають у сутичку і наносять 11718 пошкоджень тінню гравцям в радіусі 8 метрів.',
      whid: 339550
    },
    {
      type: 'spell',
      icon: 'inv_giantbeastmount2',
      name: 'Ghostly Charge',
      desc: '**Примарні вершники** проносяться через арену, наносять 11718 пошкоджень тінню і відкидають всіх гравців на шляху.',
      whid: 339751
    },
  ],
  attachments: [
    { type: 'video', videoId: 'JBvx0rVvtGw', name: 'Аналіз М+20 від Денса (2й сезон)' },
    { type: 'video', videoId: '8PP5eIgvq44', name: 'Аналіз М+17 від Денса' },
    { type: 'post', link: '/guides/tormented-affix', name: '10+ афікс' },
  ]
}
