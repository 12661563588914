import { capitalize, htmlize } from '@/utils'
import appSettings from '@/assets/app-settings.json'
import appPosts from '@/assets/app-posts'

function setDocumentTitle(data, posts) {
  const names = []

  if (typeof data == 'string') {
    names.push(data)
  } else if (typeof data == 'object' && data.name) {
    if (data.parent && data.header?.parent) {
      const parent = posts.find(p => p.slug == data.parent)
      if (parent && parent.name) {
        names.push(parent.name)
      }
    }
    names.push(data.name)
  }

  const info = appSettings.info
  const sections = names.length > 0
    ? [ ...names.reverse(), info.title ]
    : [ info.title, info.description ]

  window.document.title = sections.join(' — ')
}

function setDocumentMeta(name, content) {
  window.document.querySelector(`meta[name="${name}"]`)?.setAttribute('content', content)
}

const audioElement = new Audio()

export default {
  data: function() {
    return {
      get appSettings() { return appSettings },
      get appPosts() { return appPosts }
    }
  },
  methods: {
    /**
     * Setup page title and meta fields (description and keywords).
     * Should be called each time a new page is shown.
     *
     * @param {any} data Empty, String (title) or Object (post). If empty, default title is used.
     */
    setPageMetadata: function (data) {
      setDocumentTitle(data, this.appPosts)

      const description = data?.meta?.description || appSettings.info.metaDescription
      setDocumentMeta('description', description)

      const keywords = data?.meta?.keywords || appSettings.info.metaKeywords
      setDocumentMeta('keywords', keywords)
    },
    playAudio: function (src) {
      audioElement.src = src
      audioElement.play()
    }
  },
  filters: {
    capitalize,
    htmlize
  }
}
