export default {
  slug: 'hakkar-the-soulflayer',
  parent: 'de-other-side',
  name: 'Гаккар Душогуб',
  hint: 'Hakkar the Soulflayer',
  short: 'Гаккар',
  meta: {
    description: 'Тактика бою з лоа крові Гаккаром Душогубом в Іншому боці.',
    keywords: 'гаккар, душогуб, лоа крові, тактика, стратегія, механіка, гайд, бос, підземелля, данж, мітік, м+, інший бік, тінеземлі, hakkar, soulflayer, loa of blood, de other side, shadowlands'
  },
  header: { parent: true },
  sidenav: true,
  authors: [ 'Skye' ],
  attachments: [
    { type: 'video', videoId: 'Nl8_1L4zz54', name: 'Аналіз М+20 від Денса (2й сезон)' },
    { type: 'post', link: '/guides/tormented-affix', name: '10+ афікс' }
  ],
  content: `
    [p]Бій без фаз де посіпаки боса ускладнюють роботу гравцям -- чим довше бій, тим складніше
    вбити боса.[/p]

    ## Червоне коло

    [p]Коли на вас __червоне коло__, відійдіть від інших щоб не розповсюджувати ефект.
    Перший раз цю здібність бос використовує майже на початку бою.[/p]

    ## Посіпаки боса

    [p]Протягом бою Гаккар прикликає своїх [npc/170488/посіпак]. Вони обирають ціль та фіксуються
    на ній. Завдають шкоду по площі всім гравцям доки живі. Чим довше посіпака живий, тим сильніше
    його атаки -- намагайтеся вбивати їх якомога швидше.[/p]

    [p]При вбивстві посіпаки, на його місці з'являється __червона калюжа__ -- вибігайте. Вона з часом
    зменьшується, зникає і повертає посіпаку до життя, тому з часом їх кількість тільки зростає.[/p]

    ## Захисний бар'єр

    [p]Час від часу Гаккар вкривається __захисним бар'єром__, його міцність залежить від завданої шкоди
    миттевим вибухом в момент утворення бар'єру. Рекомендовано використовувати захисні здібності для
    зменьшення цього пошкодження. Всі живі посіпаки на момент вибуху також отримують пошкодження й тим
    самим додють бар'єру міцності.[/p]
  `,
  aside: [
    {
      type: 'header',
      name: 'Гаккар Душогуб'
    },
    {
      type: 'spell',
      icon: 'inv_misc_bearclaw_blue',
      name: 'Piercing Barb',
      desc: 'Проштрикує ціль обома руками, завдаючи [11541/physical] першим ударом та [9893/shadow] наступним.',
      audio: 'https://wow.zamimg.com/sound-ids/live/enus/30/3513118/VO_901_Hakkar_the_Soulflayer_193525.ogg',
      whid: 322736
    },
    {
      type: 'spell',
      icon: 'spell_shadow_corpseexplode',
      name: 'Corrupted Blood',
      namet: 'червоне коло',
      desc: 'Завдає [1979/shadow] кожні 3 сек протягом 12 сек. Поширюється на інших гравців у радіусі 7 метрів.',
      audio: 'https://wow.zamimg.com/sound-ids/live/enus/29/3513117/VO_901_Hakkar_the_Soulflayer_193526.ogg',
      whid: 323166
    },
    {
      type: 'spell',
      icon: 'inv_artifact_bloodoftheassassinated',
      name: 'Blood Barrier',
      namet: 'захисний бар\'єр',
      desc: 'Завдає [4122/shadow] всім гравцям, утворюючи захисний бар\'єр. Міцність бар\'єру залежить від розміру завданої шкоди.[br][br]Ця здібність також б\'є по всіх живих посіпаках.',
      audio: 'https://wow.zamimg.com/sound-ids/live/enus/27/3513115/VO_901_Hakkar_the_Soulflayer_193527.ogg',
      whid: 322759
    },
    {
      type: 'spell',
      icon: 'artifactability_blooddeathknight_umbilicuseternus',
      name: 'Blood Barrage',
      desc: 'Поливає дощем, краплі якого падають на відмічені на землі місця у вигляді червоних вихорів. При контакті у радіусі 3 метри, завдає [4287/shadow] та зменьшує шкоду цілі на 10%. Цей ефект складається.[br][br]Поки діє __захисний бар\'єр__, бос підтримує це закляття.',
      whid: 323064
    },
    {
      type: 'header',
      name: 'Son of Hakkar',
      namet: 'посіпака'
    },
    {
      type: 'spell',
      icon: 'inv_artifact_corruptedbloodofzakajz',
      name: 'Blood Nova',
      desc: 'Завдає [660/shadow] всім ворогам та підсилює свою темну магію на 25% на 9 сек. Цей ефект складається.[br][br]Здібність відновлюється 5 сек.',
      whid: 332335
    },
    {
      type: 'spell',
      icon: 'ability_fixated_state_red',
      name: 'Zealous',
      desc: 'Фіксується на цілі.',
      whid: 328987
    },
    {
      type: 'spell',
      icon: 'ability_ironmaidens_corruptedblood',
      name: 'Spilled Essence',
      namet: 'калюжа',
      desc: 'Посіпаки Гаккара вічні. В момент гибелі, вони проливають свою есенцію на землю, утворюючи калюжу на 45 сек що завдає [3298/shadow] щосекунди ворогам в ній.[br][br]Калюжа поступово зменьшується. Зникаючи, повертає посіпаку до життя.',
      whid: 332332
    }
  ]
}
