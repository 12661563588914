export default {
  slug: 'kelthuzad',
  parent: 'sanctum-of-domination',
  name: 'Kel\'Thuzad',
  image: '/img/kelthuzad.jpg',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Dancecaptain (Makhabunda)' ],
  attachments: [
    { type: 'video', videoId: '9jLtghsImPw', name: 'Першокіл (героїк)' },
    { type: 'video', videoId: '_uzVVdBgcxQ', name: 'Відеотактика (англійська)' }
  ],
  aside: [
    {
      type: 'header',
      name: 'Перша фаза'
    },
    {
      type: 'spell',
      icon: 'spell_frost_arcticwinds',
      name: 'Howling Blizzard',
      desc: 'Коли у боса завершується мана, він прикликає льодяну бурю, яка створює воїд-зони, стоячи у якій гравець отримує 10079 Морозяного пошкодження кожну секунду. Воїд-зони після вибуху утворюють льодяні калюжі, яка розростається впродовж 20 секунд після чого завдає 36070 Морозяної шкоди всім гравцям у 8 ярдах.',
      whid: 354206
    },
    {
      type: 'spell',
      icon: 'spell_shadow_nightofthedead',
      name: 'Dark Evocation',
      desc: 'Бос висмоктує душі мертвих гравців, які будуть допомагати босу допоки душі не будуть вбиті гравцями.',
      whid: 352530
    },
    {
      type: 'spell',
      icon: 'spell_necro_deathlyecho',
      name: 'Soul Fracture',
      namet: '(до уваги танків)',
      desc: 'Бос відкидає від себе поточну ціль, завдаючи 24018 Тіньового пошкодження. Окрім цього, з\'являються 3 копії відповідної цілі, які кастують інтераптебильний каст, який завдає 9017 Тіньового пошкодження всім гравцям. Також на ціль вішається дебаф, який збільшує вхідні Фізичні пошкодження на 500% впродовж 1 хвилини. Якщо ефект не забраний з цілі за відведений час, вона помирає.',
      whid: 348071
    },
    {
      type: 'spell',
      icon: 'ability_deathknight_pillaroffrost',
      name: 'Glacial Wrath',
      desc: 'Бос створює шипи під гравцями, які завдають 21041 Морозяного пошкодження гравцям у 6 ярдах і відкидає їх. Коли шип присумонений, він втрачає 5% свого життя кожні 2 секунди. Коли шип помирає, він завдає 12023 Морозяного пошкодження всім гравцям і вішає дот, який завдає 1503 Морозяної шкоди кожну секунду впродовж 10 секунд. Ефект доти стакається.',
      whid: 346459
    },
    {
      type: 'spell',
      icon: 'spell_shadow_coneofsilence',
      name: 'Oblivion\'s Echo',
      desc: 'Бос вішає на випадкових гравців мітки, які вибухнуть через 6 секунд і унеможливлять до будь-яких атак та кастів всі цілі в 10 ярдах вибуху. Через 6 секунд після вибуху появляться ади, які повільно рухатимуться до цілі, на якій була мітка, якщо ад дійде до цілі, він вибухне завдавши 24046 Тіньового пошкодження всім гравцям у радіусі 5 ярдів.',
      whid: 347291
    },
    {
      type: 'spell',
      icon: 'spell_frost_glacier',
      name: 'Frost Blast',
      namet: '(смертельно)',
      desc: 'Бос вішає на гравця морозяну мітку та воїд-зону, яка через 8 секунд вибухне й нанесе 120232 Морозяної шкоди між всіма гравцями, які знаходяться всередині воїд-зони і вішає дот, який завдає 3607 Морозяного пошкодження кожну секунду впродовж 12 секунд. Окрім цього бос зарутає магічним ефектом гравців (діспелиться).',
      whid: 348756
    },
    {
      type: 'spell',
      icon: 'spell_frost_ice-shards',
      name: 'Ice Shard',
      namet: '(до уваги танків)',
      desc: 'Магічні атаки боса, які завдають Фізичне пошкодження.',
      whid: 348953
    },
    {
      type: 'header',
      name: 'Друга фаза'
    },
    {
      type: 'spell',
      icon: 'tradeskill_abominationstitching_abominations_mid',
      name: 'Demolish',
      namet: '(до уваги танків)',
      desc: 'Окрім автоатак іноді випускають у напрямку випадкового гравця хвилю, яка завдає 22543 Тіньового пошкодження всім гравцям, які зачеплять хвилю.',
      whid: 349805
    },
    {
      type: 'spell',
      icon: 'spell_animamaw_missile',
      name: 'Banshee\'s Cry',
      desc: '4 банші які кастують інтераптебильний спел, який кожну секунду касту завдає 4622 Тіньового пошкодження гравцю, откім цього, кожен вдалий тік збільшує пошкодження від абілки на 25%.',
      whid: 352144
    },
    {
      type: 'spell',
      icon: 'spell_necro_inevitableend',
      name: 'Sinister Miasma',
      namet: '(до уваги хілерів)',
      desc: 'Постійно завдає 301 Тіньового пошкодження кожні 2 секунди, допоки в світі філактерії знаходяться гравці. Ефект стакається.',
      whid: 354289
    },
    {
      type: 'spell',
      icon: 'spell_animamaw_buff',
      name: 'Necrotic Surge',
      desc: 'Кожен раз, коли бос відновлюється філактерією, він збільшує свою силу на 5%. Окрім цього, збільшує кількість гравців на яких буде використано __Oblivion\'s Echo__ і __Glacial Wrath__.',
      whid: 352051
    },
    {
      type: 'spell',
      icon: 'spell_animamaldraxxus_buff',
      name: 'Necrotic Empowerment',
      desc: 'Бос бафає адів, збільшуючи їхню силу на 25% і робить їх імунним до будь-якого виду контролю.',
      whid: 355948
    },
    {
      type: 'spell',
      icon: 'spell_warlock_darkregeneration',
      name: 'Vengeful Destruction',
      namet: '(важливо)',
      desc: 'Бос виливає хвилю, яка завдає всім гравцям 36070 Тіньового пошкодження. Сила хвилі посилюється, якщо Рештки КелТузеда втратили більше 33% свого максимального здоров\'я. Цей каст переривається кожен раз, коли Рештки КелТузеда опускається до спочатку до 66%, а потім до 33% життя.',
      whid: 352293
    },
    {
      type: 'header',
      name: 'Абілки Решток КелТузеда'
    },
    {
      type: 'spell',
      icon: 'ability_argus_soulburst',
      name: 'Shadow Fissure',
      desc: 'Ад кидає під ноги гравця воїд зони, які вибухають завдаючи 22543 Тіньового пошкодження всім гравцям в радіусі 4 ярдів і відштовхує їх.',
      whid: 352002
    },
    {
      type: 'spell',
      icon: 'ability_mage_waterjet',
      name: 'Freezing Blast',
      desc: 'Ад випускає льодяну хвилю, яка завдає 7561 Морозяного пошкодження і рутає гравця, який попав під неї, на 4 секунди.',
      whid: 352379
    },
    {
      type: 'spell',
      icon: 'ability_skyreach_four_wind',
      name: 'Glacial Winds',
      desc: 'Коли в ада залишається менше 33% життя, він випускає вітрики, які при дотику до гравця завдають 701 Морозяного пошкодження і рутають на 6 секунд.',
      whid: 355055
    },
    {
      type: 'spell',
      icon: 'spell_shadow_requiem',
      name: 'Undying Wrath',
      namet: '(смертельно)',
      desc: 'Ад стає імунним до пошкоджень і випускає залп некротичної енергії, яка завдає 36070 Тіньового пошкодження всім гравцям у світі філанктерії й відштовхує їх.',
      whid: 352355
    },
    {
      type: 'header',
      name: 'Третя фаза'
    },
    {
      type: 'spell',
      icon: 'spell_necro_inevitableend',
      name: 'Sinister Miasma',
      namet: '(до уваги хілерів)',
      desc: 'Постійно завдає 301 Тіньового пошкодження кожні 2 секунди, допоки в світі філактерії знаходяться гравці. Ефект стакається.',
      whid: 354289
    },
    {
      type: 'spell',
      icon: 'spell_frost_arcticwinds',
      name: 'Deep Freeze',
      namet: '(смертельно)',
      desc: 'Кімната поступово заповнюється льодом який залишається до кінця бос-файту. Стоячи в льоді гравець отримує 10079 Морозяного пошкодження кожну секунду.',
      whid: 354638
    },
    {
      type: 'spell',
      icon: 'spell_necro_deathlyslam',
      name: 'Onslaught of the Damned',
      namet: '(важливо)',
      desc: 'Бос прикликує адів з першої та другої фаз, а також душі мертвих гравців битись на одній стороні з ним.',
      whid: 352348
    },
    {
      type: 'spell',
      icon: 'spell_frost_glacier',
      name: 'Frost Blast',
      namet: '(смертельно)',
      desc: 'Бос вішає на гравця морозяну мітку та воїд-зону, яка через 8 секунд вибухне й нанесе 120232 Морозяної шкоди між всіма гравцями, які знаходяться всередині воїд-зони і повішає дот, який завдає 3607 Морозяного пошкодження кожну секунду впродовж 12 секунд. Окрім цього бос зарутає магічним ефектом гравців (діспелиться).',
      whid: 348756
    },
    {
      type: 'spell',
      icon: 'spell_frost_ice-shards',
      name: 'Ice Shard',
      namet: '(до уваги танків)',
      desc: 'Магічні атаки боса, які завдають Фізичне пошкодження.',
      whid: 348953
    },
  ]
}
