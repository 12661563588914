export default {
  slug: 'vault-of-the-incarnates',
  parent: 'raids',
  name: 'Сховище втілень',
  hint: 'Vault of the Incarnates',
  image: '/img/vault-of-the-incarnates.jpg',
  group: 'Dragonflight',
  header: { parent: true },
  archive: true,
  meta: {
    description: 'Рейд Сховище втілень з доповнення Dragonflight.',
    keywords: 'сховище, втілень, втілення, уособлення, волт, ваулт, рейд, vault, incarnates, raid, df, wow'
  }
}
