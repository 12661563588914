export default {
  slug: 'huntsman-altimor',
  parent: 'castle-nathria',
  name: 'Huntsman Altimor',
  group: 'The Leeching Vaults',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Vorren' ],
  content: `
    <ul>
      <li>Поле бою — велика кімната</li>
      <li>Специфіка Босу — в нього спільне ХП з його песиками</li>
      <li>TW/Героїзм — під час третього песика</li>
    </ul>

    <h2>Тактика</h2>
    <p>Тримаємо боса у центрі кімнаті, розбігаємось рівномірно навколо нього. На кому буде
    <em>Sinseeker</em> стійте не рухайтесь (тільки якщо ви в одній лінії з іншою міткою
    <em>Sinseeker</em>), всі решта не мають стояти між вами та босом.</p>

    <h3>Перший песик — Margore</h3>
    <p>Першого песика кліваємо з босом, танки міняються на 2-3 стаках. На кому буде
    <em>Vicious Lunge</em> — до нього має підійти 5-6 чоловік для того щоб поділити дамаг,
    але не багато оскільки буде дот.</p>

    <h3>Другий песик — Bargast</h3>
    <p>Офтанк тримає його в босі але 5 с до <em>Rip Soul</em> виводить його під стінку.
    Хіли мають відхіляти душу яка буде йти до боса. Адів які будуть з'являтись кидати в СС
    (поліморф, пастка ханта, інше) коли він набирає 3+ стаки, на нього всім дпс треба робити
    світч (подивимось чи достатньо буде рдд), ніколи не давайте йому скастувати.</p>

    <h3>Третій песик — Hecutis</h3>
    <p>Офтанк тримає в босі та повільно рухає його біля нього обов'язково при цьому
    дивлячись на ХП рейду, якщо в рейду менше 40-45% стій.</p>

    <p>Дебаф від <em>Petrifying Howl</em> виносити під стінку.</p>
  `,
  aside: [
    {
      type: 'header',
      name: 'Huntsman Altimor'
    },
    {
      type: 'spell',
      icon: 'ability_hunter_runningshot',
      name: 'Spreadshot',
      desc: 'Конусний клів в рандомного гравця.',
      whid: 334404
    },
    {
      type: 'spell',
      icon: 'ability_hunter_assassinate2',
      name: 'Sinseeker',
      desc: 'Обирає декілька гравців по яким пролетить великий постріл і дебаф.',
      whid: 335114
    },
    {
      type: 'header',
      name: 'Margore'
    },
    {
      type: 'spell',
      icon: 'ability_druid_disembowel',
      name: 'Jagged Claws',
      desc: 'Свап для танків.',
      whid: 334971
    },
    {
      type: 'spell',
      icon: 'spell_druid_bloodythrash',
      name: 'Vicious Lunge',
      desc: 'Обирає рандомного гравця для чарджу.',
      whid: 334939
    },
    {
      type: 'header',
      name: 'Bargast'
    },
    {
      type: 'spell',
      icon: 'ability_druid_markofursol',
      name: 'Rip Soul',
      desc: 'Вирізає душу танка, яка повільно йде до боса, її треба відхілювати.',
      whid: 334797
    },
    {
      type: 'spell',
      icon: 'inv_felhound3_shadow_mount',
      name: 'Shades of Bargast',
      desc: 'Створює адів, яких треба контролити (поліморф працює), в них дофіга ХП але через певний час вони настакають дебаф який дає дуже великий % до дамагу по ним.',
      whid: 334757
    },
    {
      type: 'header',
      name: 'Hecutis'
    },
    {
      type: 'spell',
      icon: 'shaman_pvp_rockshield',
      name: 'Crushing Stone',
      desc: 'Набирає стаків поки стоїть, рух знижує стаки але здає по рейду дамаг.',
      whid: 334860
    },
    {
      type: 'spell',
      icon: 'inv_sinstonegolempet',
      name: 'Petrifying Howl',
      desc: 'Дебаф на рандомного гравця, залишає велику калабаню, треба виносити подалі.',
      whid: 334852
    },
  ],
  attachments: [
    { type: 'video', videoId: '0wbYt0PmsiU', name: 'Першокіл (мітік)' }
  ]
}
