export default {
  slug: 'patrol-captain-gerdo',
  parent: 'court-of-stars',
  name: 'Патрульний капітан Ґердо',
  hint: 'Patrol Captain Gerdo',
  image: '/img/patrol-captain-gerdo.jpg',
  meta: {
    description: 'Тактика бою проти патрульного капітана Ґердо в Зоряному Дворі.',
    keywords: 'патрульний, капітан, ґердо, гердо, тактика, стратегія, механіка, гайд, бос, зоряний двір, курт, курт оф старс, підземелля, данж, мітік, м+, легіон, patrol captain gerdo, captain gerdo, gerdo, court of stars, dungeon, legion'
  },
  header: { parent: true },
  sidenav: true,
  authors: [ 'Ta-na' ],
  content: `
    [p][image/patrol-captain-gerdo.jpg][/p]

    [p]Перш ніж почати бій з [npc/104215/босом] потрібно деактивувати п’ять пілонів
    [npc/104245/Arcane Beacon]. Якщо не вимкнути, то під час бою з’являться посіпаки,
    та зроблять бо-бо.[/p]

    ## Капітан Ґердо

    [p]Важливим є кінцева механіка, можна вплинути перед боєм. Коли Ґердо досягне 25% здоров’я,
    то побіжить до колодязя в центрі арени і вип’є зілля [spell/207815/Flask of the Solemn Night],
    що збільшить шкоду та швидкість атак на 30%. Алхіміки та розбійники можуть отруїти зілля
    перед боєм — це вб’є боса.[/p]

    ## Цілителям

    [p]Гравці інколи будуть послаблюватись ефектом [spell/207278/Arcane Lockdowns],
    цілителям важливо потужно лікувати групу в цей момент.[/p]

    ## «Arcane Lockdowns»

    [p]Сам ефект магічний, має три заряди, відноситься до класу «Вкорінення», можна очистити.
    За кожне підпригування знімає по одному заряду з гравця.[/p]

    ## Все інше

    [p]Періодично бос буде застосовувати заклинання по області [spell/219488/Streetsweeper],
    [spell/207261/Resonant Slash]. Виглядають як лінії та промені, просто відходьте.[/p]
  `
}
