export default {
  slug: 'eye-of-the-jailer',
  parent: 'sanctum-of-domination',
  name: 'Eye of the Jailer',
  image: '/img/eye-of-the-jailer.jpg',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Dancecaptain (Makhabunda)', 'Ta-na' ],
  attachments: [
    { type: 'video', videoId: 'RtGWW0nXHFo', name: 'Першокіл (мітік)' },
    { type: 'video', videoId: '7r8DvV6lJgs', name: 'Відеотактика (англійська)' }
  ],
  content: `
    ## Коротко

    [p][spell/78151/Героїзм] даємо на третій фазі 33% боса.[/p]

    ### Танки

    [p]Міняються після кожного застосування босом __Deathlink__, попередньо потрібно зменшити
    пошкодження своїми здібностями чи тринькетами, або попросити лікаря щоб допоміг аби зменшить
    вхідну шкодку по рейду і собі. На другій фазі розділитись і тримати міні босів, виглядають
    як око, на відстані 30 ярдів і більше.[/p]

    ### Бійці

    [p]Ділити __Dragging Chains__, слідкувати за життям малих очей, щоб вбити з різницею не більше
    8 секунд, мінятись при необхідності (в мітік складності буде залежати від тактики рейду).[/p]

    ### Лікарі

    [p]Ділити __Dragging Chains__, використовувати потужні здібності (рейдові сейви) на
    __Titanic Death Gaze__, [очищати//діспел] __Slothful Corruption__.[/p]

    ## Повна тактика

    [p]Потрібно поділити рейд на дві +- рівноцінні по дпсу (силі пошкодження) групи і назначити
    одну вліво іншу вправо, це потрібно для другої фази фази.[/p]

    ### Перша фаза

    [p]Танкам потрібно зменшити пошкодження своїми здібностями чи тринькетами, або попросити лікаря
    щоб допоміг перед застосуванням __Deathlink__ і мінятися на одному стаці (один негативний ефект).
    В героїчній слкадності після ланцюгів, що тягнуть гравця, з’явиться посіпака, потрібно підхоплювати
    і мінятись на 2-3 стаках. Через __Peircing Lens__ один танк завжди повинен бути біля боса.[/p]

    [p]__Dragging Chains__ кидається на гравця і тягне в низ, чим більше людей поділить тим слабше тягне,
    не рекомендується ділити танкам.[/p]

    [p]Уникайте білих калюж — __Fracture Soul__ які кидає бос, якщо потрапити по вам буде йти пошкодження
    до поки ви не пройдете через три ваші копії.
    [link/https://media.giphy.com/media/kLL4q5heMnA0UPht5U/giphy.gif/GIF]
    [/p]

    [p]Періодично бос застосовувати смертельний промінь, йде з однієї сторони до іншої, вам потрібно
    перелітати з платформи на іншу за допомогою ланцюгів що є на краях, або використати класові/ковенантські
    здібності щоб його проскочити.
    [link/https://media.giphy.com/media/BzzasYOw3yLwNckEC9/giphy.gif/GIF]
    [/p]

    [p]В мітік складності додається здібність __Hopeless Lethargy__ потрібно відходити від рейду трохи
    в бік, щоб ви й далі могли наносити пошкодження босові.[/p]

    ### Друга фаза 66%/33%

    [p]Танки хапають своїх міні босів і розводять щоб уникнути __Suffering__. Гравці відмічені
    __Spreading Misery__ мають винести під стіни чи край платформ, після детонації утвориться калюжа
    і лежатиме до кінця бою.
    [link/https://media.giphy.com/media/HENhopvQuHb9Abhrg1/giphy.gif/GIF]
    [/p]

    [p]Лікарям потрібно готувати свої сильні здібності (рейд сейви) для __Titanic Death Gaze__.
    __Desolation Beam__ — відходьте від інших гравців щоб уникнути підсиленого пошкодження.[/p]

    [p]В мітік складності додаєтсья __Scorn and Ire__, групи мають різні негативні ефекти,
    якщо гравці зійдуть з різними ефектами буде вибух.[/p]

    ### Третя фаза 33%

    [p]Бос має всі механіки першої фази і додає __Immediate Extermination__, на цій фазі дається
    героїзм і весь рейд, включно з хілами, починає бити босові в око.[/p]
  `,
  aside: [
    {
      type: 'header',
      name: 'Перша фаза'
    },
    {
      type: 'spell',
      icon: 'ability_hunter_aspectoftheviper',
      name: 'Peircing Lens',
      namet: '(до уваги танків)',
      desc: 'Бос завдає найближчій до себе цілі 12023 Фізичного пошкодження.',
      whid: 350803
    },
    {
      type: 'spell',
      icon: 'ability_felarakkoa_eyeofterrok',
      name: 'Deathlink ',
      namet: '(до уваги танків)',
      desc: 'Бос завдає 24046 Фізичного пошкодження поточній цілі. Додатково, удар випромінює часину шкоди заданої всім гравцям як Тіньове пошкодження.',
      whid: 350828
    },
    {
      type: 'spell',
      icon: 'spell_animamaw_missile',
      name: 'Eye Bolt',
      desc: 'Каст по випадковій цілі, який завдає 7514 Тіньового пошкодження.',
      whid: 348054
    },
    {
      type: 'spell',
      icon: 'sha_spell_shadow_shadesofdarkness',
      name: 'Fracture Soul',
      desc: 'Кидає під ноги гравцям воїд зони на 7 ярдів, які завдають 15029 Тіньового пошкодження, якщо у них потрапити. Гравець, який потрапив у воїд зону отримує дебаф, який завдає 902 Тіньового пошкодження кожну 1 секунду, зменшує швидкість пересування на 20% і зменшує шкоду від гравця і хілінг на 30%. Цей ефект стакається, допоки гравець знаходиться у воїд-зоні.',
      whid: 350028
    },
    {
      type: 'header',
      name: 'Stygian Abductors',
      namet: '(героїк)',
      desc: 'Під час файту з\'являється ад, який заковує граців у ланцюги і намагається зіштовхнути з платформи.',
    },
    {
      type: 'spell',
      icon: 'inv_misc_steelweaponchain',
      name: 'Dragging Chains',
      desc: '~~The Stygian Abductor~~ випускає ланцюги в ствій таргет і всіх гравців в радусі 6,5 ярдів, завдаючи 44066 пошкоджень від Тіньової магії порівну всім гравцям що в ланцюгах. В додаток стягує уражених гравців з платформи. Силя стягування зменшується від кількості гравців. Після 6 секундної боротьби ад зникає.',
      whid: 349979
    },
    {
      type: 'spell',
      icon: 'inv_sword_2h_maw_c_01',
      name: 'Assailing Lance',
      namv: '(увага танки)',
      desc: 'Коли на землі, ~~Stygian Abductors~~ кидає спис в свій таргет, завдаючи 49575 Фізичних пошкоджень і застосовує Кровотечу 3305 Фізичних пошкоджень кожні 1.5 сек протягом 10 сек. Цей ефект накладається.',
      whid: 348074
    },
    {
      type: 'header',
      name: 'Перехідна фаза'
    },
    {
      type: 'spell',
      icon: 'spell_animamaw_beam',
      name: 'Annihilating Glare',
      desc: 'Бос кастує промінь, який рухається впродовж всієї платформи. Гравець, який попадає у промінь, отримує 45087 Тіньового пошкодження впродовж кожної секунди і відштовхує його. Щоб пережити перехідну фазу, треба перестрибнути з одного кінця платформи, на інший.',
      whid: 350763
    },
    {
      type: 'header',
      name: 'Друга фаза'
    },
    {
      type: 'spell',
      icon: 'spell_animamaw_missile',
      name: 'Stygian Ejection',
      desc: 'Бос шилдує себе і створює двох адів, місце появи яких завдає 12023 Тіньового пошкодження гравцям, які стоять у ній.',
      whid: 350066
    },
    {
      type: 'header',
      name: 'Спели боса'
    },
    {
      type: 'spell',
      icon: 'ability_argus_deathfog',
      name: 'Titanic Death Gaze ',
      namet: '(до уваги хілерів)',
      desc: 'Вибух по всьому рейду, який завдає 12023 Тіньового пошкодження.',
      whid: 349028
    },
    {
      type: 'spell',
      icon: 'achievement_boss_cthun',
      name: 'Desolation Beam ',
      namet: '(смертельно)',
      desc: 'Бос вивільняє промінь на випадкового гравця, який завдає 6008 Тіньового пошкодження. Зачіпаючи союзників, промінь посилює їх.',
      whid: 350847
    },
    {
      type: 'spell',
      icon: 'sha_spell_shadow_shadesofdarkness',
      name: 'Fracture Soul',
      desc: 'Кидає під ноги гравцям воїд-зони на 7 ярдів, які наносять 15029 Тіньового пошкодження, якщо у них потрапити. Гравець, який потрапив у воїд зону отримує дебаф, який завдає 902 Тіньового пошкодження кожну 1 секунду, зменшує швидкість пересування на 20% і зменшує шкоду від гравця і хілінг на 30%. Цей ефект стакається, допоки гравець знаходиться у воїд-зоні.',
      whid: 350028
    },
    {
      type: 'header',
      name: 'Спели адів'
    },
    {
      type: 'spell',
      icon: 'inv_maweye_black',
      name: 'Shared Suffering ',
      namet: '(до уваги танків)',
      desc: 'Коли ади в 30 ярдах один від одного, вони завдають всьому рейду 6619 Тіньового пошкодження і збільшують отримання Тіньових пошкоджень впродовж 1 хвилини __(Suffering)__. Ефект стакається.',
      whid: 351816
    },
    {
      type: 'spell',
      icon: 'inv_maweye_grey',
      name: 'Dying Suffering',
      desc: 'Мертвий ад кастує кожні 8 секунд __Suffering__, допоки інший ад живий.',
      whid: 351994
    },
    {
      type: 'spell',
      icon: 'warlock_spelldrain',
      name: 'Slothful Corruption ',
      namet: '(магічний ефект)',
      desc: 'Дебаф на випадкового гравця, який завдає 1803 Тіньового пошкодження кожні півтори секунди впродовж 21 секунди, зменшуючи швидкість кастів та атак, швидкість пересування на 40%.',
      whid: 350713
    },
    {
      type: 'header',
      name: 'Третя фаза'
    },
    {
      type: 'spell',
      icon: 'ability_blackhand_marked4death',
      name: 'Immediate Extermination ',
      namet: '(важливо)',
      desc: 'Бос падає у берсерк, пришвидшуючи атаки на 50% і наносячи кожні 6 секунд 9919 Тіньового пошкодження і зменшуючи вхідний хілінг всьому рейду на 10% до смерті боса.[br][br]Всі інші спели з першої фази повторюються.',
      whid: 348969
    },
  ]
}
