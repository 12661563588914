export default {
  slug: 'carapace-of-nzoth',
  parent: 'nyalotha',
  name: 'Панцир Н\'Зота',
  hint: 'Carapace of N\'Zoth',
  group: 'The Waking Dream',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Skye' ],
  content: `
    <ul>
      <li>зміна фаз на 50% і на 40% хп боса</li>
      <li><em>на першій фазі</em>:
        <ul>
          <li>вибігаєте з рейду, якщо на вас коло (бомба над головою)</li>
          <li>ад-око: пріорітет; касти не збивати; танки підводять боса для кліву</li>
          <li>тентакля: рейд ділиться на 2 частини, пріорітет вбити нарости зверху на тентаклі</li>
        </ul>
      </li>
      <li><em>на другій фазі</em> забігаємо в середину, чистимо праву частину, юзаємо спец кнопку, чистимо ліву частину; стрибаємо вниз до боса, не потрапляємо в його аое-промінь</li>
      <li><em>на третій фазі</em> потрібно швидко тікати від калюжі, але дати їй з'явитися — не бігти раніше часу</li>
      <li>танки міняються на 2-3 стаках</li>
      <li>героїзм на останній фазі</li>
    </ul>
  `,
  attachments: [
    { type: 'video', videoId: 'SnNn6t2g4Hc', name: 'Першокіл (героїк)' }
  ]
}
