export default {
  slug: 'dark-inquisitor-xanesh',
  parent: 'nyalotha',
  name: 'Темний інквізитор Занеш',
  hint: 'Dark Inquisitor Xanesh',
  short: 'Занеш',
  group: 'Halls of Devotion',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Skye' ],
  content: `
    <ul>
      <li>визначити три групи по три вар'ята
        <ul><li>на момент Ритуалу, чергова група має стати в три кола біля боса</li></ul>
      </li>
      <li>відбігайте далеко від червоного пульсуючого кола, якщо ви його бачите</li>
      <li>танки міняються на 1-2 стаках</li>
      <li>героїзм з пула</li>
    </ul>
  `,
  attachments: [
    { type: 'video', videoId: 'xebkcKe6GcA', name: 'Першокіл (міфік)' }
  ]
}
