export default {
  slug: 'the-council-of-blood',
  parent: 'castle-nathria',
  name: 'The Council of Blood',
  group: 'Reliquary of Opulence',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Vorren' ],
  content: `
    <ul>
      <li>Поле бою — великий бальний зал</li>
      <li>Специфіка босу — коли один помирає, інші відхілюються на фул, на 50% зал наповнюють слуги, з якими треба рухатись разом заради бафу на хасту.</li>
      <li>TW/Героїзм — посередині бою, після танц-фази <b>Ніклауса</b></li>
    </ul>

    <p>Порядок вбивання босів:<br>
    **Frieda** [icon/arrow-right-bold] **Niklaus** [icon/arrow-right-bold] **Stavros**
    </p>

    <h2>Тактика</h2>

    <p>Танки — свапайтесь коли дебафи будуть на 2+. Головний Танк тримає <b>Фрейду</b> та <b>Ніклауса</b>.
    Інший танк, який тримає <b>Ставроса</b>, стій подалі від рейду і ніколи не стій спиною до інших босів,
    щоб уникати чардж по мдд.</p>

    <p>Поки <b>Ніклаус</b> живий, він час від часу викликає ада — <em>Dutiful Attendant</em>,
    який вішає щит на боса в якого найменше хп, ада потрібно швидко вбити.</p>

    <p>Як тільки в одного боса буде 50%, починається фаза танців. Залу заповнюють їх слуги (хоч не народу) і
    вони починають рухатись по певному напрямку, якщо гравець буде рухатись у вірному напрямку з ними він отримує
    стак бафу, якщо ні — дебаф і дамаг по собі. Після танців на рейді буде настаканий дебаф
    <em>Oppressive Atmosphere</em>, хіл має здати кд.</p>

    <p><b>Першу вбиваємо Фрейду</b>, збиваємо їй касти. Кого розбиває на партнера, швиденько до один одного
    та рухайтесь разом. Решта рейду має вас уникати. Ади мають помирати швидко.</p>

    <p><b>Після того як Фрейда померла, б'ємо Ніклауса</b>. Він продовжує викликати ада з щитом,
    та викликає ще малих адів, яких можна таунтити, заводити в боса та вбивати. <b>Стравос</b> починає викликати
    вампірів-танцюристів, які танцюють у певному радіусі, рейд просто може вийти на вільне від них місце. Ті хто
    перетне їх отримає стан та купу дамагу.</p>

    <p><b>Після того як Ніклаус помре, б'ємо Стравоса</b>. Тепер він робить свій чардж дуже регулярно,
    тому свапатись треба частіше. Мдд мають уникати цього, та відходити від напрямку боса. Вампіри-танцюристи
    продовжують з'являтись, їх буде більше і ще вони навколо себе вибухають. Додаткова абілка боса, це дебаф,
    який уповільнює вас коли ви рухаєтесь. Тому — кому не потрібно рухатись, стійте на місці. МДД мають бити боса
    у бік а не за спину (щоб уникати чарджу).</p>
  `,
  aside: [
    {
      type: 'spell',
      icon: 'sha_spell_fire_bluerainoffire_nightmare',
      name: 'Oppressive Atmosphere',
      desc: 'Дебаф, який стакається по рейду з часом.',
      whid: 334909
    },
    {
      type: 'header',
      name: 'Freida'
    },
    {
      type: 'spell',
      icon: 'spell_animarevendreth_missile',
      name: 'Dreadbolt Volley',
      desc: 'Б\'є по рейду та вішає дебаф, можна інтераптити.',
      whid: 337110
    },
    {
      type: 'spell',
      icon: 'inv_enchanting_wod_essence3',
      name: 'Drain Essence',
      desc: 'Дрейнить декілька гравців, простий фокус хіл.',
      whid: 327773
    },
    {
      type: 'header',
      name: 'Niklaus'
    },
    {
      type: 'spell',
      icon: 'inv_knife_1h_revendreth_d_01',
      name: 'Duelist\'s Riposte',
      desc: 'Фізичний дебаф на танка.',
      whid: 346690
    },
    {
      type: 'header',
      name: 'Stavros'
    },
    {
      type: 'spell',
      icon: 'ability_rogue_shadowstep',
      name: 'Evasive Lunge',
      desc: 'Телепортується позаду танка і робить чардж.',
      whid: 327503
    },
    {
      type: 'spell',
      icon: 'ability_warlock_soullink',
      name: 'Dark Recital',
      desc: 'Розбиває деяких гравців по партнерах, партнери мають триматись біля один одного та разом рухатись, оскільки залишають за собою червоні аое зони.',
      whid: 331634
    },
  ],
  attachments: [
    { type: 'video', videoId: '6Id7pc9m4WM', name: 'Першокіл (героїк)' }
  ]
}
