export default {
  slug: 'sanctum-of-domination',
  parent: 'raids',
  name: 'Святилище панування',
  hint: 'Sanctum of Domination',
  image: '/img/sanctum-of-domination.jpg',
  group: 'Тінеземлі',
  header: { parent: true },
  archive: true,
  meta: {
    description: 'Рейд Святилище панування з доповнення Тінеземлі.',
    keywords: 'святилище, панування, санктум, домінейшн, домінейшин, сод, рейд, тінеземлі, шл, sanctum, domination, sod, raid, shadowlands, sl, wow'
  }
}
