export default {
  slug: 'pereklad-max-dps',
  parent: 'guides',
  name: 'Переклад How To Do Top DPS in WoW',
  header: { parent: true },
  authors: [ 'Dancecaptain (Makhabunda)' ],
  content: `
    [p]Всім приготуватися! У цьому відео розглянемо основи, необхідні для максимізації ДПСу для
    будь-якого класу — за умови, що Ви вже знаєте основи вашого. Себто не розглядатимемо gear,
    sockets, spells, keybindings, тощо.[/p]

    [p]Найважливіше для ДПСу — те, як Ви граєте, і аж ніяк не гір, таланти або щось на кшталт.
    Це добре видно: адже якщо Ви глянете на верхні рядки рейтингів ДПС конкретного класу, то
    Ви побачите дуже сильні варіації. Тож якби спорядження, таланти та решта подібних речей
    були саме тим, що робить будь-який клас найкращим, то всі гравці мали би доволі близький
    рівень. Але, як Ви вже встигли побачити -- це не так.[/p]

    ## Отож насамперед: що таке ДПС?

    [p]По-перше, ДПС — це максимізація ваших глобалок, максимізація кількості спелів, які Ви можете
    видати за певний проміжок часу в бою. По-друге — це наскільки багато шкоди Ви можете завдати
    кожним використаним навиком. Саме максимізація цих двох факторів і є ключем для видавання топ
    ДПСу.[/p]

    ## Максимізація кількості кастів

    [p]Перша річ, яку варто знати, поки ви доводите до максимуму кількість спелів та глобалів за
    одиницю часу — це знати наперед, який саме спел ви кастуватимете після одного або декількох
    глобалів. Вас не обходить те, що ви вже скастували, і має бути цікавим тільки те, що скастуєте
    наступним. Іншими словами — якщо Ви кастуєте щось, то до того, як цей каст завершиться, вже
    точно маєте знати, який спел далі.[/p]

    [p]Наприклад, якщо я почав кастувати Steady Shot і бачу, що мій Aim Shot буде готовий за
    3 секунди -- тоді я знаю наперед, що в мене є можливість зробити ще один Steady Shot між
    попереднім Steady Shot та наступним Aim Shot. І спланую це перед виконанням. Коли Ви спланували
    послідовність кастів, та знаєте загальну ротацію для Вашого класу, то треба опанувати найкращий
    шлях для використання відповідних абілок: використовуючи кібайнди.[/p]

    [p]Найгірший шлях — це натискати на кнопки кастів мишкою на вашому actionbar. Не робіть так.
    Інший поганий шлях — це слідкувати за таймером завершення касту: наприкінці дії ви зрозуміли,
    що вона завершена й починаєте наступну. Але слідкування за таймером обертається немалим проміжком
    часу між одним кастом і наступним. Сам проміжок складатиметься з latency (пінгу) та часу Вашої
    реакції — а ці величини можуть бути значними.[/p]

    [p]Ще одна річ, яка можливо є найбільш популярним шляхом використання абілок — гравці виводять
    cast bar одразу з latency на ньому і реагують уже на виправлений таймер завершення касту. Причина,
    чому це також недобре, наступна: хоча Ви враховуєте більшість latency, але зазвичай воно
    розраховується НА ПОЧАТКУ касту і може бути інакшим на завершенні, яке відбувається коли Ви
    натискаєте наступну кнопку. Отож, варіації latency можуть спричиняти втрату ДПСу. Окрім цього,
    коли доводиться натискати кнопки зарано чи запізно, ви втрачатимете цю різницю в часі.[/p]

    [p]Метод найкращого використання глобальних кулдаунів — спамити кнопки, а це вимагатиме від Вас
    хороших кібайндів, які ви будете використовувати доволі часто. Саме тому вони мають бути зручно
    розташованими на Вашій клавіатурі. Багато людей використовують клавіатури з підтримкою власних
    макросів, що дозволяє імітувати спам кнопки простим її затисканням. Найбільш популярна клавіатура
    з цією функцією — Logitech G 15. Для тих, хто не має доступу до подібних клавіатур, єдиним
    найкращим виходом є спамити кнопки. І якщо Ви досягли успіху — то ви вже знаєте, як він звучить
    (шалене клацання)...[/p]

    [p]Те, що ви насправді робите методом спаму кнопок — це враховуєте наперед помилку кожного разу,
    коли відволікаєтесь на будь-що. І ця помилка реально більша, ніж про неї думають. Інтервал між
    двома натисканнями кнопки під час спаму тепер стає максимальною затримкою. Отож це найкращий
    шлях.[/p]

    [p]Ще одна порада, яку я можу дати — яка би кнопка найчастіше не натискалася (для хантера це
    Steady Shot) я все одно рекомендую додатково прив'язати цей каст до рухів угору і вниз скролу
    мишки, що дозволить спамити дві кнопки одночасно для однакових абілок заради максимального
    ДПСу.[/p]

    ## Планування руху під час бою

    [p]Наступна річ, яка домопоже Вам краще використовувати ваші спели — планування вашого переміщення.
    Під час бою доведеться рухатись — це реальність у будь-якому бою, де є механіки щодо цього. Вам
    треба буде переміщувати персонажа, і тому необхідно мати план використання спелів, поки ви рухаєтесь.
    Наприклад миттєві касти чи інші навики, котрі або завдають шкоди, або допомагають завдавати пізніше
    під час бою.[/p]

    [p]Наприклад у хантерів є миттєві постріли, такі як Arcane Shot, Aim Shot тощо, та й у всіх класів
    є щось подібне. Наприклад у варлоків ви можете завжди використати Life Tap, аби повернути дещицю
    мани, (котрої може не вистачити пізніше) і це підвищить ваш загальний ДПС. Якщо комбінувати ці
    миттєві вміння, то це дозволить зробити більше кастів, а отже після їхнього використання Ви побачите
    істотний приріст Вашого ДПСу.[/p]

    [p]Я бачив бої з босами, у яких один хантер зробив на тридцять чи сорок глобал кулдаунів більше у
    дев'ятихвилинному бою, аніж інший. Така різниця між гравцями не є такою рідкісною, як ви вважаєте.[/p]

    ## Максимізація шкоди від кожного касту

    [p]Наступний крок у збільшені ДПСу — максимізація шкоди, яку кожен з тих кастів робить. Найкраще — це
    поєднувати ваші кулдауни. Наприклад у Вас є розмежовані кулдауни, один з яких піднімає шкоду на 10%,
    а інший прискорює ваші атаки на 10%. Коли Ви перекриєте ці дві абілки, ви завдаватимете на 10% більше
    шкоди + атакуватимете на 10% швидше. Отож, вони матимуть ефект примноження. Ви зрештою завдасте більше
    шкоди, використовуючи ці абілки разом, а не окремо.[/p]

    [p]Багато людей про це знають і те що вони роблять — це виставляють собі аддонами панелі, котрі
    показують прок трінкету чи прок якогось таланту, реагуючи на них. Але те, що вони насправді роблять — це
    втрата перших секунд відповідних проків, тому найкращий шлях компенсувати втрату цих секунд — це
    використовувати аддони, які відслідковують Ваші внутрішні кулдауни. Я використовую аддон Heatsink
    і він відслідковує внутрішні кулдауни моїх трінкетів, коли вони прокають. Коли внутрішній кулдаун
    завершується, тоді трінкет зазвичай прокне доволі скоро. Отож, якщо би я превентивно використав мій
    Rapid Fire, який піднімає швидкість моїх атак, то мій трінкет був би активним під час дії Rapid Fire
    і замість того, щоб мати вісім секунд проку після "ручного" реагування, я маю повних десять секунд.[/p]

    ## Знання тривалості бою

    [p]Ви також повинні знати наскільки довго триватиме бій, вам потрібно мати добрий підрахунок перед
    початком бою і Вам варто бути обізнаними щодо речей у бою, котрі даватимуть додаткову вигоду для ДПСу.
    Такі наприклад, як лід, що застанить боса в бою — тоді бажано мати ваші кулдауни доступними в цю мить,
    оскільки ви отримаєте додатковий множник шкоди для ваших абілок. Також Ви обов'язково повинні мати
    набір доступних кулдаунів, коли використають героїзм. Ви повинні зберегти Ваші довші кулдауни так само,
    як і поти для героїзму. Вам необхідно поєднати всі ці речі з проками ваших трінкетів.[/p]

    [p]Для хантерів: у бою з Jaraxxus, наприклад, що триває чотири хвилини і на якому ми здаємо героїзм
    на другий Mistress Portal, приблизно на другій хвилині я використаю Rapid Fire спочатку. Оскільки
    обидва мої трінкети на старті бою будуть не на внутрішньому кулдауні, я використаю Readiness на
    кажана, бо хочу, щоб Readiness забезпечив останній Rapid Fire на останню хвилину бою, і збережу
    свій другий Rapid Fire для героїзму. Так само, як поєднання Call of the Wild і потів з трінкетами,
    що скоро прокнуть. На третій хвилині, коли Readiness повернеться, я його використаю, що забезпечить
    мені додатковий Kill Shot і стакатиме його з іншими кулдаунами. Коли ви поєднуєте кулдауни з проками
    трінкетів, то побачите, що кожна абілка завдаватиме збільшену середню шкоду на відміну від випадку,
    коли Ви кастуєте їх довільно та коли завгодно. Більшість цих умінь, про які ми згадали, вимагатимуть
    чимало практики, але найважливіше — це Ваша мотивація видавати більше ДПСу, аніж було минулого тижня.[/p]

    [p]Ви відкриєте багато маленьких речей під час бою, які дозволять Вам завдавати більше шкоди.
    Такі речі, як навчання ДПСити в дві цілі, в три цілі, чотири цілі — все це буде різним, з використанням
    різних типів потів, препотів, речей на кшталт The Headless Horseman Blade. Всі ці маленькі хитрощі
    будуть приємним додатком до вашого максимально можливого ДПСу.[/p]
  `,
  attachments: [
    { type: 'video', videoId: 'VgDjG_0ecTI', name: 'Оригінальне відео (англійська)' }
  ]
}
