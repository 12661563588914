export default {
  slug: 'noviy-afiks-s4-shrouded',
  parent: 'guides',
  name: 'Новий афікс Season 4 Shrouded',
  image: '/img/noviy-afiks-s4-shrouded-1.jpg',
  meta: {
    description: 'Гайд по новому афіксу 4го сезону Тінеземель',
    keywords: 'гайд, афікс, шраудед, підземелля, данж, мітік, м+, тінеземлі, dungeon, shadowlands'
  },
  header: { parent: true },
  authors: [ 'Daitro' ],
  contentvideo: {
    ytid: 'w41dmCLzolI'
  }
}
