export default {
  slug: 'terros',
  parent: 'vault-of-the-incarnates',
  name: 'Террос',
  hint: 'Terros',
  image: '/img/terros.jpg',
  meta: {
    description: 'Тактика бою проти Терроса у Сховищі втілень.',
    keywords: 'террос, терос, тактика, стратегія, механіка, гайд, бос, сховище втілень, terros, vault of the incarnates'
  },
  header: { parent: true },
  sidenav: true,
  authors: [ 'Semerkhet' ],
  content: `
    ## Загальна інформація

    [p]Можна сказати, що [npc/190496/Террос] створений для фокусування на одній цілі й
    перевірки DPS. Heroism / Bloodlust можна дати з початку бою. Бос стоїть на місці,
    не рухається. Якщо в зоні ближнього бою нікого немає -- 100% вайп. Простір кімнати
    поступово закидається калюжами.[/p]

    [p mb-0]**Головна механіка боса**[/p]
    [ul mt-0]
      [li]Гравці створюють під собою шипи, танк їх знищує;[/li]
      [li]Метеор -- розділити пошкодження; Не бігти в Даларан рятуватися, а просто
      розділити.[/li]
      [li]Уникати всього іншого.[/li]
    [/ul]

    [p]Завжди стійте біля існуючих калюж або на краю затопленої території, щоб покласти
    нові калюжі до попередніх. На фазах 1 і 2 викладіть шипи в лінію. На 3 і 4 фазах
    бажано в 2 смуги або узгодити з цілителями.[/p]

    [p][icon/skull] Міфік: кидайте шипи на дві групи справа і зліва.[/p]

    [p][icon/skull] Міфік: Фаза 1 = 5 шипів, Фаза 2 = 6 шипів, Фаза 3 = 7 шипів,
    Фаза 4 = 8 шипів.[/p]

    [p]Бос буде знищувати неполамані шипи сектором ураження.
    [icon/alert] ~~Модель боса поки що порожня і ви можете впасти під ним у прірву
    (попередження, це може бути виправлено з часом).~~[/p]

    [p][icon/skull] Міфік: войдзона кладе стаки Dust на всіх гравців. Вам потрібно
    до 2 гравців, щоб зняти негативний ефект, але рейд отримає AoE пошкодження.
    Для найкращої повної механіки - робимо спред, а потім використовуємо власні
    сейви.[/p]

    [p]Може знадобитися WA, щоб призначити сторони для шипів (праворуч-ліворуч).[/p]

    [p]__Додаткові здібності:__[br]
    1) Якщо весь майданчик затоплений -- Enrage (06:27)[br]
    2) Якщо в зоні ближнього бою немає цілі -- [spell/388393/Enrage][/p]

    ### ДЛЯ ТАНКІВ

    [ul]
      [li]Танкова абілка -- промінь в танк і далі. Не цільтеся в гравців,
      цільтесь у шипи, щоб звільнити простір. Фізична шкода. Кожні 20 сек.[/li]
      [li]Дебаф на всю вхідну шкоду на 20%. Тривалість 45 секунд. Зміна за спадом
      (або за клівом), приблизно можете витримати 10+ стаків під сейвами.[/li]
      [li]На 3-4 фазах краще не знищувати всі шипи одночасно, або узгоджувати
      з цілителями;[/li]
      [li][icon/skull] Міфічний: знищення 3-4 шипів одночасно може вбити рейд.[/li]
    [/ul]

    ### ДЛЯ ЦІЛИТЕЛІВ

    [ul]
      [li]Поки шипи стоять -- наноситься шкода по рейду, приділіть увагу гравцям,
      які скидають шипи та поділяють метеорит.[/li]
    [/ul]

    ### ДЛЯ ДПС

    [ul]
      [li]Використовуйте власні сейви, якщо на вас є __Awakened Earth__. Дивіться,
      куди вам призначено кинути шип. Кладіть калюжі до попередніх калюж.[/li]
    [/ul]

    ### ЛАЙФГАКИ

    [ul]
      [li]Метеорит -- монах може ухилятися за допомогою телепортації (ймовірно,
      Blink спрацює теж).[/li]
      [li]Ви можете акуратно розкласти зони порожнечі в одному секторі, а потім
      привести боса для кліва в цьому ж секторі, тим самим заощадивши простір
      кімнати.[/li]
    [/ul]

    [p][image/terros-1.jpg][/p]
  `,
  aside: [
    {
      type: 'image',
      src: '/img/terros.jpg'
    },
    {
      type: 'header',
      name: 'Террос'
    },
    {
      type: 'spell',
      whid: 380487,
      icon: '6bf_blackrock_nova',
      name: 'Rock Blast',
      desc: `
        Пошкодження метеоритом (радіус 12 метрів). Падає калюжа. Позначає гравців шипами
        Awakened Earth (дебафф). Коли метеорит падає, під позначеними гравцями ростуть шипи
        (навіть якщо гравець із міткою не поділяє метеорит). Дає другий удар (вийдіть з войдзони).
      `
    },
    {
      type: 'spell',
      whid: 383073,
      icon: 'spell_shaman_earthquake',
      name: 'Shattering Impact',
      desc: `
        Войдзона спричиняє спадіння __Fractured Rubble__ і __Infused Fallout__.
      `
    },
    {
      type: 'spell',
      whid: 378861,
      icon: 'inv_10_elementalcombinedfoozles_earth',
      name: 'Fractured Rubble',
      desc: `
        Войдзона. Кладе на всіх гравців __Infused Fallout__. ~~Active Dust~~ -- шкода
        11 тисяч разів кожні 0,5 секунди. Коли два гравці торкаються, завдає 23 тисячі
        шкоди та підбиває гравців.
      `
    },
    {
      type: 'spell',
      whid: 377166,
      icon: 'spell_shaman_improvedfirenova',
      name: 'Resonating Annihilation',
      desc: `
        Заповнює сектор (на чверть) на кожних 100 одиниць енергії і викидає
        __Fractured Rubble__ і __Infused Fallout__. Знищує шипи, що залишилися.
      `
    },
    {
      type: 'spell',
      whid: 381315,
      icon: 'ability_earthen_pillar',
      name: 'Awakened Earth',
      namet: 'шипи',
      desc: `
        Поки стоять -- завдають шкоду по рейду. У момент руйнування AoE завдає
        шкоди і вішає дот на 12 секунд. Стакається.
      `
    },
    {
      type: 'spell',
      whid: 388393,
      icon: 'spell_nature_earthshock',
      name: 'Tectonic Barrage',
      desc: `
       [icon/alert] Якщо в зоні ближнього бою немає цілі, Террос безперервно вивільняє пульсуючі хвилі, завдаючи [99579/nature] кожні 1,5 сек.
      `
    },
  ]
}
