export default {
  slug: 'm-plus-hitroschi-dlya-dh-tanka',
  parent: 'guides',
  name: 'М+ хитрощі для дх танка',
  image: '/img/m-plus-hitroschi-dlya-dh-tanka-1.jpg',
  meta: {
    description: 'Гайд по мисливцю на демонів у спеціалізації "Помста".',
    keywords: 'гайд, масливець на демонів, помста, танкування, підземелля, данж, мітік, м+, тінеземлі, dungeon, shadowlands'
  },
  header: { parent: true },
  authors: [ 'Heroicsolo' ],
  content: `
    ## Вступ

    [p]Любий читаче, ласкаво прошу у світ шаленої мобільності, величезних цифр AoE пошкоджень,
    елізійських печаток, магії вогня та магії хаосу! Попереду тебе чекає невеличкий гайд по мисливцю
    на демонів у спеціалізації “Помста”, який (як я сподіваюсь) допоможе тобі краще себе показувати
    у епохальних+ підземеллях як танк, допомагаючи групі у швидкому проходженні.[/p]

    [p]Для збільшення твого рівня зацікавленості у тексті нижче, залишу тут ось такий скріншот
    типового DPS/HPS на великому (20+ мобів) запулі трешу у М+ підземеллі:[/p]

    <figure>
      <img src="/img/m-plus-hitroschi-dlya-dh-tanka-1.jpg">
      <figcaption>Мал. 1 -- Запул 30+ мобів у М+, з цими цифрами реально вижити без хіла та в теорії
      добити весь цей треш самому навіть у 20+ ключах</figcaption>
    </figure>

    ## Розділи гайду

    <ul>
      <li>Максимізація DPS та HPS<ul>
        <li>Як правильно взаємодіяти із фрагментами душ</li>
        <li>Розщеплення Душ vs Спіріт Бомба</li>
        <li>Іммолейшн Аура</li>
        <li>Метаморфоза</li>
      </ul></li>
      <li>Оптимальний еквіп для М+</li>
    </ul>

    ## Максимізація DPS та HPS

    ### Як правильно взаємодіяти із фрагментами душ

    <figure>
      <img src="/img/m-plus-hitroschi-dlya-dh-tanka-2.jpg">
      <figcaption>Мал. 2 -- Чим більше таргетів навколо, тим більше генерується фрагментів душ</figcaption>
    </figure>

    [p]Почну з головного правила гри за дх танка: **~~ЗАВЖДИ треба рухатися!~~** Навіть, якщо треба
    тримати пак мобів у одній точці. Рухайся невеличкими стрибками або кроками, бігай навколо цих
    мобів по невеликому колу, як завгодно, але рухайся. Чому? Тому що коли дх танк рухається -- він
    швидше та частіше збирає фрагменти душ, а кожен фрагмент -- це як мінімум 3 корисні бонуси:[/p]

    [ul]
      [li]
        Регенерація хп (кожен зібраний фрагмент хіляє на певний % від максимального запасу хп).
        Мінімальний об’єм зцілення 1% від максимального хп та збільшується в залежності від отриманих
        пошкоджень у останні 5 секунд бою. Також, це зцілення скалюється від величини сили Атаки:[br]
        [br]
        [image/m-plus-hitroschi-dlya-dh-tanka-3.jpg][br]
        [spell/204254/Shattered Souls][br]
        [br]
      [/li]
      [li]
        Зменшення кулдауну Іммолейшн аури та Девастейшену, які є в свою чергу найбільшими за АоЄ
        пошкодженнями здібностями (цей пункт працює, якщо зібрано 4 предмета із рейдового сету 9.2):[br]
        [br]
        [spell/363737/Rapacious Hunger][br]
        [br]
      [/li]
      [li]
        Дає досить потужний бонус універсальності та гніву на протязі 20 секунд після активації
        Елізійського декрету (якщо ти кирієць). Бонус сумується до 20% універсальності:[br]
        [br]
        [spell/355893/Blind Faith][br]
        [br]
        ~~І все це працює в рази гірше в тому випадку, якщо ти стоїш на одному місці та не рухаєшся :)~~[br]
        [br]
        Для порівняння: до того як я сам не зовсім розумів ці механіки та танкував мало рухаючись,
        я у середньому видавав 6-8к HPS та 8-13к DPS на запулах трешу середніх розмірів. А додавши
        постійний рух, вже отримую цифри у 1.5-2 рази більші (відхіл взагалі став більший у декілька
        разів і може бути збільшений до 40к+ HPS на великих запулах).
      [/li]
    [/ul]

    ### Розщеплення Душ vs Спіріт Бомба

    [p]Ще одна розповсюджена помилка у ротації дх танка, яка була і в мене дуже довгий час:
    використовувати [spell/228477/Розщеплення Душ] проти однієї або 2-3 цілей, якщо це робиться
    для нанесення пошкоджень, а не зцілення.[/p]

    [p]Не дивлячись навіть на опис обох здібностей, який може збити з пантелику, насправді,
    [spell/247454/Спіріт Бомба] наносить більше пошкоджень навіть у одну ціль, ніж Розщеплення
    Душ.[/p]

    [p]Виходячи з вищесказаного, можна зробити наступний висновок: **~~використовуй Розщеплення
    Душ тільки якщо ти досить сильно просідаєш по хп і якщо проти тебе від 1 до 3-4 цілей~~**.
    Якщо це AoE сутичка, тоді навіть у такому випадку використовуй все одно Спіріт Бомбу (тому що
    нанесені фрагментами душ пошкодження в такому разі будуть видавати майже той же (або більший)
    рівень зцілення за рахунок вампірізму, накладеного цією ж здібністю).[/p]

    [p]~~Також, є одна хитрість, досить складна у реалізації, проте все одно цікава: не дивлячись
    на заявлений у описі ліміт у 5 фрагментів душ, інколи на великих запулах дх танк може згенерувати
    більшу кількість фрагментів душ і в такому випадку є приблизно 1 секунда (проміжок часу, поки
    фрагменти душ летять від цілей на землю) для того, щоб протиснути Спіріт Бомбу і тоді вона
    наносить ще більші пошкодження. Після цього дуже короткого проміжку часу (коли фрагменти вже
    впали на землю) всі надлишкові фрагменти автоматично поглинаються без нанесення пошкоджень
    (як і при звичайному їх зборі шляхом руху біля них).~~[/p]

    ### Іммолейшн Аура

    [p]І знов розповідаю про розповсюджену помилку: маючи 2 екіпованих предмети із рейдового
    сета 9.2, багато дх використовують [spell/258920/Іммолейшн Ауру] лише після спадання її
    попереднього бафу та/або якщо Фел Девастейшен і Елізійський Декрет на кд.[/p]

    [p]Здавалося б, ці 2 здібності наносять у рази більші пошкодження, особливо в AoE. Але не забувайте
    про те, що Іммолейшн Аура із [spell/364454/сет бонусом] -- це основний наш генератор фрагментів душ
    (10% шанс згенерувати фрагмент з кожної нашої цілі + при натисканні цієї здібності ми отримуємо
    ще додаткові фрагменти).[/p]

    [p]Робимо висновок: **~~навіть якщо на Елізійському Декреті або Фел Девастейшені немає кд,
    все одно спочатку натискай на Іммолейшн Ауру~~**, щоб вона вже працювала на фоні, даючи нам
    фрагменти душ та наносячи поступовий AoE дамаг. А вже потім використовуй всі інші здібності.[/p]

    ### Метаморфоза

    [p]Зазвичай, захисні кулдауни танки використовують тільки коли боляче :) Але у нашому випадку,
    цей кулдаун дає не тільки бонус виживання, але й достатньо сильно впливає на наш DPS.
    Бо [spell/187827/Метаморфоза], окрім збільшення максимального запасу хп та рівня броні,
    дає ще й збільшену генерацію фрагментів душ + посилює частину наших здібностей.[/p]

    [p]Тому **~~намагайся використовувати Метаморфозу не тільки тоді, коли дуже боляче,
    а максимально регулярно, в ідеалі треба натискати її по кулдауну, а маршрут у підземеллі
    розраховувати таким чином, щоб робити великі запули якраз під час наступної Метаморфози~~**.[/p]

    ## Оптимальний еквіп для М+

    [p]Враховуючи, що рейтинг хасти зменшує час оновлення Іммолейшн Аури, яка має ключову роль
    (особливо при активному сет бонусі) -- найбільш пріоритетною характеристикою для нас є хаст.
    Повний список пріоритетів для М+ після патчу 9.2 виглядає так:
    <div style="text-align: center;">
      [pre]Агіла/Хаст > Універсальність >>> Мастері/Кріт[/pre]
    </div>
    [/p]

    [p]Комфортним співвідношенням характеристик для танкування М+ є 30-40% хасти та
    15-20% універсальності. Мастері та кріт майже не важливі, та за будь якої можливості їх
    можна ігнорувати заради хасти, універсальності, агіли або навіть стаміни (бо стаміна
    це збільшення зцілення за рахунок кожного фрагменту душ).[/p]

    [p]Із аксесуарів можу порекомендувати [item/185836/Кодекс] + [item/179331/Кроваву Чешуйку]
    якщо важко виживати або робляться великі запули по 2-3 паки та більше, або
    [item/178769/Нескінченно Подільний Слиз] + будь який інший дд аксесуар, особливо якщо він
    дає бонус Агіли чи хасти (пасивний чи у вигляді проку).[/p]
  `
}
