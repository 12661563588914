export default {
  slug: 'dathea-ascended',
  parent: 'vault-of-the-incarnates',
  name: 'Датія, Піднесена',
  hint: 'Dathea, Ascended',
  image: '/img/dathea-ascended.jpg',
  meta: {
    description: 'Тактика бою проти Датії у Сховищі втілень.',
    keywords: 'датія, піднесена, тактика, стратегія, механіка, гайд, бос, сховище втілень, dathea, ascended, vault of the incarnates'
  },
  header: { parent: true },
  sidenav: true,
  authors: [ 'Semerkhet' ],
  content: `
    ## Загальна інформація

    [p]Викликає 1 моба на основну платформу, що в момент смерті вибухає і відкидає
    від себе. На одній з трьох платформ (випадково) будуть маленькі кастери і такий
    самий моб. Вбийте всіх і поверніться.[/p]

    [p]Призначена група повинна полетіти на платформу і вбити там усіх мобів.
    (1/2/4 + - склад групи).[/p]

    [p][icon/skull] Героїчний/міфічний: альтернативні групи.[/p]

    [p]Буде той самий моб-відкидач (вбийте його останнім), який поверне вас назад.
    Це може збігатися з механікою __Cyclone__ або __Conductive Mark__. Не витрачайте
    свої бурсти та особисті сейви по кд, використовуйте їх з максимальною прибутковістю.[/p]

    [p]Викликає мобів після досягнення 100 од. енергії (приблизно кожні 80 сек).[/p]

    ## Моби

    [p]**[npc/192934/Volatile Infuser]** (елітний):[/p]

    [ul]
      [li]Бʼє танка.[/li]
      [li][spell/387943/Diverted Essence] -- переривати каст.[/li]
      [li][icon/skull] Міфічний: більше не зникає.[/li]
      [li]Робить войдзони.[/li]
      [li]Після смерті він вибухає (пошкодження) і відкидає гравців у радіусі
      150 метрів.[/li]
    [/ul]

    [p][icon/skull] Героїчний/міфічний **[npc/190688/Blazing Fiend]**
    (6 мобів на платформу кожного разу):[/p]

    [ul]
      [li]Перервати касти.[/li]
      [li]Після появи гравців на платформі з'являється дебафф (ДоТ), викликає
      посилення ефекту -- стакається (потрібно буде змінювати групи для польоту
      на платформи).[/li]
    [/ul]

    ### ДЛЯ ТАНКІВ

    [ul]
      [li]__Zephyr Slam__ -- удар по танку, мінятися по 2 стаки. Кожен удар +50%
      вхідної шкоди та відкидання.[/li]
      [li]Не можна покидати мілі зону боса. Починає спамити постріли в танк.
      Затаунтити якщо вже кастує (у воїнів, лицарів смерті, ведмедів і мисливців
      на демонів не повинно виникнути проблем із цією механікою).[/li]
      [li]Volatile Infuser -- теж має танковий удар (природа). Маленькі моби
      не такі небезпечні, але можуть вбити, якщо їх багато. Неважливо, хто починає
      і який танк летить на платформу.[/li]
    [/ul]

    ### ДЛЯ ЦІЛИТЕЛІВ

    [ul]
      [li]Слідкуйте за гравцями з дебафами.[/li]
      [li]Нам потрібні сейви, під час виклику мобів, бо протягом 20 секунд вони
      будуть стріляти в рейд + AoE та вибух Volatile Infuser.[/li]
      [li]На боковій платформі потрібно 1-2 цілителя (на низькій складності 2,
      а якщо добра екіпіровка, то достатньо 1).[/li]
      [li]Ближче до кінця бою площа шкоди рейду збільшується завдяки збільшенню
      кількості __Raging Tempest__.[/li]
    [/ul]

    ### ДЛЯ ДПС

    [ul]
      [li]Розкладати обережно дебафи (не чіпайте інших гравців).[/li]
      [li]Контролити мобів у перехідній фазі.[/li]
      [li]Відносна перевірка DPS в боса з початку бою (або моліться, щоб цілителі
      змогли закрити 4-5-6 фази, якщо вони будуть). Just a joke.[/li]
    [/ul]

    ### ЛАЙФГАКИ

    [ul]
      [li]Танки можуть йти спиною до спини за допомогою __Raging Tempest__, щоб
      не залетіти надто далеко й отримати постріл від боса (використовуйте особистий
      сейв).[/li]
      [li]Будь-якого незапланованого руху на цьому босі можна уникнути за допомогою
      особистих телепортів (монах, чорнокнижник, маг) і стрибків / атаки (воїн,
      мисливець на демонів, друїд, мисливець...)[/li]
      [li][icon/skull] Міфік. В одній фазі з'являється 12 __Conductive Mark__, і лише
      10 потрібні для поповнення енергії. Решта 2 позначки можна віднести до наступного
      за чергою Volatile Infuser. Або до іншого, за командою лідера рейду. Може статися
      так, що Volatile Infuser з’явиться набагато раніше, ніж потрібно.[/li]
    [/ul]

    [p][image/dathea-ascended-1.jpg][/p]

    [p][image/dathea-ascended-2.jpg][/p]
  `,
  aside: [
    {
      type: 'image',
      src: '/img/dathea-ascended.jpg'
    },
    {
      type: 'header',
      name: 'Датія'
    },
    {
      type: 'spell',
      whid: 376943,
      icon: 'creatureportrait_cyclone_nodebris',
      name: 'Cyclone',
      desc: `
        Тягне всіх рейдерів до боса, а тих, кого притягне в мілі, підкидає високо.
        -25к/с шкода від AoE під час перетягування.
      `
    },
    {
      type: 'spell',
      whid: 388302,
      icon: 'spell_broker_missile',
      name: 'Raging Burst',
      desc: `
        Войдзона, залишає __Raging Tempest__.
      `
    },
    {
      type: 'spell',
      whid: 375082,
      icon: 'ability_skyreach_four_wind',
      name: 'Raging Tempest',
      desc: `
        Не торкайтеся. Пошкоджує та підбиває гравців. Періодично завдає AoE шкоди рейду
        на 1600/4сек за одиницю. +2 __Raging Tempest__ за фазу.
      `
    },
    {
      type: 'spell',
      whid: 388410,
      icon: 'inv_10_jewelcrafting_bg_air',
      name: 'Crosswinds',
      desc: `
        Переміщує __Raging Tempest__ по платформі. У зоні __Raging Tempest__ утворює зону
        порожнечі -- ви повинні вийти (привіт бою з королем Мехагоном).
      `
    },
    {
      type: 'spell',
      whid: 391686,
      icon: 'spell_shaman_staticshock',
      name: 'Conductive Mark',
      desc: `
        Візьміть спред 4 метри. При необхідності використовуйте особисті сейви при
        отриманні стаків. Не рухайтеся та не наближайтеся до інших гравців (30k AoE
        пошкодження рейду, при контакті).
        [br][br]
        [code]/range 4[/code] -- ваш друг.
        [br][br]
        Обирає 4-5 гравців одночасно. В основному це гравці дальнього бою, іноді
        (дуже рідко, найчастіше при нестачі дальників) можуть вибрати бійців ближнього
        бою. Тривалість 10 сек (якщо не продовжено).
        [br][br]
        [icon/skull] Героїчний: дебаф триває 15 секунд.
        [br][br]
        [icon/skull] Міфічний:[ul sparse]
          [li]Дебаф не зникає, необхідно підійти до трупа
          Volatile Infuser, і тим самим дати йому 10 енергії; перевірка DPS, оскільки
          Volatile Infuser є лише 5.[/li]
          [li]__Conductive Mark__ кидається на 4 гравців одночасно.[/li]
          [li]Одного Volatile Infuser достатньо, щоб наповнити енергією 10 разів
          (принести 10 дебафів).[/li]
          [li]У момент, коли один Volatile Infuser прокидається, він скидає енергію,
          отриману іншим мертвим Volatile Infuser. Це означає, що третій набір дебафів
          повинен трохи почекати. Два дебафи здаються відразу, а два -- в інший
          Volatile Infuser після відновлення першого.[/li]
        [/ul]
      `
    },
    {
      type: 'spell',
      whid: 390449,
      icon: 'spell_nature_unrelentingstorm',
      name: 'Thunderbolt',
      desc: `
        [icon/skull] Героїчний: коли одну з груп відправляють на платформу, вони
        отримують накопичувальний негативний ефект і збільшену вразливість до цього
        негативного ефекту на 50% протягом 72 сек. Змінюйте групи на кожній платформі.
      `
    },
    {
      type: 'spell',
      whid: 375580,
      icon: 'ability_skyreach_wind_wall',
      name: 'Zephyr Slam',
      desc: `
        Датія грюкає своїм підсиленим кулаком по поточній цілі вибухом вітру, завдає
        [321222/physical] та відштовхує її.
        [br][br]
        З кожним ударом збільшується пошкодження і сила відштовхування на 50% на 55 сек.
        Ефект складається.
      `
    },
  ]
}
