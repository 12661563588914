export default {
  slug: 'reid-lider',
  parent: 'guides',
  name: 'Рейд лідер',
  header: { parent: true },
  authors: [ 'Ta-na' ],
  content: `
    <p>
      Така ж людина як і ти, тіко має більше <del>влади</del> відповідальності, тому не перебивай
      коли він (вона) пояснює те чи інше, бо він (вона) витрачав(ла) свій час або то все знайти
      і зрозуміти.
    </p>

    <h3>Не будь свинею :D</h3>

    <p>
      Приходь підготовленим(ою) на рт, не закривай останній інст, ща ща я бг добіжу і т.д., тебе
      чекають люди. І воопщє супер бути біля рейду в обумовлений час, а не чекати сумон.
    </p>

    <h3>Ось чек лист</h3>

    <ul>
      <li>Їжа</li>
      <li>Фласки</li>
      <li>Чарки</li>
      <li>Каміння в сокетах</li>
    </ul>

    <p>
      Вітається зловживання (пре)потами і рунами. Для нормалу їжа, фласки, каміння і чанти можуть
      бути старі (з попереднього патчу). Для героїку і міфіку їжа, фласки, каміння і чанти —
      тільки топові. Препоти — обов'язкові.
    </p>
  `
}
