export default {
  slug: 'zophex',
  parent: 'tazavesh',
  name: 'Зо\'фекс',
  hint: 'Zo\'phex',
  image: '/img/zophex.jpg',
  group: { name: 'Митниця', hint: 'The Customs' },
  meta: {
    description: 'Тактика бою з вартовим Зо\'фексом у Тазавеші.',
    keywords: 'зо\'фекс, зофекс, вартовий, тактика, стратегія, механіка, гайд, бос, тазавеш, підземелля, данж, мітік, м+, тінеземлі, zo\'phex, zophex, sentinel, tazavesh, market, dungeon, shadowlands'
  },
  header: { parent: true },
  sidenav: true,
  authors: [ 'Skye' ],
  content: `
    [p]Бій проти вартового митниці [npc/175616/Зо'фекса], який має чотири ноги й стільки ж рук.
    Кожна в свою чергу озброєна гарним блакитним [item/185780/мечем], який чудово доповнить ваш гардероб.[/p]

    ## Бій

    [p]Майже на початку, Зо'фекс вкриється захисним __щитом__ у вигляді прозорого направленого бар'єру,
    що повільно крутиться навкого нього й поглинає всі атаки гравця, якщо той стоїть зі сторони щита.
    Напрямок щита не прив'язаний до напрямку боса, тому танк не може впливати на нього повертаючи боса.
    Рекомендовано стояти близько до боса, щоб витрачати меньше часу на перебіжки.[/p]

    [p]Протягом всього бою Зо'фекс буде розкидувати свої кінцівки у вигляді __вихорів__ на землі,
    танк має виводити боса на вільне місце. Також бос буде збільшувати своє фізичне пошкодження в моменти
    __озброєння__.[/p]

    ## Конфіскація зброї

    [image/zophex-1.jpg]

    [p]Коли Зо'фекс __конфіскує__ вашу зброю, уважно слідкуйте куди вона летить, одразу рухайтеся
    в потрібному напрямку. Допоки ви без зброї, ви не можете завдавати пошкодження або зцілювати.[/p]

    ## Клітка

    [p]Інколи Зо'фекс буде відмічати гравця червоною стрілкою, через 5 сек, цей гравець буде
    [приголомшений//Stun] та ув'язнений в [npc/175576/клітці]. Бос зафіксується на в'язні та буде
    повільно рухатися до нього щоб миттево вбити. Клітку потрібно знищити якомога швидше. Також,
    не стійте на шляху руху боса. Рекомендовано відбігати зі стрілкою подалі, щоб було більше
    часу на знищення клітки.[/p]
  `,
  aside: [
    {
      type: 'image',
      src: '/img/zophex.jpg'
    },
    {
      type: 'header',
      name: 'Зо\'фекс'
    },
    {
      type: 'spell',
      whid: 347992,
      icon: 'spell_arcane_arcaneresilience',
      name: 'Rotary Body Armor',
      namet: 'щит',
      desc: `
        Зо'фекс захищений бронею, яка повільно крутиться навколо нього. Повністю поглинає всі атаки,
        коли знаходиться між Зо'фексом та гравцем.
      `
    },
    {
      type: 'spell',
      whid: 346204,
      icon: 'ability_dualwieldspecialization',
      audio: 'https://wow.zamimg.com/sound-ids/live/enus/197/4070085/VO_91_Zophex_207965.ogg',
      name: 'Armed Security',
      namet: 'вихори',
      desc: `
        Руки Зо'фекса від'єднуються від його торсу й починають швидко крутитися, завдаючи
        [8542/physical] щосекунди гравцям в радіусі 6 метрів.
      `
    },
    {
      type: 'spell',
      whid: 348128,
      icon: 'inv_misc_enggizmos_02',
      audio: 'https://wow.zamimg.com/sound-ids/live/enus/205/4070093/VO_91_Zophex_207967.ogg',
      name: 'Fully Armed',
      namet: 'озброєння',
      desc: `
        Зо'фекс відновлює кінцівки свого торсу, збільшуючи фізичне пошкодження на 25%.
      `
    },
    {
      type: 'spell',
      whid: 346006,
      icon: 'ability_warrior_disarm',
      audio: 'https://wow.zamimg.com/sound-ids/live/enus/201/4070089/VO_91_Zophex_207969.ogg',
      name: 'Impound Contraband',
      namet: 'конфіскація',
      desc: `
        Зо'фекс проводить сканування на наявність ворожої контрабанди, роззброюючи гравців.
        Допоки гравець [spell/345770/без зброї], його пошкодження та зцілення зменшене на 100%.
        [br][br]
        Повернення зброї [spell/353421/підбадьорює] гравця на 6 сек, збільшуючи його швидкість
        атаки та вимову заклять на 25%.
      `
    },
    {
      type: 'spell',
      whid: 347949,
      icon: 'ability_rogue_bloodyeye',
      audio: 'https://wow.zamimg.com/sound-ids/live/enus/202/4070090/VO_91_Zophex_207962.ogg',
      name: 'Interrogation',
      namet: 'клітка',
      desc: `
        Зо'фекс фіксується на відміченому гравці, який утримується в клітці. Повільно наближається
        до нього, завдаючи [27425/arcane] усім на своєму шляху.
        [br][br]
        [Приголомшений//Stun] гравець у клітці отримує [4388/arcane] щосекунди протягом 30 сек.
      `
    }
  ]
}
