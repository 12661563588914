export default {
  slug: 'post-master',
  parent: 'tazavesh',
  name: 'Майстер П.О.С.Т.',
  hint: 'P.O.S.T. Master',
  image: '/img/post-master.jpg',
  group: { name: 'Двір', hint: 'The Courtyard' },
  meta: {
    description: 'Тактика бою з майстром П.О.С.Т. у Тазавеші.',
    keywords: 'майстер, мастер, п.о.с.т., пост, пошт, почт, поштар, почтовик, листоноша, автоматон, робот, тактика, стратегія, механіка, гайд, бос, тазавеш, підземелля, данж, мітік, м+, тінеземлі, p.o.s.t., post, master, tazavesh, market, dungeon, shadowlands'
  },
  header: { parent: true },
  sidenav: true,
  authors: [ 'Skye' ],
  content: `
    [p]Бій проти механічного [npc/175646/майстра], який вийшов з ладу
    в Першому Офісі Справляння Товарів (П.О.С.Т.).[/p]

    ## Бій

    [p]Час від часу майстер буде відмічати місця __фіолетовими колами__, де невдовзі з'являться калюжі,
    якщо гравець не зайде в таке коло. Намагайтеся перекривати такі кола, щоб зберегти вільне місце для
    виконання інших механік.[/p]

    [p]Коли майстер здіймає __вихор__ листів, всім гравцям в незалежності від відстані до боса завдається
    сильне щосекундне пошкодження протягом 5 сек. Намагайтеся бути біля боса в цей момент, для полегшення
    роботи цілителя вашої групи. Рекомендовано використовувати захисні здібності на цю механіку.[/p]

    [p]Майстер буде відмічати гравця __жовтим колом__, у вас є 6 сек щоб згрупуватися та розділити
    пошкодження. Ділити пошкодження краще в зоні ближнього бою, де всі бійці дістають до боса. Також,
    якщо у вас є можливість повністю уникнути пошкодження (наприклад, [spell/186265/аспект черепахи]
    у мислівців), відбіжіть від всіх.[/p]

    ## Бомби

    [p]Приблизно кожні 40 сек, на землі будуть з'являтися маленькі жовті вихори, куди невдовзі
    приземлиться __бомба__. Вам потрібно швидко зорієнтуватися де наразі активний портал доставки,
    який активується через 5 секунд після того як з'явиться перша бомба.[/p]

    [p][image/post-master-1.jpg][/p]

    [p]Підбіжіть до бомби щоб її автоматично підняти, при цьому у вас з'явиться додаткова
    здібність -- [spell/346404/Жбурнути пакунок] на вказане місце у радіусі 20 метрів. Ви будете
    заповільнені вдвічі, отже намагайтеся використовувати всі доступні вам і вашій групі здібності
    прискорення руху.[/p]
  `,
  aside: [
    {
      type: 'image',
      src: '/img/post-master.jpg'
    },
    {
      type: 'header',
      name: 'Майстер П.О.С.Т.'
    },
    {
      type: 'spell',
      whid: 346286,
      icon: 'inv_alchemy_80_flask01purple',
      audio: 'https://wow.zamimg.com/sound-ids/live/enus/93/4065885/VO_91_P_O_S_T_Master_208422.ogg',
      name: 'Hazardous Liquids',
      namet: 'фіолетові кола',
      desc: `
        Майстер П.О.С.Т. активує центральний поштовий портал, вивільняючи пакунок із небезпечними рідинами,
        які будуть пролиті на землю якщо гравці їх не впіймають.
        [br][br]
        Впіймавши, гравець вкривається [spell/346844/алхімічним залишком], що завдає [1097/arcane] шосекунди
        протягом 10 сек. Цей ефект складається.
        [br][br]
        [spell/346329/Пролита рідина] залишає калюжу, що завдає [5485/arcane] щосекунди та зменьшує швидкість
        руху на 35% гравців при контакті.
      `
    },
    {
      type: 'spell',
      whid: 346742,
      icon: 'achievement_guildperk_gmail',
      audio: 'https://wow.zamimg.com/sound-ids/live/enus/92/4065884/VO_91_P_O_S_T_Master_208421.ogg',
      name: 'Fan Mail',
      namet: 'вихор',
      desc: `
        Майстер П.О.С.Т. починає швидко крутитися, вистрілюючи листи у всіх напрямках, що завдає
        [16455/physical] щосекунди всім гравцям протягом 5 сек.
      `
    },
    {
      type: 'spell',
      whid: 346962,
      icon: 'ability_racial_packhobgoblin',
      audio: 'https://wow.zamimg.com/sound-ids/live/enus/95/4065887/VO_91_P_O_S_T_Master_208420.ogg',
      name: 'Money Order',
      namet: 'жовте коло',
      desc: `
        Майстер П.О.С.Т. звалює тону золота на гравця, завдаючи [82275/physical] рівномірно
        поділених між всіма гравцями у радіусі 6 метрів.
      `
    },
    {
      type: 'spell',
      whid: 346947,
      icon: 'spell_mage_flameorb',
      audio: 'https://wow.zamimg.com/sound-ids/live/enus/94/4065886/VO_91_P_O_S_T_Master_208427.ogg',
      name: 'Unstable Goods',
      namet: 'бомби',
      desc: `
        Майстер П.О.С.Т. активує центральний поштовий портал, вивільняючи пакунки нестабільних
        товарів, які вибухають через 30 сек, якщо не будуть доставлені.
        [br][br]
        Гравці можуть піднімати ці пакунки, зменьшуючи свою швидкість руху на 50% та отримуючи
        можливість [spell/346404/жбурнути пакунок] на вказаце місце у радіусі 20 метрів.
        При цьому пакунок має приземлитися у активному порталі доставки або в руках іншого
        гравця.
        [br][br]
        Як при жбурлянні пакунка на землю, так і по закінченню часу на доставку -- він
        [spell/346297/вибухає], чим завдає [30168/fire] всім гравцям та додатково [4388/fire]
        щосекунди протягом 12 сек.
      `
    }
  ]
}
