export default {
  slug: 'sire-denathrius',
  parent: 'castle-nathria',
  name: 'Сір Денатрій',
  hint: 'Sire Denathrius',
  short: 'Денатрій',
  group: 'An Audience with Arrogance',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Vorren' ],
  content: `
    <ul>
      <li>Поле бою — перша фаза: кімната у формі піци; друга і третья фази: кімната у формі хреста
        (на третій фазі тільки цетральна секція хреста)</li>
      <li>Специфіка — бій розбитий на 3 фази, які змінюються з життям боса:<ul>
        <li>перша фаза: 100% [icon/arrow-right-bold] 70%</li>
        <li>друга фаза: 70% [icon/arrow-right-bold] 40%</li>
        <li>третя фаза: 40% [icon/arrow-right-bold] 0%</li></ul></li>
      <li>TW/Героїзм — на третью фазу</li>
    </ul>

    <p>Бій достатньо довгий, пройдемось по кожній фазі разом з абілками боса та тактикою.</p>

    ## [icon/sword-cross] ПЕРША ФАЗА [icon/sword-cross]

    <p>Бос вішає на всіх по 5 стаків дебафу __Burden of Sin__ та телепортиться до танку (боса не треба водити,
    він постійно телепортується до активного танку).</p>

    <p>Всі абілки бос виконує у послідовності:<br>
    __Cleansing Pain__ [icon/arrow-right-bold]
    __Blood Price__ [icon/arrow-right-bold]
    __Cleansing Pain__ [icon/arrow-right-bold]
    __Ravage__</p>

    <p>Між ними також він викликає **Night Hunters**, які обирають рандомну ціль та роблять чардж по ним.
    Коли у боса 100 енергії, він починає каст __Ravage__, який заповнює 1/3 кімнати войд зоною, рейд має рухатись
    у іншу третину кімнати одразу перед кастом (окрім танків). Звісно якщо боса не перевести у наступну фазу —
    кімната буде заповнена і всі помруть.</p>

    <p>Фаза закінчується коли у боса 70% хп, він всіх притягує до середини кімнати, відкидає по краям і починає
    кастувати __March of the Penitent__. Треба всім дійти до центру кімнати до кінця касту. Чим більше у вас
    стаків __Burden of Sin__ — тим повільніше ви рухаєтесь. Щоб без проблем встигнути до центру кімнати, в кожного
    має бути 2 або менше стаків дебафу. Після того як він закінчив каст — всі падають на нижню платформу і
    починається друга фаза.</p>

    ### Тактика першої фази

    <p>Рейд потрібно розбити на дві групи, які будуть по-черзі знімати з себе стаки дебафу (заради того щоб у всіх
    було 2 на кінець фази та щоб __Blood Price__ не був летальним).</p>

    <p>Бій відбувається під стінками арени (заради того щоб ади які з'являються після __Cleansing Pain__ з'являлись
    біля один одного).<br>
    [image/sire-denathrius-1.png]</p>

    <p>Кожна група займає праву та ліву сторону стіни, танки завжди стоять по середині разом з босом, та тримають
    його обличям до своєї групи. Ротація зняття дебафу:<br>
    1 група <code>х2</code> [icon/arrow-right-bold]
    2 група <code>х2</code> [icon/arrow-right-bold]
    2 група <code>х1</code> [icon/arrow-right-bold]
    1 група <code>х1</code></p>

    <p>Завдяки цьому у груп ніколи не будуть рівними дебафи та на кінець у всіх має бути по 2 стаки.<br>
    [image/sire-denathrius-2.png]</p>

    <p>Бос сумонить **Night Hunter** адів після кожної абілки, гравцям кого фокусують треба виходити на кут
    (дивись зоображення) і бути впевненим що ніхто інший не стоїть навколо тебе, іншим треба вставати на шлях
    ада щоб понизити фінальний урон.<br>
    [image/sire-denathrius-3.png]</p>

    <p>Після того як у всіх буде по 2 стаки (при високому дпс треба буде стопати щоб не перевести боса зарано)
    можна доводити боса до 70% та переводити його.</p>

    ## [icon/sword-cross] ДРУГА ФАЗА [icon/sword-cross]

    <p>**Денатрій** кидає свій меч **Реморнія** який перетворюється у ціль яку треба танкувати. Одразу з'являються
    4 **кабалісти** (ади) на північній стороні кімнати (2 біля дзеркала для мдд та по 1 на бокових платформах
    для рдд).</p>

    <p>**Кроваві дзеркала** слугують нам як портали в інший кінець кімнати. Адів потрібно вбивати
    в пріоритеті, оскільки по-часу з'являться ще 4 ади на півдні кімнати у такому самому положені, а потім
    по 2 на заході та сході. Доки ади живі, на всіх висить дебаф (який залежить від кількості живих адів).
    Після смерті ади вибухають на аніму (маленькі кола) яка летить у позиції гравців, з них необхідно швидко
    виходити, це смертельно.</p>

    <p>**Реморнія** також кастує __Impale__, який оберає 3 людини та летить наскрізь них (тонкі червоні стрілки
    між її цілями).</p>

    <p>Бос продовжує кастувати __Cleansing Pain__ по активному танку у формі конуса, але на цій фазі воно вішає
    дебаф (+50% до урону) на всіх хто попадає. Мдд мають не стояти перед босом, а танк має утілізувати це у сторону
    **кабалістів** щоб пришвидшити їх смерть.</p>

    <p>Через чіткі інтервали часу бос використовує __Hand of Destruction__, від нього треба тікати. Чим ближче
    до боса — тим більше урону, тут і треба використовувати **дзеркала** щоб перебігати у іншу сторону кіманти.</p>

    <p>На 100 енергії бос дає команду **Реморнії** робити __Massacre__ (Комбо). Купа копій меча спавниться навколо
    платформи і починають літати наскрізь гравців, це помічено жирними червоними лініями, з цього необхідно швидко
    вибігати. Усі абілки на цій фазі залишають калюжі під гравцями, тому все що можна уникнути — треба уникати,
    і не стояти в калюжах.</p>

    <p>На 40% бос телепортується у центр разом з **кабалістами** (по краях центру) і починається третя фаза.</p>

    ### Тактика другої фази

    <p>Як тільки ми преземлемось на платформу — швидко бігти на північь платформи (вгору по міні мапі).</p>

    <p>Танкам тримати **Реморнію** та боса близько, для кліву (вони діляться ХП) і не стояти в калюжах які за
    собою залишають.</p>

    <p>Танку який тримає **Денатрія** треба найшвидше бігти на північ та ставити боса так щоб по обом адам попав
    __Cleansing Pain__.<br>
    [image/sire-denathrius-4.jpg]</p>

    <p>Далі тримати його на краях платформи, щоб не залишати калюжі у центральних частинах (або біля дзеркала).
    Дпс потрібно швидко вбити адів та наносити урон бо босу та **Реморнії**. Як тільки помирають ади — всім виходити
    із крапель аніми які полетять по всьому рейду.</p>

    <p>Коли він всіх притягує до себе та кастує __Hand of Destruction__, всьому рейду необхідно швидко забігти
    у **дзеркало**, почекати __Impale__ від **Реморнії** (трое з людей залишаються на тій стороні з 1 хілом) як
    тільки 3 цілі вибрані — решта рейду біжить назад у **дзеркало** щоб добити **кабалістів**. На другий
    __Hand of Destruction__, аналогічно але назад бігти вже не треба (наступна хвиля **кабалістів** буде на півдні).</p>

    <p>Як краще позиціонуватися вибраним гравцям цілями __Impale__ від **Реморнії**<br>
    [image/sire-denathrius-5.png]</p>

    <p>Танкам треба тримати боса + **Реморнію** з іншої сторони від РДД ада якого б'ють. Щоб цілі меча
    утілізували вільне місце з іншої сторони. **Реморнія** не має летіти через центр платформи!</p>

    <p>Якщо у боса більше 45% хп і ось ось буде нова хвиля **Кабалістів**, треба хоча б вбити 2ох з них а потім
    перевести боса на 40% на третю фазу.</p>

    ## [icon/sword-cross] ТРЕТЯ ФАЗА [icon/sword-cross]

    <p>Бос телепортується на центр кімнати (разом з живими адами). Наносить урон по рейду, бере **Реморнію** у руки
    та заповнює периметр кімнати войд зонами. На 100 енергії бос по черзі командує __Комбо__ з другої фази (**Реморнія** літає)
    або заливає 1/3 кімнати войд зоною (як на першій фазі).</p>

    <p>**Кабалістів**, якщо вони були живими на переході треба вбивати миттєво, оскільки кожен живий кабаліст вішає дебаф
    на урон та мінус хіл по рейду.</p>

    <p>Далі, танки займають край кімнати (але щоб їх не відкинуло з платформи), оскільки він продовжує використовувати
    __Hand of Destruction__. Тільки тепер без **дзеркал**, тому треба перебігати у іншу сторону вільного простору для
    зменьшення урону по рейду. Необхідно вбити боса до того як вся кімната буде заповнена войд зоною.</p>

    ### Тактика третьої фази

    <p>Швидко біжимо до **кабалістів** (або боса), героїзм/tw, поти, кд. Необхідно швидко вбити боса поки він не заповнив
    всю платформу.<br>
    [image/sire-denathrius-6.jpg]</p>

    <p>На __Hand of Destruction__ всім маскимально вибігати (не треба повертатись, бос телепортується
    до танка).</p>

    <p>Люди на кому __Final Finesse__ — не мають стояти біля інших. **Орби**, які вони залишають за собою, треба
    соукати всім, бажано з деф кд.</p>

    <p>Ніхто не має стояти на краях платформи під час __комбінації__ (а краще взагалі ніколи). Під час __комбінації__
    дивитись під ноги!</p>
  `,
  aside: [
    {
      type: 'header',
      name: 'Перша фаза'
    },
    {
      type: 'spell',
      icon: 'inv_artifact_powerofthedarkside',
      name: 'Burden of Sin',
      desc: 'Дебаф 5 стаків, вішається на всіх на початку бою.',
      whid: 326699
    },
    {
      type: 'spell',
      icon: 'spell_animarevendreth_wave',
      name: 'Cleansing Pain',
      desc: 'Фронтальний клів по активному танку, гравці які отримали від кліву урон — знімають 1 стак __Burden of Sin__ та створюють біля себе ада, який б\'є по всьому рейду поки живий.',
      whid: 326707
    },
    {
      type: 'spell',
      icon: 'ability_ironmaidens_whirlofblood',
      name: 'Blood Price',
      desc: 'Підкидає всіх угору, наносить їм урон відповідно до кількості однакових стаків у гравців. Урон великий, потрібно юзати сейви + хіл кд.',
      whid: 326851
    },
    {
      type: 'spell',
      icon: 'sha_ability_rogue_bloodyeye_nightmare',
      name: 'Night Hunter',
      desc: 'Викликає 2 копії себе, які обирають рандомних гравців (лазер на гравця та зафіксоване коло) через 6 секунд чарджиться у нього. Урон летальний, щоб його зменшити іншим людям треба вставати на шлях Ада або у модельку. Гравець з колом має виходити щоб ніхто більше в ньому не стояв. Бос отримає хіл від урону який був нанесений цілі чарджу.',
      whid: 327796
    },
    {
      type: 'spell',
      icon: 'spell_shadow_corpseexplode',
      name: 'Ravage',
      desc: 'Кастує на 100 енергії. Заповнює 1/3 платформи войд зоною.',
      whid: 327123
    },
    {
      type: 'header',
      name: 'Друга фаза'
    },
    {
      type: 'spell',
      icon: 'spell_animarevendreth_wave',
      name: 'Wracking Pain',
      desc: 'Фронтальний клів який вішає дебаф на +50% урону по цілі (стакається).',
      whid: 329181
    },
    {
      type: 'spell',
      icon: 'spell_shadow_unholystrength',
      name: 'Hand of Destruction',
      desc: 'Притягує усіх гравців до себе і починає кастувати АОЕ, чим далі від боса — тим менший урон.',
      whid: 330627
    },
    {
      type: 'spell',
      icon: 'spell_shadow_charm',
      name: 'Command: Massacre',
      desc: 'Командує **Реморнії** літати по всій кімнаті. Летально!',
      whid: 330042
    },
    {
      type: 'spell',
      icon: 'ability_gouge',
      name: 'Carnage',
      namet: 'Реморнія',
      desc: 'Кровотеча (дот) який вішає на свого танка.',
      whid: 329875
    },
    {
      type: 'spell',
      icon: 'ability_backstab',
      name: 'Impale',
      namet: 'Реморнія',
      desc: 'Обирає 3 гравця та пролітає крізь них як голка.',
      whid: 329974
    },
    {
      type: 'header',
      name: 'Третя фаза'
    },
    {
      type: 'spell',
      icon: 'spell_shadow_soulleech_3',
      name: 'Scorn',
      desc: 'Дебаф на активного танка, б\'є не сильно але стакається.',
      whid: 332585
    },
    {
      type: 'spell',
      icon: 'sha_spell_fire_blueflamestrike_nightmare',
      name: 'Shattering Pain',
      desc: 'Комбінаційна атака з трьох ударів, на останьому бос по всьому рейду наносить урон, відкидаючи від себе (не стояти на краях платформи!)',
      whid: 332619
    },
    {
      type: 'spell',
      icon: 'spell_shadow_charm',
      name: 'Sinister Reflection',
      desc: 'На 100 енергії або __комбо__ **Реморнії** або заповнює 1/3 кімнати войд зоною.',
      whid: 333979
    },
    {
      type: 'spell',
      icon: 'spell_animarevendreth_groundstate',
      name: 'Final Finesse',
      desc: 'Обирає три людини, наносить по ним, та усім біля них високий урон, залишаючи після цього **орби**, які потрібно соукати, оскільки вони вибухають через певний час.',
      whid: 332797
    },
  ],
  attachments: [
    { type: 'video', videoId: 'Fyh54HXBaoA', name: 'Першокіл (героїк)' }
  ]
}
