export default {
  slug: 'prophet-skitra',
  parent: 'nyalotha',
  name: 'Пророк Скітра',
  hint: 'Prophet Skitra',
  short: 'Скітра',
  group: 'Vision of Destiny',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Skye' ],
  content: `
    <ul>
      <li>зміна фаз на 66% і на 33% хп боса</li>
      <li>вар'ят з дебафом вибігає з рейду</li>
      <li>на 2й фазі ставимо мітки, визначаємо справжню ілюзію</li>
      <li>танки міняються на 7-8 стаках</li>
      <li>героїзм по команді, орієнтовно на 2й перехідній фазі після визначення справжньої ілюзії</li>
    </ul>

    <h3>Фаза 1</h3>

    <ul>
      <li>всі стакаються в мілі позаду боса</li>
      <li>з міткою вибігаємо вздовж середини арени, скидаєте дебаф, вертаєтеся назад
        <ul><li>рдд можуть свічитися одразу, міліки лише після першого касту ада</li></ul>
      </li>
      <li>коли стіна копій — рутати декількох навпроти боса</li>
    </ul>

    <h3>Фаза 2</h3>

    <ul>
      <li>бос ділить рейд на дві частини з дебафама (червоним і фіолетовим)</li>
      <li>кожна частина бачить 4 ілюзії, лише одна спільна (справжня) для всього рейду</li>
      <li>потрібно знайти справжню і вбити</li>
    </ul>
  `,
  attachments: [
    { type: 'video', videoId: 'UcW52kkjcsA', name: 'Першокіл (міфік)' }
  ]
}
