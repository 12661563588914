export default {
  slug: 'oryphrion',
  parent: 'spires-of-ascension',
  name: 'Oryphrion',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Ta-na' ],
  content: `
    [p]Бій має дві фази, перша фаза триває близько 1.5 хвилини, протягом неї бос б’є нас,
    потрібно тримати дистанцію щоб не задіти одне одного __Purifying Blast__ і не стояти
    біля танка або отримаєте __Charged Stomp__.[/p]

    [p]Бос буде скидати енергію на гравців __Empyreal Ordnance__, виглядає як **синя стрілка**,
    через пару секунд лишає калюжу **Anima Field**, в ній не стоїмо і виводимо швидко боса,
    не закрийте один одному прохід, плануйте наперед як будете складати.[/p]

    [p]На другій фазі **Oryphrion** починає притягувати **кулі** енергії до себе, в цей час
    пошкодження по босу збільшене на 100%, саме тут і потрібно здавати бурсти. Якщо кулі доходять
    до боса то йде АОЕ пошкодження по всій групі, важливо ловити їх, це може робити танк під
    сейвами або вся група по 1-2 куля для дд і хіла все інше в танка, такий варіант можливий
    на тиранічних афіксах.[/p]

    [p]Пошкодження від **кулі** мають 5 ярдів радіуса дії __Anima Surge__, не хапайте разом.[/p]
  `,
  aside: [
    {
      type: 'spell',
      icon: 'spell_animabastion_missile',
      name: 'Purifying Blast',
      desc: '**Oryphrion** вистрілює в гравця, наносячи 7324 пошкоджень від Аркан магії одразу, і 2344 додатково кожні 1.5 sec. протягом 12 сек всім гравцям в радіусі 8 ярдів.',
      whid: 323195
    },
    {
      type: 'spell',
      icon: 'warrior_talent_icon_bloodandthunder',
      name: 'Charged Stomp',
      desc: '**Oryphrion** б\'є поточний таргет, наносячи 13475 пошкоджень від Аркан магії та [spell/338729/заряджає] всіх гравців в радіусі 8 ярдів, що в свою чергу зменьшує швидкість руху на 30%.',
      whid: 324608
    },
    {
      type: 'spell',
      icon: 'inv_engineering_failure-detection-pylon',
      name: 'Empyreal Ordnance',
      desc: '**Oryphrion** випускає енергію, наносить 14647 пошкоджень від Аркан магії ворогам в радіусі 10 ярдів. Залишає **Anima Field**.',
      whid: 324427
    },
    {
      type: 'spell',
      icon: 'spell_animabastion_buff',
      name: 'Anima Field',
      namet: 'калюжа',
      desc: '**Oryphrion** поглинає **Anima Field** збільшуючи на 20% нанесення пошкоджень. Бос не витрачає енергію на свої закляття коли стоїть в цій калюжі.',
      whid: 324392
    },
    {
      type: 'spell',
      icon: 'inv_gizmo_fuelcell',
      name: 'Recharge Anima',
      desc: 'Коли енергія **Oryphrion** закінчується, він починає перезарядку на протязі 20 сек, а всі **калюжі** перетворються на **кулі** що рухаються до боса.[br][br]На протяці підтримки цього закляття, бос [spell/323878/розряджений] і отримує на 100% більше шкоди.',
      whid: 324046
    },
    {
      type: 'spell',
      icon: 'spell_animabastion_orb',
      name: 'Anima Surge',
      desc: 'Доторкнувшись до **куль** відбувається детонація, наносячи 2051 пошкоджень від Аркан магії всім в радіусі 5 ярдів, і додатково 586 пошкоджень від Аркан магії щосекунди наступні 10 сек.',
      whid: 331997
    }
  ],
  attachments: [
    { type: 'video', videoId: '2hoFL3NW3HY', name: 'Аналіз М+20 від Денса (2й сезон)' },
    { type: 'video', videoId: 'roVEc0IgLbo', name: 'Аналіз М+17 від Денса' },
    { type: 'post', link: '/guides/tormented-affix', name: '10+ афікс' },
  ]
}
