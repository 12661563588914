export default {
  slug: 'hylbrande',
  parent: 'tazavesh',
  name: 'Гільбранд',
  hint: 'Hylbrande',
  image: '/img/hylbrande.jpg',
  group: { name: 'Штормгейм', hint: 'Stormheim' },
  meta: {
    description: 'Тактика бою проти Гільбранда у Тазавеші.',
    keywords: 'гільбранд, гілбранд, хільбранд, хілбранд, тактика, стратегія, механіка, гайд, бос, тазавеш, підземелля, данж, мітік, м+, тінеземлі, hylbrande, stormheim, tazavesh, market, dungeon, shadowlands'
  },
  header: { parent: true },
  sidenav: true,
  authors: [ 'Skye' ],
  content: `
    [p]Бій проти механічного врайкула [npc/175663/Гільбранда] -- охоронця артефакту древніх титанів.
    Бій має дві фази, що чергуються: на першій -- бос доступний для безпосереднього бою;
    на другій -- Гільбранд стає невразливий до пошкодженя й гравцям потрібно швидко скооперуватися
    та ввести правильний код.[/p]

    ## Фаза боса

    [p]Бійці ближнього бою мають не потрапляти під __Titanic Crash__ та __Shearing Swings__, які діють
    по площі перед босом.[/p]

    [p]Гільбранд буде застосовувати __промінь__ на одного з гравців, відмітивши його жовтою стрілкою.
    Протягом 6 сек гравець має рухатися, залишаючи після себе калюжу. Рекомендується розкладати калюжі
    під стінки арени, щоб вони не заважали на наступній фазі.[/p]

    [p]Гільбранд буде прикликати [npc/176551/посіпак] парами. Вони __захищають боса__, зменшуючи
    отримуване ним пошкодження на 25%. Коли починається фаза консолі, вони можуть __захищати панель__,
    блокуючи взаємодію з нею. Тому, намагайтеся їх вбивати якомога швидше.[/p]

    ## Фаза консолі

    [p]Фаза починається кожен раз, коли Гільбранд накопичує 100% енергії, яку він отримує з плином
    часу (приблизно 5 енергії кожні 3 сек). Бос починає бій з 40%, тому перший раз ця фаза починається
    приблизно на 36-й секунді бою.[/p]

    [p]Гільбранд біжить до середини арени та береться за __очищення__. Всі живі посіпаки отримують
    [spell/355071/імунітет] до всіх форм конролю й вони будуть __захищати панелі__, тому їх потрібно
    негайно добити.[/p]

    [p]Консоль та панелі стануть доступні для взаємодії, також з'являться чотири кольорові кулі
    навколо центра арени. Вам потрібно підняти кулі та активувати панелі згідно коду на консолі.[/p]

    ### Кооперація гравців

    [p][image/hylbrande-1.jpg][/p]

    [p]Один гравець взаємодіє з консоллю. Це можна зробити лише один раз за фазу, після чого вона
    буде неактивна. Код бачить лише гравець який безпосередньо взаємодіє з консоллю. Він має
    сповістити інших про комбінацію якомога швидше. Для цього можна пронумерувати панелі й передати
    код послідовністю кольорів. Позиція кольору в коді визначає позицію панелі. Так, на малюнку,
    наведений приклад з кодом "фіолетовий, жовтий, блакитний, червоний".[/p]

    [p]Інші чотири гравці мають занести кольорові кулі з центральної частини арени до панелей.
    Порядок активації панелей неважливий, важливо лише активувати панель правильним кольором.
    Активація панелі невірним кольором призводить до [spell/356796/помилки].[/p]

    [p]Якщо код введено вірно -- Гільбранд припинить __очищення__ й почнеться перша фаза.
    Рівень його енергії обнулиться й у гравців буде приблизно 60 сек до початку фази консолі
    знову.[/p]
  `,
  aside: [
    {
      type: 'image',
      src: '/img/hylbrande.jpg'
    },
    {
      type: 'header',
      name: 'Гільбранд'
    },
    {
      type: 'spell',
      whid: 347094,
      icon: 'ability_warrior_shockwave',
      name: 'Titanic Crash',
      desc: `
        Гільбранд гупає своїм мечем по землі, завдаючи [25780/physical] всім гравцям перед собою
        та [приголомшує//Stun] їх на 4 сек.
      `
    },
    {
      type: 'spell',
      whid: 346116,
      icon: 'warrior_talent_icon_igniteweapon',
      name: 'Shearing Swings',
      desc: `
        Гільбранд двічі розмахує мечем, щоразу завдаючи [21940/physical] всім гравцям пред собою.
      `
    },
    {
      type: 'spell',
      whid: 346960,
      icon: 'ability_priest_flashoflight',
      audio: 'https://wow.zamimg.com/sound-ids/live/enus/249/4067321/VO_91_Hylbrande_208450.ogg',
      name: 'Purged by Fire',
      namet: 'промінь',
      desc: `
        Гільбранд наказує захисній [npc/175667/турелі] фокусувати інтенсивну енергію по ногах гравця,
        завдаючи [8228/holy] кожні 0.5 сек гравцям в зоні дії.
        [br][br]
        Промінь лишає [spell/346961/калюжу], яка завдає [12067/holy] кожні 1.5 сек протягом 12 сек при контакті.
      `
    },
    {
      type: 'spell',
      whid: 346766,
      icon: 'spell_holy_purify',
      audio: 'https://wow.zamimg.com/sound-ids/live/enus/250/4067322/VO_91_Hylbrande_208442.ogg',
      name: 'Sanitizing Cycle',
      namet: 'очищення',
      desc: `
        Гільбранд спрямовує потік енергії титанів на очищення зали від всіх несанкціонованих форм життя,
        завдаючи [16455/holy] кожні 1.5 сек всім гравцям при дотику до утвореної [spell/346828/калюжі].
        [br][br]
        Підтримуючи це закляття, Гільбранд поступово заливає всю арену.
      `
    },
    {
      type: 'header',
      name: 'Vault Purifier',
      namet: 'посіпака'
    },
    {
      type: 'spell',
      whid: 346980,
      icon: 'ability_paladin_infusionoflight',
      name: 'Empowered Defense',
      namet: 'захист боса',
      desc: `
        Посіпака спрямовує енергію в Гільбранда, зменьшуючи отримуване ним пошкодження на 25%.
      `
    },
    {
      type: 'spell',
      whid: 347958,
      icon: 'ability_priest_reflectiveshield',
      name: 'Keeper\'s Protection',
      namet: 'захист панелі',
      desc: `
        Посіпака захищає панель щитом, блокуючи взаємодію.
      `
    },
    {
      type: 'spell',
      whid: 352347,
      icon: 'spell_paladin_lightofdawn',
      name: 'Valorous Bolt',
      desc: `
        Посіпака знищує ціль енергією, завдаючи [13713/holy].
      `
    }
  ]
}
