export default {
  slug: 'sanguine-depths',
  parent: 'dungeons',
  name: 'Криваві глибини',
  hint: 'Sanguine Depths',
  image: '/img/sanguine-depths.jpg',
  group: 'Тінеземлі',
  meta: {
    description: 'Підземелля Криваві глибини.',
    keywords: 'криваві, глибини, сангвін, депс, діпс, підземелля, данж, мітік, м+, тінеземлі, sanguine, depths, sd, dungeon, shadowlands'
  },
  header: { parent: true },
  archive: true
}
