export default {
  slug: 'ilgynoth',
  parent: 'nyalotha',
  name: 'Іл\'ґінот',
  hint: 'Il\'gynoth',
  group: 'Gift of Flesh',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Skye' ],
  content: `
    <ul>
      <li>дві фази чередуються: <em>фаза боса</em> (завершується коли бос набирає 100% енергії), <em>фаза органів</em> (завершується коли помирає один орган)</li>
      <li>каплі сповільнювати та кайтити через міліків для кліву</li>
      <li>героїзм на останній фазі боса (після вбивства третього органа)</li>
    </ul>

    <h3>Фаза боса</h3>

    <ul>
      <li>якщо у вас під ногами промінь — кайтіть із рейду на край арени</li>
      <li>танки стоять окремо від рейда, міняються на 2 стаках</li>
      <li>у сектор танків ніхто ніколи не забігає</li>
      <li>якщо не пройти фазу швидко то буде контроль
        <ul><li>спред, сповільнювати й бити законтролених; нів'якому разі не наближатися до законтролених</li></ul>
      </li>
    </ul>

    <h3>Фаза органів</h3>

    <ul>
      <li>всім органам треба збивати касти</li>
      <li>вбивається один орган за одну фазу</li>
      <li>спред з червоними колама; нормально якщо ви стоїте в одиному-двох колах</li>
    </ul>
  `,
  attachments: [
    { type: 'video', videoId: 'KtjO_8ImPNI', name: 'Першокіл (героїк)' }
  ]
}
