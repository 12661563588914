export default {
  slug: 'affront-of-challengers',
  parent: 'theater-of-pain',
  name: 'Affront of Challengers',
  short: 'Challengers',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Johnth' ],
  content: `
    [p]Три гладіатори: **Dessia the Decapitator** б’є в ближньому бою, **Paceran the Virulent** та
    **Sathel the Accursed** -- кастери.[/p]

    [p]Кожен має Enrage механіку на 40%, тому їх рекомендовано фокусити і вбивати по черзі.
    Рекомендовано першими зливати кастерів (оптимально **Sathel** -> **Paceran** -> **Dessia**).[/p]

    ## [icon/skull] DESSIA [icon/skull]

    [p]**Dessia** ламає танка своїм __Slam__, вішає дебаф на -30% відхілу __Mortal Strike__.[/p]

    [p]На 40% здоров’я входить в __Enrage__ (щит 30% ХП + фіксейт на випадкового гравця). Ціль фіксейту
    тікає, решта збивають щит.[/p]

    ## [icon/skull] PACERAN [icon/skull]

    [p]**Paceran** кастує в гравців __Plague Bolt__ (інтераптимо), кидає калюжі __Noxious Spores__
    (наносять дамаг + сповільнюють).[/p]

    [p]На 40% здоров’я вішає на всіх гравців хворобу __Genetic Alteration__ і вони починаються розливати
    під собою калюжі __Noxious Spores__. Диспелимо хворобу та/або кладемо калюжі подалі від основного
    кемпу/босів.[/p]

    ## [icon/skull] SATHEL [icon/skull]

    [p]**Sathel** кастує в гравців __Necromantic Bolt__ (інтераптимо), кидає дебаф __Searing Death__
    (не стоїмо в купі). Також вішає на одного з інших босів баф __Spectral Transference__, який хілить його
    від демеджу **Sathel** (магічний ефект, диспелимо).[/p]

    [p]На 40% здоров’я отримує __One With Death__, абсорб щит на 30% максимального здоров’я і імунітет
    до інтераптів.[/p]
  `,
  aside: [
    {
      type: 'header',
      name: 'Dessia'
    },
    {
      type: 'spell',
      icon: 'ability_warrior_decisivestrike',
      name: 'Slam',
      desc: 'Б’є поточну ціль на 17576 фізичних пошкоджень.',
      whid: 320063
    },
    {
      type: 'spell',
      icon: 'ability_warrior_savageblow',
      name: 'Mortal Strike',
      desc: 'Б’є поточну ціль на 8788 фізичних пошкоджень і зменшує вхідний хіл на 30% на 10 сек.',
      whid: 320069
    },
    {
      type: 'spell',
      icon: 'ability_druid_enrage',
      name: 'Enrage',
      desc: '**Dessia** отримує абсорб щит розміром 30% від максимального здоров’я (тривалістю до 30 секунд), -15% швидкості пересування і фіксується (__Fixate__) на рандомному гравці.',
      whid: 324085
    },
    {
      type: 'spell',
      icon: 'ability_fixated_state_green',
      name: 'Fixate',
      desc: 'Фіксується на цілі, переслідуючи її та ігноруючи всіх інших.',
      whid: 326892
    },
    {
      type: 'header',
      name: 'Paceran'
    },
    {
      type: 'spell',
      icon: 'spell_deathknight_necroticplague',
      name: 'Plague Bolt',
      desc: 'Наносить 1465 пошкоджень чумою випадковому гравцю.',
      whid: 320120
    },
    {
      type: 'spell',
      icon: 'druid_ability_wildmushroom_a',
      name: 'Noxious Spores',
      desc: 'Викликає калюжу __Noxious Spores__ на місці (під ногами) цілі на 10 хв. Калюжа наносить 2929 пошкоджень чумою кожні 1.5 сек усім гравцям, що стоять у ній, а також зменшує швидкість пересування на 25%.',
      whid: 320180
    },
    {
      type: 'spell',
      icon: 'spell_deathknight_necroticplague',
      name: 'Genetic Alteration',
      desc: 'Жбурляє в усіх гравців флакони з чумою, яка викликає генетичні мутації. Жертви залишають під собою зони __Noxious Spores__ кожні 8 сек, поки знаходяться під ефектом чуми.',
      whid: 320248
    },
    {
      type: 'header',
      name: 'Sathel'
    },
    {
      type: 'spell',
      icon: 'spell_necro_voodooblast',
      name: 'Necromantic Bolt',
      desc: 'Наносить випадковому гравцю 1465 пошкоджень тінню.',
      whid: 320300
    },
    {
      type: 'spell',
      icon: 'spell_necro_deathrift',
      name: 'Searing Death',
      desc: 'Кидає на ціль ефект __Searing Death__, який наносить 2929 пошкоджень тінню усім гравцям в радіусі 3 метрів кожні 3 сек впродовж 12 сек.',
      whid: 333231
    },
    {
      type: 'spell',
      icon: 'spell_necro_deathall',
      name: 'Spectral Transference',
      desc: 'Кидає на випадкового союзника баф __Spectral Transference__. Після цього демедж **Sathel** також хілитиме ціль з бафом.',
      whid: 320272
    },
    {
      type: 'spell',
      icon: 'spell_necro_undyingrelentlessness',
      name: 'One With Death',
      desc: '**Sathel** стає один цілим зі смертю, отримує абсорб щит на 30% максимального здоров’я та імунітет до інтераптів (тривалістю до 30 сек або поки не пробитий щит).',
      whid: 320293
    },
  ],
  attachments: [
    { type: 'video', videoId: 'JBvx0rVvtGw', name: 'Аналіз М+20 від Денса (2й сезон)' },
    { type: 'video', videoId: '8PP5eIgvq44', name: 'Аналіз М+17 від Денса' },
    { type: 'post', link: '/guides/tormented-affix', name: '10+ афікс' },
  ]
}
