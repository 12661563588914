export default {
  slug: 'kurog-grimtotem',
  parent: 'vault-of-the-incarnates',
  name: 'Куроґ Зловісний Тотем',
  hint: 'Kurog Grimtotem',
  image: '/img/kurog-grimtotem.jpg',
  meta: {
    description: 'Тактика бою проти Куроґа Зловісного Тотема у Сховищі втілень.',
    keywords: 'куроґ, курог, зловісний, тотем, тактика, стратегія, механіка, гайд, бос, сховище втілень, kurog, grimtotem, vault of the incarnates'
  },
  header: { parent: true },
  sidenav: true,
  authors: [ 'Semerkhet' ],
  contentrows: [
    {
      content: `
        ## Загальна інформація

        [p]Розумно розташуйте його під час бою, оскільки перебування поблизу вівтаря
        дає йому дедалі більше влади над цим елементом. При рівні енергії 100 буде
        викликано два елемента залежно від того, які елементи він вже зарядив.[/p]

        [p]Після того, як він покликав кожен елемент, він буде наділений усіма їхніми
        силами, завдаючи шкоди по всьому рейду, поки не буде знищений.[/p]

        [p]Heroism/Bloodlust можна дати на третій фазі.[/p]

        ## Вміння боса

        ### Фаза 1

        [p]Бос отримує нові здібності, коли знаходиться біля вівтаря стихій.[/p]

        [ul]
          [li]__Primal Shift__ -- під час переміщення біля вівтаря завдає шкоди рейду
          та збільшує шкоду, яку від __Primal Shift__, протягом 20 секунд, стакається.[/li]
          [li]__Sundering Strike__ -- фронтальний конус на активному танку, відкидає
          назад і вішає накопичувальну кровотечу.[/li]
          [li]__Elemental Surge__ -- якщо стоїть в центрі всіх чотирьох вівтарів,
          бос пульсує хвилями, збільшуючи з часом шкоду по рейду.[/li]
          [li]__Elemental Dominance__ -- перебуваючи біля вівтаря, шкодить рейду
          імпульсами, ефект стакається.[/li]
        [/ul]
      `,
      aside: [
        {
          type: 'image',
          src: '/img/kurog-grimtotem.jpg'
        },
        {
          type: 'header',
          name: 'Куроґ Зловісний Тотем'
        },
        {
          type: 'spell',
          whid: 374861,
          icon: 'spell_fire_elementaldevastation',
          name: 'Primal Shift',
          desc: `
            Куроґ налаштовується на первісний вівтар, завдає 50 тис стихійних пошкоджень всім
            гравцям та накладає __Primal Break__.
          `
        },
        {
          type: 'spell',
          whid: 374864,
          icon: 'spell_fire_masterofelements',
          name: 'Primal Break',
          desc: `
            Збільшує пошкодження від __Primal Shift__ на 300% на 20 сек. Ефект складається.
          `
        },
        {
          type: 'spell',
          whid: 390548,
          icon: 'spell_holy_fistofjustice',
          name: 'Sundering Strike',
          desc: `
            Куроґ вивільняє атаку по своїй поточній цілі, завдає [433649/physical] всім гравцям
            у фронтальному конусі та відкидає їх. Додатково, гравці отримують [38547/physical]
            щосекунди протягом 35 сек. Ефект накладається.
          `
        },
        {
          type: 'spell',
          whid: 371971,
          icon: 'spell_fire_masterofelements',
          name: 'Elemental Surge',
          desc: `
            Куроґа періодично охоплює сила всіх чотирьох вівтарів поки він знаходиться в центрі
            зали, завдає 10 тис стихійних пошкоджень всім гравцям кожні 0.5 сек. Ефект
            складається.
          `
        },
      ]
    },
    {
      content: `
        ### Altar of Flame

        [ul sparse]
          [li]Стріляє на гравців магмою, залишаючи войдзону. Краще стояти біля стіни.[/li]
          [li]Поширюється хвиля лави, її можна уникнути.[/li]
          [li]Позначає гравців дебафом __Searing Carnage__, скидаючи їм під ноги войдзони
          через 5 секунд, які через кілька секунд вибухають. Треба рейду ходити разом.[/li]
        [/ul]
      `,
      aside: [
        {
          type: 'spell',
          whid: 382563,
          icon: 'ability_rhyolith_magmaflow_wave',
          name: 'Magma Burst',
          desc: `
            Куроґ метає магму в випадкових гравців, завдає [80306/fire] та спричиняє утворення
            __Magma Pool__ у місцях влучань.
          `
        },
        {
          type: 'spell',
          whid: 374554,
          icon: 'ability_rhyolith_lavapool',
          name: 'Magma Pool',
          desc: `
            Розплавлена магма завдає [144550/fire] щосекунди гравцям які стоять в ній.
          `
        },
        {
          type: 'spell',
          whid: 374022,
          icon: 'spell_fire_moltenblood',
          name: 'Searing Carnage',
          desc: `
            Наповнює гравців вогнем, які в свою чергу відмічають свою поточну позицію
            щосекунди протягом 5 сек. Відмічене місце вибухає через 2 сек, завдає
            [598754/fire] всім гравцям неподалік.
          `
        },
        {
          type: 'spell',
          whid: 373329,
          icon: 'ability_rhyolith_magmaflow_wave',
          name: 'Molten Rupture',
          desc: `
            Куроґ вивільняє хвилі лави, завдає [240916/fire] гравцям на шляху.
          `
        },
      ]
    },
    {
      content: `
        ### Altar of Frost

        [ul sparse]
          [li]Посилає хвилі заморожених куль, які відкидають гравців і приголомшують гравців
          на своєму шляху.[/li]
          [li]Націлюється в гравця __Ice__, завдаючи шкоди (треба ділити гравцями поблизу) і
          застосовуючи __Frost Bite__ (за двох стаків __Frozen Solid__ заморожує на 10
          секунд).[/li]
          [li]Дві групи поділяють кола __Frost Bite__ від гравців, щоб розділити пошкодження
          (2 розділення = стан).[/li]
        [/ul]
      `,
      aside: [
        {
          type: 'spell',
          whid: 372456,
          icon: 'spell_frost_glacier',
          name: 'Absolute Zero',
          desc: `
            Метає уламок льоду в гравця, завдає [963665/frost] рівномірно розподіленого
            між гравцями в радіусі 8 метрів.
          `
        },
        {
          type: 'spell',
          whid: 372514,
          icon: 'ability_mage_coldasice',
          name: 'Frost Bite',
          desc: `
            Вплив __Absolute Zero__ застужує гравців до кісток. При досягненні 2 стаків,
            гравець замерзає [spell/391446/Frozen Solid], при цьому отримує [48183/frost]
            щосекунди протягом 10 сек.
          `
        },
        {
          type: 'spell',
          whid: 373678,
          icon: 'spell_frost_arcticwinds',
          name: 'Biting Chill',
          desc: `
            Куроґ окутує всіх гравців кусливим холодом, завдає [16061/frost] щосекунди
            протягом 10 сек.
          `
        },
        {
          type: 'spell',
          whid: 391019,
          icon: 'spell_frost_ring-of-frost',
          name: 'Frigid Torrent',
          desc: `
            Створює хвилі крижаних куль кожні 2 сек протягом 4 сек, відкидає гравців
            у радіусі 4 метрів. Кожна куля завдає [240916/frost] будь-якому гравцю на
            шляху та [приголомшує//Stun] його на 6 сек.
          `
        },
      ]
    },
    {
      content: `
        ### Altar of Earth

        [ul sparse]
          [li]Від боса йде хвиля, що завдає шкоди.[/li]
          [li]Негативний ефект поглинання лікування кидається на гравців. Треба прохілити.[/li]
          [li]Земля розколюється, відкидаючи гравців поблизу виверження та збільшуючи отриману
          ними фізичну шкоду на 6% протягом 10 секунд.[/li]
          [li]Відходіть від боса під час його удару.[/li]
          [li]Ухиляйтеся від гігантських вихорів і вбивайте мобів, які з’являються з них.[/li]
        [/ul]
      `,
      aside: [
        {
          type: 'spell',
          whid: 395894,
          icon: 'spell_shaman_earthquake',
          name: 'Erupting Bedrock',
          desc: `
            Утворює тектонічну хвилю крізь землю, завдає [385466/nature] гравцям на шляху.
          `
        },
        {
          type: 'spell',
          whid: 374705,
          icon: 'spell_nature_earthquake',
          name: 'Seismic Rupture',
          desc: `
            Вивільняє земледроби з-під землі, завдає [289100/nature] гравцям поряд із
            виверженням та відштовхує їх.
          `
        },
        {
          type: 'spell',
          whid: 391055,
          icon: 'inv_elementalearth2',
          name: 'Enveloping Earth',
          desc: `
            Куроґ оточує декількох гравців землею, спричиняє поглинання 430 тис
            їхнього подального зцілення.
          `
        },
      ]
    },
    {
      content: `
        ### Altar of Storm

        [ul sparse]
          [li]Кілька гравців вибухають дебафом через 5 секунд, завдаючи шкоди гравцям поруч.[/li]
          [li]Кидає дебафи __Lightning__ на гравців. Коли вони закінчується, то збільшують
          отриману шкоду від природи та завдають шкоди від природи найближчому гравцеві на
          8 секунд, стакається.[/li]
          [li]Гравці з дебаффом Lightning стоять разом з 1 гравцем без дебаффу.[/li]
          [li]Блискавка вдаряє в землю, якщо не встати під удар -- завдає шкоди всьому рейду.[/li]
          [li]Вийти з рейду з гігантським вибуховим колом.[/li]
        [/ul]
      `,
      aside: [
        {
          type: 'spell',
          whid: 374217,
          icon: 'ability_vehicle_electrocharge',
          name: 'Thunder Strike',
          desc: `
            Прикликає грозу, блискавки завдають [112428/nature] гравцям в місцях ударів.
            [br][br]
            Якщо блискавка не зачепила жодного гравця, завадає [291999/nature] всім
            гравцям.
          `
        },
        {
          type: 'spell',
          whid: 390920,
          icon: 'spell_nature_unrelentingstorm',
          name: 'Shocking Burst',
          desc: `
            Куроґ заряджає декількох гравців протягом 5 сек. По завершенню, вони
            завдають [224855/nature] всім гравцям навколо себе.
          `
        },
        {
          type: 'spell',
          whid: 373487,
          icon: 'inv_misc_stormlordsfavor',
          name: 'Lightning Crash',
          desc: `
            Викликає блискавку, застосовує аури до випадкових цілей. Після завершення,
            збільшує вхідну шкоду магією природи на 30% та завдає [16061/nature]
            щосекунди найближчому гравцю протягом 8 сек. Ефект складається.
          `
        },
      ]
    },
    {
      content: `
        [p][icon/skull] Міфік: Викликає мобів, коли стоїть поруч із вівтарями стихій.[/p]

        [ul]
          [li]__Flame__ -- фронтальний конус полум’я, створює басейни магми, що
          породжують Hellion.[/li]
          [li]__Frost__ -- фронтальна шкода з уповільненням на 75%, сповільнює та
          заморожує гравців що стоять занадто близько один до одного.[/li]
          [li]__Earth__ -- поглинання лікування танка.[/li]
          [li]__Storm__ -- фронтальний електричний конус, який ланцюгом кидає блискавку
          та скидає сфери, яких потрібно торкнутися, щоб знищити.[/li]
        [/ul]

        ### Фаза 2

        [p]Бос має імунітет, викликає елементалів, на основі яких змінює вміння,
        з яких він отримав силу. Елементалі завдають шкоду рейду.[/p]

        [ul]
          [li]__Flame__ -- у випадкових місцях викидає басейни лави, які призводять
          до переривання. Спавнить моба Hellion, що завдає шкоди від вогню випадковим
          гравцям.[/li]
          [li]__Frost__ -- завдає рейдової шкоди від вибуху +кастує __Frost Binds__ -- ДоТ,
          якщо її не перервати. Час від часу викликає штормовий вітер, завдаючи шкоди
          гравцям на відстані більше 10 метрів.[/li]
          [li]__Earth__ -- послідовні атаки ближнього бою дебафають танк, збільшуючи
          отриману фізичну шкоду. Кидає на гравців дебаф, який залишає войдзони
          землетрусу.[/li]
          [li]__Storm__ -- випадково телепортується до гравця та завдає ланцюгової
          шкоди йому та гравцям поблизу.[/li]
        [/ul]

        ### Фаза 3

        [p]Бос отримує всі стихійні здібності одночасно, завдаючи рейдової шкоди,
        стакається.[/p]

        ### ДЛЯ ТАНКІВ

        [p]Моб від __Earth__ має танківський удар, змінювати активного танка за
        потреби.[/p]

        ### ДЛЯ ЦІЛИТЕЛІВ

        [p]Знімати стан від __Frigid Torrent__.[/p]

        ### ДЛЯ ДПС

        [p]Переривати __Frost Bolt__ та __Flame Bolt__.[/p]

        [p][image/kurog-grimtotem-1.jpg][/p]

        [p][image/kurog-grimtotem-2.jpg][/p]
      `
    }
  ]
}
