export default {
  slug: 'fatescribe-roh-kalo',
  parent: 'sanctum-of-domination',
  name: 'Fatescribe Roh-Kalo',
  short: 'Roh-Kalo',
  image: '/img/fatescribe-roh-kalo.jpg',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Dancecaptain (Makhabunda)' ],
  attachments: [
    { type: 'video', videoId: 'ZbPc6h5dWoY', name: 'Першокіл (героїк)' },
    { type: 'video', videoId: 'CWzX7c3LeUs', name: 'Відеотактика (англійська)' }
  ],
  aside: [
    {
      type: 'header',
      name: 'Перша фаза'
    },
    {
      type: 'spell',
      icon: 'spell_animamaw_buff',
      name: 'Invoke Destiny',
      namet: '(до уваги танків)',
      desc: 'Вішає на танка мітку, яка через 8 секунд вибухне й завдасть 27052 Тіньових пошкоджень. Гравці, які стоять дальше від мітки, отримують менше пошкоджень.',
      whid: 351680
    },
    {
      type: 'spell',
      icon: 'spell_arcane_focusedpower',
      name: 'Shade of Destiny',
      desc: 'Ад, який фіксується на гравці, збільшуючи вхідні пошкодження на 75% допоки живий. Якщо ад досягає гравця, або наберає фул-стак енергії, він підриває рейд завдаючи 7514 Тіньових пошкоджень всім гравцям кожні 2 секунди впродовж 10 секунд.',
      whid: 353432
    },
    {
      type: 'spell',
      icon: 'spell_animamaw_debuff',
      name: 'Anomalous Blast',
      desc: 'Ад, який кастує інтераптебильний спел, який завдає 9919 Тіньового пошкодження найближчій цілі.',
      whid: 353398
    },
    {
      type: 'spell',
      icon: 'spell_shadow_shadowembrace',
      name: 'Diviner`s Probe',
      namet: '(до уваги танків)',
      desc: 'Кожного разу, коли бос атакує нову ціль він вішає декілька стаків __Diviner\'s Probe__, зникнення якого завдає 16532 Тіньових пошкоджень гравцю.',
      whid: 353603
    },
    {
      type: 'spell',
      icon: 'spell_animamaw_debuff',
      name: 'Twist Fate',
      desc: 'Дот, який вішається на декількох гравців, який завдає 5410 Тіньових пошкоджень кожні 2 секунди впродовж 6 секунд.',
      whid: 353931
    },
    {
      type: 'spell',
      icon: 'spell_animamaw_beam',
      name: 'Fated Conjunction',
      namet: '(смертельно)',
      desc: 'Бос просякає кімнату декількома променями, які вибухнуть через 6 секунд і завдасть 25520 Тіньового пошкодження гравцям, які попали під його траєкторію. Окрім цього, на гравця вішається дебаф, який збільшує вхідне пошкодження від відповідної абілки на 50% впродовж 1 хвилини.',
      whid: 350355
    },
    {
      type: 'spell',
      icon: 'spell_animamaw_nova',
      name: 'Call of Eternity',
      desc: 'Бос вішає дебаф на гравця, який завдає 4509 Тіньового пошкодження кожні 2 секунди впродовж 8 секунд. По завершенню дебаф вибухає завдаючи всім гравцям в 20 ярдах 24046 Тіньового пошкодження.',
      whid: 350819
    },
    {
      type: 'spell',
      icon: 'spell_animamaw_nova',
      name: 'Echo of Eternity',
      namet: '(героїк)',
      desc: 'Воїд-зони, які з\'являються під час файту й по завершенню Call of Eternity вибухають завдаючи 33100 Тіньового пошкодження гравцям в 20 ярдах.',
      whid: 350826
    },
    {
      type: 'header',
      name: 'Друга фаза'
    },
    {
      type: 'spell',
      icon: 'trade_archaeology_vrykul_runestick',
      name: 'Realignment: Clockwise',
      desc: 'За годинниковою стрілкою, платформа рухається, якщо на ній знаходиться непарна кількість гравців на платформі.',
      whid: 353149
    },
    {
      type: 'spell',
      icon: 'trade_archaeology_vrykul_runestick',
      name: 'Realignment: Counterclockwise',
      desc: 'Проти годинникової стрілки, платформа рухається, якщо на ній знаходиться парна кількість гравців.',
      whid: 353150
    },
    {
      type: 'spell',
      icon: 'ability_vehicle_sonicshockwave',
      name: 'Realing Fate',
      desc: 'Бос шилдує себе на 99% зменшених вхідних пошкоджень впродовж 40 секунд. Якщо гравці не встигли активувати руни за відведений час, щит вибухає вайпаючи весь рейд.',
      whid: 351969
    },
    {
      type: 'spell',
      icon: 'spell_animamaw_orb',
      name: 'Unstable Accretion',
      namet: '(до уваги хілерів)',
      desc: 'Допоки щит активний, бос завдає всім гравцям 4208 Тіньового пошкодження кожні 3 секунди.',
      whid: 354966
    },
    {
      type: 'spell',
      icon: 'spell_animamaw_buff',
      name: 'Monstrosity\'s Boon',
      desc: 'Ад, який посилює себе на 10% кожні 8 секунд.',
      whid: 357141
    },
    {
      type: 'spell',
      icon: 'spell_misc_emotionafraid',
      name: 'Despair',
      desc: 'Іноді кастує інтераптебильний спел, який завдає 13526 Тіньового пошкодження всім гравцям у радіусі 40 ярдів кожні 2 секунди і зменшуючи швидкість пересування гравців на 33% впродовж 6 секунд.',
      whid: 357144
    },
    {
      type: 'spell',
      icon: 'spell_animamaw_orb',
      name: 'Fate Fragment',
      desc: 'Бос періодично обирає долю для гравця, завдаючи йому 21041 Тіньових пошкоджень і зменшуючи його швидкість руху на 33% впродовж 7 секунд.',
      whid: 353162
    },
  ]
}
