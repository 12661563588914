export default {
  slug: 'ra-den',
  parent: 'nyalotha',
  name: 'Ра-ден',
  hint: 'Ra-den',
  group: 'Halls of Devotion',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Skye' ],
  content: `
    <ul>
      <li>бій у дві фази, друга фаза починається на 40% хп боса</li>
      <li>три групи: дві електричні (по три вар'ята), одна волейбольна (п'ять вар'ятів)</li>
      <li>три мітки: дві для електричних груп, одна для боса і рейда на електричній фазі</li>
      <li>танки міняються на 2 стаках</li>
      <li>героїзм на останній фазі</li>
    </ul>

    <h3>Фаза 1</h3>

    <ul>
      <li>час від часу бос притягує до себе дві кільки з кутків арени, потрібно вбити одну; кожен наступний раз потрібно вбивати іншу</li>
      <li>перший вар'ят, якого вдарить блискавка, біжить в мітку до електричної групи</li>
      <li>воїд зона яка крутиться навкого вар'ята має сокатися всім рейдом; а ти, вар'ят-епіцентр, стій і не рухайся! :)</li>
      <li>ада напулюємо на танків; фокусим, кліваєм боса</li>
    </ul>

    <h3>Фаза 2</h3>

    <ul>
      <li>тримати танків на половину хп</li>
      <li>розбігатися з електричними ланцюгами над головою</li>
    </ul>
  `,
  attachments: [
    { type: 'video', videoId: 'WigAud_YdBA', name: 'Першокіл (героїк)' }
  ]
}
