export default {
  slug: 'surgeon-stitchflesh',
  parent: 'the-necrotic-wake',
  name: 'Surgeon Stitchflesh',
  short: 'Stitchflesh',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Ta-na' ],
  content: `
    <p>Бій має дві фази. Перша це бій з **Stitchflesh Creation's**. Друга, відповідно, з самим босом.
    Хитрість в тому що на першій фазі бос не активний, його потрібно стягнути ланцюгом — __Meat Hook__,
    при провалі бій затягується.</p>

    <p>**Творіння** тримаємо по прямій від боса, щоб зручно стягувати __ланцюгом__, періодично бос буде
    кидати __Embalming Ichor__, важливо не стояти між **творінням** і босом, щоб гравцям не довелось
    стояти в __калюжі__. Коли в мілі біля **ада** буде __калюжа__ просто відведіть, ренж __ланцюга__
    великий.</p>

    <p>Першим __ланцюгом__ стягуємо боса, другим збиваємо __Morbid Fixation__, а третій не залежно
    від того де стоїть бос заносимо так як і перший, тут важливо танку правильно позиціонувати
    **творіння** щоб інші могли занести, далі все йде по колу.</p>

    <p>Здавати героїзм і бурсти краще на другій фазі, якщо тиранічний афікс рекомендую завжди бити
    **творіння** інакше їх буде багато і танк помре.</p>
  `,
  aside: [
    {
      type: 'spell',
      icon: 'spell_necro_conclave',
      name: 'Noxious Fog',
      desc: 'Платформа боса оточена випаровуваннями, які захищають його від будь-якої шкоди.<br><br>Гравці отримують 5869 пошкоджень щосекунди при контакті.',
      whid: 327100
    },
    {
      type: 'header',
      name: 'Surgeon Stitchflesh',
      namet: 'бос'
    },
    {
      type: 'spell',
      icon: 'ability_skeer_bloodletting',
      name: 'Stitchneedle',
      desc: 'Наносить 2197 фізичної шкоди і змушує ціль кровоточити протягом 10 сек, наносячи 439 фіз. шкоди щосекунди.',
      whid: 320200
    },
    {
      type: 'spell',
      icon: 'inv_archaeology_orcclans_jareye',
      name: 'Embalming Ichor',
      desc: 'Кидає склянку отрути наносячи 4393 пошкоджень від чуми всім гравцям в радіусі 4,5 метрів і створює __калюжу__.',
      whid: 327664
    },
    {
      type: 'spell',
      icon: 'inv_knife_1h_maldraxxusquest_b_02_blue',
      name: 'Sever Flesh',
      desc: 'Наносить 11718 фіз. пошкоджень поточній цілі.',
      whid: 334488
    },
    {
      type: 'spell',
      icon: 'ability_rogue_bloodyeye',
      name: 'Morbid Fixation',
      desc: 'Фіксується на цілі протягом 8 сек, збільшуючи пошкодження від боса на 100%, але зменшуючи його швидкість руху на 50%.',
      whid: 338606
    },
    {
      type: 'header',
      name: 'Stitchflesh\'s Creation',
      namet: 'творіння'
    },
    {
      type: 'spell',
      icon: 'spell_deathknight_butcher2',
      name: 'Mutlilate',
      desc: 'Наносить 14647 фіз. шкоди.',
      whid: 320376
    },
    {
      type: 'spell',
      icon: 'ability_rogue_grapplinghook',
      name: 'Meat Hook',
      desc: 'Наносить 11718 фіз. шкоди першій цілі в яку потрапить і притягує до себе.',
      whid: 320208
    },
    {
      type: 'spell',
      icon: 'inv_shoulder_leather_maldraxxus_d_01',
      name: 'Festering Rot',
      desc: 'Творіння покрите гниючой рідиною, наносить 59 пошкоджень від чуми кожні 3 сек.',
      whid: 348170
    },
  ],
  attachments: [
    { type: 'video', videoId: 'Uj7gFqzCAS0', name: 'Аналіз М+20 від Денса (2й сезон)' },
    { type: 'video', videoId: 'LRwEfMAXtCg', name: 'Аналіз М+16 від Денса' },
    { type: 'post', link: '/guides/tormented-affix', name: '10+ афікс' },
  ]
}
