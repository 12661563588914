export default {
  slug: 'tazavesh',
  parent: 'dungeons',
  name: 'Тазавеш, прихований ринок',
  hint: 'Tazavesh, the Veiled Market',
  short: 'Тазавеш',
  image: '/img/tazavesh.jpg',
  group: 'Тінеземлі',
  meta: {
    description: 'Підземелля Тазавеш, прихований ринок.',
    keywords: 'тазавеш, ринок, базар, маркет, підземелля, данж, мітік, м+, тінеземлі, tazavesh, market, dungeon, shadowlands'
  },
  header: { parent: true },
  archive: true
}
