export default {
  slug: 'hungering-destroyer',
  parent: 'castle-nathria',
  name: 'Hungering Destroyer',
  group: 'The Leeching Vaults',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Ta-na' ],
  content: `
    [ul]
      [li]Гера з пула[/li]
      [li][link/https://wago.io/pRjJp9JLw/Аура з мітками][/li]
    [/ul]

    ## Коротко

    [p]Головна механіка __Gluttonous Miasma__ — зменшує вхідний хіл на 100%, наносить 3341 пошкоджень
    від Темної магії кожні 1,5 сек і забирає здоров’я союзників в тому ж розмірі кожну секудну.[/p]

    [p]Протягом всього бою бос буде вішати міазму — __Gluttonous Miasma__ на чотирьох гравців, кожні
    24 секунди дебаф скаче на нових гравців, завжди чотири, і це триває весь бій. У додаток періодично
    на гравців будуть кидатись промені — __Volatile Ejection__ і кульки — __Expunge__. На 100 енергії
    настає друга фаза, бос тягне до себе і б’є по сраці, чим ближче тим сильніше, фази чергуються.[/p]

    ## Розгорнуто

    [p]Ділимо рейд на 4 групи і назначаємо кожній по мітці як на фото, задача груп віддавати своє
    здоров’я гравцям з __Gluttonous Miasma__ заходячи в їх коло.[/p]

    [image/hungering-destroyer-1.png]

    [p]Коли ви захілюєте людей з міазмою на вас вішається дебаф — __Essence Sap__, збільшує отримані
    пошкодження від міазмів на 8% протягом 20 сек, ефект накладається. Група повинна слідкувати за
    здоров’ям свого підопічного, аби дарма не заходити в коло, що є надзвичайно важко, найлегше
    поставити [link/https://wago.io/pRjJp9JLw/ось цю ауру].[/p]

    [p]Окрім міазмів на рейд буде кидатись __Volatile Ejection__ -- це промінь що треба винести між
    мітками. Важливо! Не за групу, а між ними, тому що бос вистрелить променем в свою жертву і ті хто
    на шляху теж отримають.[/p]

    [p]Ще буде __Expunge__ — це дебаф на декілька секунд після чого рейд детонує і наносить пошкодження
    всім хто поруч в розмірі 12992 від Темної магії (використовуйте захист) та залишить кульки, при
    дотику до них ціль отримає 12373 пошкоджень від Темної магії. Неактивний танк чи людина з імунітетом
    повинна почистити територію.[/p]

    [p]Через 20 сек з початку бою, а в подальшому кожну хвилину бос почне застосовувати
    __Desolate__ — наносить 14848 пошкоджень від Темної магії всім гравцям (використовуйте захист).
    Через часте застосування ця здібність буде накладатись на інші тому хілери повинні бути готовими
    заздалегідь, а рейд має подбати про гравців з міазмами.[/p]

    [p]При досягненні 100 енергії розпочнеться друга фаза, бос буде тягнути до себе і чим ближче ви
    тим болючішим буде __Consume__. Заплануйте хілерськи і персональні сейви, хп поти та каміння на
    цей період, не забувайте про гравців з міазмами. Після закінчення фази бос перейде на першу і все
    почнеться з початку.[/p]

    [p]Важливо! Одразу по закінченні другої фази бос задіє __Expunge__.[/p]
  `,
  aside: [
    {
      type: 'spell',
      icon: 'spell_shadow_manaburn',
      name: 'Overwhelm',
      desc: 'Сильний удар по танкам, свапайтесь.',
      whid: 329774
    },
    {
      type: 'spell',
      icon: 'ability_deathknight_frozencenter',
      name: 'Gluttonous Miasma',
      desc: 'Вішає дебаф на двох гравців, їх неможливо хіляти але вони можуть викрадати хп у сусідніх гравців.',
      whid: 329298
    },
    {
      type: 'spell',
      icon: 'spell_shadow_focusedpower',
      name: 'Essence Sap',
      desc: 'Збільшує отримані пошкодження від __Gluttonous Miasma__ на 8% протягом 20 сек, ефект накладається.',
      whid: 334755
    },
    {
      type: 'spell',
      icon: 'achievement_boss_lordmarrowgar',
      name: 'Volatile Ejection',
      desc: 'Вішає сині стрілки на рандомнів гравців, через декілька секунд по ним буде лазери.',
      whid: 334266
    },
    {
      type: 'spell',
      icon: 'ability_argus_soulbombdebufflarge',
      name: 'Expunge',
      desc: 'Вішає дебаф на кожного, через певний час взривається.',
      whid: 329742
    },
    {
      type: 'spell',
      icon: 'ability_argus_soulburst',
      name: 'Desolate',
      desc: 'Наносить 14848 пошкоджень від Темної магії всім гравцям.',
      whid: 329455
    },
    {
      type: 'spell',
      icon: 'ability_argus_deathfog',
      name: 'Consume',
      desc: 'На 100 енергії починає всіх тягнути до себе, чим ближче — тим більше дамагу по тобі.',
      whid: 334522
    },
  ],
  attachments: [
    { type: 'video', videoId: 'scKojr2N7wI', name: 'Першокіл (мітік)' }
  ]
}
