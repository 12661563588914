export default {
  slug: 'kryxis-the-voracious',
  parent: 'sanguine-depths',
  name: 'Kryxis the Voracious',
  short: 'Kryxis',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Dancecaptain (Makhabunda)' ],
  content: `
    <p>Поєдинок з босом на одну фазу, головна суть якого полягає в унеможливленні **Крайксісу**
    захілити себе кульками **Living Essence**, які з'являються внаслідок __Severing Smash__,
    для цього вам необхідно схопити їх, однак не одночасно всі, адже вони наносять чимале
    Тіньове пошкодження.</p>

    ## Severing Smash

    <p>Радіус дії удару 100 ярдів. За межами відповідної відстані кулі удар не проходить і кулі
    не появляються.</p>

    ## Living Essense

    <p>Кулі спавнити не надто далеко від боса, остання, бажано, на відстані кастів ренджера по босу.
    Ідея полягає у тому, якщо створити кулі надто далеко від боса, то можна дійти до моменту,
    коли ви їх хапатиме і після цього **Крайксіс** розпочне __Severing Smash__. Відстань між самими
    кулями також не повинна бути дуже великою, орієнтовно 8 ярдів більш ніж достатньо, щоб хілер встигав
    піднімати групу. Рекомендовано закривати кулі або танку, або дпсам не більше 2 куль на гравця.</p>
  `,
  aside: [
    {
      type: 'spell',
      icon: 'ability_argus_soulburst',
      name: 'Severing Smash',
      desc: 'На 100% енергії **Крайксіс** робить удар по землі, який наносить невелику кількість Тіньового пошкодження та відкидає всіх гравців назад. Окрім цього, після відкидання, біля гравців з\'являються голубі кулі **Living Essence**.',
      whid: 319685
    },
    {
      type: 'spell',
      icon: 'spell_mage_flameorb_blue',
      name: 'Living Essence',
      desc: 'Голубі кулі які з\'являються після удару __Severing Smash__ і рухаються до **Крайксіса**. Якщо кулі доходять до боса, вони лікують його на 12% здоров\'я. Схопивши кулю вся група отримує значну кількість Тіньового пошкодження, тому ловити всі кулі одночасно не рекомендується.',
      whid: 319684
    },
    {
      type: 'spell',
      icon: 'ability_warrior_bullrush',
      name: 'Juggernaut Rush',
      desc: '**Крайксіс** обирає будь-якого гравця та через короткий час зробить ривок до нього наносячи летальну кількість Тіньового пошкодження. Перекриваємо гравця, якщо він не може пережити ривок самостійно (використавши ультимативні сейв здібності).',
      whid: 319713
    },
    {
      type: 'spell',
      icon: 'ability_bullrush',
      name: 'Vicious Headbutt',
      desc: 'Удар по танку, який наносить значну кількість Фізичної шкоди. Танку варто тримати персональні сейви, особливо ті, які збільшують кількість броні, на відповідну здібність боса.',
      whid: 319650
    },
    {
      type: 'spell',
      icon: 'warlock_spelldrain',
      name: 'Hungering Drain',
      desc: '**Крайксіс** висмоктує енергію з гравців посилюючи себе на 1% від кожного гравця кожну секунду. Перебиваємо каст здібності, щоб бос себе не надто підсилив.',
      whid: 319654
    }
  ],
  attachments: [
    { type: 'video', videoId: 'DT0dHS9onLY', name: 'Аналіз М+20 від Денса (2й сезон)' },
    { type: 'video', videoId: 'rjR4kQrCIj0', name: 'Аналіз М+16 від Денса' },
    { type: 'post', link: '/guides/tormented-affix', name: '10+ афікс' },
  ]
}
