export default {
  slug: 'theater-of-pain',
  parent: 'dungeons',
  name: 'Театр болю',
  hint: 'Theater of Pain',
  image: '/img/theater-of-pain.jpg',
  group: 'Тінеземлі',
  meta: {
    description: 'Підземелля Театр болю.',
    keywords: 'театр, болю, болі, пейн, підземелля, данж, мітік, м+, тінеземлі, theater, pain, top, dungeon, shadowlands'
  },
  header: { parent: true },
  archive: true
}
