import appSettings from '@/assets/app-settings.json'

function appFriendsList() {
  return '<ul>' + appSettings.friends.map(({ name, desc, link }) => `
    <li>
      <a href="${link}" target="_blank">${name}</a>
      ${ desc ? '&mdash; ' + desc : '' }
    </li>
  `).join('') + '</ul>'
}

export function capitalize(text) {
  if (text) {
    text = text.toString()
    return text.charAt(0).toUpperCase() + text.slice(1)
  } else {
    return ''
  }
}

export function htmlize(text) {
  if (!text) return ''

  const data = []
  const load = index => data[index]
  const save = content => data.push(content) - 1

  return text
    // next lines must go first
    .replace(/\[html\](.*?)\[\/html\]/gs, (_, o) => `[data=${save(o.split('\n').map(e => e.trim()).filter(e => e).join('\n'))}]`)
    .replace(/\[pre\](.*?)\[\/pre\]/gs, (_, o) => `[data=${save(`<pre>${o.split('\n').map(e => e.trim()).filter(e => e).join('\n')}</pre>`)}]`)
    .replace(/\[code\](.*?)\[\/code\]/gs, (_, o) => `[data=${save(`<code>${o}</code>`)}]`)
    .replace(/\[link\/([^\]]*)\/([^\]]*)\]/g, (_, o1, o2) => `[data=${save(`<a href="${o1}" target="_blank">${o2}</a>`)}]`)
    .replace(/\[nav\/([^\]]*)\/([^\]]*)\]/g, (_, o1, o2) => `[data=${save(`<a href="${o1}">${o2}</a>`)}]`)
    .replace(/\[item\/(.*?)\/(.*?)\]/g, (_, o1, o2) => `[data=${save(`<a href="https://www.wowhead.com/item=${o1}" target="_blank">${o2}</a>`)}]`)
    .replace(/\[spell\/(.*?)\/(.*?)\]/g, (_, o1, o2) => `[data=${save(`<a href="https://www.wowhead.com/spell=${o1}" target="_blank">${o2}</a>`)}]`)
    .replace(/\[npc\/(.*?)\/(.*?)\]/g, (_, o1, o2) => `[data=${save(`<a href="https://www.wowhead.com/npc=${o1}" target="_blank">${o2}</a>`)}]`)
    .replace(/\[quest\/(.*?)\/(.*?)\]/g, (_, o1, o2) => `[data=${save(`<a href="https://www.wowhead.com/quest=${o1}" target="_blank">${o2}</a>`)}]`)
    .replace(/\[zone\/(.*?)\/(.*?)\]/g, (_, o1, o2) => `[data=${save(`<a href="https://www.wowhead.com/zone=${o1}" target="_blank">${o2}</a>`)}]`)
    .replace(/\[achievement\/(.*?)\/(.*?)\]/g, (_, o1, o2) => `[data=${save(`<a href="https://www.wowhead.com/achievement=${o1}" target="_blank">${o2}</a>`)}]`)
    .replace(/\[object\/(.*?)\/(.*?)\]/g, (_, o1, o2) => `[data=${save(`<a href="https://www.wowhead.com/object=${o1}" target="_blank">${o2}</a>`)}]`)
    .replace(/\[profession-trait\/(.*?)\/(.*?)\]/g, (_, o1, o2) => `[data=${save(`<a href="https://www.wowhead.com/profession-trait/${o1}" target="_blank">${o2}</a>`)}]`)
    // ordinary substitutions
    .replace(/\[p(.*?)\](.*?)\[\/p\]/gs, '<p class="$1">$2</p>')
    .replace(/\[ul(.*?)\](.*?)\[\/ul\]/gs, '<ul class="$1">$2</ul>')
    .replace(/\[li\](.*?)\[\/li\]/gs, '<li>$1</li>')
    .replace(/\[sup\](.*?)\[\/sup\]/gs, '<sup>$1</sup>')
    .replace(/\[sub\](.*?)\[\/sub\]/gs, '<sub>$1</sub>')
    .replace(/\[br\]/g, '<br>')
    .replace(/\[(\d+)\/arcane\]/g, (_, o) => `<span class="damage arcane" title="Арканічна магія">${o} [icon/bullseye]</span> пошкоджень`)
    .replace(/\[(\d+)\/nature\]/g, (_, o) => `<span class="damage nature" title="Магія природи">${o} [icon/leaf]</span> пошкоджень`)
    .replace(/\[(\d+)\/fire\]/g, (_, o) => `<span class="damage fire" title="Магія вогню">${o} [icon/fire]</span> пошкоджень`)
    .replace(/\[(\d+)\/frost\]/g, (_, o) => `<span class="damage frost" title="Магія криги">${o} [icon/snowflake]</span> пошкоджень`)
    .replace(/\[(\d+)\/holy\]/g, (_, o) => `<span class="damage holy" title="Священна магія">${o} [icon/candle]</span> пошкоджень`)
    .replace(/\[(\d+)\/shadow\]/g, (_, o) => `<span class="damage shadow" title="Магія тіні">${o} [icon/skull]</span> пошкоджень`)
    .replace(/\[(\d+)\/physical\]/g, (_, o) => `<span class="damage physical" title="Фізична шкода">${o} [icon/shield-half-full]</span> пошкоджень`)
    .replace(/ -- /g, ' &mdash; ')
    .replace(/->/g, '[icon/arrow-right]')
    .replace(/=>/g, '[icon/arrow-right-bold]')
    .replace(/\*\*(.+?)\*\*/gs, '<b>$1</b>')
    .replace(/~~(.+?)~~/gs, '<i>$1</i>')
    .replace(/__(.*?)__/gs, '<u>$1</u>')
    .replace(/--(.*?)--/gs, '<del>$1</del>')
    .replace(/#### (.*)/g, '<h4>$1</h4>')
    .replace(/### (.*)/g, '<h3>$1</h3>')
    .replace(/## (.*)/g, '<h2>$1</h2>')
    .replace(/\[icon\/(.*?)\]/g, '<i class="mdi mdi-$1"></i>')
    .replace(/\[image\/(.*?)\]/g, '<img src="/img/$1">')
    .replace(/\[(\S.+?)\/\/(\S.+?)\]/g, (_, o1, o2) => `<span class="implication" title="${o2}">${o1}</span>`)
    .replace(/(\d+)/g, (_, o) => Number(o).toLocaleString('uk'))
    .replace(/#appDiscordLink/g, `<a href="${appSettings.links.discord}" target="_blank">Discord</a>`)
    .replace(/#appFacebookLink/g, `<a href="${appSettings.links.facebook}" target="_blank">Facebook</a>`)
    .replace(/#appInstagramLink/g, `<a href="${appSettings.links.instagram}" target="_blank">Instagram</a>`)
    .replace(/#appBattlenetLink/g, `<a href="${appSettings.links.battlenet}" target="_blank">Battle.net</a>`)
    .replace(/#appFriendsList/g, appFriendsList())
    .replace(/#appDiscordWidget/g, `<iframe src="${appSettings.widgets.discordIframeSrc}" width="350" height="500" allowtransparency="true" frameborder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>`)
    // next line must go last
    .replace(/\[data=(\d+)\]/gs, (_, o) => load(o))
}

const fetchJsonCache = {}

function fetchJson(url) {
  return new Promise((resolve, reject) => {
    if (fetchJsonCache[url]) {
      resolve(fetchJsonCache[url])
    } else {
      fetch(url).then(r => r.json())
        .then(data => {
          fetchJsonCache[url] = data
          const copy = JSON.parse(JSON.stringify(data))
          resolve(copy)
        })
        .catch(err => reject(err))
    }
  })
}

export function apiFetch(route) {
  return fetchJson(appSettings.api + route)
}
