export default {
  slug: 'shadhar-the-insatiable',
  parent: 'nyalotha',
  name: 'Шад\'гар Ненажерливий',
  hint: 'Shad\'har the Insatiable',
  short: 'Шад\'гар',
  group: 'Gift of Flesh',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Skye' ],
  content: `
    <ul>
      <li>зміна фаз на 66% і на 33% хп боса</li>
      <li>вар'ят, якого переслідує капля, вибігає з рейду на край арени</li>
      <li>вибігайти із конусу подиху боса (окрім 2ої фази, на ній його подих миттевий)</li>
      <li>на першій фазі швидко вибігайте з калюж під ногами</li>
      <li>на другій фазі рдд і хіли сокають фіолетові орби</li>
      <li>на третій фазі танки повільно ведуть боса по краю арени, весь рейд під босом</li>
      <li>бос бьє таків двома абілками: <em>Краш</em> та <em>Дісолв</em>; один танк не може ловити іншу абілку поки не спав дебаф першої, в цей момент потрібно мінятися</li>
      <li>героїзм на останній фазі</li>
    </ul>
  `,
  attachments: [
    { type: 'video', videoId: 'o4G7Qh_rfFc', name: 'Першокіл (міфік)' }
  ]
}
