export default {
  slug: 'talanti-alhimii',
  parent: 'guides',
  name: 'Таланти алхімії',
  image: '/img/talanti-alhimii.jpg',
  meta: {
    description: 'Гайд по талантах алхімії Дрегонфлайту.',
    keywords: 'гайд, алхімія, таланти, професія, дрегонфлайт, дф, alchemy, profession, dragonflight, df'
  },
  header: { parent: true },
  authors: [ 'Ta-na' ],
  content: `
    [p][image/talanti-alhimii.jpg][/p]

    ## Дерево талантів

    [p]Кожна професія має дерево талантів, як і звичайні талантами професійні
    теж можна поділити на «збірки». Щоб його відкрити потрібно підняти рівень
    володіння професією до 25. Потім ви можете заробляти очки знань — «Знання»,
    потрібні для розвитку гілок.[/p]

    ### Очки знань

    [p]Очки знань, або «Знання» — це нова валюта, яку ви отримаєте під час
    «Dragonflight». Можна витратити на дерева талантів вашої професії, щоб покращити
    свою майстерність.[/p]

    [p]Обрані таланти незмінні, хоча можна вивчити все, що може запропонувати
    спеціалізація, ця система призначена для того, щоб максимізувати значні витрати
    часу. Зараз немає способу повернути витрачені «Знання», важливо розумно вибирати
    початкові збірки. Відмова від вивчення професії та повторне її отримання
    не призведе до скидання та повернення витрачених балів. Ви отримаєте назад усе,
    чого навчилися за допомогою спеціалізації, а також будь-які невитрачені бали,
    хоча ви все одно повернетеся до навички (1) і вам доведеться заново вивчати
    будь-які рецепти.[/p]

    ### Шляхи отримання

    [p]«First Time Crafts» — створіть рецепт вперше, щоб отримати +1 очко,
    професійні квести, одноразові скарби.[/p]

    [p]**[item/194697/Драконівський трактат про алхімію]** — створюється професією
    «Письма» (роблять символи), персональний при піднятті, +1 знання, обмежено 1 на
    тиждень.[/p]

    [p]**[item/191784/Dragon Shard of Knowledge]** — після проходження
    **[quest/69946/«The Master of Their Craft»]** з **[npc/193110/Хадіном]**
    ви отримаєте 1-3 очок знань і **[item/190456/Artisan's Mettle]** .
    (Після того, як це було відкрито для одного персонажа, альтернативи можуть
    пропускати деякі квести Консорціуму Ремісника, щоб одразу відкрити доступ
    до здачі **[item/191784/Dragon Shard of Knowledge]** . Однак це також пропускає
    деякі квести, які дають безкоштовне спорядження для професії низького рівня).[/p]

    [p]**[item/200974/Dusty Alchemist's Research]** — купіть у **[npc/194057/Рабула]**
    у Вальдраккені за 100 — **[item/190456/Artisan's Mettle]** біля зони наказів ремісника;
    нагорода +15 Знань. Кожного разу, коли ви купуєте цей товар, він стає дорожчим.
    Залежно від того, який предмет ви купуєте, ви повинні мати різні рівні репутації
    **[link/https://www.wowhead.com/beta/faction=2544/artisans-consortium-dragon-isles-branch/«Artisan's Consortium - Dragon Isles Branch»]**.[/p]

    ### Transmutation

    [p]0-5 - [profession-trait/19539/Alchemical Theory],[br]
    5-25 - [profession-trait/19538/Transmutation],[br]
    25-35 - [profession-trait/19539/Alchemical Theory],[br]
    35-55 - [profession-trait/19536/Decayology].[/p]

    ### Майстер зілля

    [p]0-10 - [profession-trait/19487/Potion Mastery],[br]
    10-20 - [profession-trait/19484/Potion Lore],[br]
    20-40 - [profession-trait/19482/Batch Production],[br]
    40-55 - [profession-trait/19484/Potion Lore],[br]
    55-60 - [profession-trait/19539/Alchemical Theory],[br]
    60-90 - [profession-trait/19534/Inspiring Ambience].[/p]

    ### Майстер фіалів

    [p]0-10 - [profession-trait/22483/Phial Mastery],[br]
    10-20 - [profession-trait/22480/Phial Lore],[br]
    20-40 - [profession-trait/22478/Batch Production],[br]
    40-55 - [profession-trait/22480/Phial Lore],[br]
    55-60 - [profession-trait/19539/Alchemical Theory],[br]
    60-60 - [profession-trait/19536/Decayology],[br]
    60-70 - [profession-trait/19539/Alchemical Theory],[br]
    70-100 - [profession-trait/19534/Inspiring Ambience].[/p]

    [p]~~[link/https://www.wowhead.com/guide/professions/alchemy/specialization-builds-dragonflight/Оригінал ґайду]~~[/p]
  `
}
