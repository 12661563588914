export default {
  slug: 'putivnuk-po-resyrsam',
  parent: 'guides',
  name: 'Путівник по ресурсам',
  header: { parent: true },
  authors: [ 'Ta-na' ],
  content: `
    <p>Якщо хочете ловити рибу, придбайде у НПС в Орібосі вудку і наживку.</p>

    <h3>Bastion</h3>
    <p>
    [icon/fish] [item/173034/Silvergill Pike]<br>
    [icon/flower] [item/168586/Rising Glory]<br>
    [icon/pickaxe] [item/171829/Solenium Ore]<br>
    </p>

    <h3>Maldraxxus</h3>
    <p>
    [icon/fish] [item/173035/Pocked Bonefish]<br>
    [icon/flower] [item/168589/Marrowroot]<br>
    [icon/pickaxe] [item/171830/Oxxein Ore]<br>
    </p>

    <h3>Ardenweald</h3>
    <p>
    [icon/fish] [item/173033/Iridescent Amberjack]<br>
    [icon/flower] [item/170554/Vigil's Torch]<br>
    [icon/pickaxe] [item/171831/Phaedrum Ore]<br>
    [icon/pickaxe] [item/177061/Twilight Bark] з [object/349982/Phaedrum Deposit]<br>
    </p>

    <h3>Revendreth</h3>
    <p>
    [icon/fish] [item/173036/Spinefin Piranha]<br>
    [icon/flower] [item/168583/Widowbloom]<br>
    [icon/pickaxe] [item/171832/Sinvyr Ore]<br>
    </p>

    <p>Невказана трава чи риба падає кругом.</p>
  `
}
