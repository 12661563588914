export default {
  slug: 'myzas-oasis',
  parent: 'tazavesh',
  name: 'Оазис Мізи',
  hint: 'Myza\'s Oasis',
  image: '/img/myzas-oasis.jpg',
  group: { name: 'Двір', hint: 'The Courtyard' },
  meta: {
    description: 'Тактика бою проти Зо\'ґрона в клубі "Оазис Мізи" у Тазавеші.',
    keywords: 'оазис, мізи, музи, ау\'мізи, аумізи, аю\'мізи, аюмізи, зо\'ґрон, зо\'грон, зогрон, тактика, стратегія, механіка, гайд, бос, тазавеш, підземелля, данж, мітік, м+, тінеземлі, zo\'gron, zogron, myza, au\'myza, oasis, tazavesh, market, dungeon, shadowlands'
  },
  header: { parent: true },
  sidenav: true,
  authors: [ 'Skye' ],
  content: `
    [p]Бій в клубі, де гравці відіграють роль музичного гурту, грають на інструментах
    та нервах відвідувачів, які не стримуються та вибігають на сцену битися. Все це невдовзі
    привертає увагу викидайла [npc/176564/Зо'ґрона].[/p]

    ## Виступ

    [p]На сцені буде п'ять музичних інструментів. Взаємодія з інструментом наділяє гравця здібністю:
    [icon/microphone] __мікрофон__, [icon/pot-mix] __барабани__, [icon/guitar-electric] __гітара__,
    [icon/saxophone] __саксофон__, [icon/bugle] __труба__.
    Здібності мікрофону та барабанів -- утворюють ноти; в той час як гітара, саксофон та труба
    дозволяють ковзати вперед по нотах, отримуючи __жвавість__. Рекомендовано танку брати мікрофон,
    цілителю -- барабани, решті -- гітару, саксофон, трубу.[/p]

    ## Клієнти та охоронці

    [p]Почніть виступ -- застосовуйте здібності інструментів. Коли один з гравців отримає 10 кратний
    ефект __жвавості__, на сцену почнуть підніматися [npc/180159/невгамовні] та
    [npc/176565/збуджені] клієнти разом із [npc/179269/охоронцями] закладу.[/p]

    [p]Збуджені клієнти будуть __стримувати__ гравців, намагайтеся переривати цю дію.[/p]

    [p]Охоронці будуть __дерти горло__ протягом 3 сек -- важливо їх переривати, інакше всі гравці
    отримають пошкодження та не зможуть вимовляти закляття протягом 6 сек.[/p]

    [p]Всі супостати мають шкалу люті. Вона заповнюється з плином часу (приблизно 10% за 6 сек).
    Якщо лють досягає 100%, супостат стає [spell/353706/галасливим], що збільшує його пошкодження
    в два рази. Рекомендовано не давати затримуватися на сцені ворогам -- фокусуйтеся на тих хто
    прийшов раніше.[/p]

    [p]Після двох хвиль розлючених клієнтів та охоронців, до гравців вибіжить викидайло Зо'ґрон.[/p]

    ## Зо'ґрон

    [p]Бос буде __дерти горло__ так само як це робили охоронці -- важливо переривати.[/p]

    [p]Коли викидайло починає __тягнути__ до себе всіх гравців, розбіжіться так щоб через
    6 сек не задіти вибухом один одного. Гравцям з гітарою, саксофоном та трубою зручно
    ковзати від боса в цей момент.[/p]

    [p]Зо'ґрон застосовує __контроль натовпу__ у вигляді прозорого бар'єру перед собою. Гравці
    повинні відійти, адже через 5 сек в напрямку, куди був направлений бар'єр, він завдасть значного
    пошкодження всім гравцям.[/p]
  `,
  aside: [
    {
      type: 'image',
      src: '/img/myzas-oasis.jpg'
    },
    {
      type: 'spell',
      whid: 348567,
      icon: 'spell_misc_emotionhappy',
      name: 'Jazzy',
      namet: 'жвавий',
      desc: `
        Влучання по ноті збільшує швидкість атаки та вимову заклять на 1% протягом 40 сек.
        Цей ефект складається.
      `,
    },
    {
      type: 'spell',
      whid: 359019,
      icon: 'spell_misc_emotionangry',
      name: 'Up Tempo!',
      namet: 'швидкий',
      desc: `
        При 12 кратному ефекті __жвавості__, збільшує пошкодження, зцілення, швидкість атаки та вимову заклять на 25% протягом 40 сек.
        [br][br]
        Прибирає ефекти __жвавості__. Влучання по нотах під час дії __швидкості__, оновлює її тривалість.
      `,
    },
    {
      type: 'header',
      name: 'Музичні інструменти',
    },
    {
      type: 'spell',
      whid: 350731,
      icon: 'inv_misc_horn_01',
      name: 'Horn Improvisation',
      namet: 'мікрофон',
      desc: `
        Виконати іпровізацію горном щоб влучити по нотах у радіусі 4 метри.
        Кожна нота надає виконавцю __жвавості__.
        [br][br]
        ~~Примітка: горн має вигляд мікрофону.~~
      `,
    },
    {
      type: 'spell',
      whid: 353221,
      icon: 'inv_leatherworking_drums',
      name: 'Drum Beat',
      namet: 'барабани',
      desc: `
        Вдарити по барабанах щоб влучити по нотах поблизу.
        Кожна нота надає виконавцю __жвавості__.
      `,
    },
    {
      type: 'spell',
      whid: 349026,
      icon: 'ability_brokerjazzband_guitar',
      name: 'Guitar Riff',
      namet: 'гітара',
      desc: `
        Ковзати вперед на 12 метрів, влучаючи по нотах на шляху.
        Кожна нота надає виконавцю __жвавості__.
      `,
    },
    {
      type: 'spell',
      whid: 349771,
      icon: 'inv_misc_horn_04',
      name: 'Sax Solo',
      namet: 'саксофон',
      desc: `
        Ковзати вперед на 12 метрів, влучаючи по нотах на шляху.
        Кожна нота надає виконавцю __жвавості__.
      `,
    },
    {
      type: 'spell',
      whid: 349789,
      icon: 'ability_brokerjazzband_trumpet',
      name: 'Trumpet Slide',
      namet: 'труба',
      desc: `
        Ковзати вперед на 12 метрів, влучаючи по нотах на шляху.
        Кожна нота надає виконавцю __жвавості__.
      `,
    },
    {
      type: 'header',
      name: 'Brawling Patron',
      namet: 'невгамовний клієнт'
    },
    {
      type: 'spell',
      whid: 348566,
      icon: 'inv_potion_158',
      name: 'Throw Drink',
      desc: `
        Бризкає напій в обличчя гравця, завдаючи [10970/physical].
      `,
    },
    {
      type: 'header',
      name: 'Disruptive Patron',
      namet: 'збуджений клієнт'
    },
    {
      type: 'spell',
      whid: 353836,
      icon: 'spell_broker_missile',
      name: 'Hyperlight Bolt',
      desc: `
        Завдає гравцю [8228/arcane].
      `,
    },
    {
      type: 'spell',
      whid: 353835,
      icon: 'spell_broker_debuff',
      name: 'Suppression',
      namet: 'стримування',
      desc: `
        Стримує гравця протягом 8 сек, завдаючи [411/arcane] кожні 0.5 сек та зменшуючи
        швидкість руху на 30%. Заклинач підтримує цю дію.
      `,
    },
    {
      type: 'header',
      name: 'Oasis Security',
      namet: 'охоронець'
    },
    {
      type: 'spell',
      whid: 350916,
      icon: 'inv_mace_1h_broker_c_01',
      name: 'Security Slam',
      desc: `
        Калатає ціль молотом, завдаючи [43880/physical].
      `,
    },
    {
      type: 'spell',
      whid: 350922,
      icon: 'ability_warrior_battleshout',
      name: 'Menacing Shout',
      namet: 'дерти горло',
      desc: `
        Завдає [10970/physical] всім гравцям та перериває вимову заклять на 6 сек. Ігнорує броню.
      `,
    },
    {
      type: 'header',
      name: 'Zo\'gron',
      namet: 'Зо\'ґрон'
    },
    {
      type: 'spell',
      whid: 359028,
      icon: 'inv_mace_1h_broker_c_01',
      name: 'Security Slam',
      desc: `
        Калатає ціль молотом, завдаючи [65820/physical].
      `,
    },
    {
      type: 'spell',
      whid: 350922,
      icon: 'ability_warrior_battleshout',
      name: 'Menacing Shout',
      namet: 'дерти горло',
      desc: `
        Завдає [10970/physical] всім гравцям та перериває вимову заклять на 6 сек. Ігнорує броню.
      `,
    },
    {
      type: 'spell',
      whid: 355438,
      icon: 'spell_broker_nova',
      audio: 'https://wow.zamimg.com/sound-ids/live/enus/82/4077650/VO_91_Zogron_208706.ogg',
      name: 'Suppression Spark',
      namet: 'притягування',
      desc: `
        Зо'ґрон тягне до себе всіх гравців, створюючи сприятливий момент для виступу гурту.
        [br][br]
        Після 6 сек, кожен гравець завдає [21940/arcane] іншим гравцям у радіусі 4.5 метри.
      `,
    },
    {
      type: 'spell',
      whid: 350919,
      icon: 'inv_shield_1h_broker_c_01',
      audio: 'https://wow.zamimg.com/sound-ids/live/enus/83/4077651/VO_91_Zogron_208740.ogg',
      name: 'Crowd Control',
      namet: 'контроль натовпу',
      desc: `
        Зо'ґрон відбиває всі атаки та закляття спереду протягом 5 сек.
        [br][br]
        По завершенню, Зо'ґрон завдає [41138/arcane] всім гравцям у конусі перед собою та
        відкидує їх.
      `,
    }
  ]
}
