export default {
  slug: 'painsmith-raznal',
  parent: 'sanctum-of-domination',
  name: 'Painsmith Raznal',
  short: 'Raznal',
  image: '/img/painsmith-raznal.jpg',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Dancecaptain (Makhabunda)' ],
  attachments: [
    { type: 'video', videoId: 's2ptpQjrENE', name: 'Першокіл (героїк)' },
    { type: 'video', videoId: 'Fco6Kbz89Nc', name: 'Відеотактика (англійська)' }
  ],
  aside: [
    {
      type: 'header',
      name: 'Головна фаза'
    },
    {
      type: 'spell',
      icon: 'inv_axe_2h_mawraid_diff',
      name: 'Cruciform Axe',
      namet: '(до уваги танкам)',
      desc: 'Сокира. Стартова зброя боса. Наносить удар по цілі боса, який завдає 54104 Фізичного пошкодження і вішає дот, який завдає 4509 Вогняного пошкодження кожну секунду і збільшує вхідну Фізичні пошкодження на 200% впродовж 12 секунд . Ефект доти стакається. Окрім цього, після удару відбувається вибух по цілі, яка отримала удар сокирою, який завдає 18035 Вогняного пошкодження всім гравцям. Чим далі ціль знаходиться від вибуху, тим менше пошкодження отримує.',
      whid: 355568
    },
    {
      type: 'spell',
      icon: 'inv_mace_2h_maw_c_01',
      name: 'Reverberating Hammer',
      desc: 'Молот діє так само, як сокира, однак шипи вистрілюють хрестом від гравця.',
      whid: 348508
    },
    {
      type: 'spell',
      icon: 'inv_polearm_2h_mawnecromancerboss_d_01_grey',
      name: 'Dualblade Scythe',
      desc: 'Коса поєднує ефект розповзання шипів від сокири і молота.',
      whid: 355778
    },
    {
      type: 'spell',
      icon: 'ability_fomor_boss_pillar02',
      name: 'Spiked',
      desc: 'Окрім вибуху, під гравцем появляються піки, які рухаються вперед від гравця на 360 градусів і завдають 12023 Фізичного пошкодження й станять ціль, яка потрапила у них на 2 секунди.',
      whid: 348255
    },
    {
      type: 'spell',
      icon: 'ability_warrior_trauma',
      name: 'Spiked Balls ',
      namet: '(смертельно, до уваги ДПСрів)',
      desc: 'По платформі котяться кулі (ади). Якщо куля переїде гравця, він отримає 120232 Фізичного пошкодження і стан на 8 секунд.',
      whid: 352052
    },
    {
      type: 'spell',
      icon: 'ability_deathknight_deathchain',
      name: 'Shadowsteel Chains',
      desc: 'Бос кидає на гравця ланцюги, які завдають 2405 Вогняного пошкодження кожну секунду впродовж 20 секунд. Гравець з відповідним дотом завдає 12023 Фізичного пошкодження іншим гравцям в 4 ярдах від себе й відкидає їх.',
      whid: 355504
    },
    {
      type: 'spell',
      icon: 'ability_hunter_steeltrap',
      name: 'Flameclasp Trap',
      namet: '(героїк)',
      desc: 'Бос маркує 4 гравців, які через 8 секунд отримають трапу під ноги. Трапа спрацьовує, якщо на неї забіг гравець, або якщо її заділи шипи, або кулі.',
      whid: 348456
    },
    {
      type: 'spell',
      icon: 'ability_warlock_inferno',
      name: 'Flameclasp Eruption',
      namet: '(героїк)',
      desc: 'Вибух від трапи завдає 10344 Вогняного пошкодження всім гравцям і збільшує пошкодження від наступного вибуху на 200% впродовж півтори секунди.',
      whid: 348463
    },
    {
      type: 'header',
      name: 'Перехідна фаза На 70% і 40%'
    },
    {
      type: 'spell',
      icon: 'inv_ember',
      name: 'Shadowsteel Embers',
      desc: 'Бос бомбардує платформу вогнем, який завдає 18035 Вогняного пошкодження гравцям в 3 ярдах навколо себе.',
      whid: 355534
    },
    {
      type: 'spell',
      icon: 'ability_rhyolith_magmaflow_wave',
      name: 'Black Flames',
      namet: '(до уваги хілерів)',
      desc: 'Вибух по всьому рейду, який завдає 4509 Вогняного пошкодження всім гравцям і стакає дот, який наносить Вогняне пошкодження впродовж 30 секунд.',
      whid: 356844
    },
  ]
}
