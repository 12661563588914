export default {
  slug: 'the-tarragrue',
  parent: 'sanctum-of-domination',
  name: 'The Tarragrue',
  image: '/img/the-tarragrue.jpg',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Dancecaptain (Makhabunda)' ],
  attachments: [
    { type: 'video', videoId: 'AylxbJSTdio', name: 'Першокіл (мітік)' },
    { type: 'video', videoId: '-ybonf7jThg', name: 'Відеотактика (англійська)' }
  ],
  content: `
    [p]
      «Бій проти Таррагрю містить чергування специфічних механік».
      Перед пулом боса, Ваш рейд повинен зачистити треш і підняти 4 Аніма-сил
      з __Infused Goliath__ (то такий жирний ад). Впевніться, щоб хоча б 1 гравець
      взяв **__Ever Beating Heart__** (то аніма така) і уникайте __Ten Towers__ або
      __Lumbering Form__ сили допоки група не буде готовою
      протистояти/відслідковувати їхнє пошкодження.
    [/p]

    [p]
      Під час цього бою хілери контролюватимуть пошкодження по рейду і діспелитимуть
      __Dread__ дебаф, коли гравці з пурпурним колом, від яких інші гравці повинні
      відбігти, щоб перших можна було діспелити. __The Chains of Eternity__ — повинні
      бути поглинені гравцями з імунами до стану, наприклад з аніма-силою
      __Ever Beating Heart__. Під час __Hungering Mist__ рейд повинен рухатись разом
      до сейф-зон між АОЄ тінями. Танки можуть мінятись після кожного касту __Overpower__.
    [/p]

    [p]
      На герїку __Remnants of Forgotten Torments__ з'являється впродовж бою. Вона (абілка)
      кладе калюжу з набором чарджів і гравці повинні поглинати ці чарджі, щоб зменшити
      їх вплив на рейд (на людей мається на увазі).
    [/p]
  `,
  aside: [
    {
      type: 'header',
      name: 'Перша фаза'
    },
    {
      type: 'spell',
      icon: 'inv_misc_steelweaponchain',
      name: 'Chains of Eternety',
      desc: 'Таррагрю фокусує свій погляд на гравця. Після 8 секунд ланцюги полетять у своєму напрямку і станять першу уражену ціль, завдає 15029 Фізичного пошкодження і затягує їх в __Annihilating Smash__.',
      whid: 347269
    },
    {
      type: 'spell',
      icon: 'inv_misc_powder_tin',
      name: 'Annihilating Smash',
      desc: 'Таррагрю стирає у попіл тих, хто попався у його ланцюги, завдає 45087 Фізичного пошкодження і станячи їх на 15 секунд.',
      whid: 347274
    },
    {
      type: 'spell',
      icon: 'ability_argus_soulburst',
      name: 'Hungering Mist',
      desc: 'Таррагрю розчиняється у тумані Торгасту, утворюючи декілька зображень. Ці зображення вибухають завдає 28555 Тіньового пошкодження всім гравцям у радіусі 25 ярдів.',
      whid: 347737
    },
    {
      type: 'spell',
      icon: 'spell_animamaw_groundstate',
      name: 'Remnant of Forgotten Torments ',
      namet: '(героїк)',
      desc:`На платформі появляються кольорові зони з орбами, які треба соукати (максимум 5 тіків на одну калюжу)[ul with-item-gaps]
        [li]Зелена. Поки існує, збільшує Фізичні пошкодження від боса на 50%. Кожний соук збільшує вхідне Фізичне пошкодження по гравцю на 10% впродовж півтори хвилини.[/li]
        [li]Чорна. Поки існує, збільшує Магічні пошкодження від боса на 50%. Кожний соук збільшує вхідне Магічне пошкодження по гравцю на 10% впродовж півтори хвилини.[/li]
        [li]Червона. Поки існує, періодично завдає 10350 Вогняного пошкодження всім гравцям. Кожний соук настакує дот на гравці, який кожні 5 секунд завдає Вогняне пошкодження впродовж півтори хвилини.[/li]
      [/ul]`,
      whid: 352368
    },
    {
      type: 'spell',
      icon: 'ability_creature_cursed_05',
      name: 'Predator\'s Howl ',
      namet: '(Магічний ефект)',
      desc: 'Таррагрю виє, викликаючи ауру жаху, яка формується навколо гравців впродовж 21 секунди. Це заставляє кожну ціль виділяти __Unshakeable Dread__ до інших гравців навколо 5 ярдів кожні 3 секунди.',
      whid: 347283
    },
    {
      type: 'spell',
      icon: 'spell_nzinsanity_fearofdeath',
      name: 'Unshakeable Dread ',
      namet: '(Магічний ефект)',
      desc: 'Заставляє ціль тікати у страху впродовж 8 секунд.',
      whid: 347286
    },
    {
      type: 'spell',
      icon: 'inv_mawguardpet_red',
      name: 'Fury of the Ages ',
      namet: '(Енрейж)',
      desc: 'Посилює пошкодження від боса на 25% і швидкість його атак на 25% впродовж 35 секунд.',
      whid: 347490
    },
    {
      type: 'spell',
      icon: 'spell_animamaw_buff',
      name: 'Grasp of Death ',
      namet: '(до уваги хілерів)',
      desc: 'Дот між гравцями, який завдає 6012 Тіньового пошкодження кожні 2 секунди впродовж 40 секунд.',
      whid: 347668
    },
    {
      type: 'spell',
      icon: 'ability_titankeeper_titanicsmash',
      name: 'Overpower',
      namet: '(до уваги танків)',
      desc: 'Таррангрю вдаряє ціль, завдає 60116 Фізичного пошкодження і станить її на 4 секунди. Цей удар викликає __Crushed Armor__.',
      whid: 346985
    },
    {
      type: 'spell',
      icon: 'ability_warrior_colossussmash',
      name: 'Chushed Armor',
      desc: 'Збільшує отримання Фізичного пошкодження на 200% впродовж 20 секунд.',
      whid: 346986
    },
    {
      type: 'header',
      name: 'Друга фаза'
    },
    {
      type: 'spell',
      icon: 'spell_animamaw_debuff',
      name: 'The Jailer\'s Gaze',
      desc: 'Коли у Таррагрю залишається 10% життя, він забирає з гравців всі Аніма-сили і збільшує свою силу на 500% допоки не помре.',
      whid: 347369
    },
  ]
}
