export default {
  slug: 'gerenal-kaal',
  parent: 'sanguine-depths',
  name: 'Gerenal Kaal',
  short: 'Kaal',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Dancecaptain (Makhabunda)' ],
  content: `
    [p]Унікальний поєдинок з босом, який складається з двох фаз:
      [ul]
        [li]“Коридор Смерті” під час якого через кожні втрачені **Каал** 10% її здоров'я
            вона відступатиме назад допоки не дійде до 70% свого здоров'я;[/li]
        [li]“Бій на обриві” під час якого гравці повинні зменшити здоров'я **Каал** до
            51% після чого поєдинок завершується втечею боса.[/li]
      [/ul]
    [/p]

    ## Shining Radiance

    [p]Золота Наару-приблуда яку тарабанить на своїй спині бажано танк і якою він може
    послуговуватись задля створення золотого **куполу**, який зменшує все вхідне пошкодження
    по гравцям всередині на 65%. Найбільш доцільно та вкрай важливо його використовувати
    на __Gloom Squall__.[/p]

    ## Wicked Rush

    [p]Ривок боса по відмічених гравцях. Кожен повторний ривок боса навішує додатковий
    стак доти. Дота зникає якщо зайти у купол __Shining Radiance__.[/p]

    [p][spell/331415/Дот] перманентний, однак, окрім щита, його можна скинути за допомогою
    кіріанської пляшки, або деяких расових здібностей. Зазвичай у проміжку між застосуванням
    двох куполів __Shining Radiance__ вішається 1, а на окремому гравці 2 стаки доти.
    Відповідний гравець змушений застосувати власний сейв для відповідної ситуації.[/p]
  `,
  aside: [
    {
      type: 'spell',
      icon: 'spell_holy_powerwordbarrier',
      name: 'Shining Radiance',
      namet: 'гравець',
      desc: 'Створює **купол** навколо гравця, який [ul][li]зменьшує всю вхідну шкоду на 65%[/li][li]знімає всі стаки [spell/331415/Wicked Gash][/li][li]надає імунітет до відштовхування[/li][/ul] Цю здібність має один із гравців у вигляді окремої спец кнопки з часом відновлення в 35 сек.',
      whid: 324086
    },
    {
      type: 'spell',
      icon: 'ability_ironmaidens_whirlofblood',
      name: 'Gloom Squall',
      desc: 'Набравши 100% енергії **Каал** створює червону кулю, яка вибухне після чого нанесе всім гравцям летальну кількість Тіньового пошкодження і відкине їх від себе. Ставимо **купол** і спокійно переживаємо.',
      whid: 322895
    },
    {
      type: 'spell',
      icon: 'spell_warrior_wildstrike',
      name: 'Wicked Rush',
      desc: '**Каал** помічає марками двох гравців до яких вона зробить ривок і повішає доту [spell/331415/Wicked Gash], яка наносить Фізичне пошкодження. Також дот отримують гравці, які знаходяться у 5 ярдах від поміченого гравця під час ривка.',
      whid: 323845
    },
    {
      type: 'spell',
      icon: 'ability_skyreach_piercing_rush',
      name: 'Piercing Blur',
      desc: '**Каал** створює шість копій себе, які кастують промінь одна до одної. Якщо гравець не встиг вийти з променя, отримає Фізичне пошкодження.[br][br][icon/alert-circle-outline] ВАЖЛИВО: Бос б\'є трьома променями, йдуть один за одним, стійте в тому що ще не вдарив і переходьте на чисту територію.',
      whid: 323810
    }
  ],
  attachments: [
    { type: 'video', videoId: 'DT0dHS9onLY', name: 'Аналіз М+20 від Денса (2й сезон)' },
    { type: 'video', videoId: 'rjR4kQrCIj0', name: 'Аналіз М+16 від Денса' },
    { type: 'post', link: '/guides/tormented-affix', name: '10+ афікс' },
  ]
}
