export default {
  slug: 'tormented-affix',
  parent: 'guides',
  name: '10+ афікс другого сезону Торментед',
  image: '/img/tormented-affix-1.jpg',
  header: { parent: true },
  // sidenav: true,
  authors: [ 'Ta-na' ],
  contentrows: [
    {
      content: `
        ## Торментед

        [p]
          Чотири генерали, мають ряд противних абілок проте після вбивства з’явится сфера.
          Юзаєте сферу і отримуєте аніма павер, як в торгасті, що дає вам вибір з трьох підсилень,
          можна вибрати один, і так з кожного генерала.
        [/p]

        [p]
          [image/tormented-affix-1.jpg]
        [/p]
      `
    },
    {
      content: `
        ## Incinerator Arkolath

        [p]
          Арколат має ауру __Soulforge Flames__, поки в комбаті наносить всім пошкодженя від Магії вогню.
          Отже інтераптимо __Inferno__, діспелимо __Melt Soul__, відходимо від __Scorching Blast__.
          Якщо бій затяжний __Scorching Blast__ краще розкладати кучно, щоб вистачило місця.
        [/p]

        [p]
          [image/tormented-affix-2.jpg]
        [/p]
      `,
      aside: [
        {
          type: 'spell',
          icon: 'ability_warlock_fireandbrimstone',
          name: 'Soulforge Flames',
          namet: 'аура',
          desc: 'Наносить пошкодження вогнем гравцям кожні 3 секунди.',
          whid: 355707
        },
        {
          type: 'spell',
          icon: 'ability_mage_flamecannon',
          name: 'Scorching Blast',
          desc: 'Підпалює землю, наносить 11541 пошкоджень від Магії вогню всім в радіусі 8 ярдів і додатково наносить 2638 пошкоджень кожні 1.5 сек всім хто стоїть в калюжі. Кидаєтся на рандомного гравця.',
          whid: 355737
        },
        {
          type: 'spell',
          icon: 'ability_foundryraid_melt',
          name: 'Melt Soul',
          desc: 'Магічний дебаф, вішається на всіх, збільшує отримані пошкодження від магії на 100%.',
          whid: 355732
        },
        {
          type: 'spell',
          icon: 'ability_foundryraid_blastwave',
          name: 'Inferno',
          desc: 'Наносить пошкодження від Магії вогню всій групі, інтераптиться.',
          whid: 358967
        },
      ]
    },
    {
      content: `
        ## Oros Coldheart

        [p]
          Орос має ауру __Chilling Presence__, зменшує швидкість пересування на 50%.
        [/p]

        [p]
          [image/tormented-affix-3.jpg]
        [/p]
      `,
      aside: [
        {
          type: 'spell',
          icon: 'spell_shadow_soulleech_2',
          name: 'Chilling Presence',
          namet: 'аура',
          desc: 'Зменшує швидкість пересування гравців на 50%.',
          whid: 355710
        },
        {
          type: 'spell',
          icon: 'ability_deathknight_frozencenter',
          name: 'Biting Cold',
          desc: 'Вішає дебаф на гравця, у вигляді кола, наносить 2968 пошкоджень від магії Льоду кожну секунду таргету і всім хто в колі протягом 10 сек.',
          whid: 356666
        },
        {
          type: 'spell',
          icon: 'spell_frost_frostbolt',
          name: 'Frost Lance',
          desc: 'Промінь, наносить 16488 пошкоджень від магії Льоду і відкидує.',
          whid: 356414
        },
        {
          type: 'spell',
          icon: 'spell_fire_bluehellfire',
          name: 'Cold Snap',
          desc: 'Кидає глиби льоду протягом 5 сек і б’є гравців в радіусі 3.5 ярдів від місця падіння.',
          whid: 358894
        },
      ]
    },
    {
      content: `
        ## Soggodon the Breaker

        [p]
          Соґґодон має ауру __Intimidation__, збільшує отримання Фізичних пошкоджень на 50%.
          Важливою механікою є __Massive Smash__, дуже боляче б’є, одночасно з цим кастом генерал
          притягне всіх до себе — __Bindings of Misery__. Потрібно розбити ланцюги дпсом, друїди,
          паладини і гноми можуть вийти самостійно.
        [/p]

        [p]
          [image/tormented-affix-4.jpg]
        [/p]
      `,
      aside: [
        {
          type: 'spell',
          icon: 'inv_icon_wingbroken04a',
          name: 'Intimidation',
          namet: 'аура',
          desc: 'Збільшує отримання Фізичних пошкоджень гравцями на 50%.',
          whid: 355719
        },
        {
          type: 'spell',
          icon: 'ability_warrior_decisivestrike',
          name: 'Crush',
          desc: 'Підтягує основну ціль до заклинача і розчавлює, танкам бути готовими.',
          whid: 358784
        },
        {
          type: 'spell',
          icon: 'spell_nature_earthquake',
          name: 'Seismic Wave',
          desc: 'Наносить Фізичні пошкодження по всій групі в радіусі 60 ярдів.',
          whid: 358970
        },
        {
          type: 'spell',
          icon: 'inv_misc_hook_01',
          name: 'Bindings of Misery',
          desc: 'Підтягує всіх гравців і застосовує __Bindings of Misery__, вкорінюючи їх на місці. Триває 8 секунд або до тих пір, поки не буде знищена.',
          whid: 358774
        },
        {
          type: 'spell',
          icon: 'ability_titankeeper_titanicsmash',
          name: 'Massive Smash',
          desc: 'Розбиває землю навколо себе, наносячи Фізичні пошкодження. Крім того, гравці, вражені цим ефектом, оглушені на 5 секунд.[br][br][image/tormented-affix-5.gif]',
          whid: 355806
        },
      ]
    },
    {
      content: `
        ## Executioner Varruth

        [p]
          Веіррюс має ауру __Thanatophobia__, зменшує вхідний хіл на 50%.
          Головна механіка генерала __Wave of Terror__, після неї одразу йде __Carnage__.
          Стоїмо разом, після фіру розбігаємось, після __Carnage__ (ривок) збігаємось.
        [/p]

        [p]
          [image/tormented-affix-6.jpg]
        [/p]
      `,
      aside: [
        {
          type: 'spell',
          icon: 'spell_misc_emotionafraid',
          name: 'Thanatophobia',
          namet: 'аура',
          desc: 'Зменшує вхідний хіл гравців на 50%.',
          whid: 355714
        },
        {
          type: 'spell',
          icon: 'inv_axe_1h_maldraxxus_d_01',
          name: 'Sever',
          desc: 'Робить боляче танку і вішає дот, Фізичне пошкодження.',
          whid: 356923
        },
        {
          type: 'spell',
          icon: 'spell_shadow_possession',
          name: 'Wave of Terror',
          desc: 'Наносить пошкодження від Темної магії всім в радіусі 60 ярдів, якщо ви не стоїте в 6 ярдах від союзників — отримаєте фір на 5 секнуд.',
          whid: 358971
        },
        {
          type: 'spell',
          icon: 'ability_warrior_trauma',
          name: 'Carnage',
          namet: 'ривок',
          desc: 'Робить ривок до таргета, наносить сильние Фізичне пошкодження таргету і всім в радіусі 5 ярдів, вішає Кровотечу що б’є раз в 5 сек протягом 8 секунд.',
          whid: 356925
        },
      ]
    }
  ]
}
