export default {
  slug: 'page-not-found',
  name: 'Сторінку не знайдено',
  header: true,
  content: `
    [p]
      Нажаль такої сторінки не існує.
      Можливо, вона була спалена Сильваною разом із Тельдрассілем [icon/emoticon-sad-outline]
    [/p]
  `
}
