export default {
  slug: 'grand-proctor-beryllia',
  parent: 'sanguine-depths',
  name: 'Grand Proctor Beryllia',
  short: 'Beryllia',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Dancecaptain (Makhabunda)' ],
  content: `
    <p>Поєдинок з босом, який полягає у переживанні hard hitting здібностей боса.</p>

    ## Rite of Supremacy

    <p>Довгий каст боса, який завдає смертельної шкоди.</p>

    <p>Пережити цю механіку можливо використавши потужну захисну здібність або
    зібравши достатньо **золотих куль** на землі. Ці кулі створює дружня нпц
    [npc/166642/Z'rali], читаючи __Fragment of Radiance__. Кожна зібрана куля
    вагомо зменьшує вхідну шкоду від __Rite of Supremacy__. Збираємо не більше
    3 кульок, щоб іншим також було достатньо.</p>
  `,
  aside: [
    {
      type: 'spell',
      icon: 'spell_animarevendreth_nova',
      name: 'Rite of Supremacy',
      desc: 'Довготривалий каст боса, завдає 20506 тіньових пошкоджень всім гравцям, після чого вішає доту __Agonize__ на 6 сек.',
      whid: 325360
    },
    {
      type: 'spell',
      icon: 'spell_holy_divineprovidence',
      name: 'Fragment of Radiance',
      namet: 'Z\'rali',
      desc: 'Невеликі **золоті кульки**, кожна з яких зменшує на 20% Тіньове пошкодження від __Rite of Supremacy__.',
      whid: 328737
    },
    {
      type: 'spell',
      icon: 'inv_artifact_bloodoftheassassinated',
      name: 'Endless Torment',
      desc: 'Бос впродовж тривалого часу розливає під ноги гравців воїд зони, які, якщо не відбігти до моменту їхнього появлення зі зони, наносять дуже значну кількість Святого пошкодження. Під час відповідного касту можна не бити боса, а дивитися виключно під ноги.',
      whid: 326039
    },
    {
      type: 'spell',
      icon: 'inv_knife_1h_revendrethquest_b_01',
      name: 'Iron Spikes',
      desc: 'Бос метає у танка підряд 4 списи, які наносять по окремості незначну кількість Фізичної шкоди, однак у сумі можуть бути летальними, якщо танк не застосує сейв здібність.',
      whid: 325254
    }
  ],
  attachments: [
    { type: 'video', videoId: 'DT0dHS9onLY', name: 'Аналіз М+20 від Денса (2й сезон)' },
    { type: 'video', videoId: 'rjR4kQrCIj0', name: 'Аналіз М+16 від Денса' },
    { type: 'post', link: '/guides/tormented-affix', name: '10+ афікс' },
  ]
}
