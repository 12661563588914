export default {
  slug: 'the-manastorms',
  parent: 'de-other-side',
  name: 'Манашторми',
  hint: 'The Manastorms',
  meta: {
    description: 'Тактика бою з Манаштормами в Іншому боці.',
    keywords: 'манашторм, манашторми, міллхаус, мілхаус, мілліфісента, мілліфісент, міліфісент, тактика, стратегія, механіка, гайд, бос, підземелля, данж, мітік, м+, інший бік, тінеземлі, manastorms, millhouse, millificent, de other side, shadowlands'
  },
  header: { parent: true },
  sidenav: true,
  authors: [ 'Skye' ],
  attachments: [
    { type: 'video', videoId: 'Nl8_1L4zz54', name: 'Аналіз М+20 від Денса (2й сезон)' },
    { type: 'post', link: '/guides/tormented-affix', name: '10+ афікс' }
  ],
  content: `
    [p]Бій має дві фази які чергуються кожні ~50 сек. На кожній фазі лише один бос буде на арені
    з гравцями. Інший -- вистрибує за арену, стає недоступний, але продовжує застосовувати певні
    закляття. Кожен з босів, поза ареною має одне закляття яке допомагає гравцям переривати вимову
    закляття іншого.[/p]

    [p]Здоров'я босів не спільне. Бій з конкретним босом завершується при досягененні 10%; при цьому
    він стає дружнім, вистрибує за арену й більше не повертається. Також він не використовує закляття
    які допомагають гравцям переривати активному босу вимову його заклятть -- робіть так щоб не довелося
    вести довгий бій без другого боса.[/p]

    [p]На початку бою Міллхаус залишається, Мілліфісента вистрибує за арену.[/p]

    ## На арені Міллхаус

    [p]Бос застосовує __кристал сили__, промінь від якого, щосекунди підсилюватиме його -- гравець
    має перекрити цей промінь.[/p]

    [p]Неактивна Мілліфісента буде застосовувати __зигзаг__. Два гравці будуть відмічені
    червоними стрілками, через 5 сек через них пройде лазер зигзагоподібним шляхом, завдаючи шкоду
    гравцям на своєму шляху, а також перериваючи вимову закляття активним босом -- використовуйте
    це щоб переривати вимови __дуууму__ Міллхаусом -- для цього гравцям зі стрілками потрібно
    позиціонуватися так щоб бос був між ними.[/p]

    ## На арені Мілліфісента

    [p]Скажена гномка застосовує __бомби__. Вони з'являються зарядженими, рухаються від неї
    у різних напрямках і невдовзі вибухають -- гравець має підбігти до бомби та деактивувати.[/p]

    [p]Неактивний Міллхаус буде застосовувати __фіолетове коло__ на гравця, відмічаючи
    його білою стрілкою; через декілька секунд цей гравець і всі хто в колі, включаючи активного
    боса будуть приголомшені -- використовуйте це щоб переривати __бомбардування__ Мілліфісентою,
    яке вона починає наприкінці своєї фази.[/p]
  `,
  aside: [
    {
      type: 'header',
      name: 'Міллхаус Манашторм'
    },
    {
      type: 'spell',
      icon: 'spell_frost_frostbolt02',
      name: 'Frostbolt',
      desc: 'Завдає [1649/frost] та сповільнює швидкість руху на 10% на 3 сек.',
      whid: 320008
    },
    {
      type: 'spell',
      icon: 'inv_datacrystal02',
      name: 'Summon Power Crystal',
      namet: 'кристал сили',
      desc: 'Прикликає кристал сили який фокусується у вигляді променя у напрямку Міллхауса, підсилюючи його, якщо на шляху немає гравця.[br][br]При дії на гравця, завдає [132/arcane] щосекунди та збільшує шкоду від нього на 2% на 3 сек. Цей ефект складається.[br][br]При дії на Міллхауса, збільшує шкоду від нього на 4% на 3 сек. Цей ефект складається.',
      audio: 'https://wow.zamimg.com/sound-ids/live/enus/148/3582100/VO_901_Millhouse_Manastorm_193609.ogg',
      whid: 320787
    },
    {
      type: 'spell',
      icon: 'spell_shadow_charm',
      name: 'Diabolical Dooooooom!',
      namet: 'дууум',
      desc: 'Завдає серію ударів такої диявольської сили, яку неможливо передати словами. Вражені цілі також не взмозі осягнути глибини дії цього закляття, через те що його сліпуча геніальність вимивається, немов припливна хвиля спустошення.[br][br]Завдає [3298/shadow] всім ворогам щосекунди протягом 15 сек, збільшуючи ушкодження на 10%. Цей ефект складається.',
      audio: 'https://wow.zamimg.com/sound-ids/live/enus/149/3582101/VO_901_Millhouse_Manastorm_193607.ogg',
      whid: 320142
    },
    {
      type: 'spell',
      icon: 'spell_shadow_shadowfury',
      name: 'Shadowfury',
      namet: 'фіолетове коло',
      desc: 'Вивільняє приховану лють, завдає [6595/shadow] та приголомшує всіх гравців в радіусі 8 метрів на 2 сек. Цей ефект також діє на Мілліфісенту.',
      audio: 'https://wow.zamimg.com/sound-ids/live/enus/150/3582102/VO_901_Millhouse_Manastorm_193608.ogg',
      whid: 320132
    },
    {
      type: 'header',
      name: 'Мілліфісента Манашторм'
    },
    {
      type: 'spell',
      icon: 'inv_sword_1h_deathwingraiddw_d_01',
      name: 'Buzz-Saw',
      desc: 'Проштрикує ціль своєю пилою, знерухомлює на 1 сек, завдає [1979/physical] кожні 0.5 сек протягом 3 сек та спричиняє [spell/320147/кровотечу].',
      whid: 320144
    },
    {
      type: 'spell',
      icon: 'inv_misc_bomb_01',
      name: 'Experimental Squirrel Bomb',
      namet: 'бомба',
      desc: 'Бомба заряджається короткий час і вибухає, завдає [10882/fire] всім гравцям. Бомба можне бути деактивована або знищена до того як вибухне.',
      audio: 'https://wow.zamimg.com/sound-ids/live/enus/129/3582081/VO_901_Millificent_Manastorm_193617.ogg',
      whid: 320823
    },
    {
      type: 'spell',
      icon: 'inv_misc_enggizmos_rocketchicken',
      name: 'Aerial Rocket Chicken Barrage',
      namet: 'бомбардування',
      desc: 'Активує ракетне бомбардування підсиленими півнями, що завдають [989/fire] всім гравцям при приземленні.',
      audio: 'https://wow.zamimg.com/sound-ids/live/enus/127/3582079/VO_901_Millificent_Manastorm_193615.ogg',
      whid: 321061
    },
    {
      type: 'spell',
      icon: 'ability_siege_engineer_superheated',
      name: 'Delta Finger Laser X-treme',
      namet: 'зигзаг',
      desc: 'Лазерний промінь, проходить через випадкового гравця; миттево завдає [513/fire] та додатково [126/fire] кожні 2 сек протягом 12 сек.',
      audio: 'https://wow.zamimg.com/sound-ids/live/enus/128/3582080/VO_901_Millificent_Manastorm_193616.ogg',
      whid: 201159
    }
  ]
}
