export default {
  slug: 'nzoth-the-corruptor',
  parent: 'nyalotha',
  name: 'Н\'Зот Спотворювач',
  hint: 'N\'Zoth the Corruptor',
  short: 'Н\'Зот',
  group: 'The Waking Dream',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Skye' ],
  content: `
    <ul>
      <li>рейд ділиться на дві частини</li>
      <li>збивати касти адам-очам</li>
      <li>відновлювати саніті перед заходом в портал; не рухатися під час відновлення</li>
      <li><em>Параноя</em> — з'єднує два вар'ята — збігатися; ніхто крім вас не має бути поряд</li>
      <li><em>на останній фазі</em>:
        <ul>
          <li>з дебафами зібратися одну точку (в танка); в момент скидання рухатися в сторону</li>
          <li>збігатися в коло від ада, якщо в вас хоча б половина саніті</li>
          <li>ухилятися від променів</li>
        </ul>
      </li>
      <li>героїзм на останній фазі (~50% хп боса)</li>
    </ul>
  `,
  attachments: [
    { type: 'video', videoId: 't2xcxuGbdVE', name: 'Першокіл (героїк)' }
  ]
}
