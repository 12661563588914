export default {
  slug: 'soleah',
  parent: 'tazavesh',
  name: 'Со\'лея',
  hint: 'So\'leah',
  image: '/img/soleah.jpg',
  group: { name: 'Розкішний нексус', hint: 'The Opulent Nexus' },
  meta: {
    description: 'Тактика бою проти Со\'леї у Тазавеші.',
    keywords: 'со\'лея, солея, со\'леаг, солеаг, со\'леах, солеах, тактика, стратегія, механіка, гайд, бос, тазавеш, підземелля, данж, мітік, м+, тінеземлі, so\'leah, soleah, tazavesh, market, dungeon, shadowlands'
  },
  header: { parent: true },
  sidenav: true,
  authors: [ 'Skye' ],
  content: `
    [p]Бій проти [npc/180863/Со'леї] -- лідерки картелю Со, де у гравців є невеликий шанс отримати
    чудовий [item/186638/глайдер].[/p]

    [p]Бій має дві фази. Перша фаза закінчується коли здоров'я Со'леї падає до 40%. Після чого вона
    трансформується, повністю відновлює здоров'я й починається друга фаза, яка залишається до самого
    кінця бою.[/p]

    ## Перша фаза

    [p]Со'лея прикликатиме [npc/177716/посіпак] парами, яких потрібно швидко вбивати, при цьому
    не дозволяючи їм задіяти __сюрикени__. Посіпака телепортується напочатку, потім у вас є близько
    трьох секунд щоб йому завадити, інакше всі гравці отримають сильну кровотечу, з якою буде важко
    впоратися вашому цілителю.[/p]

    [p]Со'лея утворюватиме __коло__ неподалік, яке має 4 заряди. Кожен раз коли гравець торкається
    кола, всіх гравців вражає [spell/350804/колапсуюча енергія], яка діє 3 сек. Якщо протягом цього
    часу торкнути коло знову -- ефект складеться. Тому, обов'язково витримуйте паузу -- слідкуйте
    за тим щоб ефект колапсуючої енергії встигав проходити. Але не гайте багато часу, адже
    через 25 сек від початку створення __кола__, воно вибухне зі всіма залишеними зарядами.[/p]

    [p]Коли здоров'я Со'леї зменшується до 40%, вона повністю відновлює своє здоров'я та починається
    наступна фаза.[/p]

    ## Друга фаза

    [p]Со'лея більше не буде прикликати посіпак. Натомість, навколо неї з'явиться 5 реліквій у
    вигляді зірок. Інколи бос буде швидко [spell/351057/переміщувати] їх по арені. Кожен раз,
    коли бос вкривається __щитом__, вона буде застосовувати __стрілки__ до тих пір, поки не влучити
    ними кожну зірку.[/p]

    [p]__Щит__ зменьшує вхідне пошкодження боса на 99%, а також __стрілки__ вдаряють гравців кожен
    наступний раз на 50% сильніше. Намагайтеся одразу визначитися де ваша зірка, щоб кожен гравець
    точно знав до якої зірки йому бігти.[/p]

    [p]Со'лея використовує __промені__ у вигляді почергового вибуху зірок. Кожен вибух супроводжується
    вивільненням променів у всіх напрямках. Намагайтеся бути подалі від зірок на цю механіку, щоб було
    легше ухилятися від них. Потрапляння навіть в один такий промінь може бути фатальним, адже вас
    відкине, можливо в інші промені. Рекомендовано використовувати здібності що надають імунітет,
    якщо ви не впевнені що зможете успішно ухилитися.[/p]

    [p]Со'лея застосовує __Hyperlight Nova__, у вигляді великих кіл навколо кожної зірки,
    а також неї самої. Всі, включаючи танка, мають уникати них.[/p]

    [p]__Коло__ з попередньої фази буде продовжувати з'являтися без змін. Додаткова складність
    з ним буде лише в накладанні механік цієї фази.[/p]
  `,
  aside: [
    {
      type: 'image',
      src: '/img/soleah.jpg'
    },
    {
      type: 'header',
      name: 'So\' Cartel Assassin',
      namet: 'посіпака'
    },
    {
      type: 'spell',
      whid: 351119,
      icon: 'ability_hunter_assassinate2',
      name: 'Shuriken Blitz',
      namet: 'сюрикени',
      desc: `
        Швидко змінює позицію та готує залп сюрикенів. Завдає [8228/physical] всім гравцям
        та викликає кровотечу, яка завдає [4388/physical] щосекунди протягом 10 сек.
      `
    },
    {
      type: 'header',
      name: 'Со\'лея'
    },
    {
      type: 'spell',
      whid: 351124,
      icon: 'achievement_guildperk_havegroup-willtravel',
      audio: 'https://wow.zamimg.com/sound-ids/live/enus/27/4070683/VO_91_Soleah_208702.ogg',
      name: 'Summon Assassins',
      namet: 'посіпаки',
      desc: `
        Со'лея прикликає елітних вбивць на допомогу.
      `
    },
    {
      type: 'spell',
      whid: 350796,
      icon: 'spell_broker_buff',
      name: 'Hyperlight Spark',
      desc: `
        Со'лея створює надсвітловий спалах, який стрибає між всіма гравцями, завдаючи [6582/arcane].
      `
    },
    {
      type: 'spell',
      whid: 353635,
      icon: 'spell_broker_orb',
      audio: 'https://wow.zamimg.com/sound-ids/live/enus/18/4070674/VO_91_Soleah_208709.ogg',
      name: 'Collapsing Star',
      namet: 'коло',
      desc: `
        Со'лея формує кулю, яка утримує 4 заряди колапсуючої енергії. Вона повільно падає на землю
        протягом наступних 25 сек. Навколо кулі є коло. Щоразу гравець торкається цього кола, заряд
        поглинається та всіх гравців вражає [spell/350804/колапсуюча енергія]. Яка, в свою чергу,
        завдає [4114/arcane] щосекунди протягом 3 сек. Цей ефект складається.
        [br][br]
        По завершенюю, куля падає на землю, вражаючи гравців всіма залишеними зарядами колапсуючої
        енергії.
      `
    },
    {
      type: 'spell',
      whid: 351086,
      icon: 'spell_mage_overpowered',
      audio: 'https://wow.zamimg.com/sound-ids/live/enus/28/4070684/VO_91_Soleah_208687.ogg',
      name: 'Power Overwhelming',
      namet: 'щит',
      desc: `
        Со'лея формує зв'язок із реліквіями, використовуючи їх енргію для зменьшення вхідного
        пошкодження на 99%.
        [br][br]
        Враження реліквії __стрілкою__, розриває її зв'язок із Со'леєю.
      `
    },
    {
      type: 'spell',
      whid: 350875,
      icon: 'spell_animabastion_beam',
      audio: 'https://wow.zamimg.com/sound-ids/live/enus/22/4070678/VO_91_Soleah_208723.ogg',
      name: 'Hyperlight Jolt',
      namet: 'стрілка',
      desc: `
        Со'лея вивільняє вибух енергії крізь всіх гравців, завдаючи [10970/arcane] в лінії між
        кожним гравцем та збільшує їхне вхідне пошкодження від __стрілок__ на 50% протягом
        15 сек. Цей ефект складається.
        [br][br]
        Враження реліквії таким вибухом розриває її зв'язок із Со'леєю.
      `
    },
    {
      type: 'spell',
      whid: 351096,
      icon: 'spell_broker_missile',
      audio: 'https://wow.zamimg.com/sound-ids/live/enus/20/4070676/VO_91_Soleah_208722.ogg',
      name: 'Energy Fragmentation',
      namet: 'промені',
      desc: `
        Со'лея спричиняє несамовиту реакцію в реліквії, вивільняючи фрагменти енергії в різних
        напрямках. При контакті з фрагментом енергії, гравця відкидує, завдаючи [18101/arcane] та
        додатково [5485/arcane] кожні 2 сек протягом 6 сек.
        [br][br]
        [image/soleah-1.jpg]
      `
    },
    {
      type: 'spell',
      whid: 351646,
      icon: 'spell_broker_nova',
      audio: 'https://wow.zamimg.com/sound-ids/live/enus/19/4070675/VO_91_Soleah_208719.ogg',
      name: 'Hyperlight Nova',
      desc: `
        Со'лея та реліквії спалахують, завдаючи [27425/arcane] гравцям у радіусі 14 метрів.
        [br][br]
        [image/soleah-2.jpg]
      `
    }
  ]
}
