export default {
  slug: 'yak-proytu-nepomichenum-v-klyuchah',
  parent: 'guides',
  name: 'Як пройти непоміченим в ключах',
  header: { parent: true },
  image: 'https://wow.zamimg.com/uploads/screenshots/small/34634.jpg',
  authors: [ 'Ta-na' ],
  content: `
    [p]Існує три види контролю, які дозволяють пройти повз мобів, так, щоб вони вас не помітили.
    Сап роги, клітка дх і суз від пріста.[/p]

    [html]
      <div style="display: flex; height: 360px; max-height: 60vh;">
        <div style="flex: 1; display: flex;">
          <a style="flex: 1; display: flex;" href="https://www.wowhead.com/spell=453" target="_blank">
            <img style="width: 100%; height: 100%; object-fit: cover;" src="https://wow.zamimg.com/uploads/screenshots/normal/70338-mind-soothe.jpg" alt="Mind Soothe" />
          </a>
        </div>
        <div style="flex: 1; display: flex;">
          <a style="flex: 1; display: flex;" href="https://www.wowhead.com/spell=6770" target="_blank">
            <img style="width: 100%; height: 100%; object-fit: cover;" src="https://wow.zamimg.com/uploads/screenshots/small/34634.jpg" alt="Sap" />
          </a>
        </div>
        <div style="flex: 1; display: flex;">
          <a style="flex: 1; display: flex;" href="https://www.wowhead.com/spell=217832" target="_blank">
            <img style="width: 100%; height: 100%; object-fit: cover;" src="https://wow.zamimg.com/uploads/screenshots/small/552441.jpg" alt="Imprison" />
          </a>
        </div>
      </div>
    [/html]

    [p]Чому саме вони? Дуже просто! Прістівсікий суз зменшує видимість мобів, а от клітка дх і сап роги
    повністю виводять з гри і ви можете танцювати перед ними. Всі інші види контролю на кшталт трапи ханта,
    сону друїда і т.д. не зменшують видимість моба і пройшовши повз, він загриться.[/p]
  `
}
