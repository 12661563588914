export default {
  slug: 'drestagath',
  parent: 'nyalotha',
  name: 'Дрест\'аґат',
  hint: 'Drest\'agath',
  group: 'Gift of Flesh',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Skye' ],
  content: `
    <ul>
      <li>три типи адів: очі, зубастики, тентаклі
        <ul>
          <li>до зубастиків ніхто ніколи не підходить</li>
          <li>рдд бьють очі й зубастиків</li>
          <li>мдд бьють тентаклі; вони мають туман навколо себе; бігайте за хілом</li>
        </ul>
      </li>
      <li>після смерті, будь який ад, навколо себе залишає кулькі — всі дд мають їх брати
        <ul>
          <li>бити боса, коли на вас баф кульки</li>
          <li>після спадіння бафу, буде дебаф, недозволяючий брати кульку знову певний час</li>
        </ul>
      </li>
      <li>ухилятися від променів та від аое на землі</li>
      <li>відбігати від всіх, коли на вас коло</li>
      <li>танки міняються в момент коли на активного танка вішається фіолетове коло
        <ul><li>з цим колом танк біжить до ада, скидає коло і дебафає ада на вхідний дамаг</li></ul>
      </li>
      <li>героїзм після вбивства перших очей побоках</li>
    </ul>
  `,
  attachments: [
    { type: 'video', videoId: 'tbPXa1udr18', name: 'Першокіл (героїк)' }
  ]
}
