export default {
  slug: 'lord-chamberlain',
  parent: 'halls-of-atonement',
  name: 'Lord Chamberlain',
  short: 'Chamberlain',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Ta-na' ],
  content: `
    ## Pull

    <p>Гера і бурсти з пула, бій простий, уникайте __Telekinetic Repulsion__ і __Unleashed Suffering__.
    Інколи бос метатиме **статую** в гравців — __Telekinetic Toss__, не стійте на шляху.</p>

    <p>__Ritual of Woe__ — стягує **статуї**, не попадайте під них, потім відштовхує пошкоджуючи всіх
    на шляху і витягує енергію, потрібно перекрити **промені**.</p>

    <p>Танкам бути уважним з дотою __Stigma of Pride__.</p>
  `,
  aside: [
    {
      type: 'spell',
      icon: 'ability_deathwing_bloodcorruption_death',
      name: 'Stigma of Pride',
      desc: 'Дота, наносить 2929 пошкоджень від темної магії раз в три секунди протягом 18 сек. Пошкодження збільшуються на 30% з кожним тіком.',
      whid: 323437
    },
    {
      type: 'spell',
      icon: 'ability_ironmaidens_bloodritual',
      name: 'Unleashed Suffering',
      desc: 'Наносить 11718 пошкоджень від темної магії всім в конусі перед собою.',
      whid: 323236
    },
    {
      type: 'spell',
      icon: 'ability_socererking_forcenova',
      name: 'Telekinetic Repulsion',
      desc: 'Наносить 10253 фізичних пошкоджень і відкидає назад.',
      whid: 323129
    },
    {
      type: 'spell',
      icon: 'spell_fire_moltenblood',
      name: 'Telekinetic Toss',
      desc: 'Жбурляє **статую** наносячи 10253 фізичних пошкоджень.',
      whid: 323143
    },
    {
      type: 'spell',
      icon: 'spell_deathknight_vendetta',
      name: 'Ritual of Woe',
      desc: 'Витягує енергію зі **статуй** наносячи 2344 пошкоджень від темної магії всім гравцям.<br><br>Якщо на шляху **променя** є гравець, шкоду отримує лише він.',
      whid: 323393
    },
  ],
  attachments: [
    { type: 'video', videoId: '-IdFbS8oG3Q', name: 'Тиранія М+20 від Денса (2й сезон)' },
    { type: 'video', videoId: '7Omu-CwQpNs', name: 'Аналіз М+17 від Денса' },
    { type: 'post', link: '/guides/tormented-affix', name: '10+ афікс' },
  ]
}
