export default {
  slug: 'plaguefall',
  parent: 'dungeons',
  name: 'Чумоспад',
  hint: 'Plaguefall',
  image: '/img/plaguefall.jpg',
  group: 'Тінеземлі',
  meta: {
    description: 'Підземелля Чумоспад.',
    keywords: 'чумоспад, плейгфол, підземелля, данж, мітік, м+, тінеземлі, plaguefall, pf, dungeon, shadowlands'
  },
  header: { parent: true },
  archive: true
}
