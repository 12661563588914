export default {
  slug: 'executor-tarvold',
  parent: 'sanguine-depths',
  name: 'Executor Tarvold',
  short: 'Tarvold',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Dancecaptain (Makhabunda)' ],
  content: `
    <p>Доволі складний поєдинок, який дуже залежить від кількості дпсу вашої групи, хілера,
    позиціонування задля уникнення контакту вашого персонажа з **Sintouched Anima** та
    грамотного використання бурстів на кожного ада **Fleeting Manifestation** і сейвів
    на __Castigate__.</p>

    ## Fleeting Manifestation

    <p>Адд, який з'являється після каста боса __Coalesce Manifestation__, має незначну кількість
    життя і наносить Тіньове пошкодження всій групі кожні дві секунди. Маніфестейшн повинен бути
    вбитим якнайшвидше, адже чим довше він живе, тим більше він себе підсилює та більшу калюжу
    створить по смерті.</p>
  `,
  aside: [
    {
      type: 'spell',
      icon: 'spell_animarevendreth_orb',
      name: 'Sintouched Anima',
      desc: 'Дві червоні кулі, які самостійно катаються по колу в протилежних напрямках, при контакті з якими на гравця вішається дебаф, який наносить незначну кількість Тіньового пошкодження та знижує швидкість (haste) на 25%.[br][br]Намагаємось тримати боса і групу біля тієї стіни повз яку тільки що прокотилась куля. Таким чином міняємо позицію впродовж всього бою рухаючись з ліва на право.',
      whid: 328494
    },
    {
      type: 'spell',
      icon: 'ability_rogue_vendetta',
      name: 'Castigate',
      desc: '**Тарволд** обирає одного з гравців та направляє у нього промінь, який наносить значну кількість Тіньового пошкодження, а також створює коло навколо відповідного гравця, у якому дублюється пошкодження іншим гравцям, якщо вони у ньому знаходяться.[br][br]Переживається або сильним захілом + сейвом, або ультимативним сейвом. Хантери і Найт Ельфи можуть скинути ченел зі себе прикинувшись мертвим, або сховавшись у тінь.',
      whid: 322554
    },
    {
      type: 'spell',
      icon: 'ability_vanish',
      name: 'Coalesce Manifestation',
      desc: '**Тарволд** прикликає ада [npc/168882/Fleeting Manifestation].',
      whid: 322574
    }
  ],
  attachments: [
    { type: 'video', videoId: 'DT0dHS9onLY', name: 'Аналіз М+20 від Денса (2й сезон)' },
    { type: 'video', videoId: 'rjR4kQrCIj0', name: 'Аналіз М+16 від Денса' },
    { type: 'post', link: '/guides/tormented-affix', name: '10+ афікс' },
  ]
}
