export default {
  slug: 'the-grand-menagerie',
  parent: 'tazavesh',
  name: 'Великий звіринець',
  hint: 'The Grand Menagerie',
  image: '/img/the-menagerie.jpg',
  group: { name: 'Двір', hint: 'The Courtyard' },
  meta: {
    description: 'Тактика бою з босами звіринця -- Alcruux, Achillite та Venza Goldfuse у Тазавеші.',
    keywords: 'великий, звіринець, менагеріт, зоопарк, звірі, алькруукс, алкруукс, ачілліт, венза, голдфьюз, тактика, стратегія, механіка, гайд, бос, тазавеш, підземелля, данж, мітік, м+, тінеземлі, grand, menagerie, alcruux, achillite, venza, goldfuse, tazavesh, market, dungeon, shadowlands'
  },
  header: { parent: true },
  sidenav: true,
  authors: [ 'Skye' ],
  contentrows: [
    {
      content: `
        [p]Бій проти трьох босів -- [npc/176556/Alcruux], [npc/176555/Achillite] та [npc/176705/Venza Goldfuse],
        які вступають у бій по черзі. Кожен наступний приєднується невдовзі після того, як здоров'я попереднього
        зменьшиться до 40%.[/p]

        [p]Кожен раз коли на арені два боси, намагайтеся швидко добити попереднього, щоб мінімізувати час
        накладання їхніх механік.[/p]
      `,
      aside: [
        {
          type: 'image',
          src: '/img/the-menagerie.jpg'
        }
      ]
    },
    {
      content: `
        ## Alcruux

        ### Вихор

        [p]На початку бою Alcruux одразу задіє __вихор__ на найближчого гравця. Цей ефект буде перестрибувати на
        найближчого гравця кожні 21 сек до самого кінця бою. Носій __вихору__ завдаватиме пошкодження всім у радіусі
        5 метрів, тому намагайтеся робити так щоб ціль цього закляття не була танком чи бійцем ближнього бою.[/p]

        ### Кола

        [p]Час від часу Alcruux буде задіювати __кола__, у вигляді двох хвиль синіх куль, що повільно падають на землю,
        при чому кожна куля відображає велике сине коло дії свого вибуху на землі. Куля падає протягом 4 сек, після чого
        вибухає. Вся механіка займає 6 сек:[br]
        => 0 сек -- перша хвиля з'явилася -- біжіть до краю одного з кіл[br]
        => 2 сек -- друга хвиля з'явилася[br]
        => 4 сек -- перша хвиля вибухає -- забіжіть в коло щойно вибухнувшої кулі, так щоб не бути в колах другої хвилі[br]
        => 6 сек -- друга хвиля вибухає[/p]
      `,
      aside: [
        {
          type: 'spell',
          whid: 349498,
          icon: 'spell_animaardenweald_buff',
          name: 'Gluttonous Feast',
          namet: 'вихор',
          desc: `
            Alcruux інфікує найближчого гравця есенцією голоду на 21 сек, дозволяючи йому безпечно
            поглинати __кулі__ непостійної аніми, але також завдає [5485/shadow] щосекунди всім гравцям
            у радіусі 5 метрів.
            [br][br]
            Після завершення, __вихор__ переходить на іншого найближчого гравця.
          `
        },
        {
          type: 'spell',
          whid: 349663,
          icon: 'spell_animaardenweald_wave',
          name: 'Grip of Hunger',
          namet: 'притягування',
          desc: `
            Alcruux миттєво притягує до себе всіх гравців і через 4 сек вдаряє по землі з вибуховою силою,
            завдаючи [21940/shadow] всім гравцям у радіусі 20 метрів.
          `
        },
        {
          type: 'spell',
          whid: 349797,
          icon: 'spell_animaardenweald_nova',
          name: 'Grand Consumption',
          namet: 'кола',
          desc: `
            Alcruux створює хвилі куль поглинання, що вибухають через 4 сек, висмоктуючи 21940 здоров'я
            зі всіх гравців у радіусі 12 метрів.
            [br][br]
            [image/the-menagerie-1.gif]
          `
        }
      ]
    },
    {
      content: `
        ## Achillite

        [p]Вступивши в бій, Achillite, майже одразу застосує __Purification Protocol__ на двох гравців,
        при цьому вони будуть відмічені великими синіми колами. Ці гравці мають розбігтися подалі, щоб
        вибухом не задіти інших.[/p]

        [p]Коли Achillite застосовує __Flagellation Protocol__, всі бійці мають зосередитися на ньому,
        щоб знищити щит якомога швидше, навіть якщо попередній бос ще живий.[/p]

        ### Кулі непостійної аніми

        [p]Час від часу Achillite буде зупинятися й вивільняти __кулі__ непостійної аніми протягом
        декількох секунд. Ці кулі будуть повільно рухатися по прямих траекторіях й відбиватися від стін
        арени. Гравець з __вихором__ має збирати їх -- чим більше, тим краще.[/p]

        [p]Після смерті, тіло робота продовжує вивільняти __кулі__ через
        [spell/350037/незахищене ядро аніми].[/p]
      `,
      aside: [
        {
          type: 'spell',
          whid: 349954,
          icon: 'spell_animabastion_groundstate',
          audio: 'https://wow.zamimg.com/sound-ids/live/enus/246/4075510/VO_91_Achillite_208039.ogg',
          name: 'Purification Protocol',
          desc: `
            Achillite вражає двох гравців нестабільною анімою, завдаючи [1646/holy] щосекунди
            протягом 6 сек. По завершенню, вибухає, завдаючи [15358/holy] всім гравцям у радіусі
            15 метрів.
          `
        },
        {
          type: 'spell',
          whid: 349934,
          icon: 'spell_animabastion_missile',
          audio: 'https://wow.zamimg.com/sound-ids/live/enus/244/4075508/VO_91_Achillite_208037.ogg',
          name: 'Flagellation Protocol',
          desc: `
            Achillite вкривається щитом, який поглинає 36567 пошкодження та запобігає перериванню
            дій. Після цього він починає лупцювати свою ціль, завдаючи [10970/physical] кожні
            0.5 сек протягом 5 сек.
          `
        },
        {
          type: 'spell',
          whid: 349987,
          icon: 'spell_animabastion_buff',
          audio: 'https://wow.zamimg.com/sound-ids/live/enus/240/4075504/VO_91_Achillite_208040.ogg',
          name: 'Venting Protocol',
          namet: 'кулі',
          desc: `
            Achillite охолоджує своє ядро, вивільняючи __кулі__ непостійної аніми. Завдає [5485/holy]
            та відштовхує всіх гравців в радіусі 7.5 метрів.
            [br][br]
            При зіткненні __кулі__:[ul]
            [li]з гравцем без __вихору__, вибухає [spell/349989/непостійною анімою] -- завдає
            [19198/holy] всім гравцям в радіусі 4 метри та зменьшує їхнє пошкодження на 5% на 1 хв.
            Цей ефект складається.[/li]
            [li]з гравцем із __вихором__, поглинається й підсилює його
            [spell/350010/поглиненою анімою] -- збільшує пошкодження на 5% на 18 сек.
            Цей ефект складається.[/li]
            [/ul]
          `
        }
      ]
    },
    {
      content: `
        ## Venza Goldfuse

        [p]Venza буде погіршувати життя гравців двома здібностями: притягувати всіх до себе під
        час __Whirling Annihilation__ та знерухомлювати випадкового гравця __ланцюгами__.[/p]

        [p]Якщо на вас __ланцюги__, намагайтеся використовувати здібності, які знімають ефекти
        заповільнення (наприклад, [spell/781/звільнення] у мисливців). У випадках, коли це
        неможливо та в групі немає того, хто може зняти цей ефект дистанційно -- бійцям потрібно
        фокусувати пошкодження на [npc/177237/ланцюгах].[/p]
      `,
      aside: [
        {
          type: 'spell',
          whid: 350101,
          icon: 'inv_misc_steelweaponchain',
          audio: 'https://wow.zamimg.com/sound-ids/live/enus/64/4075584/VO_91_Venza_Goldfuse_208046.ogg',
          name: 'Chains of Damnation',
          namet: 'ланцюги',
          desc: `
            Venza приковує до землі ланцюгами випадкового гравця, зменшуючи швидкість його пересування
            на 100% доти, доки їх не буде знищено.
          `
        },
        {
          type: 'spell',
          whid: 350086,
          icon: 'spell_animamaw_groundstate',
          audio: 'https://wow.zamimg.com/sound-ids/live/enus/67/4075587/VO_91_Venza_Goldfuse_208049.ogg',
          name: 'Whirling Annihilation',
          desc: `
            Venza скажено крутиться, притягуючи всіх гравців до себе. Завдає [32910/physical] щосекунди
            всім гравцям в радіусі 7 метрів.
          `
        }
      ]
    }
  ]
}
