export default {
  slug: 'ingra-maloch',
  parent: 'mists-of-tirna-scithe',
  name: 'Ingra Maloch',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Ta-na', 'Dancecaptain (Makhabunda)' ],
  content: `
    <h2>В чому суть</h2>
    <p>Ingra Maloch контролить Droman Oulfarran і змушує його дамажити гравців —
    Bewildering Pollen і Tears of the Forest, в цей час Ingra Maloch захищени
    аурою Embrace Darkness, тому треба просадити Droman Oulfarran до 20% —
    Droman's Wrath, і потім забустити в Ingra Maloch.</p>

    <h2>Pull</h2>
    <p>Дамажим Droman Oulfarran, інтераптимо Ingra Maloch, не попадаємо в промінь —
    <u>Bewidering Pollen</u>, виходим з калюж.</p>

    <h2>Наступна фаза</h2>
    <p>Здаємо геру, на цю фазу повинні бути бусти оскільки Droman Oulfarran збільшує
    вхідну шкоду по <u>Ingra Maloch на 200%</u>.</p>
  `,
  aside: [
    {
      type: 'header',
      name: 'Ingra Maloch',
      namet: 'Друст',
    },
    {
      type: 'spell',
      icon: 'ability_warlock_soullink',
      name: 'Soul Shackle',
      namet: 'Соул Шейкл',
      desc: 'Змушує атакувати гравців допоки має більше 20% хп.',
      whid: 321005
    },
    {
      type: 'spell',
      icon: 'spell_fire_twilightflamebolt',
      name: 'Spirit Bolt',
      namet: 'Спіріт Болт',
      desc: 'Наносить 4 823 шкоди від Темної магії.',
      whid: 323057
    },
    {
      type: 'spell',
      icon: 'spell_shadow_psychicscream',
      name: 'Repulsive Visage',
      namet: 'Репулсів Віседж',
      desc: 'Фіряє всіх гравців на три секунди, магія.',
      whid: 328756
    },
    {
      type: 'spell',
      icon: 'spell_shadow_rune',
      name: 'Embrace Darkness',
      namet: 'Імбрайс Даркнес',
      desc: 'Зменьшує шкоду від гравців на 80%, після спуску пенька до 20% він знімає цей дебаф.',
      whid: 323149
    },
    {
      type: 'header',
      name: 'Droman Oulfarran',
      namet: 'Пеньок'
    },
    {
      type: 'spell',
      icon: 'inv_misc_herb_plaguebloom',
      name: 'Bewildering Pollen',
      namet: 'Бівайлдерінґ Поллен',
      desc: 'Промінь наносить 7 031 шкоди від природи і дезорієнтує на 4 сек.',
      whid: 323137
    },
    {
      type: 'spell',
      icon: 'inv_misc_volatilewater',
      name: 'Tears of the Forest',
      namet: 'Тіарс оф зе Форест',
      desc: 'Розкидує краплі що наносять 4 687 природньої шкоди при попаданні і лишає калюжу.',
      whid: 323177
    },
    {
      type: 'spell',
      icon: 'inv_misc_herb_nightmarevine',
      name: 'Droman\'s Wrath',
      namet: 'Дромас Врас',
      desc: 'Знімає <u>Embrace Darkness</u> з друста і збільшує отриману шкоду на 200% протягом 12 сек.',
      whid: 323059
    }
  ],
  attachments: [
    { type: 'video', videoId: 'H7rr8aTKDk0', name: 'Аналіз М+20 від Денса (2й сезон)' },
    { type: 'video', videoId: 'X-WtKtC7lMs', name: 'Аналіз М+16 від Денса' },
    { type: 'post', link: '/guides/tormented-affix', name: '10+ афікс' },
  ]
}
