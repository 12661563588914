<template>
<nav class="app-navbar">
  <ul class="menu logo">
    <li><router-link class="link" to="/">Кнайпа<br>Вар'ятів</router-link></li>
  </ul>
  <ul class="menu main">
    <li class="mobile-home"><router-link class="link" to="/" exact>Головна</router-link></li>
    <li>
      <router-link class="link" to="/roster">Вар'яти</router-link>
      <ul class="menu sub d-none d-sm-flex">
        <li><router-link class="link" to="/roster" exact>Ростер</router-link></li>
        <li><router-link class="link" to="/roster/rankings">Лідери</router-link></li>
        <li><router-link class="link" to="/roster/recipes">Ремесло</router-link></li>
      </ul>
    </li>
    <li>
      <router-link class="link" to="/raids">Рейди</router-link>
      <ul class="menu sub d-none d-sm-flex">
        <li v-for="({ name, hint, link }, i) in postsByParent('raids')" :key="i">
          <router-link class="link" :to="link">
            <div>{{ name }}</div>
            <div v-if="hint" class="hint">{{ hint }}</div>
          </router-link>
        </li>
      </ul>
    </li>
    <li>
      <router-link class="link" to="/dungeons">Підземелля</router-link>
      <ul class="menu sub d-none d-sm-flex">
        <li v-for="({ name, hint, link }, i) in postsByParent('dungeons')" :key="i">
          <router-link class="link" :to="link">
            <div>{{ name }}</div>
            <div v-if="hint" class="hint">{{ hint }}</div>
          </router-link>
        </li>
      </ul>
    </li>
    <li><router-link class="link" to="/guides">Ґайди</router-link></li>
    <li><router-link class="link" to="/videos">Відео</router-link></li>
    <li><router-link class="link" to="/addons">Аддони</router-link></li>
  </ul>
</nav>
</template>

<script>
export default {
  methods: {
    postsByParent: function (parent) {
      return this.appPosts.filter(p => p.parent == parent)
    }
  }
}
</script>

<style scoped>

.app-navbar {
  position: sticky;
  top: 0;
  z-index: 20;
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: var(--navbar-min-height);
  background-color: var(--color-text);
}

.link {
  font: var(--font-text);
  font-size: 14px;
  line-height: 1;
  color: #fff;
  text-decoration: none;
  transition: .25s color ease-in-out;
}

.link .hint {
  padding-top: .2em;
  font-size: 80%;
  opacity: .65;
}

.menu.logo {
  line-height: 1;
  text-transform: uppercase;
}

.menu {
  display: flex;
  justify-content: center;
  padding: 0;
  list-style: none;
}

.menu li {
  position: relative;
  padding: 6px 10px;
}

.menu .link .v-icon {
  padding-right: 8px;
  font-size: 20px;
  color: #fff;
}

.menu.main .link {
  display: block;
  padding-bottom: .25em;
  border-bottom: 2px solid transparent;
  transition: .3s color ease-in-out, .3s border-bottom-color ease-in-out;
}

.menu.main .link.router-link-active {
  border-bottom-color: #fff;
}

.menu.sub {
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 1;
  padding: 10px 0;
  width: max-content;
  min-width: 160px;
  max-width: 280px;
  flex-direction: column;
  background-color: var(--color-text);
  transition: .2s opacity ease-in-out, .2s transform ease-in-out;
  pointer-events: none;
  transform: scale(.9);
  opacity: 0;
}

.menu.main > li:hover > .menu.sub {
  pointer-events: all;
  transform: scale(1);
  opacity: 1;
}

.menu.logo,
.mobile-home {
  display: none;
}

@media (min-width: 768px) {
  .link {
    font-size: 16px;
  }

  .menu li {
    padding: 10px 16px;
  }

  .menu.logo {
    display: flex;
  }
}

@media (max-width: 767px) {
  .app-navbar {
    justify-content: center;
  }

  .app-navbar .menu {
    flex-wrap: wrap;
  }

  .mobile-home {
    display: list-item;
  }
}

</style>
