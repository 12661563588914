export default {
  slug: 'remnant-of-nerzhul',
  parent: 'sanctum-of-domination',
  name: 'Remnant of Ner\'zhul',
  image: '/img/remnant-of-nerzhul.jpg',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Dancecaptain (Makhabunda)', 'Ta-na' ],
  attachments: [
    { type: 'video', videoId: 'OoH1U0v-tiI', name: 'Першокіл (героїк)' },
    { type: 'video', videoId: 'hjnTPnH4W2U', name: 'Відеотактика від Hazel' },
    { type: 'video', videoId: 'yOKzV29X9dU', name: 'Відеотактика від Limit' }
  ],
  content: `
    ## Normal

    [p][spell/78151/Героїзм] застосовуємо на 30%.[/p]

    [p]Під час бою бос створюватиме сфери які будуть наносити пошкодження по рейду проте самі захищені
    щитом, він знімається заклинанням боса __Suffering__, тому танкам потрібно ставити боса лицем до
    сфери.[/p]

    [p]Після зняття щита сферу можна підібрати і викинути з платформи, це краще робити неактивному танку
    або швидкому бійцю, поки гравець тримає сферу отримує пошкодження.
    [link/https://media.giphy.com/media/49RAli6iU5q4LdW6OC/giphy.gif/GIF]
    [/p]

    [p]Танки міняйтесь після кожного __Suffering__, слідкуйте щоб танк який має ефект __Suffering__ не
    потрапив під наступний промінь. Рейд має завжди уникати променя.[/p]

    [p]Гравці відмічені ефектом __Malevolence__ мають відійти на край платформи і стати Лицем до прірви,
    рейд повинен стати за спиною цього гравця. Після розсіювання рейд і гравець відлетять від платформи.
    [link/https://media.giphy.com/media/SvfADVhfwoyU4i8oFv/giphy.gif/GIF]
    [/p]

    [p]Уникайте всіх калюж і променів що бачите.
    [link/https://media.giphy.com/media/KSq3uY0l8gfAAZ8YOd/giphy.gif/GIF]
    [/p]

    [p]На 80%, 60% і 30% бос буде застосовувати __Shatter__, це закринання потрібно пережити за допомогою
    лікарів і персональних чи рейдових захисних здібностях. Також буде застосовувати __Aura of Spite__,
    уникайте.
    [link/https://media.giphy.com/media/BUKa8kvP0sWrxZ1kNo/giphy.gif/GIF]
    [/p]

    ## Heroic

    [p]В героїчній складності __Malevolence__ буде застосовуватись до двох гравців одразу, тому після
    розсіювання рейд і інший відмічений гравець повинні перейти на іншу сторону і повторити. Також після
    розсіювання __Malevolence__ на землі лишиться калюжа до кінця бою.[/p]

    ## Mythic

    [p]Рекомендується 5 хілів. Крім попередніх здібностей бос з кожним __Shatter__ буде скидати з себе
    частину одягу яка буде повторювати його заклинання.[/p]

    [p]Першим буде шолом 80% — повторюватиме __Suffering__. Від шолома до боса буде йти промінь. Його можна
    використовувати для зняття щитів зі сфер.[/p]

    [p]На 60% рукавиці — будуть повторювати одну лінію __Grasp of Malice__.[/p]

    [p]30% груди — повторить __Malevolence__, ви не можете взаємодіяти з цим об’єктом тому не вийде розсіяти,
    детонація відбудетсья після закінчення ефекту.[/p]
  `,
  aside: [
    {
      type: 'spell',
      icon: 'ability_argus_blightorb',
      name: 'Torment',
      namet: '(важливо)',
      desc: 'Вішає дот на декількох гравців, який завдає 3006 Тіньового пошкодження кожні 3 секунди впродовж 15 секунд.',
      whid: 350073
    },
    {
      type: 'spell',
      icon: 'spell_arcane_arcaneresilience',
      name: 'Eternal Torment',
      desc: 'Щит, який зменшує вхідну шкоду на 99% по сфері, ламається абілкою боса __Suffering__.',
      whid: 355790
    },
    {
      type: 'spell',
      icon: 'ability_deathknight_icygrip',
      name: 'Sorrowful Procession',
      desc: 'Коли обр знищений, він перетворюється у інтерактивний об\'єкт, який треба викинути з платформи гравцю. Коли гравець піднімає обру, він отримує 2104 Тіньового пошкодження кожні 3 секунди і зменшує швидкість руху на 30%.',
      whid: 350388
    },
    {
      type: 'spell',
      icon: 'spell_mage_infernoblast',
      name: 'Suffering',
      namet: '(до уваги танків)',
      desc: 'На 100% енергії бос кастує фронталку, яка завдає 15032 Тіньового пошкодження всім хто на лінії, а також збільшує пошкодження від наступного Suffering на 500% впродовж 30 секунд. Окрім цього, орби задіті Suffering втрачають свій щит.',
      whid: 349890
    },
    {
      type: 'spell',
      icon: 'achievement_boss_lichking',
      name: 'Shatter',
      desc: 'На 80%, 60% і 30% здоров`я на босі вибухає броня, осколки від якої завдають всьому рейду 12023 Тіньового пошкодження.',
      whid: 351066
    },
    {
      type: 'spell',
      icon: 'ability_warlock_eradication',
      name: 'Malevolence',
      namet: '(магічний ефект, смертельно)',
      desc: 'Бос вішає дебаф на гравця, який триває 21 секунду. Коли цей дебаф завершений, чи здіспельнутий, навколо гравця відбувається вибух, який завдає 4509 Тіньового пошкодження і відкидає всіх гравців від місця вибуху. В героїку вибух Malevolence утворює воїд-зони, які завдають 12417 Тіньового пошкодження кожну секунду, допоки гравець знаходиться у ній.',
      whid: 350469
    },
    {
      type: 'spell',
      icon: 'ability_bossdarkvindicator_auraofmalice',
      name: 'Grasp of Malice',
      desc: 'Бос заповнює частинки платформи воїд зонами, попадаючи у які гравець отримує 15029 Тіньового пошкодження і відкидає його назад.',
      whid: 350076
    },
    {
      type: 'spell',
      icon: 'ability_bossfelorcs_necromancer_red',
      name: 'Aura of Spite',
      desc: 'Аура боса, яка завдає 2402 Тіньового пошкодження всім гравцям кожні 3 секунди. Після 80% життя іноді аура здає абілку, яка завдає 12023 Тіньового пошкодження всім гравцям в 5 ярдах від боса та унеможливлює їхню можливість атакувати та кастувати впродовж 3 секунд.',
      whid: 350671
    },
  ]
}
