export default {
  slug: 'maut',
  parent: 'nyalotha',
  name: 'Маут',
  hint: 'Maut',
  group: 'Vision of Destiny',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Skye' ],
  content: `
    <ul>
      <li>бій має дві фази; фаза 2 наступає коли бос наберає 100% мани</li>
      <li>на 2й фазі бьємо щит боса, який рефлектить весь дамаг (потрібно інтенсивно захілювати)</li>
    </ul>

    <h3>Фаза 1</h3>

    <ul>
      <li>якщо на вас коло, відходимо недалеко, скидаємо</li>
      <li>на завершення касту Анігіляція всі мають бути в фіолетових колах</li>
      <li>адів не бьємо (ханти напулють на танків, щоб вони одразу зайшли в боса)</li>
    </ul>

    <h3>Фаза 2</h3>

    <ul>
      <li>танки ловлять блакитні орби</li>
      <li>діспелити фіолетові дебафи якомога швидше</li>
      <li>весь дамаг рефлектиться, тому якщо в вас меньше половини хп, чекайте поки захілять</li>
    </ul>
  `,
  attachments: [
    { type: 'video', videoId: 'YyERDYu3Ryw', name: 'Першокіл (міфік)' }
  ]
}
