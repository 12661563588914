export default {
  slug: 'court-of-stars',
  parent: 'dungeons',
  name: 'Зоряний Двір',
  hint: 'Court of Stars',
  image: '/img/court-of-stars.jpg',
  group: 'Легіон',
  meta: {
    description: 'Підземелля Зоряний Двір.',
    keywords: 'двір, курт оф старс, підземелля, данж, мітік, м+, тінеземлі, court, court of stars, dungeon, legion'
  },
  header: { parent: true },
  archive: true
}
