export default {
  slug: 'markup',
  parent: 'guides',
  name: 'Форматування статті',
  header: { parent: true },
  authors: [ 'Skye' ],
  content: `
    [p]Ви можете писати текст статті в [link/https://docs.google.com/Google Docs],
    який потім буде відформатований як описано в цій статті. Або ви можете одразу
    писати так, простим текстом, який потім перетвориться в веб сторінку.[/p]

    ## ФОРМАТУВАННЯ

    ### Заголовок

    [pre]
      ## Заголовок
      ### Підзаголовок
      #### Підпідзаголовок
    [/pre]

    ### Абзац

    [p]Блок тексту. Весь текст має складатися з цих блоків.[/p]

    [p]Приклади форматування тексту:
      **жирний**
      ~~нахилений~~
      __підкреслений__
      --перекреслений--
      [sup]степінь[/sup]
      [sub]індекс[/sub]
      [code]код[/code]
      [link/https://www.knaipa.info/сайт Кнайпи]
      перший рядок[br]другий рядок
    [/p]

    [pre]
      [p]Блок тексту. Весь текст має складатися з цих блоків.[/p]
      [p]Приклади форматування тексту:
        **жирний**
        ~~нахилений~~
        __підкреслений__
        --перекреслений--
        [sup]степінь[/sup]
        [sub]індекс[/sub]
        [code]код[/code]
        [link/https://www.knaipa.info/сайт Кнайпи]
        перший рядок[br]другий рядок
      [/p]
    [/pre]

    ### Список

    [ul]
      [li]Перший елемент[/li]
      [li]Другий елемент[/li]
    [/ul]

    [pre]
      [ul]
        [li]Перший елемент[/li]
        [li]Другий елемент[/li]
        ...
      [/ul]
    [/pre]

    ### Макрос

    [pre]
      [pre]
        будь-який текст чи список команд
        п.с.: всі блоки в цій статті зроблені через "pre"
      [/prе]
    [/pre]

    ### Іконка

    [p]Будь-яка іконка з сайту [link/https://materialdesignicons.com/Material Design Icons].
    Приклади: [icon/alert-box] Приклад один, [icon/alien] Приклад два, [icon/anchor] Приклад три.[/p]

    [pre]
      [icon/alert-box] Приклад один
      [icon/alien] Приклад два
      [icon/anchor] Приклад три
    [/pre]

    ### Зображення

    [p]Файли зображень потрібно надати окремо.
      [image/spires-of-ascension.jpg]
    [/p]

    [pre]
      [p]Приклад абзацу з зображенням в тексті
        [image/1.jpg]
      [/p]
    [/pre]

    ### Додаткові автозаміни

    [ul]
      [li]тире [code]раз -- два[/code] раз -- два[/li]
      [li]стрілка вправо [code]раз -> два[/code] раз -> два[/li]
      [li]жирна стрілка вправо [code]раз => два[/code] раз => два[/li]
      [li]довгі числа авто-форматуються нерозривним пробілом [code]12345[/code] 12345[/li]
      [li]уточнення, наприклад
        [code][приголомшений//Stun] гравець[/code]
        дасть "[приголомшений//Stun] гравець"
      [/li]
      [li]значення пошкодження, наприклад
        [code]Завдає [1234/fire] всім гравцям[/code]
        дасть "Завдає [1234/fire] всім гравцям". Зауважте, слово "пошкоджень" додається автоматично.
        Вид пошкоджень може бути "fire", "frost", "nature", "arcane", "holy", "shadow" або "physical".
      [/li]
    [/ul]

    ## РЕСУРСИ WOWHEAD

    [p]Ви можете зробити будь яке посилання на ресурс [link/https://www.wowhead.com/Wowhead]
    використовуючи просто [code][link/посилання/текст][/code], а можете використовувати наступні
    скорочення:[/p]

    [ul]
      [li]предмет [code][item/173032/Lost Sole][/code] [item/173032/Lost Sole][/li]
      [li]закляття [code][spell/32182/героїзм][/code] [spell/32182/героїзм][/li]
      [li]персонаж [code][npc/130/продавець програмістів][/code] [npc/130/продавець програмістів][/li]
      [li]завдання [code][quest/5101/тестовий квест Лі][/code] [quest/5101/тестовий квест Лі][/li]
      [li]локація [code][zone/12/Ельвиннський ліс][/code] [zone/12/Ельвиннський ліс][/li]
      [li]досягнення [code][achievement/6/десятий рівень!][/code] [achievement/6/десятий рівень!][/li]
      [li]об'єкт [code][object/32349/поштова скринька][/code] [object/32349/поштова скринька][/li]
      [li]риса професії [code][profession-trait/19538/трансмутація][/code] [profession-trait/19538/трансмутація][/li]
    [/ul]
  `
}
