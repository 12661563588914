export default {
  slug: 'home',
  content: `
    [p]
      Ми -- українська спільнота у World of Warcraft, граємо за **Альянс** на **європейському**
      сервері **Terokkar** у гільдії **Knaipa Variativ**. Спілкуємося нашою рідною українською мовою.
      Займаємося всім, що пропонує гра -- рейди, м+, рбг, арени, ачіви тощо. Ходять плітки, дехто навіть
      робить петбатли [icon/emoticon-happy-outline]
    [/p]

    [p]
      Нас багато і нас не подолати, про що свідчать наступні цифри:
    [/p]

    [ul]
      [li]у грі по вечорах онлайн 40+ гравців[/li]
      [li]в діскорді вечірній онлайн по 100+ вар'ятів[/li]
      [li]рейдовий прогрес різний, але зазвичай закриваємо героїк і по можливості декілька мітік босів[/li]
    [/ul]

    [p]
      Якщо ви маєте будь-які запитання або просто хочете подивитися на нас з боку -- долучайтеся до нашого
      діскорду. Якщо ви вже хочете до нас у гільдію -- напишіть у приват будь-кому з нас і вас запросять
      до гільдії. Щоб побачити хто з нас онлайн, напишіть у чат у грі:
    [/p]

    [pre]
      /who knaipa
    [/pre]

    [p]
      Щодо правил і вимог до вас, їх небагато: спілкування українською мовою і взаємоповага до інших вар'ятів.
      Ваш досвід і рівень гри, клас, спек, роль, як часто граєте, чи маєте мікрофон та інше -- все це не є
      обов'язковим. Ми хочемо та робимо (а вам судити, чи вдається) так, щоб усі могли назвати це ком'юніті
      "своїм".
    [/p]

    [p]
      Можливо, ви бажаєте подивитися та послухати нас перед тим, як узагалі кудись долучатися. Це також
      можливо, завітайте до розділу [nav/videos/Відео], там є посилання на канали вар'ятів що створюють відео
      чи транслюють гру наживо. Сподіваємося, вам сподобається!
    [/p]

    [p]
      З нами можна зв'язатись за допомогою #appDiscordLink, #appFacebookLink,
      чату #appBattlenetLink або в #appInstagramLink.
    [/p]

    ## Наші друзі
    [p]
      #appFriendsList
    [/p]

    ## Наш Discord
    [p]
      #appDiscordWidget
    [/p]
  `
}
