export default {
  slug: 'eranog',
  parent: 'vault-of-the-incarnates',
  name: 'Ераноґ',
  hint: 'Eranog',
  image: '/img/eranog.jpg',
  meta: {
    description: 'Тактика бою проти Ераноґа у Сховищі втілень.',
    keywords: 'ераноґ, ераног, тактика, стратегія, механіка, гайд, бос, сховище втілень, eranog, vault of the incarnates'
  },
  header: { parent: true },
  sidenav: true,
  authors: [ 'Semerkhet' ],
  content: `
    ## Загальна інформація

    [p]На 100 одиницях власного ресурсу [npc/184972/Ераноґ] викликає коло елементалів,
    що звужується, а ви не повинні торкнутися його. Потрібно вбити одного з них і вибігти
    в прохід. У разі контакту зі стіною, або мобом пошкодження є смертельним.[/p]

    [p]Розкладайте калюжі дебафу ближче до старих, можете складати одна в одну, якщо
    ви імунер.[/p]

    [p]Не відходьте далеко від боса -- чхає конусом з великим коефіцієнтом пошкодження
    в довільному напрямку кожні 30 секунд.[/p]

    [p][icon/skull] Міфік: 2 стіни елементалів за переходку. Спочатку пробийте зовнішню,
    потім внутрішню.[/p]

    ## Моби

    [p]Flamescale Tarasek -- моби, які з’являються на місці порталів полум’я від гравців.
    5 з них одночасно зосереджують свою увагу на випадковому гравцеві, поки не будуть вбиті.
    Дає 4 удари по 22 тис. кожні 1,5 с протягом 6 с. Фізична шкода. Контрольовані всіма
    доступними здібностями.[/p]

    [p][icon/skull] Героїчний/міфічний: Tarasek впадає в гнів нижче 50% HP. Прискорення +75%,
    шкода +50%[/p]

    [p][icon/skull] Міфік: Flamescale Captain -- З’являється з Great Flame Tank Portal.
    Одночасно з Tarasek кидає ваншоти які треба переривати (також можна спробувати
    Spell Reflect) Leaping flame - AoE на рейді, залишає ДоТ.[/p]

    ### ДЛЯ ТАНКІВ

    [ul]
      [li]Стаки боса - зміна на 8-10 (до спадіння).[/li]
      [li][icon/skull] Міфік: Віднесіть калюжу та танкуйте Flamescale Captain.[/li]
    [/ul]

    ### ДЛЯ ЦІЛИТЕЛІВ

    [ul]
      [li]Періодичний вогонь на рейді, кожні 30 секунд.[/li]
      [li]AoE б'є під час перехідної фази.[/li]
      [li]Увагу гравцям, які виносять калюжі. В ту ж мить може виникнути Roar або Сleave,
      які вбьють.[/li]
    [/ul]

    ### ДЛЯ ДПС

    [ul]
      [li]Пошкодження від початку бою.[/li]
      [li]Готуйте бурсти для перехідної фази.[/li]
      [li]Контролюйте мобів.[/li]
      [li][icon/skull] Міфік: перервати Flamescale Captain.[/li]
    [/ul]

    ### ЛАЙФГАКИ

    [ul]
      [li]Blink діє крізь стіну елементалів.[/li]
      [li]У зоні ближнього бою боса легше уникнути кліву.[/li]
    [/ul]

    [p][image/eranog-1.jpg][/p]
  `,
  aside: [
    {
      type: 'image',
      src: '/img/eranog.jpg'
    },
    {
      type: 'header',
      name: 'Ераноґ'
    },
    {
      type: 'spell',
      whid: 390715,
      icon: 'inv_misc_head_dragon_01',
      name: 'Flamerift',
      desc: `
        Кожні 30 секунд бос випадковим чином обирає 5 гравців (3rdd + 2mdd). Ці гравці
        залишать калюжу через 7 секунд. Моби Flamescale Tarasek будуть з'являтися там,
        де падають калюжі.[br]
        [br]
        [icon/skull] Міфік: на додаток до 5 стандартних негативних ефектів, накладає
        негативний ефект на танка, який згодом породжує Flamescale Captain. Завдання
        рейдерів - перервати заклинання Flamescale Captain.
      `
    },
    {
      type: 'spell',
      whid: 396023,
      icon: 'ability_warrior_dragonroar',
      name: 'Incinerating Roar',
      desc: 'AoE, на гравцях сткається дебаф.'
    },
    {
      type: 'spell',
      whid: 394904,
      icon: 'spell_shaman_improvelavalash',
      name: 'Burning Wound',
      desc: 'Автоматичні атаки боса накладають негативний ефект на танка.'
    },
    {
      type: 'spell',
      whid: 396022,
      icon: 'ability_fomor_boss_pillar02',
      name: 'Molten Spikes',
      desc: `
        Шипи з підлоги. Вони залишаються до тих пір, поки бос не зламає їх ревом.
        Перетворюються в тріщини.
      `
    },
    {
      type: 'spell',
      whid: 370615,
      icon: 'ability_rhyolith_magmaflow_wave',
      name: 'Molten Cleave',
      desc: `
        Сильний клів у випадковому напрямку. Залишає негативний ефект та калюжу вогню
        під ураженими гравцями. З кожним кастом збільшується область дії вогню на рейді.
      `
    },
  ]
}
