export default {
  slug: 'lady-inerva-darkvein',
  parent: 'castle-nathria',
  name: 'Lady Inerva Darkvein',
  group: 'The Leeching Vaults',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Vorren' ],
  content: `
    <ul>
      <li>Поле бою — балкон з 4 контейнерами аніми</li>
      <li>Специфіка босу — посилення абілок босу через контейнери аніми</li>
      <li>TW/Героїзм — на початку, поки нам не треба слідкувати за посиленими абілками боса</li>
    </ul>

    <h2>Контейнери аніми</h2>
    <p>На балконі будуть 4 контейнери, які постійно наповнюються, 1 контейнер заповнюється швидко, після заповнення одного вона
    починає пришвидшувати другий і так далі. З контейнерами можна інтерактувати. Це їх очищує, невеликий дамаг по рейду,
    і вони знов починають заповнюватись. Через певний час, з контейнером не можна інтерактувати.</p>

    <h2>Тактика</h2>

    <p>Протягом всього бою, ми будемо мати справу з 4 абілками боса та їх посиленою версією. Кожен контейнер відповідає
    за певну абілку боса. Як тільки 1 контейнер заповнюється на 1/3 ми отримаємо 2 рівень відповідної абілки, 2/3 3 рівень.
    Як тільки 1 контейнер заповнився повністю 1 людина інтерактує з ним щоб нас очистити. Контейнери заповнюються по-порядку.
    Тому відповідно далі тактики на кожну абілку.</p>

    <h3>Warped Desires</h3>
    <p>Свапаються танки на 2 стаках, на другому рівні просто фокусити хіл на тих в кого дебаф, на третьому — танк після свапу
    та люди з дебафом тікають до краю балкону щоб понизити вибух по рейду.</p>

    <h3>Bottled Anima</h3>
    <p>Проста абілка, ренжі просто ловлять пляшки, на другому рівні швидко вибігають як тільки зловили, на третьому — ловлять двічі.</p>

    <h3>Lesser Sins and Suffering</h3>
    <p>Найскладніша абілка. Люди між якими лазер мають об'єднати орби своїм лазером, наприклад:<br>
    [image/lady-inerva-darkvein-1.jpg]<br>
    На другому рівні між лазером орб ніхто не має пробігати, на третьому всім треба буде ухилятись від лазеру.</p>

    <h3>Unleashed Shadow</h3>
    <p>На кому дебаф мають підійти якомога ближче до боса не стоячи у колах інших, інші гравці теж мають з них виходити.
    Як тільки спавняться ади — великого танкує офтанк (будуть свапи між босом та адом), двох маленьких треба буде
    інтераптити постійно, мдд інтераптять одного, рдд іншого. На другому рівні, на людей з дебафом треба буде вішати
    <em>Blessing of Freedom</em>, або вони будуть використовувати персональні здібності для того щоб зняти корні.
    На третьому після того як ади спавняться — всі мають ухилитись від червоних хвиль, пропоную мдд трошки відбігати
    щоб було місце.</p>
  `,
  aside: [
    {
      type: 'spell',
      icon: 'ui_venthyranimaboss_heartsword',
      name: 'Warped Desires',
      desc: `Дебаф на танка, свап на 2 стаках.<ul>
        <li>2й рівень — маленька форма дебафу копіюється на інших</li>
        <li>3й рівень — після скінчення дебафу, гравець вибухає по рейду</li>
      </ul>`,
      whid: 325382
    },
    {
      type: 'spell',
      icon: 'ui_venthyranimaboss_bottle',
      name: 'Bottled Anima',
      desc: `Кидає пляшки з анімою (велике червоне коло) які б'ють по рейду якщо їх не зловити.<ul>
        <li>2й рівень — банки при приземлені залишають калюжу</li>
        <li>3й рівень — банка стрибає ще раз після того як її зловив</li>
      </ul>`,
      whid: 325769
    },
    {
      type: 'spell',
      icon: 'ui_venthyranimaboss_orblines',
      name: 'Lesser Sins and Suffering',
      desc: `Створює три орби які б'ють по рейду, і одночасно вішає на трьох гравців дебаф, який створює між ними лазер.<ul>
        <li>2й рівень — між орбами буде лазер, який при контакті вішає болючий дебаф</li>
        <li>3й рівень — лазери з орб будуть крутитися</li>
      </ul>`,
      whid: 342287
    },
    {
      type: 'spell',
      icon: 'inv_chaos_orb',
      name: 'Unleashed Shadow',
      desc: `На трьох гравцях буде велике червоне коло і жовта стрілка, через певний час вони на своїй позиції створять адів (1 великого і 2 маленьких).<ul>
        <li>2й рівень — на гравцях будуть корні, які не дозволять їм рухатись</li>
        <li>3й рівень — після того як з'являться ади, від них будуть червоні хвилі, які боляче б'ють по гравцям</li>
      </ul>`,
      whid: 341590
    }
  ],
  attachments: [
    { type: 'video', videoId: 'Sa1_I4OH2P8', name: 'Першокіл (героїк)' }
  ]
}
