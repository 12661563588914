export default {
  slug: 'spires-of-ascension',
  parent: 'dungeons',
  name: 'Шпилі вознесіння',
  hint: 'Spires of Ascension',
  image: '/img/spires-of-ascension.jpg',
  group: 'Тінеземлі',
  meta: {
    description: 'Підземелля Криваві глибини.',
    keywords: 'шпилі, вознесіння, спаерс, спаєрс, асеншин, есеншин, соа, підземелля, данж, мітік, м+, тінеземлі, spires, ascension, soa, dungeon, shadowlands'
  },
  header: { parent: true },
  archive: true
}
