export default {
  slug: 'soazmi',
  parent: 'tazavesh',
  name: 'Со\'азмі',
  hint: 'So\'azmi',
  image: '/img/soazmi.jpg',
  group: { name: 'Двір', hint: 'The Courtyard' },
  meta: {
    description: 'Тактика бою проти Со\'азмі у Тазавеші.',
    keywords: 'со\'азмі, соазмі, тактика, стратегія, механіка, гайд, бос, тазавеш, підземелля, данж, мітік, м+, тінеземлі, so\'azmi, soazmi, tazavesh, market, dungeon, shadowlands'
  },
  header: { parent: true },
  sidenav: true,
  authors: [ 'Skye' ],
  content: `
    [p]Бій проти смертоносного вбивці [npc/175806/Со'азмі], який буде ділити арену на окремі частини
    й гравцям потрібно швидко орієнтуватися, використовуючи парні телепорти.[/p]

    ## Бій

    [p]На початку бою Со'азмі прикличе __телепорти__. Вони будуть хаотично рухатися по арені до кінця
    бою. Всього шість телепортів, у вигляді трьох пар, кожна має свою фігуру. Телепорт дозволяє гравцю
    миттєво переміститися до іншого парного телепорту. Телепорти критичні для проходження деяких
    механік бою. Гравець може швидко переміщатися між ними без обмежень.[/p]

    [p]Со'азмі буде __ділити__ арену бар'єром -- намагайтеся пильнувати за телепортами які доступні
    в вашому секторі. Коли бос створює __коло__, шукайте телепорт в середині кола та його пару. Інколи
    потрібно шукати пару до телепорту який перемістить вас у сектор до телепорту першої пари.[/p]

    [p]Застосування __подвійного прийому__ Со'азмі завжди починається з його миттєвого
    [spell/353219/шмигання] у інший сектор. У вас є 15 сек щоб двічі перервати дію боса, при чому,
    після кожного переривання він буде шмигати знову. Рекомендовано, на перше переривання
    не бігти по телепортах всім гравцям, нехай це зробить хтось один, тому що бос потім шмигне
    у інший сектор, можливо він буде вашим.[/p]

    [p]Намагайтеся бути неподалік від боса, щоб зайвий раз не блукати по телепортах у випадку
    __поділу__ арени.[/p]

    [p][image/soazmi-2.jpg][/p]
  `,
  aside: [
    {
      type: 'image',
      src: '/img/soazmi.jpg'
    },
    {
      type: 'header',
      name: 'Со\'азмі'
    },
    {
      type: 'spell',
      whid: 347392,
      icon: 'spell_nature_massteleport',
      name: 'Deploy Relocators',
      namet: 'телепорти',
      desc: `
        Со'азмі прикликає пари телепортів щоб забаламутити противників. Коли гравець торкається
        телепорту, він миттєво переміщується до іншого парного телепорту.
        [br][br]
        Три пари телепортів, де кожна має свою фігуру.
        [br][br]
        [image/soazmi-1.jpg]
      `
    },
    {
      type: 'spell',
      whid: 347249,
      icon: 'inv_netherportal',
      audio: 'https://wow.zamimg.com/sound-ids/live/enus/255/4066303/VO_91_Soazmi_208335.ogg',
      name: 'Divide',
      namet: 'поділ',
      desc: `
        Со'азмі ділить арену бар'єром, який не дозволяє гравцям проходити крізь нього та
        застосовувати здібності на цілі по іншу сторону бар'єру.
        [br][br]
        Со'азмі застосовує цю здібність два рази: на 70% і 40% його здоров'я.
      `
    },
    {
      type: 'spell',
      whid: 347610,
      icon: 'ability_socererking_forcenova',
      audio: 'https://wow.zamimg.com/sound-ids/live/enus/11/4066315/VO_91_Soazmi_208353.ogg',
      name: 'Shuri',
      namet: 'коло',
      desc: `
        Со'азмі створює коло арканічної енергії, яке розширюється та завдає [8228/arcane]
        щосекунди протягом 10 сек гравцям на своєму шляху.
      `
    },
    {
      type: 'spell',
      whid: 357188,
      icon: 'ability_dualwieldspecialization',
      audio: 'https://wow.zamimg.com/sound-ids/live/enus/5/4066309/VO_91_Soazmi_208360.ogg',
      name: 'Double Technique',
      namet: 'подвійний прийом',
      desc: `
        Со'азмі застосовує свій секретний подвійний прийом. По завершенню, він щосили кидає
        палаючі леза в усіх гравців, завдаючи [16467/fire] щосекунди протягом 10 сек.
        [br][br]
        Щоб зупинити Со'азмі, цю здібність потрібно переривати два рази.
      `
    },
    {
      type: 'spell',
      whid: 347623,
      icon: 'ability_rogue_fanofknives',
      name: 'Quickblade',
      desc: `
        Со'азмі швидко проходить крізь гравця, завдаючи всім гравцям на шляху [1646/physical]
        та спричиняє кровотечу на [1646/physical] шосекунди на 10 сек.
      `
    }
  ]
}
