export default {
  slug: 'guides',
  name: 'Ґайди',
  meta: {
    description: 'Ґайди по Воркрафту.',
    keywords: 'ґайди, ґайд, статті, стаття, опис, механіка, тактика, хитрість, хитрості, підказка, лайфхак, проходження, прокачка, розвиток, левелінг, боси, данжі, професії, мітік, воркрафт, варкрафт, вов, тінеземлі, guides, wow, shadowlands'
  },
  header: true,
  archive: true
}
