export default {
  slug: 'wrathion',
  parent: 'nyalotha',
  name: 'Вратіон',
  hint: 'Wrathion',
  group: 'Vision of Destiny',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Skye' ],
  content: `
    <ul>
      <li>три вар'ята заходять в червоні кола на фазі шпилів</li>
      <li>танки міняються на 1-2 стаках</li>
      <li>героїзм з пула</li>
    </ul>

    <h3>Фаза 1</h3>

    <ul>
      <li>ніхто не стоїть перед лицем боса або позаду боса</li>
      <li>з червоним колом під ногами вибігаємо з рейду</li>
      <li>з великого кола під босом вибігаємо</li>
    </ul>

    <h3>Переходка</h3>

    <p>Слідкуємо куди летить голова боса і біжемо на протилежний край арени.</p>

    <h3>Фаза 2</h3>

    <ul>
      <li>три вар'ята мають зайти в червоні кола біля боса</li>
      <li>біжите по шпилях, стараєтеся зачепити найдальші від центру арени</li>
      <li>танки збирають адів і підводять до шпилів (ханти й роги їм допомагають напулом)</li>
      <li>всі вбивають шпилі й адів</li>
    </ul>
  `,
  attachments: [
    { type: 'video', videoId: '7TYTI1dNOQ0', name: 'Першокіл (міфік)' }
  ]
}
