export default {
  slug: 'tredova',
  parent: 'mists-of-tirna-scithe',
  name: 'Tred\'ova',
  header: { parent: true },
  sidenav: true,
  authors: [ 'Ta-na', 'Dancecaptain (Makhabunda)' ],
  content: `
    <h2>Отже</h2>
    <p>Дати геру і бурсти з пула розосередитися навколо боса щоб легше уникати <em>Acid Expulsion</em>.
    На 70% і 40% бос закриє себе коконом <em>Gorging Shield</em> і почне аоешити — <em>Consumption</em>,
    тож треба пробити щит і збити каст.</p>

    <p><em>Mind Link</em> — спел що поєднує гравців, тому вам потрібно розбігтись аби розірвати пута,
    як варіант гравець в котрого кастується спел одразу біжить по далі тоді всі пута будуть розірвані.</p>

    <p>Бос буде прикликати адів — <em>Accelerated Incubation</em>, вони фіксують на своїй цілі,
    тому потрібно звести разом і за аоешити. Їх можна контролити, після смерті залишають калюжу
    тому не вбивати під босом щоб протидіяти — <em>Gorging Shield</em> + <em>Consumption</em>,
    під час дії цих спелів бос йде в центр.</p>

    <p>І на останок, є три касти що можна і потрібно збивати це — <em>Parasitic Domination</em>,
    <em>Parasitic Incapacitation</em> і <em>Parasitic Pacification</em>; не здавайте інтерапти одночасно,
    розподіліть їх.</p>
  `,
  aside: [
    {
      type: 'spell',
      icon: 'ability_pet_baneling',
      name: 'Parasitic Domination',
      desc: 'Кидає в гравця паразита, контролить гравця і робить щит на 50% від їх максимального хп. Зламайте щит, вбийте паразита. Можна і треба інтераптити.',
      whid: 337253
    },
    {
      type: 'spell',
      icon: 'ability_pet_baneling',
      name: 'Parasitic Incapacitation',
      desc: 'Кидає паразита в гравця, наносить 2 929 шкоди від природи кожну секунду і станить доки паразит не помре. Можна інтераптити.',
      whid: 337251
    },
    {
      type: 'spell',
      icon: 'ability_pet_baneling',
      name: 'Parasitic Pacification',
      desc: 'Кидає паразита в гравця, наносить 2 929 шкоди від природи кожну секунду і станить доки паразит не помре. Можна інтераптити.',
      whid: 337220
    },
    {
      type: 'spell',
      icon: 'inv_larva2_darkpurple',
      name: 'Accelerated Incubation',
      desc: 'Бос швидко інкубує яйця, прикликає 4 <em>Gormling Larva</em>.',
      whid: 322550
    },
    {
      type: 'spell',
      icon: 'ability_creature_poison_02',
      name: 'Acid Expulsion',
      desc: 'Tred’ova плює кислотою поблизу гравців, завдаючи 9 377 шкоди будь-якому гравцеві на відстані 4 ярдів. З часом Tred’ova намагатиметься передбачити, куди рухатимуться її вороги і стрілятиме кислотою в ці місця.',
      whid: 322654
    },
    {
      type: 'spell',
      icon: 'spell_animaardenweald_missile',
      name: 'Anima Shedding',
      desc: 'Tred’ova випускає згусток аніми в рандомну локацію поряд, наносячи 8 791 шкоди від природи всім гравцям в радіусі 8 ярдів від точки падіння.',
      whid: 326281
    },
    {
      type: 'spell',
      icon: 'ability_hunter_pet_spider',
      name: 'Consumption',
      desc: 'Tred’ova руйнує <em>Cocoon of Lakali</em> звільняючи аніму що була в ньому, наносячи 2 341 шкоди від природи кожні 2 секунди всім ворогам протягом 14 секунд. Поки діє ця абілка Tred’ova запускає аніму в рандомну локацію навколо гравців. Можна інтераптити.',
      whid: 322450
    },
    {
      type: 'spell',
      icon: 'ability_mage_shattershield',
      name: 'Gorging Shield',
      desc: 'Tred’ova піднімає щит, поглинає 45 734 шкоди. Поки щит активний, <em>Consumption</em> не можна інтераптити.',
      whid: 322527
    },
    {
      type: 'spell',
      icon: 'ability_fixated_state_red',
      name: 'Marked Prey',
      desc: 'Tred’ova маркує рандомного гравця щоб <em>Gormling Larva</em> переслідувала протягом 20 секунд.',
      whid: 322563
    },
    {
      type: 'spell',
      icon: 'spell_animaardenweald_debuff',
      name: 'Mind Link',
      desc: 'Окутує путами гравців, наносячи 1 463 шкоди від темної магії кожну секунду. Вся шкода дублюється в головного гравця (в кого йшов каст). Рухаючись понад 40 ярдів від гравців ви звільняєтесь від пут.',
      whid: 322614
    }
  ],
  attachments: [
    { type: 'video', videoId: 'H7rr8aTKDk0', name: 'Аналіз М+20 від Денса (2й сезон)' },
    { type: 'video', videoId: 'X-WtKtC7lMs', name: 'Аналіз М+16 від Денса' },
    { type: 'post', link: '/guides/tormented-affix', name: '10+ афікс' },
  ]
}
