var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"app-navbar"},[_c('ul',{staticClass:"menu logo"},[_c('li',[_c('router-link',{staticClass:"link",attrs:{"to":"/"}},[_vm._v("Кнайпа"),_c('br'),_vm._v("Вар'ятів")])],1)]),_c('ul',{staticClass:"menu main"},[_c('li',{staticClass:"mobile-home"},[_c('router-link',{staticClass:"link",attrs:{"to":"/","exact":""}},[_vm._v("Головна")])],1),_c('li',[_c('router-link',{staticClass:"link",attrs:{"to":"/roster"}},[_vm._v("Вар'яти")]),_c('ul',{staticClass:"menu sub d-none d-sm-flex"},[_c('li',[_c('router-link',{staticClass:"link",attrs:{"to":"/roster","exact":""}},[_vm._v("Ростер")])],1),_c('li',[_c('router-link',{staticClass:"link",attrs:{"to":"/roster/rankings"}},[_vm._v("Лідери")])],1),_c('li',[_c('router-link',{staticClass:"link",attrs:{"to":"/roster/recipes"}},[_vm._v("Ремесло")])],1)])],1),_c('li',[_c('router-link',{staticClass:"link",attrs:{"to":"/raids"}},[_vm._v("Рейди")]),_c('ul',{staticClass:"menu sub d-none d-sm-flex"},_vm._l((_vm.postsByParent('raids')),function(ref,i){
var name = ref.name;
var hint = ref.hint;
var link = ref.link;
return _c('li',{key:i},[_c('router-link',{staticClass:"link",attrs:{"to":link}},[_c('div',[_vm._v(_vm._s(name))]),(hint)?_c('div',{staticClass:"hint"},[_vm._v(_vm._s(hint))]):_vm._e()])],1)}),0)],1),_c('li',[_c('router-link',{staticClass:"link",attrs:{"to":"/dungeons"}},[_vm._v("Підземелля")]),_c('ul',{staticClass:"menu sub d-none d-sm-flex"},_vm._l((_vm.postsByParent('dungeons')),function(ref,i){
var name = ref.name;
var hint = ref.hint;
var link = ref.link;
return _c('li',{key:i},[_c('router-link',{staticClass:"link",attrs:{"to":link}},[_c('div',[_vm._v(_vm._s(name))]),(hint)?_c('div',{staticClass:"hint"},[_vm._v(_vm._s(hint))]):_vm._e()])],1)}),0)],1),_c('li',[_c('router-link',{staticClass:"link",attrs:{"to":"/guides"}},[_vm._v("Ґайди")])],1),_c('li',[_c('router-link',{staticClass:"link",attrs:{"to":"/videos"}},[_vm._v("Відео")])],1),_c('li',[_c('router-link',{staticClass:"link",attrs:{"to":"/addons"}},[_vm._v("Аддони")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }